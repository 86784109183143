.root {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  position: relative;
  min-width: 0;
}
.freeBox__uSye {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-weight: 500;
  font-size: var(--token-wZK1o5uz59Brqbf);
  min-width: 0;
}
.energyConsumptionItems {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.energyConsumptionItems > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  margin-top: calc(0px - var(--token-H3JPdTVdV));
  height: calc(100% + var(--token-H3JPdTVdV));
}
.energyConsumptionItems > :global(.__wab_flex-container) > *,
.energyConsumptionItems
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.energyConsumptionItems > :global(.__wab_flex-container) > picture > img,
.energyConsumptionItems
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-H3JPdTVdV);
}
.energyConsumptionItemsisEmpty {
  display: none;
}
.dynamicEnergyConsumptionListItem:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__rdD9Z {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__rdD9Z > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__rdD9Z > :global(.__wab_flex-container) > *,
.freeBox__rdD9Z > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rdD9Z > :global(.__wab_flex-container) > picture > img,
.freeBox__rdD9Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.addEnergyConsumption:global(.__wab_instance) {
  max-width: none;
  position: relative;
  width: auto;
  align-self: auto;
}
.svg__xi2T0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.addEnergyConsumptionButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qxkCh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__ffEga:global(.__wab_instance) {
  position: relative;
}
.svg__vIZu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__vNqDb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__sbTs6:global(.__wab_instance) {
  position: relative;
}
.svg__haEtr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__cmGmU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__hu6Uy:global(.__wab_instance) {
  position: relative;
}
.svg__a3AcT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__cQtxk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___73Py5:global(.__wab_instance) {
  position: relative;
}
.svg__x5LGw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___5RytY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__sc8Wc:global(.__wab_instance) {
  position: relative;
}
.svg__oQ0PO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__kDfgM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__eDvfB:global(.__wab_instance) {
  position: relative;
}
.svg__cZFk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__yt46Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__e1Qtd:global(.__wab_instance) {
  position: relative;
}
.svg___0PPAv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__esAmE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__cVcbl:global(.__wab_instance) {
  position: relative;
}
.svg__dpgJe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__ztAk3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__h5JdG:global(.__wab_instance) {
  position: relative;
}
.svg__g4K5J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__dAhKc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___0IJw:global(.__wab_instance) {
  position: relative;
}
.svg___8CdxN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__iNkX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___7TzMn:global(.__wab_instance) {
  position: relative;
}
.svg__n5CvY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___572Ts {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__r2QwK:global(.__wab_instance) {
  position: relative;
}
.svg__p54Yb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__uEa85 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___9N9Xd:global(.__wab_instance) {
  position: relative;
}
.svg__n6S01 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__x8Mg6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__dfzjx:global(.__wab_instance) {
  position: relative;
}
.svg__osE9A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__oaF5Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__sz8UC:global(.__wab_instance) {
  position: relative;
}
.svg__rjW3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___6VLEg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__s0QTk:global(.__wab_instance) {
  position: relative;
}
.svg__hdmjh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__jMsGa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__tFJu:global(.__wab_instance) {
  position: relative;
}
.svg__zvekY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__lw4Wh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__ngcUl:global(.__wab_instance) {
  position: relative;
}
.svg__qHynl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__do6W2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__mlQkj:global(.__wab_instance) {
  position: relative;
}
.svg__dkOSj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__hMzN4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__hKgfN:global(.__wab_instance) {
  position: relative;
}
.svg__yTexi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__atLih {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
