.root {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-hFcnMUlxM) var(--token-hFcnMUlxM) var(--token-8IFSbVVlb);
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-8IFSbVVlb);
}
@media (max-width: 480px) {
  .root {
    padding: 16px;
  }
}
.freeBox__hOvKi {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__hOvKi > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__hOvKi > :global(.__wab_flex-container) > *,
.freeBox__hOvKi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hOvKi > :global(.__wab_flex-container) > picture > img,
.freeBox__hOvKi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.svg__mV78 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.freeBox__tfC34 {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__tfC34 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__tfC34 > :global(.__wab_flex-container) > *,
.freeBox__tfC34 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tfC34 > :global(.__wab_flex-container) > picture > img,
.freeBox__tfC34
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.slotTargetTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.slider__niuJ5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__oxvxs {
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-top: 32px;
  min-width: 0;
  display: none;
}
.freeBox__oxvxs > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__oxvxs > :global(.__wab_flex-container) > *,
.freeBox__oxvxs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oxvxs > :global(.__wab_flex-container) > picture > img,
.freeBox__oxvxs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBoxisOpen__oxvxs017Hi {
  display: flex;
}
.freeBox__l72OT {
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  max-width: 100%;
  background: var(--token-EdVWKDB6CssMfRE);
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .freeBox__l72OT {
    display: none;
  }
}
.freeBox__kc0Nu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__kc0Nu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__kc0Nu > :global(.__wab_flex-container) > *,
.freeBox__kc0Nu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kc0Nu > :global(.__wab_flex-container) > picture > img,
.freeBox__kc0Nu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 8px;
}
.slotTargetDataInfoTitle {
  font-weight: 500;
}
.freeBox__gtPb3 {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.freeBox__gtPb3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__gtPb3 > :global(.__wab_flex-container) > *,
.freeBox__gtPb3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gtPb3 > :global(.__wab_flex-container) > picture > img,
.freeBox__gtPb3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (max-width: 480px) {
  .freeBox__gtPb3 > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .freeBox__gtPb3 > :global(.__wab_flex-container) > *,
  .freeBox__gtPb3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__gtPb3 > :global(.__wab_flex-container) > picture > img,
  .freeBox__gtPb3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 12px;
  }
}
.freeBox__kHcDa {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.freeBox__kHcDa > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox__kHcDa > :global(.__wab_flex-container) > *,
.freeBox__kHcDa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kHcDa > :global(.__wab_flex-container) > picture > img,
.freeBox__kHcDa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.freeBox___2Cmlm {
  display: flex;
  position: relative;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  width: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.freeBox__u8E0W {
  display: block;
  position: relative;
  width: 2px;
  height: 16px;
  max-width: 100%;
  background: var(--token-QYymIwPX-bo_uMH);
  flex-shrink: 0;
  border-radius: 100px;
}
.freeBox__nvbW {
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid var(--token-QYymIwPX-bo_uMH);
}
.text__y4MUa {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
  letter-spacing: 0.2px;
}
.freeBox__aj4DJ {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.freeBox__aj4DJ > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox__aj4DJ > :global(.__wab_flex-container) > *,
.freeBox__aj4DJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aj4DJ > :global(.__wab_flex-container) > picture > img,
.freeBox__aj4DJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.freeBox__dWvbe {
  display: block;
  position: relative;
  width: 1px;
  height: 16px;
  max-width: 100%;
  background: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
}
.text__uCslM {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
  letter-spacing: 0.2px;
}
.freeBox__zZ6 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: column;
  min-width: 0;
}
.freeBox__zZ6 > :global(.__wab_flex-container) {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__zZ6 > :global(.__wab_flex-container) > *,
.freeBox__zZ6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zZ6 > :global(.__wab_flex-container) > picture > img,
.freeBox__zZ6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.dataBarSegment__s7BeO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___2Pst6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__cldvC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__fdF6B:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
