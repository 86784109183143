.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  position: relative;
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-MZ_89NRuN) var(--token-cTBZAIcMp);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-nPzqyQpH_);
}
.roottype_eco {
  background: var(--token-yQ5PNjYizuW9slV);
}
.freeBox__jTqh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__jTqh > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-nPzqyQpH_));
  height: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__jTqh > :global(.__wab_flex-container) > *,
.freeBox__jTqh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jTqh > :global(.__wab_flex-container) > picture > img,
.freeBox__jTqh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-nPzqyQpH_);
}
.freeBox__lVy7P {
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
}
.slotTargetEyebrowText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
.slotTargetEyebrowTexttype_eco {
  color: var(--token-djHL9A1ISxE7ONr);
}
.freeBox__fNiWp {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__fNiWp > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__fNiWp > :global(.__wab_flex-container) > *,
.freeBox__fNiWp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fNiWp > :global(.__wab_flex-container) > picture > img,
.freeBox__fNiWp
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
.slotTargetNumber {
  font-size: var(--token-Jhhay0F31-lTY91);
  font-weight: 500;
  color: var(--token-IOs4wk1cx7qv8eT);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.slotTargetNumbertype_eco {
  color: var(--token-CXh3DsvYUDbb4NE);
}
.freeBox__wR9St {
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
}
.slotTargetAside {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetAsidetype_eco {
  color: var(--token-djHL9A1ISxE7ONr);
}
.svg__lgznL {
  position: relative;
  object-fit: cover;
  height: 32px;
  width: 32px;
  color: var(--token-og7KBFmjrPhZ3dc);
  flex-shrink: 0;
}
