.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  transition-property: transform;
  transition-duration: 0.5s;
  transform: none;
  min-width: 0;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.5s;
  padding: var(--token-3S5TLMpb5) var(--token-7dIrE6133);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-3S5TLMpb5);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    padding: 20px 40px;
  }
}
@media (max-width: 480px) {
  .root {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.backButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .backButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.backButtonshowAddressEditButton:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__eUzUo {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text {
  height: auto;
}
.svg__pWbKt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__tVlEx {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__tVlEx > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 48px);
  width: calc(100% + 48px);
}
.freeBox__tVlEx > :global(.__wab_flex-container) > *,
.freeBox__tVlEx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tVlEx > :global(.__wab_flex-container) > picture > img,
.freeBox__tVlEx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 48px;
}
@media (max-width: 480px) {
  .freeBox__tVlEx > :global(.__wab_flex-container) {
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__tVlEx > :global(.__wab_flex-container) > *,
  .freeBox__tVlEx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__tVlEx > :global(.__wab_flex-container) > picture > img,
  .freeBox__tVlEx
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.freeBox___1Hbfo {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___1Hbfo > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox___1Hbfo > :global(.__wab_flex-container) > *,
.freeBox___1Hbfo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1Hbfo > :global(.__wab_flex-container) > picture > img,
.freeBox___1Hbfo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
}
.freeBox__abcl7 {
  display: flex;
  flex-direction: column;
  width: auto;
}
@media (max-width: 480px) {
  .freeBox__abcl7 {
    width: auto;
  }
}
.freeBox__k1TXb {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
.slotTargetNutz {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.freeBox__kkUel {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
.freeBox__kkUel > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__kkUel > :global(.__wab_flex-container) > *,
.freeBox__kkUel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kkUel > :global(.__wab_flex-container) > picture > img,
.freeBox__kkUel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
.slotTargetAddress {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.button:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.buttonshowAddressEditButton:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.svg__c3Rza {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eYjw7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__qRSi {
  display: flex;
  flex-direction: row;
  height: auto;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.freeBox__qRSi > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__qRSi > :global(.__wab_flex-container) > *,
.freeBox__qRSi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qRSi > :global(.__wab_flex-container) > picture > img,
.freeBox__qRSi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 480px) {
  .freeBox__qRSi {
    width: auto;
  }
}
@media (max-width: 480px) {
  .freeBox__qRSi > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__qRSi > :global(.__wab_flex-container) > *,
  .freeBox__qRSi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qRSi > :global(.__wab_flex-container) > picture > img,
  .freeBox__qRSi
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.navHelpButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .navHelpButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .navHelpButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__iNsVl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__c3Yo2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navProfileDropdown:global(.__wab_instance):global(.__wab_instance) {
  position: relative;
}
