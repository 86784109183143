.root {
  display: inline-flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  padding-bottom: 40px;
  padding-left: 40px;
  position: relative;
  background: var(--token-nJQnmTwmF4SPdl6);
  padding-right: 40px;
  min-width: 320px;
  min-height: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    min-width: auto;
    max-width: none;
    width: 100%;
    height: auto;
    border-right-style: none;
    border-right-color: var(--token-EdVWKDB6CssMfRE);
    flex-direction: row;
    overflow: auto;
    padding: 16px 0px 16px 40px;
    border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .root {
    height: auto;
    display: flex;
    flex-direction: row;
    max-width: none;
    width: 100%;
    overflow: auto;
    border-right-color: var(--token-EdVWKDB6CssMfRE);
    border-right-style: none;
    min-width: 0;
    padding: 16px 0px 16px 24px;
    border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: row;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
.rootisHorizontal {
  width: 100%;
  height: auto;
  min-width: auto;
  max-width: none;
  border-right-style: none;
  border-right-color: var(--token-EdVWKDB6CssMfRE);
  background: var(--token-nJQnmTwmF4SPdl6);
  flex-direction: row;
  padding: 16px 0px 16px 40px;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.rootisHorizontal > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisHorizontal > :global(.__wab_flex-container) > *,
.rootisHorizontal > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootisHorizontal > :global(.__wab_flex-container) > picture > img,
.rootisHorizontal
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.freeBox__smHiH {
  display: flex;
  position: sticky;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__smHiH {
    position: relative;
    left: auto;
    top: auto;
  }
}
@media (max-width: 480px) {
  .freeBox__smHiH {
    position: relative;
    left: auto;
    top: auto;
  }
}
.freeBoxisHorizontal__smHiHzGkl1 {
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
}
.freeBox__mfxiL {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  overflow: auto;
  max-height: 100vh;
  padding-top: var(--token-3wiInXIIr);
  min-width: 0;
}
.freeBox__mfxiL > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__mfxiL > :global(.__wab_flex-container) > *,
.freeBox__mfxiL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mfxiL > :global(.__wab_flex-container) > picture > img,
.freeBox__mfxiL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mfxiL {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: none;
    flex-direction: row;
    overflow: visible;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mfxiL > :global(.__wab_flex-container) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mfxiL > :global(.__wab_flex-container) > *,
  .freeBox__mfxiL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mfxiL > :global(.__wab_flex-container) > picture > img,
  .freeBox__mfxiL
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__mfxiL {
    display: flex;
    flex-direction: row;
    max-height: none;
    overflow: visible;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__mfxiL > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__mfxiL > :global(.__wab_flex-container) > *,
  .freeBox__mfxiL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mfxiL > :global(.__wab_flex-container) > picture > img,
  .freeBox__mfxiL
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.freeBoxisHorizontal__mfxiLzGkl1 {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: none;
  flex-direction: row;
  padding: 0px;
}
.freeBoxisHorizontal__mfxiLzGkl1 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxisHorizontal__mfxiLzGkl1 > :global(.__wab_flex-container) > *,
.freeBoxisHorizontal__mfxiLzGkl1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxisHorizontal__mfxiLzGkl1
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxisHorizontal__mfxiLzGkl1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.freeBox__mhsP {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__mhsP > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-3wiInXIIr));
  height: calc(100% + var(--token-3wiInXIIr));
}
.freeBox__mhsP > :global(.__wab_flex-container) > *,
.freeBox__mhsP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mhsP > :global(.__wab_flex-container) > picture > img,
.freeBox__mhsP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-3wiInXIIr);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mhsP {
    width: auto;
    height: auto;
    max-width: none;
    flex-direction: row;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mhsP > :global(.__wab_flex-container) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 24px);
    width: calc(100% + 24px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mhsP > :global(.__wab_flex-container) > *,
  .freeBox__mhsP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mhsP > :global(.__wab_flex-container) > picture > img,
  .freeBox__mhsP
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 24px;
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__mhsP {
    display: flex;
    flex-direction: row;
    position: relative;
    top: auto;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__mhsP > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__mhsP > :global(.__wab_flex-container) > *,
  .freeBox__mhsP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mhsP > :global(.__wab_flex-container) > picture > img,
  .freeBox__mhsP
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 12px;
    margin-top: 0px;
  }
}
.freeBoxisHorizontal__mhsPzGkl1 {
  width: auto;
  height: auto;
  max-width: none;
  flex-direction: row;
  padding: 0px;
}
.freeBoxisHorizontal__mhsPzGkl1 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxisHorizontal__mhsPzGkl1 > :global(.__wab_flex-container) > *,
.freeBoxisHorizontal__mhsPzGkl1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxisHorizontal__mhsPzGkl1
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxisHorizontal__mhsPzGkl1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
  margin-top: 0px;
}
.scrollNavGroup___59TyA:global(.__wab_instance) {
  position: relative;
}
.button__yqSqo:global(.__wab_instance) {
  max-width: 100%;
}
.svg__icWwp {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___1Mbnk {
  height: auto;
}
.svg__pOAwG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__px1S4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__moZk {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___3Odg7 {
  height: auto;
}
.svg__mdnJx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__isrvN {
  display: block;
  position: relative;
  width: 100%;
  height: var(--token-j8nt-mARd);
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__isrvN {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .freeBox__isrvN {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
}
.freeBoxisHorizontal__isrvNzGkl1 {
  width: 20px;
  height: 20px;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__pSnr3 {
  display: block;
  position: relative;
  width: 100%;
  height: var(--token-j8nt-mARd);
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__pSnr3 {
    width: 1px;
    height: 20px;
    max-width: 100%;
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .freeBox__pSnr3 {
    width: 1px;
    height: 20px;
    flex-shrink: 0;
  }
}
.freeBoxisHorizontal__pSnr3ZGkl1 {
  width: 1px;
  height: 20px;
  max-width: 100%;
  flex-shrink: 0;
}
.rightScrollButton:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: auto;
  right: 0px;
  z-index: 1;
  height: 100%;
  min-height: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .rightScrollButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ir7Vm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
