.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 36px;
  align-items: center;
  justify-content: flex-start;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: sticky;
  box-shadow: inset 0px 0px 0px 1px var(--token-EdVWKDB6CssMfRE),
    0px 0px 0px 0px #2571eb03;
  transition-property: box-shadow, color;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  min-width: 0;
  -webkit-transition-property: box-shadow, color;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 6px;
  padding: 0px;
}
.rootshowStartIcon {
  padding-left: 8px;
}
.rootshowEndIcon {
  padding-right: 8px;
}
.rootisDisabled {
  background: var(--token-nJQnmTwmF4SPdl6);
  cursor: not-allowed;
}
.rootinputType_textArea {
  height: auto;
  min-height: 36px;
}
.rootinputSize_sm {
  height: 28px;
}
.rootinputSize_lg {
  height: 44px;
}
.rootinputSize_xl {
  height: 56px;
}
.rootstate_success {
  box-shadow: inset 0px 0px 0px 1px var(--token-djHL9A1ISxE7ONr),
    0px 0px 0px 0px #2571eb03;
  background: var(--token-yQ5PNjYizuW9slV);
}
.rootstate_warning {
  background: linear-gradient(var(--token-zn-VjztWD), var(--token-zn-VjztWD)),
    var(--token-NPNMQf7VB8u7Kel);
  box-shadow: inset 0px 0px 0px 1px var(--token-G449N6G1x),
    0px 0px 0px 0px #2571eb03;
}
.rootstate_error {
  box-shadow: inset 0px 0px 0px 1px var(--token-2N8zcUvyUXwuOJv),
    0px 0px 0px 0px #2571eb03;
  background: var(--token-Lymfn4M1YIjdOgD);
}
.root:hover {
  box-shadow: inset 0px 0px 0px 1px var(--token-sHkWBDcvLab64_p),
    0px 0px 0px 0px #2571eb03;
}
.root:focus-within {
  box-shadow: inset 0px 0px 0px 1px var(--token-PjtuKumWd61Gyyy),
    0px 0px 0px 4px #2571eb4d;
  outline: none;
}
.root___focusVisibleWithin {
  box-shadow: inset 0px 0px 0px 1px var(--token-PjtuKumWd61Gyyy),
    0px 0px 0px 3px #2571eb4d;
  outline: none;
}
.rootinputType_date:focus-within {
  outline: none;
}
.startIconContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-right: 8px;
  display: none;
}
.startIconContainershowStartIcon {
  display: flex;
}
.root .startIconContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetStartIcon {
  font-size: 16px;
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetStartIconstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.svg___2XG7L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.startAddon {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  border-right: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.startAddonhasAddon_start {
  display: flex;
}
.slotTargetStartAddonText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetStartAddonTextinputSize_sm {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
}
.slotTargetStartAddonTextstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.startCurrencty {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.startCurrenctystate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.startCurrenctyaddonType_currency {
  display: block;
}
.startPercent {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
  display: none;
}
.startPercentstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.startPercentaddonType_currency {
  display: none;
}
.startPercentaddonType_percent {
  display: block;
}
.startSquareMeter {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.startSquareMeterstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.startSquareMeteraddonType_squareMeter {
  display: block;
}
.span__m5BP {
  font-weight: 500;
}
.freeBox__cwmPs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  align-self: stretch;
  min-width: 0;
}
.freeBox__cwmPs > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__cwmPs > :global(.__wab_flex-container) > *,
.freeBox__cwmPs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cwmPs > :global(.__wab_flex-container) > picture > img,
.freeBox__cwmPs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBoxinputType_date__cwmPsSRPq {
  width: 100%;
  min-width: 0;
}
.freeBoxinputType_date__cwmPsSRPq > :global(.__wab_flex-container) {
  min-width: 0;
}
.root:focus-within .freeBox__cwmPs {
  outline: none;
}
.input {
  width: 100%;
  background: #ffffff00;
  font-size: 14px;
  line-height: 1.4;
  outline-style: none;
  min-width: 0;
  padding: var(--token-8IFSbVVlb) var(--token-uoDiu2hxc);
  border: 1px none var(--token-IOs4wk1cx7qv8eT);
}
.inputshowStartIcon {
  padding-left: 0px;
}
.inputshowEndIcon {
  padding-right: 0px;
}
.inputisDisabled {
  cursor: not-allowed;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.inputinputType_textArea {
  display: none;
}
.inputalignment_center {
  text-align: center;
}
.inputalignment_right {
  text-align: right;
}
.inputalignment_left {
  text-align: left;
}
.inputinputSize_sm {
  padding: 4px 8px;
}
.inputstate_error {
  color: var(--token-ZC2EQaLgStzAJRW);
}
.root .input___focusVisibleWithin {
  outline: none;
}
.root:focus-within .input {
  outline: none;
}
.root .input:focus {
  outline: none;
}
.root .input::placeholder {
  color: var(--token-5eiHu7b54PkQh5O);
}
.rootinputType_password .inputinputType_password:focus {
  outline: none;
}
.rootinputType_date .inputinputType_date:focus {
  outline: none;
}
.rootalignment_right .inputalignment_right::placeholder {
  text-align: right;
}
.rootstate_error .inputstate_error::placeholder {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.root:focus-within .input:focus {
  outline: none;
}
.root:focus-within .input::placeholder {
  outline: none;
}
.freeBox__l6Rad {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  min-width: 0;
  display: none;
  border-radius: 6px;
  padding: 8px 10px;
}
.freeBox__l6Rad > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.freeBox__l6Rad > :global(.__wab_flex-container) > *,
.freeBox__l6Rad > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__l6Rad > :global(.__wab_flex-container) > picture > img,
.freeBox__l6Rad
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2px;
}
.freeBox__cGMdv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___68Nm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__y7ZPj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.textarea {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  font-size: 14px;
  line-height: 1.4;
  margin-right: 2px;
  margin-bottom: 2px;
  min-width: 0;
  min-height: 0;
  padding: 8px 10px;
  border-width: 1px;
  border-style: none;
}
.textareainputType_textArea {
  align-self: stretch;
  display: block;
}
.textareaalignment_center {
  text-align: center;
}
.textareaalignment_right {
  text-align: right;
}
.textareaalignment_left {
  text-align: left;
}
.textareainputSize_xl_inputType_textArea {
  font-size: var(--token-FutXsu2VTAR30Ez);
  padding: 20px 24px;
}
.rootinputType_textArea:focus-within .textareainputType_textArea {
  outline: none;
}
.root .textarea::placeholder {
  color: var(--token-5eiHu7b54PkQh5O);
}
.endAddon {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  padding-right: 8px;
  padding-left: 8px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 1px;
  display: none;
  border-left: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.endAddonhasAddon_end {
  display: flex;
}
.slotTargetEndAddonText {
  white-space: pre;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.slotTargetEndAddonTextstate_error {
  color: var(--token-ZC2EQaLgStzAJRW);
}
.endCurrency {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.endCurrencystate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.endCurrencyaddonType_currency {
  display: block;
}
.endPercent {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
  display: none;
}
.endPercentstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.endPercentaddonType_currency {
  display: none;
}
.endPercentaddonType_percent {
  display: block;
}
.endSquareMeter {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.endSquareMeterstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.endSquareMeteraddonType_squareMeter {
  display: block;
}
.span__lv5Ug {
  font-weight: 500;
}
.endCubicMeter {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.endCubicMeterstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.endCubicMeteraddonType_cubicMeter {
  display: block;
}
.span__poWcE {
  font-weight: 500;
}
.endIconContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-left: 8px;
  display: none;
}
.endIconContainershowEndIcon {
  display: flex;
}
.slotTargetEndIcon {
  font-size: 16px;
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetEndIconstate_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.svg___0Ljxz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
