.root {
  display: flex;
  position: relative;
  flex-direction: column;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.freeBox__hrnGv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.slotTargetTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
.barContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: auto;
  min-width: 0;
}
.segmentsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 16px;
  max-width: 100%;
  min-width: 200px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 4px;
}
.segment1 {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-gUr8y4ZPkdFjJ7H);
  min-height: 0;
}
.segment2 {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-zzo8jryirB3foEj);
  min-height: 0;
}
.median {
  display: block;
  position: relative;
  height: 100%;
  width: 1px;
  background: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
  min-height: 0;
}
.segment3 {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-bYBIsaiSK_SL0wu);
  min-height: 0;
}
.segment4 {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-HYIomnc-tSnfgIb);
  min-height: 0;
}
.shadow {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0px -2px 2px -1px #0000000a;
}
.freeBox__rqlYh {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: -4px;
  top: auto;
  width: 100%;
  height: 0px;
  right: 4px;
  bottom: auto;
  min-width: 0;
  flex-shrink: 0;
}
.indicator {
  display: flex;
  position: absolute;
  height: auto;
  max-width: 100%;
  left: 40%;
  top: auto;
  width: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.freeBox___9Zput {
  display: block;
  position: relative;
  width: 2px;
  height: 24px;
  max-width: 100%;
  background: var(--token-QYymIwPX-bo_uMH);
  flex-shrink: 0;
  border-radius: 100px;
}
.freeBox__zajh5 {
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid var(--token-QYymIwPX-bo_uMH);
}
