.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2px;
  max-width: 100%;
  background: var(--token-sHkWBDcvLab64_p);
  position: relative;
  min-width: 0;
  border-radius: 6px;
}
.rootfilled {
  background: var(--token-dw-C2qwAAM28VJ9);
  height: 4px;
}
.rootcolor_gold {
  background: var(--token-cb_Nd6iAoCFyn8W);
}
.rootfilled_color_gray {
  background: var(--token-sHkWBDcvLab64_p);
}
