.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 6px;
}
.rootisShownInitially {
  display: flex;
}
.rootisHidden {
  display: none;
}
.freeBox__mxmKt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  padding-left: 2px;
  align-self: stretch;
  min-width: 0;
}
.freeBox__mxmKt > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__mxmKt > :global(.__wab_flex-container) > *,
.freeBox__mxmKt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mxmKt > :global(.__wab_flex-container) > picture > img,
.freeBox__mxmKt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.freeBoxhasNoLabel__mxmKtUlccC {
  display: none;
}
.slotTargetLabel {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.slotTargetLabelisRequired {
  color: var(--token-Hken4BJDt);
}
.slotTargetLabelSecondary {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  font-weight: 400;
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.text {
  position: relative;
  font-size: var(--token-wZK1o5uz59Brqbf);
  line-height: var(--token-f2GFyMCUtiJjuKU);
  color: var(--token-mOJuRJhlC);
}
.textisRequired {
  display: block;
}
.link {
  position: relative;
  display: flex;
}
.linkhasHelperLink {
  display: flex;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  height: 1em;
}
.freeBox__cSavG {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.freeBox__cSavG > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__cSavG > :global(.__wab_flex-container) > *,
.freeBox__cSavG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cSavG > :global(.__wab_flex-container) > picture > img,
.freeBox__cSavG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBoxhasPrefixLabel__cSavGdlOnQ {
  padding: 0px;
}
.freeBoxhasPrefixLabel__cSavGdlOnQ > :global(.__wab_flex-container) {
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasPrefixLabel__cSavGdlOnQ > :global(.__wab_flex-container) > *,
.freeBoxhasPrefixLabel__cSavGdlOnQ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasPrefixLabel__cSavGdlOnQ
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxhasPrefixLabel__cSavGdlOnQ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB {
  display: flex;
  flex-direction: column;
}
.freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
  > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
  > :global(.__wab_flex-container)
  > *,
.freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: var(--token-8IFSbVVlb);
}
@media (max-width: 480px) {
  .freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
    > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
    > :global(.__wab_flex-container)
    > *,
  .freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
    > :global(.__wab_flex-container)
    > picture
    > img,
  .freeBoxisStacked_hasAlgoButton__cSavGiYahEpgpB
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.slotTargetPrefixLabel {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.freeBox__n41Th {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBoxisStacked__n41ThIYah {
  width: 100%;
  min-width: 0;
}
.freeBoxisStacked_hasAlgoButton__n41ThIYahEpgpB {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBoxisStacked_hasAlgoButton__n41ThIYahEpgpB {
    width: 100%;
    min-width: 0;
  }
}
.textInput__qUo78:global(.__wab_instance) {
  position: relative;
}
.svg__g5XE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cXblt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ah0Ta {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___9KbD8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__tr7Xv {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  display: none;
}
.freeBoxhasAlgoButton__tr7XVepgpB {
  max-width: 100%;
  overflow: hidden;
  min-width: 30%;
  display: flex;
}
.freeBoxisStacked_hasAlgoButton__tr7XviYahEpgpB {
  width: 100%;
  min-width: 100%;
}
@media (max-width: 480px) {
  .freeBoxisStacked_hasAlgoButton__tr7XviYahEpgpB {
    width: 100%;
    min-width: 0;
  }
}
.algoButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__ubCt1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 2px;
  justify-content: space-between;
  min-width: 0;
  display: none;
}
@media (max-width: 480px) {
  .freeBox__ubCt1 {
    justify-content: flex-end;
    align-items: center;
  }
}
.freeBoxhasHelperText__ubCt14EHqm {
  display: flex;
}
@media (max-width: 480px) {
  .freeBoxhasHelperText__ubCt14EHqm {
    justify-content: flex-start;
    align-items: center;
  }
}
.freeBoxhasSymbolCounter__ubCt1Dl3Me {
  display: flex;
}
.slotTargetHelperText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.symbolCounter__bGKj:global(.__wab_instance) {
  position: relative;
}
.stateTextWrapper {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 2px;
  min-width: 0;
}
.stateTextWrapperstate_success {
  display: flex;
}
.stateTextWrapperstate_warning {
  display: flex;
}
.stateTextWrapperstate_error {
  display: flex;
}
.slotTargetStateText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.slotTargetStateTextstate_success {
  color: var(--token-djHL9A1ISxE7ONr);
}
.slotTargetStateTextstate_warning {
  color: var(--token-G449N6G1x);
}
.slotTargetStateTextstate_error {
  color: var(--token-2N8zcUvyUXwuOJv);
}
