.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
}
.button:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ynUvS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
