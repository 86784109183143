.root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  position: relative;
  height: auto;
  min-width: 0;
}
.freeBox___97GD {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox___97GD > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox___97GD > :global(.__wab_flex-container) > *,
.freeBox___97GD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___97GD > :global(.__wab_flex-container) > picture > img,
.freeBox___97GD
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.selectAllButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__t7HHi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__feXas {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.deselectAllButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__knRKm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qAmhZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.checkboxes {
  display: flex;
  position: relative;
  flex-direction: column;
  grid-column-end: span 6;
}
.checkboxes > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.checkboxes > :global(.__wab_flex-container) > *,
.checkboxes > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxes > :global(.__wab_flex-container) > picture > img,
.checkboxes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
.formGroup___1N2Kf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__vfPsM:global(.__wab_instance) {
  position: relative;
}
.formGroup__nLyl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__sIgEz:global(.__wab_instance) {
  position: relative;
}
.formGroup__d4Pqi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__pn5Kz:global(.__wab_instance) {
  position: relative;
}
.formGroup__akoQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter___8BUbV:global(.__wab_instance) {
  position: relative;
}
.formGroup__cc6UQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__iir3D:global(.__wab_instance) {
  position: relative;
}
.formGroup__yk7Gb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter___97Qzf:global(.__wab_instance) {
  position: relative;
}
.formGroup__yqGr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__lxGio:global(.__wab_instance) {
  position: relative;
}
.formGroup__jOm9W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox10:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__tsiGd:global(.__wab_instance) {
  position: relative;
}
.formGroup__zgrXf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox11:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter___5I4:global(.__wab_instance) {
  position: relative;
}
.formGroup__hmMtl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox12:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__mjNSk:global(.__wab_instance) {
  position: relative;
}
.formGroup__zaSrL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox13:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__qpMk4:global(.__wab_instance) {
  position: relative;
}
.formGroup__nIQnr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.checkbox14:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter___2Sirr:global(.__wab_instance) {
  position: relative;
}
.formGroup__zq30E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox15:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__mqg3Q:global(.__wab_instance) {
  position: relative;
}
.formGroup__qxQFx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox16:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter___8Gpxh:global(.__wab_instance) {
  position: relative;
}
.formGroup__vKpp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox17:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__dAmS:global(.__wab_instance) {
  position: relative;
}
.formGroupSeitenauswahlNachhaltigkeitsbeurteilung:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox22:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__pOwg6:global(.__wab_instance) {
  position: relative;
}
.formGroupSeitenauswahlComparables:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox18:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__jDlVj:global(.__wab_instance) {
  position: relative;
}
.formGroup__z2Y5Z:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox19:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__uYaYv:global(.__wab_instance) {
  position: relative;
}
.formGroup__xxoa0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox20:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__fg75Z:global(.__wab_instance) {
  position: relative;
}
.formGroup___0JDdt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.checkbox21:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__b94MI:global(.__wab_instance) {
  position: relative;
}
.freeBox__pzKc4 {
  position: relative;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  display: flex;
  grid-column-end: span 6;
}
.validationMessage:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
