.root {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  position: relative;
  min-width: 0;
}
.freeBox__x6AqT {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__iWcYq {
  display: flex;
  flex-direction: row;
  width: 120px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
}
.freeBox__kqUel {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.text___6ZY {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
}
.freeBox__aXjH8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 120px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__wFlal {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
}
.freeBox__coUa6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__hAhLz {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
}
.freeBox__es90 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 220px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-enYJIO6w3sEL0NO);
  border-top-left-radius: var(--token-MhDGai0bW);
  border-top-right-radius: var(--token-MhDGai0bW);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__jQiJc {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
}
.freeBox__nDTph {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 200px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text___8WJe {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
}
.freeBox__wdfp9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 140px;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__wdfp9 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__wdfp9 > :global(.__wab_flex-container) > *,
.freeBox__wdfp9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wdfp9 > :global(.__wab_flex-container) > picture > img,
.freeBox__wdfp9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__hr8G {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  text-align: center;
}
.freeBox___9LOx9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 280px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___9LOx9 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___9LOx9 > :global(.__wab_flex-container) > *,
.freeBox___9LOx9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9LOx9 > :global(.__wab_flex-container) > picture > img,
.freeBox___9LOx9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__ozeVl {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  text-align: center;
}
.freeBox__xz05C {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox__nmQ0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
  height: var(--token-0Rp89NVfd);
  flex-shrink: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBox__rhXoG {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 120px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__yHute {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__u0RSo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__ftRkk {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__m87Pt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 120px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text___0HoS {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__xs6Zj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__zlbX7 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  padding-right: 0px;
}
.freeBox___4M9L {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__vvDC {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__zJkc5 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__zJkc5 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__zJkc5 > :global(.__wab_flex-container) > *,
.freeBox__zJkc5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zJkc5 > :global(.__wab_flex-container) > picture > img,
.freeBox__zJkc5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__xEcog {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 140px;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__xEcog > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__xEcog > :global(.__wab_flex-container) > *,
.freeBox__xEcog > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xEcog > :global(.__wab_flex-container) > picture > img,
.freeBox__xEcog
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__lKxea {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__fKEup {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 140px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__fKEup > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__fKEup > :global(.__wab_flex-container) > *,
.freeBox__fKEup > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fKEup > :global(.__wab_flex-container) > picture > img,
.freeBox__fKEup
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__t4Joi {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__bgGi4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 140px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__bgGi4 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__bgGi4 > :global(.__wab_flex-container) > *,
.freeBox__bgGi4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bgGi4 > :global(.__wab_flex-container) > picture > img,
.freeBox__bgGi4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__yavOl {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-IgvLORwjhxLX5NA);
}
