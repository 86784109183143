.root {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--token-nJQnmTwmF4SPdl6);
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.processSubpageNav:global(.__wab_instance) {
  max-width: 100%;
}
.backButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sPka6 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__cKd3J {
  height: auto;
}
.svg__pIk2I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__zxWQk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__zxWQk {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__zxWQk {
    display: flex;
    flex-direction: column;
  }
}
.freeBox__xRxTg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1440px;
  min-width: 0;
}
.freeBox___61BsA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox___4AMvn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 72px 40px 88px;
}
.freeBox___4AMvn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.freeBox___4AMvn > :global(.__wab_flex-container) > *,
.freeBox___4AMvn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4AMvn > :global(.__wab_flex-container) > picture > img,
.freeBox___4AMvn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
@media (max-width: 480px) {
  .freeBox___4AMvn {
    padding: 0px 0px 72px;
  }
}
.processCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processCardSection__qI3CB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 100%;
  min-height: 0;
}
.freeBox__zAx3R {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__qRZox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__xKmgf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__xKmgf > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__xKmgf > :global(.__wab_flex-container) > *,
.freeBox__xKmgf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xKmgf > :global(.__wab_flex-container) > picture > img,
.freeBox__xKmgf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
.formGroup__uzTXd:global(.__wab_instance) {
  max-width: 100%;
}
.text___1HRzM {
  padding-right: 0px;
}
.singleSelectButtonGroup:global(.__wab_instance) {
  max-width: 100%;
}
.symbolCounter__qnT9J:global(.__wab_instance) {
  position: relative;
}
.processCardSection__g0K69:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__krNfh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__kstMq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__yUwvr {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  min-width: 0;
}
.freeBox___7ETdx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox__z3QZo {
  display: flex;
  flex-direction: row;
  width: 280px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
}
.freeBox__oW4Jp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBox__g7UTu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 300px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.text__r1Du8 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: center;
}
.freeBox__dz4Op {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 80px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__dz4Op > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__dz4Op > :global(.__wab_flex-container) > *,
.freeBox__dz4Op > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dz4Op > :global(.__wab_flex-container) > picture > img,
.freeBox__dz4Op
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__crWuc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 90px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__crWuc > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__crWuc > :global(.__wab_flex-container) > *,
.freeBox__crWuc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__crWuc > :global(.__wab_flex-container) > picture > img,
.freeBox__crWuc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__waGfH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__waGfH > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__waGfH > :global(.__wab_flex-container) > *,
.freeBox__waGfH > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__waGfH > :global(.__wab_flex-container) > picture > img,
.freeBox__waGfH
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__f5IfJ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__f5IfJ > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__f5IfJ > :global(.__wab_flex-container) > *,
.freeBox__f5IfJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__f5IfJ > :global(.__wab_flex-container) > picture > img,
.freeBox__f5IfJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__xyn63 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__xyn63 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__xyn63 > :global(.__wab_flex-container) > *,
.freeBox__xyn63 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xyn63 > :global(.__wab_flex-container) > picture > img,
.freeBox__xyn63
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__avUy4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox__prCrZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  flex-shrink: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBox__kwIpm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__w41Is {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  padding-right: 0px;
}
.divider__vfFja:global(.__wab_instance) {
  max-width: 100%;
  height: 25%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.freeBox__uapnT {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__w1MWf {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__sWnXb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text___3O5Y6 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.divider___1Vv97:global(.__wab_instance) {
  max-width: 100%;
  height: 25%;
  position: absolute;
  left: auto;
  top: 0px;
  right: 0px;
}
.freeBox__omQNb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__omQNb > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__omQNb > :global(.__wab_flex-container) > *,
.freeBox__omQNb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__omQNb > :global(.__wab_flex-container) > picture > img,
.freeBox__omQNb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox___1DQzl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 120px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___1DQzl > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___1DQzl > :global(.__wab_flex-container) > *,
.freeBox___1DQzl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1DQzl > :global(.__wab_flex-container) > picture > img,
.freeBox___1DQzl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__sp4SP {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__sp4SP > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__sp4SP > :global(.__wab_flex-container) > *,
.freeBox__sp4SP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sp4SP > :global(.__wab_flex-container) > picture > img,
.freeBox__sp4SP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox___6LfFo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___6LfFo > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___6LfFo > :global(.__wab_flex-container) > *,
.freeBox___6LfFo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6LfFo > :global(.__wab_flex-container) > picture > img,
.freeBox___6LfFo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox___4JcKh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___4JcKh > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___4JcKh > :global(.__wab_flex-container) > *,
.freeBox___4JcKh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4JcKh > :global(.__wab_flex-container) > picture > img,
.freeBox___4JcKh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__rk8Y5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.herleitungNhkRow__hN8GD:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell___8Q1Od:global(.__wab_instance) {
  position: relative;
}
.text__mtRQ {
  padding-left: 0px;
}
.algoButton__z7Gv8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__oLc5I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rDoGi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__imLmm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lPzr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ufShq:global(.__wab_instance) {
  max-width: 100%;
}
.svg__rLdC1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__skOem {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__mUkKv:global(.__wab_instance) {
  position: relative;
}
.text___4YmH1 {
  padding-left: 0px;
}
.algoButton___1ZR7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6P00 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cJni5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bpUz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cMgeO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__smj95:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vaBbU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hG3WJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___2G8Nb:global(.__wab_instance) {
  position: relative;
}
.text__yBmee {
  padding-left: 0px;
}
.algoButton__re6G1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zkdRw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yzifV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dcZcJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__tj8Az {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__cqWpi:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hFlcZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aXzUd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__hcVWk:global(.__wab_instance) {
  position: relative;
}
.text__pZxOf {
  padding-left: 0px;
}
.algoButton__ssgO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__h7C7G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8ALu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__m7C8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qSj6L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__xGizr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lLidZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yMqx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__djML:global(.__wab_instance) {
  position: relative;
}
.text__ogJps {
  padding-left: 0px;
}
.algoButton__lEqbL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tUw1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__a7Pfb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__w4Nwo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__o2A6P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__vwvTp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kyQG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4L5S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__lhVcC:global(.__wab_instance) {
  position: relative;
}
.text__adHM {
  padding-left: 0px;
}
.algoButton__nRrh9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__aPz6O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kCi37 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yFevM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__t9Z3V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__krmhO:global(.__wab_instance) {
  max-width: 100%;
}
.svg___4GSbr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tQsC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___1BseF:global(.__wab_instance) {
  position: relative;
}
.text__b8Y3C {
  padding-left: 0px;
}
.algoButton__oeimb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6Fezi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ozlnq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2QtX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__pmdSa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__xgntq:global(.__wab_instance) {
  max-width: 100%;
}
.svg__j0It {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aaAuv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__dcCgL:global(.__wab_instance) {
  position: relative;
}
.text__iTfii {
  padding-left: 0px;
}
.algoButton__fcVjD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput150:global(.__wab_instance) {
  position: relative;
}
.svg__cq32T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aqWxB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5Kvw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xkDeo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__y50A:global(.__wab_instance) {
  max-width: 100%;
}
.svg__jiRgw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ucu0O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___2LCbG:global(.__wab_instance) {
  position: relative;
}
.text___28Jo {
  padding-left: 0px;
}
.algoButton___60Zso:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__kqYpA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__leKvF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__a6Wy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__joXr4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__sXgAm:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kXcp7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__t7Pqm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__i0Bo3:global(.__wab_instance) {
  position: relative;
}
.text__vqv6S {
  padding-left: 0px;
}
.algoButton__qmrxy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__q6Z4C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ngv4T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hr4S4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__pulZo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__orsrt:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lgo28 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zEdEq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__dlXts:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__eS4Zl:global(.__wab_instance) {
  position: relative;
}
.text___2An82 {
  padding-left: 0px;
}
.algoButton__vEhmt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__elL1V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rbRGb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gS2P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dt4KO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__d6QYx:global(.__wab_instance) {
  max-width: 100%;
}
.svg___7QWp6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qliHt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__yVq6Z:global(.__wab_instance) {
  position: relative;
}
.text___73E0X {
  padding-left: 0px;
}
.algoButton__bvljP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__lIg2U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___63Vw9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6WsbN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yyAss {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pv4SS:global(.__wab_instance) {
  max-width: 100%;
}
.svg___4A5BE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__udnbD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__wQlgg:global(.__wab_instance) {
  position: relative;
}
.text__vcT1 {
  padding-left: 0px;
}
.algoButton__oyzot:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__cFYu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rtICp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tk0Zy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__azHrn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iIvXf:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qsYjF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3TYp2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__e93Li:global(.__wab_instance) {
  position: relative;
}
.text__kJoo3 {
  padding-left: 0px;
}
.algoButton__znH3A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__qD8Km {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__f9TYd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pQ3Qs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5Xaye {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__oa3Ps:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qPSx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oBnhu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__coQDr:global(.__wab_instance) {
  position: relative;
}
.text___9JlSf {
  padding-left: 0px;
}
.algoButton__lrh2A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___897Qs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0UYlV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ww78R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__q9GrC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__daq5:global(.__wab_instance) {
  max-width: 100%;
}
.svg___93GmS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dSzLc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__qY9J:global(.__wab_instance) {
  position: relative;
}
.text__a8ZgI {
  padding-left: 0px;
}
.algoButton__wxzTa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qroBm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wn7Nc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wtLH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__t1R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__no9Qx:global(.__wab_instance) {
  max-width: 100%;
}
.svg__aB1Ue {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__o1Mox {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__o8An:global(.__wab_instance) {
  position: relative;
}
.text__wevXa {
  padding-left: 0px;
}
.algoButton__ckTi2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rE179 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__w7IEr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__daxKr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__f0OtR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pCGj8:global(.__wab_instance) {
  max-width: 100%;
}
.svg__daWkf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fMpL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___39W0P:global(.__wab_instance) {
  position: relative;
}
.text___0Ujdv {
  padding-left: 0px;
}
.algoButton__eDvbu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput140:global(.__wab_instance) {
  position: relative;
}
.svg__zCEs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pkHcf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hMwg5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kuIbb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__p0V:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gFcZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__f89Bw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__xLYmA:global(.__wab_instance) {
  position: relative;
}
.text__ltC2Y {
  padding-left: 0px;
}
.algoButton__oiJlH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pgInw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gJGxI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xRazV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xRdRy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__p4ZoQ:global(.__wab_instance) {
  max-width: 100%;
}
.svg___1P7Nk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lGapp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__aq7Su:global(.__wab_instance) {
  position: relative;
}
.text__zp9WS {
  padding-left: 0px;
}
.algoButton__jngVm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___8Rbar {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__f8DbD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__suwqz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vYga {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iPteK:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dzdH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__imPx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__tLzxx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___1O9Dd {
  display: flex;
  flex-direction: row;
  width: 280px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
}
.freeBox__yw7Fy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBox__g17Lq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.text___9Kg0P {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: center;
}
.dynamicText:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.text___7Vqqc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-IgvLORwjhxLX5NA);
  font-weight: 500;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  text-align: left;
  min-width: 0;
}
.processCardSection__kvBk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___0Ax8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__iEDzI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__kTbOu {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  min-width: 0;
}
.freeBox___35Vdk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox__zgKtd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  flex-shrink: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBox__aluzQ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.divider___5ZZe:global(.__wab_instance) {
  max-width: 100%;
  height: 25%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.freeBox___8YugK {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__xKdSw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__rQb3Q {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 80px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__zBeHe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___6YIkc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 80px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___6YIkc > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___6YIkc > :global(.__wab_flex-container) > *,
.freeBox___6YIkc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6YIkc > :global(.__wab_flex-container) > picture > img,
.freeBox___6YIkc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__iseHd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__iseHd > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__iseHd > :global(.__wab_flex-container) > *,
.freeBox__iseHd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__iseHd > :global(.__wab_flex-container) > picture > img,
.freeBox__iseHd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__rDoOy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__rDoOy > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__rDoOy > :global(.__wab_flex-container) > *,
.freeBox__rDoOy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rDoOy > :global(.__wab_flex-container) > picture > img,
.freeBox__rDoOy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox___1DWHb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___1DWHb > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___1DWHb > :global(.__wab_flex-container) > *,
.freeBox___1DWHb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1DWHb > :global(.__wab_flex-container) > picture > img,
.freeBox___1DWHb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__jIgc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__jIgc > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__jIgc > :global(.__wab_flex-container) > *,
.freeBox__jIgc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jIgc > :global(.__wab_flex-container) > picture > img,
.freeBox__jIgc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__twjgu {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: right;
}
.freeBox__u792H {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.herleitungNhkRow___21Buv:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__r7CG6:global(.__wab_instance) {
  position: relative;
}
.text__o4M6M {
  padding-left: 0px;
}
.algoButton___7Ack8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0Xn36 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3Ne7C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__y4Qjz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uLhY6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__wqkNw:global(.__wab_instance) {
  max-width: 100%;
}
.svg___6GQOa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gyrby {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__lvqfG:global(.__wab_instance) {
  position: relative;
}
.text__guPqf {
  padding-left: 0px;
}
.algoButton__hHbd3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0JwwO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__z9ECt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gTqz8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__g29Gf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__foHaf:global(.__wab_instance) {
  max-width: 100%;
}
.svg__tr7Nb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__viw2V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__oBlC5:global(.__wab_instance) {
  position: relative;
}
.text__gHwa8 {
  padding-left: 0px;
}
.algoButton___4AylX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gs2Cq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__veLxP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nZmEp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rq09C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__qdph4:global(.__wab_instance) {
  max-width: 100%;
}
.svg__rarYh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mKlPw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___40To1:global(.__wab_instance) {
  position: relative;
}
.text__kFjFj {
  padding-left: 0px;
}
.algoButton__vm37V:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__b3MoY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eP3LM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uIxuo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fCpZc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__eftJy:global(.__wab_instance) {
  max-width: 100%;
}
.svg__pArxs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jtHl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__q258:global(.__wab_instance) {
  position: relative;
}
.text__uLu2Q {
  padding-left: 0px;
}
.algoButton__c4KBg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uEa2F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__viLzL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__z2Ai {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kddlr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zXmIg:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hRii5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mRiw1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__fUdI:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.text__r1Nzl {
  padding-left: 0px;
}
.algoButton__b05G2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__b1S6W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dRkWf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__eWxR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__x9VP7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__dpNzD:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uavzW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3XJfo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___68I5O:global(.__wab_instance) {
  position: relative;
}
.text__gn5Y {
  padding-left: 0px;
}
.algoButton__mJf5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qFEiA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lBzzK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1U5Sh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ldMzo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lsnZs:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xzgzV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eBVh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__uqPjJ:global(.__wab_instance) {
  position: relative;
}
.text__r9Hqd {
  padding-left: 0px;
}
.algoButton___4P6CU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__lwqpK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zivs0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__knGRu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rrx7X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__hsKss:global(.__wab_instance) {
  max-width: 100%;
}
.svg___2Q2EH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mmLxe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__vMcKe:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__iU37 {
  padding-left: 0px;
}
.algoButton__ycN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sQgsq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mnO2G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uuxSu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__tlNrr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ei7J2:global(.__wab_instance) {
  max-width: 100%;
}
.svg__d2Jk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bdCiM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__bp4Pw:global(.__wab_instance) {
  position: relative;
}
.text__jBnJd {
  padding-left: 0px;
}
.algoButton__mMchP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___4UjTh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7Uz0T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dLzos {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dOsB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__tq88W:global(.__wab_instance) {
  max-width: 100%;
}
.svg___5QCg8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gPwm9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.divider__kaaV:global(.__wab_instance) {
  max-width: 100%;
  margin-top: var(--token-8IFSbVVlb);
  margin-bottom: var(--token-8IFSbVVlb);
  flex-shrink: 0;
}
.herleitungNhkRow__g5CbS:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__s0Y8V:global(.__wab_instance) {
  position: relative;
}
.text__tBgIv {
  padding-left: 0px;
}
.algoButton__mJ0Ro:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pLw6C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9KjCy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ruUdk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__oVKo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mOYnI:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lG87L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pQNeJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__gGlJq:global(.__wab_instance) {
  position: relative;
}
.text__xt2Dq {
  padding-left: 0px;
}
.algoButton__fEgvl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__eZ87X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mvkhR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___8EyQt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___23En5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3GZed:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ytCoh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lZZke {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__mtSp8:global(.__wab_instance) {
  position: relative;
}
.text__nwRTy {
  padding-left: 0px;
}
.algoButton__gQrzp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__g5KdW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__e9Ssf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__b0KOh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kJ5Tt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__asm1P:global(.__wab_instance) {
  max-width: 100%;
}
.svg___6YqXt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uGkGb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ye26G:global(.__wab_instance) {
  position: relative;
}
.text__em1Ye {
  padding-left: 0px;
}
.algoButton___4JCv8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__mveXa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jMloF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5SLbm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uaNwF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__fC9Hx:global(.__wab_instance) {
  max-width: 100%;
}
.svg__zicHs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vwNr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__kgA1J:global(.__wab_instance) {
  position: relative;
}
.text__cGPt {
  padding-left: 0px;
}
.algoButton__nj0Xd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ozUO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__eZyu0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8Do7X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___1MNh2:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dfrqs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qk1O4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___9Ib0Z:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.text__edF6 {
  padding-left: 0px;
}
.algoButton__xGwU3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__khT00 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__t8Vnj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jvDba {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__f2VeL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___1Rgzs:global(.__wab_instance) {
  max-width: 100%;
}
.svg___70XXz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9Xut {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__y5ZYr:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton___3C62B:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput163:global(.__wab_instance) {
  position: relative;
}
.svg__jXdE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tEqvP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tgOiQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sqP3M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uNgFu:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yazN7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zgfLh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__nsXm:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__cesM {
  padding-left: 0px;
}
.algoButton__qegB6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___1Ybtb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0Ne6C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tGzjz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__flDyk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__vj6Gb:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qbn4U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xXlt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__apo6Z:global(.__wab_instance) {
  position: relative;
}
.text__oBrJ {
  padding-left: 0px;
}
.algoButton__or7Iv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0FkUl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vghvX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4Apgv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6OfsV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__dIkmh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__rc7MQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iB8RF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___0QAJ:global(.__wab_instance) {
  position: relative;
}
.text__zA73F {
  padding-left: 0px;
}
.algoButton__invu6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__e2VWy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pWeYn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jSwE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6OUf6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__kgXp1:global(.__wab_instance) {
  max-width: 100%;
}
.svg__q9Yt0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__x8NSl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardSection__nFs9C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___2AQlj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__obfTo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__cv9Rk {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  min-width: 0;
}
.algoButton__bn5VB:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__ayjKc {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  min-width: 0;
}
.freeBox___9XXlH {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox___1RhKo {
  display: flex;
  flex-direction: row;
  width: 280px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
}
.freeBox___5K8YB {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 300px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.text__w0RDy {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__orQp7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 80px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__orQp7 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__orQp7 > :global(.__wab_flex-container) > *,
.freeBox__orQp7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__orQp7 > :global(.__wab_flex-container) > picture > img,
.freeBox__orQp7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__tvE1B {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 90px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__tvE1B > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__tvE1B > :global(.__wab_flex-container) > *,
.freeBox__tvE1B > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tvE1B > :global(.__wab_flex-container) > picture > img,
.freeBox__tvE1B
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__tf6F {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__tf6F > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__tf6F > :global(.__wab_flex-container) > *,
.freeBox__tf6F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tf6F > :global(.__wab_flex-container) > picture > img,
.freeBox__tf6F
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__x1GSt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__x1GSt > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__x1GSt > :global(.__wab_flex-container) > *,
.freeBox__x1GSt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__x1GSt > :global(.__wab_flex-container) > picture > img,
.freeBox__x1GSt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__aH6MM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__aH6MM > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__aH6MM > :global(.__wab_flex-container) > *,
.freeBox__aH6MM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aH6MM > :global(.__wab_flex-container) > picture > img,
.freeBox__aH6MM
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__izA8 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox___2Cu4F {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  flex-shrink: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.text___0Yi0Q {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__fSMx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text___9Y52D {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.divider__c1VXa:global(.__wab_instance) {
  max-width: 100%;
  height: 25%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.freeBox__oFnp8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__j7JZ2 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__kfu18 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__keHot {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.divider__g2Aet:global(.__wab_instance) {
  max-width: 100%;
  height: 25%;
  position: absolute;
  left: auto;
  top: 0px;
  right: 0px;
}
.freeBox__dueK {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 80px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__dueK > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__dueK > :global(.__wab_flex-container) > *,
.freeBox__dueK > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dueK > :global(.__wab_flex-container) > picture > img,
.freeBox__dueK
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__zKnJ0 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox___0Qe4T {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___0Qe4T > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___0Qe4T > :global(.__wab_flex-container) > *,
.freeBox___0Qe4T > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0Qe4T > :global(.__wab_flex-container) > picture > img,
.freeBox___0Qe4T
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__derJq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__derJq > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__derJq > :global(.__wab_flex-container) > *,
.freeBox__derJq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__derJq > :global(.__wab_flex-container) > picture > img,
.freeBox__derJq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text___7Rvh6 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox___7OtOk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___7OtOk > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___7OtOk > :global(.__wab_flex-container) > *,
.freeBox___7OtOk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___7OtOk > :global(.__wab_flex-container) > picture > img,
.freeBox___7OtOk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__z1MMf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__z1MMf > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__z1MMf > :global(.__wab_flex-container) > *,
.freeBox__z1MMf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__z1MMf > :global(.__wab_flex-container) > picture > img,
.freeBox__z1MMf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.text__luWsF {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: right;
}
.freeBox___9D4LJ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.herleitungNhkRow__ymf6Z:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__i1L4V:global(.__wab_instance) {
  position: relative;
}
.text__c88Yy {
  padding-left: 0px;
}
.algoButton__nEgtK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___5Obmj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kwM1V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6Vi46 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vo9Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___150Oe:global(.__wab_instance) {
  max-width: 100%;
}
.svg__x6A92 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vAxlm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__vu0Aw:global(.__wab_instance) {
  position: relative;
}
.text___2Qhvf {
  padding-left: 0px;
}
.algoButton__twGl5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wFqr6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__c9CSu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wN4H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__oeL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___5Yfft:global(.__wab_instance) {
  max-width: 100%;
}
.svg__jWtGv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__u6AiG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__niOxm:global(.__wab_instance) {
  position: relative;
}
.text__dlpCp {
  padding-left: 0px;
}
.algoButton__r1Cx0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__m12Ib {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__boLzk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ybMxl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vhd8X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__n5Yqj:global(.__wab_instance) {
  max-width: 100%;
}
.svg__jHOa8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ns9TU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__int7L:global(.__wab_instance) {
  position: relative;
}
.text__y3Eos {
  padding-left: 0px;
}
.algoButton__lOagr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__mQno {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i8VKd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__r88Nh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___36GTc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__xvsxu:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uUuhK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__naVru {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__yrlzV:global(.__wab_instance) {
  position: relative;
}
.text___3Exdv {
  padding-left: 0px;
}
.algoButton__v2VHv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__yBp2W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jktR6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yZiap {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hV8Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3A9E8:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vxxgn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yPIvi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__rG9Nw:global(.__wab_instance) {
  position: relative;
}
.text__yzNOd {
  padding-left: 0px;
}
.algoButton__vx15N:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gtVCm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vdXhY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hoJUc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___65BGn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__anju9:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gfpn6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lqfnc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___8F0Mu:global(.__wab_instance) {
  position: relative;
}
.text__uAtuV {
  padding-left: 0px;
}
.algoButton__z5Pc1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__a4Z59 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bVeA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k2Zas {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__nyg5S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__cNfP2:global(.__wab_instance) {
  max-width: 100%;
}
.svg___4BgUr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mbWRn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ppHqZ:global(.__wab_instance) {
  position: relative;
}
.text__urCzT {
  padding-left: 0px;
}
.algoButton___7VxYa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput141:global(.__wab_instance) {
  position: relative;
}
.svg__o8PC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nzBFx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0S31H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__em7Hd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___0OFct:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hykGl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__niZz6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___6WlUf:global(.__wab_instance) {
  position: relative;
}
.text__zMz9F {
  padding-left: 0px;
}
.algoButton___5DhvD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6M6N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__m3Wxs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vtuQd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__biahn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lboZi:global(.__wab_instance) {
  max-width: 100%;
}
.svg__jRdxt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9QC54 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__kneUx:global(.__wab_instance) {
  position: relative;
}
.text___5B0W {
  padding-left: 0px;
}
.algoButton___8M5UX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gP2Ak {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gMzlj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fzrJb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aKqjM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__egL8D:global(.__wab_instance) {
  max-width: 100%;
}
.svg__olSa7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ea6BM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__atokU:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__lLpmi:global(.__wab_instance) {
  position: relative;
}
.text___5Drpz {
  padding-left: 0px;
}
.algoButton___25Mtl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rl5Rv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qwRd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rWs7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yreny {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__aoLi:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vat7U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oNou4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__auz7X:global(.__wab_instance) {
  position: relative;
}
.text__vuztn {
  padding-left: 0px;
}
.algoButton__c5S9E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__n6OiZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__erspH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5HcT7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___03XAd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__gcuUg:global(.__wab_instance) {
  max-width: 100%;
}
.svg__v0PBh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ftDq2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__pzVli:global(.__wab_instance) {
  position: relative;
}
.text___0Y6Rp {
  padding-left: 0px;
}
.algoButton__ljPry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nriQx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tcQh8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uNhR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__atB6N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__eqw2G:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gvL2V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__k1Oxg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__xNsz:global(.__wab_instance) {
  position: relative;
}
.text__gUgy {
  padding-left: 0px;
}
.algoButton__hH9Wn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dkvf8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__onKl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hLcWh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3Gv1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__htlje:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ghBfp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__o7DjG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ijoN:global(.__wab_instance) {
  position: relative;
}
.text__nESeB {
  padding-left: 0px;
}
.algoButton__zOdHa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ggMnf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qwTvy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__b72Ve {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eV4Mq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iDwle:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dkqDi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gomuI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__lpg9Z:global(.__wab_instance) {
  position: relative;
}
.text__uj7Lg {
  padding-left: 0px;
}
.algoButton___83W1K:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ciDWk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__taYXl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__r8Gz5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vJqS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__jSls:global(.__wab_instance) {
  max-width: 100%;
}
.svg__bGl7H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lNBmA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__lmdi7:global(.__wab_instance) {
  position: relative;
}
.text___9CTL {
  padding-left: 0px;
}
.algoButton__f61TL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pQjk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8Jnlq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__eLm1S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mPrzh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__rvoP:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xfGUn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mClK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__rgVr7:global(.__wab_instance) {
  position: relative;
}
.text__yfTh3 {
  padding-left: 0px;
}
.algoButton__dnPif:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput142:global(.__wab_instance) {
  position: relative;
}
.svg__zEuvz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3Ghef {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uGpJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__huoie {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__byQRz:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yiRjB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__c92YP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__kN7:global(.__wab_instance) {
  position: relative;
}
.text__wVyhM {
  padding-left: 0px;
}
.algoButton___3Ssx8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hwU4U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3FvVr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__q3Ypc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kp6S8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__fwvuk:global(.__wab_instance) {
  max-width: 100%;
}
.svg__aWex {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__d3Rqj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__l6Lj0:global(.__wab_instance) {
  position: relative;
}
.text__x47Rm {
  padding-left: 0px;
}
.algoButton__ihijv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__i6QbS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wAjh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lmm9S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5H4Vq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ty2Jm:global(.__wab_instance) {
  max-width: 100%;
}
.svg__iy3Mi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__baaw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow___7E3Xk:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__vvfrO:global(.__wab_instance) {
  position: relative;
}
.text__jtSrA {
  padding-left: 0px;
}
.algoButton__fj3E8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__oomMg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wy2U4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7TFhV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ogUt7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__qpmcf:global(.__wab_instance) {
  max-width: 100%;
}
.svg__q6J8K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6BQdg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__j0R1K:global(.__wab_instance) {
  position: relative;
}
.text__ic25H {
  padding-left: 0px;
}
.algoButton__i3ODl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tKtKd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8FQo0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nPRvz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jxYb4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__w3KN2:global(.__wab_instance) {
  max-width: 100%;
}
.svg___6Rs9F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cz1V3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__i8Crv:global(.__wab_instance) {
  position: relative;
}
.text__raBax {
  padding-left: 0px;
}
.algoButton__bXwFm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nlJlg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__z8SEi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yUDfK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gXhN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__qXm1K:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hgHwW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mVu1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__qpZoW:global(.__wab_instance) {
  position: relative;
}
.text__gSdWw {
  padding-left: 0px;
}
.algoButton___0W3Co:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sg7VP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qXwVz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qdxN7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mgtbi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__f92Kb:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gLpvm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___121Vs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__t441:global(.__wab_instance) {
  position: relative;
}
.text___67M5M {
  padding-left: 0px;
}
.algoButton__i41C7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__twgHg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lm8KD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__t5KZm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uVc6U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__s5Xzk:global(.__wab_instance) {
  max-width: 100%;
}
.svg___6Wss8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__d8OCi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jNGgk:global(.__wab_instance) {
  position: relative;
}
.text__ziUF {
  padding-left: 0px;
}
.algoButton__uxvZc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zckK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rkXz2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__knZtZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7QmH0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__nnfW:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kFaT3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__z1AaZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__nhwne:global(.__wab_instance) {
  position: relative;
}
.text__v8JvG {
  padding-left: 0px;
}
.algoButton__pGlT5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___2XnK8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__prjiz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___8VUw1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__a6Fb6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mtXq:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fEoY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cSzi8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__gzHyO:global(.__wab_instance) {
  position: relative;
}
.text__mfH0E {
  padding-left: 0px;
}
.algoButton___42Wbm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput143:global(.__wab_instance) {
  position: relative;
}
.svg__wcwfP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__n4FE6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zt8Tz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___0PHq5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___39Ucw:global(.__wab_instance) {
  max-width: 100%;
}
.svg__z4BtN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9NCnb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__pRMzy:global(.__wab_instance) {
  position: relative;
}
.text__oDbDu {
  padding-left: 0px;
}
.algoButton__qLcek:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ziqmP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pjIqb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vhjt8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jmwsW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__tWoAd:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yPi61 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fgUrU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__dqVs:global(.__wab_instance) {
  position: relative;
}
.text__iYuVe {
  padding-left: 0px;
}
.algoButton__p1OFw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cEwmD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ih43U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yVPfh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fs8Dg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___836Nz:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kgV0R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ni43O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__fiu2G:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__mHHsz:global(.__wab_instance) {
  position: relative;
}
.text__dTmd {
  padding-left: 0px;
}
.algoButton__pFbnh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__h30RR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4TNjt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__i0Ly3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xrKny {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__qYzBl:global(.__wab_instance) {
  max-width: 100%;
}
.svg___0EzV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ztHws {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__zMTj7:global(.__wab_instance) {
  position: relative;
}
.text__babtM {
  padding-left: 0px;
}
.algoButton__xuySi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___9RVpg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fXjl8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oAjJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sIiY2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__okVpA:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yDkIp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yUNj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__kGx4I:global(.__wab_instance) {
  position: relative;
}
.text__pulTy {
  padding-left: 0px;
}
.algoButton__yI2Cp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__a3C19 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__csFle {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jDshl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__w2KSh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3CEbF:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fmBWg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sgF29 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__hsoiw:global(.__wab_instance) {
  position: relative;
}
.text__t0WD {
  padding-left: 0px;
}
.algoButton__zCdbp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ri7Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fh79 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__i23IU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__d1Xo8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__dEiZk:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yTlbj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pubVg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ljjlA:global(.__wab_instance) {
  position: relative;
}
.text__mElQ8 {
  padding-left: 0px;
}
.algoButton___84Na:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__oZib {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vw1Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wuZke {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lNts {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__tfpZs:global(.__wab_instance) {
  max-width: 100%;
}
.svg__oclup {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ef36H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__aYkxl:global(.__wab_instance) {
  position: relative;
}
.text__iuJe5 {
  padding-left: 0px;
}
.algoButton__c6Ezo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___1OrCz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2Iftl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qGhpT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kXck8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__wefUg:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3IXA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fbdb5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jak4B:global(.__wab_instance) {
  position: relative;
}
.text__xqdgz {
  padding-left: 0px;
}
.algoButton__hjbXh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dzvNq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2U4II {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fsXib {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__adF2D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3BvEr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__oe9PW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uKdTc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__q9Nsz:global(.__wab_instance) {
  position: relative;
}
.text__q6H1S {
  padding-left: 0px;
}
.algoButton___4B3Hk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput144:global(.__wab_instance) {
  position: relative;
}
.svg__oLlZq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rRca {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__z4K5W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uOsKe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mfFkM:global(.__wab_instance) {
  max-width: 100%;
}
.svg__s2Yew {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cJco6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___7Ef0I:global(.__wab_instance) {
  position: relative;
}
.text___93ZL3 {
  padding-left: 0px;
}
.algoButton__qo8Mg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tRDgR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uW1Rw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rXdYt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3VppC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__jeRkd:global(.__wab_instance) {
  max-width: 100%;
}
.svg___9EUm9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1Z2ZK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___9EYup:global(.__wab_instance) {
  position: relative;
}
.text__taLi9 {
  padding-left: 0px;
}
.algoButton__webej:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__i1Qw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mvS7J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__edLq4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__d3EZe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3BUmM:global(.__wab_instance) {
  max-width: 100%;
}
.svg__owhDc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aHu5Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__sx4R7:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__rmJg2:global(.__wab_instance) {
  position: relative;
}
.text__lz5Fd {
  padding-left: 0px;
}
.algoButton___90X4L:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vDzw0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iwLE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__otk2E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__j1Obl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uTuwm:global(.__wab_instance) {
  max-width: 100%;
}
.svg___2Np0J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aHbKe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__kV8DF:global(.__wab_instance) {
  position: relative;
}
.text___74Txy {
  padding-left: 0px;
}
.algoButton__wnxbh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zNstp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9ENMm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gMuZi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yg22E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lBnEv:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hpXX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rV2A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__rePUw:global(.__wab_instance) {
  position: relative;
}
.text__jRekc {
  padding-left: 0px;
}
.algoButton__krDH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ryIjs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xLk5V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uPt98 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xGsVt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__fx6Q0:global(.__wab_instance) {
  max-width: 100%;
}
.svg___26BaB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pbjTf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___3GUxK:global(.__wab_instance) {
  position: relative;
}
.text__bLbbI {
  padding-left: 0px;
}
.algoButton__utYs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___4Sxto {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tfCnq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hliDn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aAAs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__rxcuC:global(.__wab_instance) {
  max-width: 100%;
}
.svg__syQb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4HmKz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__t40Z:global(.__wab_instance) {
  position: relative;
}
.text__msgX4 {
  padding-left: 0px;
}
.algoButton__piP7J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6NBq7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wlXkb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aob87 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__b1V1O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pQcx:global(.__wab_instance) {
  max-width: 100%;
}
.svg__cPiQn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__l4IJ9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__yrijr:global(.__wab_instance) {
  position: relative;
}
.text__izCco {
  padding-left: 0px;
}
.algoButton___5YaGa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hy9Cs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4Iksw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yEs16 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___844Ov {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__rj7Pi:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uxi5L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wwbXt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jBvW4:global(.__wab_instance) {
  position: relative;
}
.text__yNc3A {
  padding-left: 0px;
}
.algoButton__cBsNs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__v1Kta {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7S6Fy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sPOf4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6FkaE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__dm0Td:global(.__wab_instance) {
  max-width: 100%;
}
.svg__cMmCj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tzH8T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__zDDys:global(.__wab_instance) {
  position: relative;
}
.text___7Rm5A {
  padding-left: 0px;
}
.algoButton__i0KUd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput145:global(.__wab_instance) {
  position: relative;
}
.svg__kn4Se {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9Un14 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aihg5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sc3DT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__d7Nm8:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3BOzG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i4Jgk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__l8Mx4:global(.__wab_instance) {
  position: relative;
}
.text__maF4B {
  padding-left: 0px;
}
.algoButton__fZWk5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rInyI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__e97P3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ayJbd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vvDxn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__jorIp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mAdpH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eLsfg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__z1ACt:global(.__wab_instance) {
  position: relative;
}
.text__vHcNr {
  padding-left: 0px;
}
.algoButton___4Tgvr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__kiTm5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xMWxq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vH70 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kPzJs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zUcka:global(.__wab_instance) {
  max-width: 100%;
}
.svg___02D2O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ucsK8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__qhQvx:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__uvcCw:global(.__wab_instance) {
  position: relative;
}
.text__zqb50 {
  padding-left: 0px;
}
.algoButton__siyoj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__y6I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vlGoO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zdlGw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jw4Q1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uRaWi:global(.__wab_instance) {
  max-width: 100%;
}
.svg__bS4Ja {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gOmVv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__obeHz:global(.__wab_instance) {
  position: relative;
}
.text__yv2B0 {
  padding-left: 0px;
}
.algoButton__ptLKc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__yDgJq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zpZxQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cl2Tc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kNgKh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__tnkd8:global(.__wab_instance) {
  max-width: 100%;
}
.svg__m71O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aRyNr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__qwtoe:global(.__wab_instance) {
  position: relative;
}
.text__pqkT9 {
  padding-left: 0px;
}
.algoButton___4SgRa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sckRd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nh65D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ii3VL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yotNo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__c24Sg:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gZmat {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ktE54 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__fxqkp:global(.__wab_instance) {
  position: relative;
}
.text__wAlv {
  padding-left: 0px;
}
.algoButton__pbSy9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tb3T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__f2Ki4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uG0O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sZsUh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___04REy:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fsMs9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sZVjC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__qgRMt:global(.__wab_instance) {
  position: relative;
}
.text__kElY7 {
  padding-left: 0px;
}
.algoButton__jg3LB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__xpYkt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qssla {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hyknp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__a69GN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___0AibZ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__sy4D7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fpcHm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___5UFxv:global(.__wab_instance) {
  position: relative;
}
.text__vPrTh {
  padding-left: 0px;
}
.algoButton__gRxPz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___1Vuok {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8Wl6K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2YpbE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xvzV6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___5CtLo:global(.__wab_instance) {
  max-width: 100%;
}
.svg___9Bz8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__em13J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__f82Ru:global(.__wab_instance) {
  position: relative;
}
.text__r2Amv {
  padding-left: 0px;
}
.algoButton__rYtE:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__i1LNz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jEgo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__js6Sr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bbtU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__egBpI:global(.__wab_instance) {
  max-width: 100%;
}
.svg__pTvZ3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3OYv1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___5Oros:global(.__wab_instance) {
  position: relative;
}
.text__qz44L {
  padding-left: 0px;
}
.algoButton__cirNp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput146:global(.__wab_instance) {
  position: relative;
}
.svg__crJAc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9QQ7T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cPn9E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jPNg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___6ElC:global(.__wab_instance) {
  max-width: 100%;
}
.svg__oj9Np {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3Tu4T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___3Jv3H:global(.__wab_instance) {
  position: relative;
}
.text__roUqi {
  padding-left: 0px;
}
.algoButton__xjIny:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dtqF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jNm1P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__z4Ul {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bQ7Qe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__owkwl:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gbZl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2Keoj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jhydZ:global(.__wab_instance) {
  position: relative;
}
.text__toMrp {
  padding-left: 0px;
}
.algoButton__xanZy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__e2UKx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aYl2U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___11Yl8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sjOqv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__vBgV:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3F3C2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__f4WSf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__xhKHd:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__o5RUy:global(.__wab_instance) {
  position: relative;
}
.text__l08Bj {
  padding-left: 0px;
}
.algoButton__zh6Ku:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uDYe6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rKud {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fjRvH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yK50X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__wOxBh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__nVwjf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eIx33 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___7CtYk:global(.__wab_instance) {
  position: relative;
}
.text__t1HOv {
  padding-left: 0px;
}
.algoButton__rFjpg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qx6Wm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zhnRt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___78Vsx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__raOb3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__o6Bze:global(.__wab_instance) {
  max-width: 100%;
}
.svg__eh8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lPz9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__nSqva:global(.__wab_instance) {
  position: relative;
}
.text__ifWem {
  padding-left: 0px;
}
.algoButton___7XjMj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qFsxc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xnu8X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xD8AY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cpr9C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___2MBp5:global(.__wab_instance) {
  max-width: 100%;
}
.svg___0BpG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__coS02 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___8IDos:global(.__wab_instance) {
  position: relative;
}
.text__iCa8E {
  padding-left: 0px;
}
.algoButton___5T904:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___5Vicx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4PXY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wdaJb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kz8Xb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__nfTpy:global(.__wab_instance) {
  max-width: 100%;
}
.svg__d1Q2R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qukqM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ohQCw:global(.__wab_instance) {
  position: relative;
}
.text__liSNc {
  padding-left: 0px;
}
.algoButton__hhaHl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__yErWa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iHg5D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7Zdom {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8Tlo5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__tr8Zy:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hLfoc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eCn3H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__vVh9T:global(.__wab_instance) {
  position: relative;
}
.text__aXqQj {
  padding-left: 0px;
}
.algoButton__rKuyN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__stG6X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xi2Gy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fjcsv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fnK7U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uFpPb:global(.__wab_instance) {
  max-width: 100%;
}
.svg___1L6S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0GqZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___4Ubwo:global(.__wab_instance) {
  position: relative;
}
.text___39EA {
  padding-left: 0px;
}
.algoButton__iobq6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rRos2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oUqMg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0J64K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__pw72S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__hUGgz:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xn2MX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__niza4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__hzMuy:global(.__wab_instance) {
  position: relative;
}
.text__rs9Aa {
  padding-left: 0px;
}
.algoButton__k6HcG:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput147:global(.__wab_instance) {
  position: relative;
}
.svg__ns1X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lChVy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ri7PZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uAymK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pwoGh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ycohK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2KS7M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___4ZKsN:global(.__wab_instance) {
  position: relative;
}
.text__texaq {
  padding-left: 0px;
}
.algoButton__otG9K:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pwsCl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qBvR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tsVk8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sk5Oj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iYarS:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qJcUp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bu1V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__snDus:global(.__wab_instance) {
  position: relative;
}
.text__qHfNp {
  padding-left: 0px;
}
.algoButton__nCt2Y:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__euWhz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__whwmk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vgMY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mg2F6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__udgYe:global(.__wab_instance) {
  max-width: 100%;
}
.svg__jzPb5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ojJoY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__liJFr:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__e0FrV:global(.__wab_instance) {
  position: relative;
}
.text__fjReh {
  padding-left: 0px;
}
.algoButton__tRldD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uxw7A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bfrzt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wLy2N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jNU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__e5P5S:global(.__wab_instance) {
  max-width: 100%;
}
.svg__nMcVn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aGoxd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___4Uziv:global(.__wab_instance) {
  position: relative;
}
.text__uRwJi {
  padding-left: 0px;
}
.algoButton__slDcj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sVN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9LPh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xCpIp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4Izkx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uVxlH:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xLlA5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nld4S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ytSq4:global(.__wab_instance) {
  position: relative;
}
.text__hYxk1 {
  padding-left: 0px;
}
.algoButton__g04:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__xcz49 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jXmtg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oKerd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vFb8X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__vVmYs:global(.__wab_instance) {
  max-width: 100%;
}
.svg__h1Zpq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__g3Mig {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__unlRq:global(.__wab_instance) {
  position: relative;
}
.text___7LQa5 {
  padding-left: 0px;
}
.algoButton__hqoKk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qL3LS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0Ocoz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fLfTp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__p11YU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__kiHxI:global(.__wab_instance) {
  max-width: 100%;
}
.svg___2Bt6Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vjWqj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__v5U6H:global(.__wab_instance) {
  position: relative;
}
.text___9ZnOo {
  padding-left: 0px;
}
.algoButton__k8ZaB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0WZ2G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zgoRy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nti33 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eQxa8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__nzyfe:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fW0HZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__btEbb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__gVMjJ:global(.__wab_instance) {
  position: relative;
}
.text___9DLq6 {
  padding-left: 0px;
}
.algoButton__xa5Sv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__kzGyS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3BGpq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dxeas {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jrtUz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__eyTSo:global(.__wab_instance) {
  max-width: 100%;
}
.svg___5YpFq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fd8Av {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__phjg1:global(.__wab_instance) {
  position: relative;
}
.text__xyW6Y {
  padding-left: 0px;
}
.algoButton___4V71A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___1Z6Vb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oi1Hn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fvni1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___2ZJ2M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__fdaWn:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vMgQg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ae6N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___3PSmX:global(.__wab_instance) {
  position: relative;
}
.text__frxwO {
  padding-left: 0px;
}
.algoButton___0GjC6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput148:global(.__wab_instance) {
  position: relative;
}
.svg___744 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mHObO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__iiLr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uZakU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___5XaZk:global(.__wab_instance) {
  max-width: 100%;
}
.svg___1XmDw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yJlI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__zZbI4:global(.__wab_instance) {
  position: relative;
}
.text__jhekS {
  padding-left: 0px;
}
.algoButton__vl2DA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___9Gopb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vSkAj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1HcMp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mxub3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mPxhe:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uPeJ4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gyRqW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__t0I5D:global(.__wab_instance) {
  position: relative;
}
.text___1Vw8U {
  padding-left: 0px;
}
.algoButton___9Tc6A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rqF5Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2PuHl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1KVdD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fx5Ld {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___4HRFu:global(.__wab_instance) {
  max-width: 100%;
}
.svg__a0DbT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___21Hbr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__nnzk4:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__jFz5G:global(.__wab_instance) {
  position: relative;
}
.text__hjIoq {
  padding-left: 0px;
}
.algoButton__aT3Rz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ppzOc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__q4XjX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ofAmO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8HeNq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zcKf3:global(.__wab_instance) {
  max-width: 100%;
}
.svg___5Utts {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vzMq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__d383E:global(.__wab_instance) {
  position: relative;
}
.text__uwwVb {
  padding-left: 0px;
}
.algoButton__cPgBo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__bx6TL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__knIpY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cdErj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__nl75T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__rG7A:global(.__wab_instance) {
  max-width: 100%;
}
.svg__eNySs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fNWo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__crB6G:global(.__wab_instance) {
  position: relative;
}
.text__qKjU {
  padding-left: 0px;
}
.algoButton__t3ScE:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wofF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3SdD7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__faNHx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4HIwW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lgKsh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__na9GS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bYlE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__tekm3:global(.__wab_instance) {
  position: relative;
}
.text__nZjfS {
  padding-left: 0px;
}
.algoButton__sdw6E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__aa593 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hahKq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__f50Ol {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xm84 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__oWrie:global(.__wab_instance) {
  max-width: 100%;
}
.svg___583Gg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mrzqT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ep9U:global(.__wab_instance) {
  position: relative;
}
.text__oJ3Dw {
  padding-left: 0px;
}
.algoButton__vrc77:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hoKiS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cSFgR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gUhZe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cUvhy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__jB6QZ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lgbho {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__e2Xhe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__xWbsw:global(.__wab_instance) {
  position: relative;
}
.text__sSMi {
  padding-left: 0px;
}
.algoButton___6E84P:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__kMxJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zoAp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__u1R9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7GxRt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___1TFU:global(.__wab_instance) {
  max-width: 100%;
}
.svg__zZaSy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eZvsz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__zgqSz:global(.__wab_instance) {
  position: relative;
}
.text___5M65Y {
  padding-left: 0px;
}
.algoButton___50Qzs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__faR7J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cenPy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lxzpl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mRaK4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___2TNwM:global(.__wab_instance) {
  max-width: 100%;
}
.svg__a5Zl7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__l70Rh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__hiLit:global(.__wab_instance) {
  position: relative;
}
.text__lMpxh {
  padding-left: 0px;
}
.algoButton__zkvNd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput8:global(.__wab_instance) {
  position: relative;
}
.svg__aFApQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0KEzV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tQDwl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8GT8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__sRdJd:global(.__wab_instance) {
  max-width: 100%;
}
.svg___4NFhQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zgd4S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jrEc:global(.__wab_instance) {
  position: relative;
}
.text__cBolB {
  padding-left: 0px;
}
.algoButton__wWdnl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__txifa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__edsie {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__shv1N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kpoA5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__guyOa:global(.__wab_instance) {
  max-width: 100%;
}
.svg__afDRq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7S3AJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___2E8G1:global(.__wab_instance) {
  position: relative;
}
.text__mOgHv {
  padding-left: 0px;
}
.algoButton___49V:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__mkzRq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xCcd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qg3Y5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wrKfd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__k222:global(.__wab_instance) {
  max-width: 100%;
}
.svg___8SzNp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qqOyp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.divider__e8Fgz:global(.__wab_instance) {
  max-width: 100%;
  margin-top: var(--token-8IFSbVVlb);
  margin-bottom: var(--token-8IFSbVVlb);
  flex-shrink: 0;
}
.herleitungNhkRow__wwKVr:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__h1Rfu:global(.__wab_instance) {
  position: relative;
}
.text__zMrNg {
  padding-left: 0px;
}
.algoButton__o6OvL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ekewj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__umbHl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___3Fp9N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fiecP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ftPh1:global(.__wab_instance) {
  max-width: 100%;
}
.svg__z6I95 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rmOhN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ak0Md:global(.__wab_instance) {
  position: relative;
}
.text__zM3Ql {
  padding-left: 0px;
}
.algoButton__tyRqI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uD8At {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xX1K4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ada8B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zA84R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__nyIee:global(.__wab_instance) {
  max-width: 100%;
}
.svg__q0KLp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fmcDj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__lG5C4:global(.__wab_instance) {
  position: relative;
}
.text__lqzXg {
  padding-left: 0px;
}
.algoButton__d3Sjf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tdTRm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gO5L7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6MawA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5Cfb0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ttS7Y:global(.__wab_instance) {
  max-width: 100%;
}
.svg__q3Q2F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sTirV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__lwzZn:global(.__wab_instance) {
  position: relative;
}
.text__vCmBh {
  padding-left: 0px;
}
.algoButton__jENu4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__fxisq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3LxKn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bimva {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___27CK0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___97WZ:global(.__wab_instance) {
  max-width: 100%;
}
.svg___75N6E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lmfh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__hZkKb:global(.__wab_instance) {
  position: relative;
}
.text__kGW {
  padding-left: 0px;
}
.algoButton__ivz7C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___8TtSg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bD4IO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mKjc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6A0Xu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zldT3:global(.__wab_instance) {
  max-width: 100%;
}
.svg__emlKl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8OmjR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__dAfH6:global(.__wab_instance) {
  position: relative;
}
.text___54Tjc {
  padding-left: 0px;
}
.algoButton__vqjMy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rlgti {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pd41F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4GTKd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wgX0Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pFzF7:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mmIQp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__p4Wo8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__mjVKp:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.text__i0Mcc {
  padding-left: 0px;
}
.algoButton__tdStw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__flLrS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jY2H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7L9Dl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7P3Qi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lTDgI:global(.__wab_instance) {
  max-width: 100%;
}
.svg___4EWzR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__g4Az0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___9QLpj:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton__dtYd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput107:global(.__wab_instance) {
  position: relative;
}
.svg__biFui {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i3WAk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7SyK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eskg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__b2K94:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vGcvy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vIjJ2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__occv1:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__ccIv {
  padding-left: 0px;
}
.algoButton__xhNee:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__r5UYv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9Dhn6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hfUzm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ljXfl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__dcDyf:global(.__wab_instance) {
  max-width: 100%;
}
.svg__iFsUu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__b2Aji {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__fcDGq:global(.__wab_instance) {
  position: relative;
}
.text__ibRgm {
  padding-left: 0px;
}
.algoButton___03GMg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uqrPl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uGmZh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___9La2Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1PrUd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zr87N:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gkypr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__e0E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__qmPyl:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__y2U3:global(.__wab_instance) {
  position: relative;
}
.text__iEyWg {
  padding-left: 0px;
}
.algoButton__kECvr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__i2Xv6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qrt2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cDOox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gqor8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___7XwoI:global(.__wab_instance) {
  max-width: 100%;
}
.svg__nvbkr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xqP6K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___9Ddmu:global(.__wab_instance) {
  position: relative;
}
.text__jIrb3 {
  padding-left: 0px;
}
.algoButton__tDq0G:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__j0Jrg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0QiN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wnPM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ebtcM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__oh7Lp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__zgiQh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bz7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jRyTv:global(.__wab_instance) {
  position: relative;
}
.text__hnvxO {
  padding-left: 0px;
}
.algoButton__zqnVl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ynTsa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ezjRf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0Fd3W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__y7Doz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iJOm:global(.__wab_instance) {
  max-width: 100%;
}
.svg__i4Zns {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hiva3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___1NAXi:global(.__wab_instance) {
  position: relative;
}
.text__uTlMt {
  padding-left: 0px;
}
.algoButton__iwFha:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tPchd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__txAMc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__myJqh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__v3XkW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__y2AJa:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ccP0E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zNgs2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__j6ZZi:global(.__wab_instance) {
  position: relative;
}
.text__uojJl {
  padding-left: 0px;
}
.algoButton___16CKc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vdvsz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vUiUf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fx0Zv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__d9AcK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__gFmcG:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3XnRr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rvelP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__p6Loj:global(.__wab_instance) {
  position: relative;
}
.text__lMx {
  padding-left: 0px;
}
.algoButton___0Gxcf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput221:global(.__wab_instance) {
  position: relative;
}
.svg___53GJv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1OEgK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zf0Te {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7Wle1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___6GSnp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__tl9Dd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ghxT4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__eRnUb:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.algoButton__tpKIk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cgAu7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cv5Za {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__x7W6I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fZtVu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___5WdOx:global(.__wab_instance) {
  max-width: 100%;
}
.svg__eDvHb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iRfU3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ksizS:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton__vs7Wj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput222:global(.__wab_instance) {
  position: relative;
}
.svg__uoOdq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jqCpq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__doMl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__z5Ub5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3P5Qv:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dsKWb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__o49DX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__pFb0D:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__j4Umq {
  padding-left: 0px;
}
.algoButton__a7GzP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zav {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qCzQ1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k97M5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lUrvY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__sC7NQ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lmndz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iZFus {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__x1AVc:global(.__wab_instance) {
  position: relative;
}
.text__k0QoR {
  padding-left: 0px;
}
.algoButton__h3Y9C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dYeSh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eRdBy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qvZxu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rlco {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__nvQqt:global(.__wab_instance) {
  max-width: 100%;
}
.svg__eUzPo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lvw9I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__zu5Ax:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__mL0YM:global(.__wab_instance) {
  position: relative;
}
.text__uaubs {
  padding-left: 0px;
}
.algoButton___4NlR5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__t0YBw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__khRRd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__buW5I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__u3Vt8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__idydo:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fYsd0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nkBLt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__o4SHe:global(.__wab_instance) {
  position: relative;
}
.text__ugKVb {
  padding-left: 0px;
}
.algoButton__oRyef:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__an9D3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qSBrU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7YyrV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vzWod {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mPfFr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yLVz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kkLza {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__cyR:global(.__wab_instance) {
  position: relative;
}
.text___9Zn0W {
  padding-left: 0px;
}
.algoButton__sboZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nQuEq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__q6Jfr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jRiy8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rGcU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ak34A:global(.__wab_instance) {
  max-width: 100%;
}
.svg__i0ZSp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eyh7M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__lsTwm:global(.__wab_instance) {
  position: relative;
}
.text__xYpw4 {
  padding-left: 0px;
}
.algoButton__kdYyr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__bkI8Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__omj39 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__la5IA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xQ4AD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mgRP:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lxusu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4Dm8R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__nRjEo:global(.__wab_instance) {
  position: relative;
}
.text___7C0I1 {
  padding-left: 0px;
}
.algoButton__hJDn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wZaYh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sNd2R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__g4NfF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jc1VC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__by78O:global(.__wab_instance) {
  max-width: 100%;
}
.svg__m5DrY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__goWg7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__wkymR:global(.__wab_instance) {
  position: relative;
}
.text___8IA1R {
  padding-left: 0px;
}
.algoButton__pBvRy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput231:global(.__wab_instance) {
  position: relative;
}
.svg__gCnGw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ahHHn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5LSsL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__epdfh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___7DZtg:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mRGxP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__us5Dk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__nJhx:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.algoButton__hqvEm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__h4K4Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xtZaS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__i2Kj9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eGhk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__eny1Q:global(.__wab_instance) {
  max-width: 100%;
}
.svg__oLx2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xr6Za {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__enxU1:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton__mWwth:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput232:global(.__wab_instance) {
  position: relative;
}
.svg__ftisi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yo9G0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ctejr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3LD1C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__aRnUj:global(.__wab_instance) {
  max-width: 100%;
}
.svg___306HA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kdRji {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__dGdoH:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__i145I {
  padding-left: 0px;
}
.algoButton__jp3Ci:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__iqauz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ayGa5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__h3Aje {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__upWHn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__se9H:global(.__wab_instance) {
  max-width: 100%;
}
.svg__rYwZ9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jrDyW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__e5CLs:global(.__wab_instance) {
  position: relative;
}
.text__t7Z1N {
  padding-left: 0px;
}
.algoButton__qwKeL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___5Z0Rb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nygNs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7VlsV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uHPrJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__xl1D1:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kdA8M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tGIkC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__d1Wc6:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__autnG:global(.__wab_instance) {
  position: relative;
}
.text___0Kuk7 {
  padding-left: 0px;
}
.algoButton__q9BzF:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__d15U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zP1Xk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5KV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aY5E4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ljOd9:global(.__wab_instance) {
  max-width: 100%;
}
.svg__py0IA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pzcdQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__uy7Sr:global(.__wab_instance) {
  position: relative;
}
.text__wCrFi {
  padding-left: 0px;
}
.algoButton__v0XVq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__j22Hz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wyC1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1LjyL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vIv51 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__u1KyL:global(.__wab_instance) {
  max-width: 100%;
}
.svg___0U5P2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sEGjW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___95EaA:global(.__wab_instance) {
  position: relative;
}
.text__aGuGk {
  padding-left: 0px;
}
.algoButton__ypj14:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6Z1Ga {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sUrw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hgFlk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__tbVmT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zk1Bm:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ksniu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__s8Asi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__nA5Cl:global(.__wab_instance) {
  position: relative;
}
.text___0Z6Ng {
  padding-left: 0px;
}
.algoButton__ld9TZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__lT7Q1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rubSp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__u4Lc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lZd3V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___8APrg:global(.__wab_instance) {
  max-width: 100%;
}
.svg___0MUXv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xbFuk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__g9Mob:global(.__wab_instance) {
  position: relative;
}
.text___9IAmn {
  padding-left: 0px;
}
.algoButton__cxgvS:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___1GMc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__anbn6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vfEd1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__g5A34 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__sudoh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lkGwv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rwYs9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__tV19Z:global(.__wab_instance) {
  position: relative;
}
.text__f4YAa {
  padding-left: 0px;
}
.algoButton__eYp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___5R2I5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__l68Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5Gi3X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__oYjsq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iNkm:global(.__wab_instance) {
  max-width: 100%;
}
.svg___025Ou {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4SSWk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__dehlq:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.text__wLCm {
  padding-left: 0px;
}
.algoButton__g9EhR:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__xiJT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2FT22 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5RhHi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kjick {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___0Rn:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lerc0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eetnC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__e9Q4E:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton___934JO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput212:global(.__wab_instance) {
  position: relative;
}
.svg___91M7Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zMyl9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hxUaJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4JGhN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zQo9X:global(.__wab_instance) {
  max-width: 100%;
}
.svg___4Ye0Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tuwFg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__qlHpa:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text___9Hlzi {
  padding-left: 0px;
}
.algoButton__leuKq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__iHwyl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mgEr5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fpS7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6Erjg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ae3D8:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xoRi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xid12 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__v7Kp:global(.__wab_instance) {
  position: relative;
}
.text__zwtCp {
  padding-left: 0px;
}
.algoButton__zdpj1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0QJix {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1FNq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vgRMj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7A5Wm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__glSqG:global(.__wab_instance) {
  max-width: 100%;
}
.svg__z4OdW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__w5BhU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow___2VxvY:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.herleitungTableCell__zgZs6:global(.__wab_instance) {
  position: relative;
}
.text__iuqkg {
  padding-left: 0px;
}
.algoButton___0ZXxj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vcp5S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ynnFk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__iezgs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vsObI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__rEaaA:global(.__wab_instance) {
  max-width: 100%;
}
.svg___5P8Eh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bDknW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__is8Ty:global(.__wab_instance) {
  position: relative;
}
.text__ke7Rt {
  padding-left: 0px;
}
.algoButton__cYirh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__mAfxe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wwJ0S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yFze3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uxddX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iBa8J:global(.__wab_instance) {
  max-width: 100%;
}
.svg__s57Xa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kzV40 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___8Lah:global(.__wab_instance) {
  position: relative;
}
.text__jnWfk {
  padding-left: 0px;
}
.algoButton__xj5N:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__khJ9U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dYk6Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___15JoM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__oObj6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__y9X0B:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uPbeB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2SiOp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__xTIj6:global(.__wab_instance) {
  position: relative;
}
.text__zn0HP {
  padding-left: 0px;
}
.algoButton__soPpC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__p0JIz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__a0I4O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__f3UIj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zaP6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mvYCa:global(.__wab_instance) {
  max-width: 100%;
}
.svg__cBeov {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rm9Q1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__yMydX:global(.__wab_instance) {
  position: relative;
}
.text__cr80 {
  padding-left: 0px;
}
.algoButton__lnauJ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__r7RD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__b90Nk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___8HkYo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uHxTr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___4F3MY:global(.__wab_instance) {
  max-width: 100%;
}
.svg__h6FvE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__umfHa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__e1GGj:global(.__wab_instance) {
  position: relative;
}
.text__dJsZk {
  padding-left: 0px;
}
.algoButton__fshZe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__g8Dqp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2LSJz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___3V5MV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bAdRs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uDnkS:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fjeU4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pPqTz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__sEAzb:global(.__wab_instance) {
  position: relative;
}
.text__qjHpk {
  padding-left: 0px;
}
.algoButton__qnXp4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__w9OkX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6DAtW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sAg3C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__n0NYi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__m0Fd6:global(.__wab_instance) {
  max-width: 100%;
}
.svg___5ZGpC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jv1Sw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__fvdq:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__x77 {
  padding-left: 0px;
}
.algoButton__sQ09:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput112:global(.__wab_instance) {
  position: relative;
}
.svg__j5A54 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__p2O2M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tloCe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wmhXh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__jpw47:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ys9Nf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__m813L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__z4JRb:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__jpGhk {
  padding-left: 0px;
}
.algoButton__eMTz9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cCsOr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ypKrL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lN9Ok {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8Ok1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pxEe:global(.__wab_instance) {
  max-width: 100%;
}
.svg___8RLo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rcdm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__inJi:global(.__wab_instance) {
  position: relative;
  width: 100px;
}
.text__ldeP7 {
  padding-left: 0px;
}
.algoButton__h3Sil:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___7Ut00 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jaq7H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oGxhc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7MGo3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3Xrju:global(.__wab_instance) {
  max-width: 100%;
}
.svg__nKtMu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kTokg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.divider___53X18:global(.__wab_instance) {
  max-width: 100%;
  margin-top: var(--token-8IFSbVVlb);
  margin-bottom: var(--token-8IFSbVVlb);
  flex-shrink: 0;
}
.herleitungNhkRow__odby3:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__xv9Nc:global(.__wab_instance) {
  position: relative;
}
.text__ubKrl {
  padding-left: 0px;
}
.algoButton__vSnEp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hXxon {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yNc75 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ttHn7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__euYmn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___2Al1I:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gZvxi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uxIy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__fh2Qe:global(.__wab_instance) {
  position: relative;
}
.text__nEzDl {
  padding-left: 0px;
}
.algoButton__bPsGo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ndjV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ji66L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7XkUf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wdHul {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uxZ7:global(.__wab_instance) {
  max-width: 100%;
}
.svg___1TGoH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__p0EdW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__t2JCw:global(.__wab_instance) {
  position: relative;
}
.text__oaUmm {
  padding-left: 0px;
}
.algoButton__qUcWn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ne1BS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ic7Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gci1M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ttO0E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pPqG7:global(.__wab_instance) {
  max-width: 100%;
}
.svg__wxWd3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pZett {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___6MGNk:global(.__wab_instance) {
  position: relative;
}
.text__mqhXw {
  padding-left: 0px;
}
.algoButton___03Z6F:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vNqfX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uge2H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k28Po {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7B6EE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__caC4V:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dWFjq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fyijZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__cL26K:global(.__wab_instance) {
  position: relative;
}
.text__rXHaT {
  padding-left: 0px;
}
.algoButton__jaDsd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__br3Ek {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tMfZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__plFcF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__y5Mdn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__viItP:global(.__wab_instance) {
  max-width: 100%;
}
.svg__iQ6F4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kf3X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__xKzZt:global(.__wab_instance) {
  position: relative;
}
.text___1D4Y0 {
  padding-left: 0px;
}
.algoButton___9Tyw5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput133:global(.__wab_instance) {
  position: relative;
}
.svg___66EiS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bTlLq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kh2FL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__to466 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__rEjL0:global(.__wab_instance) {
  max-width: 100%;
}
.svg___21ZZo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sMetw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__vw1MA:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.text__gGj5N {
  padding-left: 0px;
}
.algoButton___1T09F:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ykH1T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uFa8J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___8442G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gkSvO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___8HQrn:global(.__wab_instance) {
  max-width: 100%;
}
.svg___9D9Qu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ymEw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__oi9W:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton__giFps:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput132:global(.__wab_instance) {
  position: relative;
}
.svg__kth3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fdsv1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___35Vc2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yKdFh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__m6Ijp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ppgJi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__seFew {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___2Xywt:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__s6Byt {
  padding-left: 0px;
}
.algoButton__vQPhs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__s3X0L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fy8BO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6BDps {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__za9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__clQ20:global(.__wab_instance) {
  max-width: 100%;
}
.svg___932Z1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__udoDk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__bANa:global(.__wab_instance) {
  position: relative;
}
.text__wxLe7 {
  padding-left: 0px;
}
.algoButton__qstm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nEj1U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6WhC2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qRYdG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gd1Gg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__wTNu5:global(.__wab_instance) {
  max-width: 100%;
}
.svg___9HMcQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5MAhQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardSection__hqqrc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__rwmk2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__cIn8O {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.herleitungNhkRow__aGnf:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__foV96:global(.__wab_instance) {
  position: relative;
}
.text__fNkwa {
  padding-left: 0px;
}
.algoButton__xnrUb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tr5Ab {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vFzzL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xNPT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1KHxs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__xZPc9:global(.__wab_instance) {
  max-width: 100%;
}
.svg__wbWoF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xyJl5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__g5Gg6:global(.__wab_instance) {
  position: relative;
}
.text__g4T48 {
  padding-left: 0px;
}
.algoButton__cOTwu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dqUHb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__b7VZq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dLx04 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vatJl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__cxz0K:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ibY11 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7TFhh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___1JTmI:global(.__wab_instance) {
  position: relative;
}
.text__zw6S6 {
  padding-left: 0px;
}
.algoButton__d3SWg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ea73 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kcsZu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oVblR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vAuqv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lGp6:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fxwsg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kxcCb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__b3CWi:global(.__wab_instance) {
  position: relative;
}
.text__zM3Xi {
  padding-left: 0px;
}
.algoButton__pczZg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nakYv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qrM8U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__w4VN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gp3Ia {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uB8Kg:global(.__wab_instance) {
  max-width: 100%;
}
.svg__neLwq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uceSp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___6DqYw:global(.__wab_instance) {
  position: relative;
}
.text__mqxxT {
  padding-left: 0px;
}
.algoButton__tbpDd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rmiUd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__s9QbU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vQiWl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kf54O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uyl6C:global(.__wab_instance) {
  max-width: 100%;
}
.svg__no2Gp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xlqRm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___8Nswt:global(.__wab_instance) {
  position: relative;
}
.text___4Mdv1 {
  padding-left: 0px;
}
.algoButton__yPPys:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___92KMz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lUlo8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xLxSj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ld1DK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___9Nm5B:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mfBxI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kCoyO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___3ZTrm:global(.__wab_instance) {
  position: relative;
}
.text___2Cvaj {
  padding-left: 0px;
}
.algoButton__gaXiN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__p7RuB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2M0Ql {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mVqIl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qZat {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___4JVu7:global(.__wab_instance) {
  max-width: 100%;
}
.svg__g1QEf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mYwY8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__r1ThD:global(.__wab_instance) {
  position: relative;
}
.text__xAxCr {
  padding-left: 0px;
}
.algoButton__bFr8H:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput260:global(.__wab_instance) {
  position: relative;
}
.svg__gg5Ud {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uba8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__iTfD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___0Jq9R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__thJkp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__v9V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nu7X2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__yi5Ta:global(.__wab_instance) {
  position: relative;
}
.text___99RQc {
  padding-left: 0px;
}
.algoButton___7FznS:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__heFcc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vwymO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yvjcU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__t3Wy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__jwpw3:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kfvrs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ohU5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___6Zjv:global(.__wab_instance) {
  position: relative;
}
.text__nuIPk {
  padding-left: 0px;
}
.algoButton__exmKa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__e1Xf4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oCrMt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hvull {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wsPQe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__jhIzP:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xroK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__h0Pf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__ekWzl {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  min-width: 0;
}
.freeBox__rCbx3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox__zoA4M {
  display: flex;
  flex-direction: row;
  width: 280px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
}
.freeBox__reJiw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBox__qjqe2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 300px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.text__vCBer {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: center;
}
.freeBox__dtvGs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 80px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__dtvGs > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__dtvGs > :global(.__wab_flex-container) > *,
.freeBox__dtvGs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dtvGs > :global(.__wab_flex-container) > picture > img,
.freeBox__dtvGs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__tRyy9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 90px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__tRyy9 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__tRyy9 > :global(.__wab_flex-container) > *,
.freeBox__tRyy9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tRyy9 > :global(.__wab_flex-container) > picture > img,
.freeBox__tRyy9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__qbsJ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__qbsJ > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__qbsJ > :global(.__wab_flex-container) > *,
.freeBox__qbsJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qbsJ > :global(.__wab_flex-container) > picture > img,
.freeBox__qbsJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__mhMc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__mhMc > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__mhMc > :global(.__wab_flex-container) > *,
.freeBox__mhMc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mhMc > :global(.__wab_flex-container) > picture > img,
.freeBox__mhMc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__lK64W {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__lK64W > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__lK64W > :global(.__wab_flex-container) > *,
.freeBox__lK64W > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lK64W > :global(.__wab_flex-container) > picture > img,
.freeBox__lK64W
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__rZzHg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox__s55L {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  flex-shrink: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBox__kbbNp {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__zZGaQ {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  padding-right: 0px;
}
.divider__aKdXj:global(.__wab_instance) {
  max-width: 100%;
  height: 25%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.freeBox__vncni {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__xX3V1 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__vYdDo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__x4VvO {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.divider__mDcRz:global(.__wab_instance) {
  max-width: 100%;
  height: 25%;
  position: absolute;
  left: auto;
  top: 0px;
  right: 0px;
}
.freeBox__emNXb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__emNXb > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__emNXb > :global(.__wab_flex-container) > *,
.freeBox__emNXb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__emNXb > :global(.__wab_flex-container) > picture > img,
.freeBox__emNXb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__k61Y {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 120px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__k61Y > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__k61Y > :global(.__wab_flex-container) > *,
.freeBox__k61Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__k61Y > :global(.__wab_flex-container) > picture > img,
.freeBox__k61Y
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__dvcWc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__dvcWc > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__dvcWc > :global(.__wab_flex-container) > *,
.freeBox__dvcWc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dvcWc > :global(.__wab_flex-container) > picture > img,
.freeBox__dvcWc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__dzw4M {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 110px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__dzw4M > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__dzw4M > :global(.__wab_flex-container) > *,
.freeBox__dzw4M > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dzw4M > :global(.__wab_flex-container) > picture > img,
.freeBox__dzw4M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__ncMtq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__ncMtq > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__ncMtq > :global(.__wab_flex-container) > *,
.freeBox__ncMtq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ncMtq > :global(.__wab_flex-container) > picture > img,
.freeBox__ncMtq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox__dUuvg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.herleitungNhkRow__qq5VZ:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__eBaMi:global(.__wab_instance) {
  position: relative;
}
.text___4TgUl {
  padding-left: 0px;
}
.algoButton__fbDen:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wmvNr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uPn2P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___9GwTe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gdUvR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__pnUEi:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yjXyL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mh3Vf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__gEpHv:global(.__wab_instance) {
  position: relative;
}
.text__z4E3I {
  padding-left: 0px;
}
.algoButton___9Rv49:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__xRvQv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wyEdo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5VC8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6CmXf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__cjBcy:global(.__wab_instance) {
  max-width: 100%;
}
.svg___54EaI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pJvoH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__hsKe8:global(.__wab_instance) {
  position: relative;
}
.text__fefvd {
  padding-left: 0px;
}
.algoButton__zGHa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wwJc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2DK7L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__o6Vga {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vfGsl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__rVbYo:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fZw6H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wXOkT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__coC3B:global(.__wab_instance) {
  position: relative;
}
.text__tpUg4 {
  padding-left: 0px;
}
.algoButton___7FqDr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__peIh1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hAtKo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gbf3T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rD20L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___0Uvx:global(.__wab_instance) {
  max-width: 100%;
}
.svg__aiVF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dZ0Nd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jgEdn:global(.__wab_instance) {
  position: relative;
}
.text__gXtH8 {
  padding-left: 0px;
}
.algoButton__deRbA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__v3Jwo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3Fd3L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__n7Ys {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qQaM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__vDbal:global(.__wab_instance) {
  max-width: 100%;
}
.svg__p8FW2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__okJ98 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__a7HkI:global(.__wab_instance) {
  position: relative;
}
.text__rvHpz {
  padding-left: 0px;
}
.algoButton__yfpNo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___16Ce {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gh71U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___15Xc6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fb1Cq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__aNo6C:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dvTzB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__htdin {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__x6SU3:global(.__wab_instance) {
  position: relative;
}
.text__i09Pn {
  padding-left: 0px;
}
.algoButton__pSMor:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__bb01K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vYT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___582Mx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gC0O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ou6Hn:global(.__wab_instance) {
  max-width: 100%;
}
.svg__m5OWc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cOnIl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__sbEh8:global(.__wab_instance) {
  position: relative;
}
.text__zvQwo {
  padding-left: 0px;
}
.algoButton__cFGqV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput261:global(.__wab_instance) {
  position: relative;
}
.svg__creqS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8FYwb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gj7Ac {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hKtxZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ovex:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dfBy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hUIz1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___1Run:global(.__wab_instance) {
  position: relative;
}
.text___6LLtr {
  padding-left: 0px;
}
.algoButton__esJUn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___8LeEn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__polqP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vpIy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cX47N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__xloT:global(.__wab_instance) {
  max-width: 100%;
}
.svg__n1QJx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eKddV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__aEnO9:global(.__wab_instance) {
  position: relative;
}
.text__qVdgH {
  padding-left: 0px;
}
.algoButton__l0469:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cYyF6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mwpnj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2Dz3R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ti05K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__fEjmr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xj2Qs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__o5OZs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow___6JdVc:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell__gcstw:global(.__wab_instance) {
  position: relative;
}
.text__s9Twv {
  padding-left: 0px;
}
.algoButton__fr6FH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__tYx5R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pyTAy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lqnkt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__st8R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___1Sq0:global(.__wab_instance) {
  max-width: 100%;
}
.svg__aqdGl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__x92Lg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__mylLm:global(.__wab_instance) {
  position: relative;
}
.text__xa7Db {
  padding-left: 0px;
}
.algoButton__cYomM:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__ox34A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dAoa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ylnjk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__pXeKh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___48Cq0:global(.__wab_instance) {
  max-width: 100%;
}
.svg__jaPfU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xYhon {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__wBwHb:global(.__wab_instance) {
  position: relative;
}
.text__wSt3S {
  padding-left: 0px;
}
.algoButton___0Q6Im:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__xd9H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fTGnc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xio {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vpGpY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__kvWjB:global(.__wab_instance) {
  max-width: 100%;
}
.svg__rwacY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qPl0Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__dsMtq:global(.__wab_instance) {
  position: relative;
}
.text___67L {
  padding-left: 0px;
}
.algoButton__x1AwV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__obExd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3N5Zk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__h5Aed {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uhxXs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__apHFv:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vowc7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7Ybr2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___35NMe:global(.__wab_instance) {
  position: relative;
}
.text__cpVtt {
  padding-left: 0px;
}
.algoButton__wMNer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__u22Tq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yz2Np {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vvHp3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zlpkp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__aUp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__opgOb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__v47Un {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___9CPWe:global(.__wab_instance) {
  position: relative;
}
.text__qqCeK {
  padding-left: 0px;
}
.algoButton__cN86:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___1Xg4M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__whDwU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__j4WjJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__pQoJl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__fhrFq:global(.__wab_instance) {
  max-width: 100%;
}
.svg__o1Z43 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__k5ZoX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__nflXy:global(.__wab_instance) {
  position: relative;
}
.text__ry2C {
  padding-left: 0px;
}
.algoButton__clgu7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__bbtxn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jsTgi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zWlhy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__tU0Fo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ikJvp:global(.__wab_instance) {
  max-width: 100%;
}
.svg___0Ainq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wEbob {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__tBNjC:global(.__wab_instance) {
  position: relative;
}
.text__jRzPp {
  padding-left: 0px;
}
.algoButton__meU8Y:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput262:global(.__wab_instance) {
  position: relative;
}
.svg__hPz2D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wHEb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ll9Ar {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__j8JhK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__dl41R:global(.__wab_instance) {
  max-width: 100%;
}
.svg__cc8CP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__y11Ae {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__tMtfX:global(.__wab_instance) {
  position: relative;
}
.text__fInd {
  padding-left: 0px;
}
.algoButton__z7EwY:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__yijnd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rZefp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zoZtg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__beRn2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iYoCh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__n9JEw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__atro {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___0Ti1:global(.__wab_instance) {
  position: relative;
}
.text___6LjM {
  padding-left: 0px;
}
.algoButton__fHfUi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6Z3Wk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gAiBt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bDu5N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__nLykL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__gZhFc:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lJv55 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xxnny {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.divider__xnQr:global(.__wab_instance) {
  max-width: 100%;
  margin-top: var(--token-8IFSbVVlb);
  margin-bottom: var(--token-8IFSbVVlb);
  flex-shrink: 0;
}
.herleitungNhkRow__jjpAn:global(.__wab_instance) {
  max-width: 100%;
}
.herleitungTableCell___8M9Kf:global(.__wab_instance) {
  position: relative;
}
.text__do44P {
  padding-left: 0px;
}
.algoButton___2E0Ek:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__bItAw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oyBzs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__v0Hhy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3VyIo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__oBrei:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hr8M9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9XRSv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__xmpF2:global(.__wab_instance) {
  position: relative;
}
.text__sYeLr {
  padding-left: 0px;
}
.algoButton__uFf8V:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gfX8V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cEvMn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__v8Ih {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hmTgQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lZtU:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kvFe4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__op6T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__fqFey:global(.__wab_instance) {
  position: relative;
}
.text__zo4CU {
  padding-left: 0px;
}
.algoButton__mWdX8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__deXfi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eOc3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aBigi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uLhiL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__wYh8C:global(.__wab_instance) {
  max-width: 100%;
}
.svg___5MJrv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6UImx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__i6VPh:global(.__wab_instance) {
  position: relative;
}
.text__yMpsD {
  padding-left: 0px;
}
.algoButton__gFqK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6E2Ud {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eeoWf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__r4Kup {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5A4Ur {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__wYxI:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ow0Yw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vQaNm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__pirZg:global(.__wab_instance) {
  position: relative;
}
.text__cGpMb {
  padding-left: 0px;
}
.algoButton__utpA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___4JMc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bAv3U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__y7Ety {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qvHqA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mzWhF:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gnOqC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__typqk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__xkixp:global(.__wab_instance) {
  position: relative;
}
.text__mc0Vr {
  padding-left: 0px;
}
.algoButton__cmEiM:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput271:global(.__wab_instance) {
  position: relative;
}
.svg__m2UtD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cqGf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jiGc9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hVEfN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__nOYeh:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3KSBc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i4Cvr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__dehtk:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.text__d8WN {
  padding-left: 0px;
  padding-right: 0px;
}
.algoButton__zoBHs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qq2Ey {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zdJ36 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__j18Hw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8OvMr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mnNk0:global(.__wab_instance) {
  max-width: 100%;
}
.svg__koCkz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fSb0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__jAoFp:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton___6FGn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput272:global(.__wab_instance) {
  position: relative;
}
.svg__fhmjM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6YoT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jmWxl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sayP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__lxm2V:global(.__wab_instance) {
  max-width: 100%;
}
.svg___72Rfh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kfEi4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__sZeZh:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__metqM {
  padding-left: 0px;
}
.algoButton__sgRKs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__h3AQu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kgyrl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mVk96 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1W4Bm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__daren:global(.__wab_instance) {
  max-width: 100%;
}
.svg___2Doty {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cBe27 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__iBeYh:global(.__wab_instance) {
  position: relative;
}
.text__x7TUx {
  padding-left: 0px;
}
.algoButton___3CHn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__iFqRx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aiBcr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dQciD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hMk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__cMjjf:global(.__wab_instance) {
  max-width: 100%;
}
.svg__cGpd0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9EmL3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungNhkRow__vt9Ji:global(.__wab_instance) {
  max-width: 100%;
}
.text__jeiyz {
  padding-left: 0px;
}
.herleitungTableCell__pqibr:global(.__wab_instance) {
  position: relative;
}
.text__i82Uq {
  padding-left: 0px;
}
.algoButton__mwYp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pjHzI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__txQjT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zH6Fm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__la5As {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__zZowZ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qexsm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uog72 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ixTd7:global(.__wab_instance) {
  position: relative;
}
.text__rpexm {
  padding-left: 0px;
}
.algoButton__rKckl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ypUxd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jUhbQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rqkee {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5CuNo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ykzzC:global(.__wab_instance) {
  max-width: 100%;
}
.svg__keHme {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fh9IG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__cXoaM:global(.__wab_instance) {
  position: relative;
}
.text__gZ1Ae {
  padding-left: 0px;
}
.algoButton___04EFa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__a9XJq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dcmgf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___58Pt3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__r3Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__hwhEy:global(.__wab_instance) {
  max-width: 100%;
}
.svg__pVh3Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xj6L2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__ad91S:global(.__wab_instance) {
  position: relative;
}
.text__wkkXg {
  padding-left: 0px;
}
.algoButton__ukS5L:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__oJOs8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zsK8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oVdyw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mtEhZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__vty0Q:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gAeOp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mJj14 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__eVG0:global(.__wab_instance) {
  position: relative;
}
.text__eWlD {
  padding-left: 0px;
}
.algoButton__tsPgp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rtqlm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___28Fw4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__z97Dw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sr5QE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__i3Nh6:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mQp24 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jdGey {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__upsRv:global(.__wab_instance) {
  position: relative;
}
.text__x2H1T {
  padding-left: 0px;
}
.algoButton___3KI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput281:global(.__wab_instance) {
  position: relative;
}
.svg__rpIqg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wV8Gq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__c4PEg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__o0Wkx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___7Isrb:global(.__wab_instance) {
  max-width: 100%;
}
.svg___2PjY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nPi1H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__zPq9:global(.__wab_instance) {
  position: relative;
  width: 120px;
}
.text__lYwdv {
  padding-left: 0px;
}
.algoButton___0S1AI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__bUpDw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bLwZc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bLaF1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vYe3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__fAez7:global(.__wab_instance) {
  max-width: 100%;
}
.svg___98OOz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fmhIw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__sv0Hc:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.algoButton___1QMp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput282:global(.__wab_instance) {
  position: relative;
}
.svg__l5H8F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zpzVr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wSnzr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__z7VMf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__h9ITv:global(.__wab_instance) {
  max-width: 100%;
}
.svg__bTs1P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__krOgj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell__tz82L:global(.__wab_instance) {
  position: relative;
  width: 110px;
}
.text__ntMqP {
  padding-left: 0px;
}
.algoButton__nXwAe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__xVwIl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gzXcc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vg8J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__k25Sc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__mdOqy:global(.__wab_instance) {
  max-width: 100%;
}
.svg__i1A2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iFshL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.herleitungTableCell___9AzmB:global(.__wab_instance) {
  position: relative;
}
.text___0L8Wt {
  padding-left: 0px;
}
.algoButton__lSFu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zmvra {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ojBm1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gYAi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kadKe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__r1Yzi:global(.__wab_instance) {
  max-width: 100%;
}
.svg___7Pcln {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uXqeY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.formGroup__bDmsp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__aRQc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7YNOi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__iMtBd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kQr1I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter:global(.__wab_instance) {
  position: relative;
}
.text__r798K {
  font-weight: 400;
}
.formGroup__rdri:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__tfmT:global(.__wab_instance) {
  position: relative;
}
.svg__rq1Rz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ztCpE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__i5TD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__u9BqE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__lprZr:global(.__wab_instance) {
  position: relative;
}
.text__uysC5 {
  padding-right: 0px;
}
.svg__gqcqr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eWxPf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pZoyp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processBottomNav:global(.__wab_instance) {
  position: fixed;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processBottomNav:global(.__wab_instance) {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
@media (max-width: 480px) {
  .processBottomNav:global(.__wab_instance) {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.closeButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___2OiQr {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___1DWyN {
  height: auto;
}
.svg__gOIs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.saveButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__lz0Zc {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__agUyp {
  height: auto;
}
.svg__gp33N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
