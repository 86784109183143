.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.freeBox {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-MZ_89NRuN);
  grid-row-gap: var(--token-MZ_89NRuN);
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
.energyConsumptionType:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 5;
}
@media (max-width: 480px) {
  .energyConsumptionType:global(.__wab_instance) {
    grid-column-end: span 6;
  }
}
.select:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  min-width: 0;
}
.svg__oj4I7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.option__xv8Bj:global(.__wab_instance) {
  position: relative;
}
.svg__caOo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___6SSrR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___2MzQ:global(.__wab_instance) {
  position: relative;
}
.svg__cSOs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__u9R5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__ucEdg:global(.__wab_instance) {
  position: relative;
}
.svg__vu5GY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__bNrq8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__oAaMf:global(.__wab_instance) {
  position: relative;
}
.svg__yoZz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__n1Z32 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__sXmti:global(.__wab_instance) {
  position: relative;
}
.svg__vfVmb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__pjd0E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___22Lrg:global(.__wab_instance) {
  position: relative;
}
.svg___9MAaq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__dkeq4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__zRfsw:global(.__wab_instance) {
  position: relative;
}
.svg__z7NWc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__thRAt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__a2Ous:global(.__wab_instance) {
  position: relative;
}
.svg__whKe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__lTOtO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___0VPEg:global(.__wab_instance) {
  position: relative;
}
.svg__bIs4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__t1Ivq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__aXdRs:global(.__wab_instance) {
  position: relative;
}
.svg__vkUo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__fydlF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__enjAt:global(.__wab_instance) {
  position: relative;
}
.svg__aQLim {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__bD5X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___4ZayK:global(.__wab_instance) {
  position: relative;
}
.svg__it5Ir {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__rBhfr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___3ZE:global(.__wab_instance) {
  position: relative;
}
.svg__am91L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__tyLl8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__ydRcZ:global(.__wab_instance) {
  position: relative;
}
.svg__wQQsD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__eTIqO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__vfamx:global(.__wab_instance) {
  position: relative;
}
.svg__rfS5W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__dEgZc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__vO95I:global(.__wab_instance) {
  position: relative;
}
.svg__uYvu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__vfcXu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__bAv7F:global(.__wab_instance) {
  position: relative;
}
.svg__wUlhi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__yyCdp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__n6WnO:global(.__wab_instance) {
  position: relative;
}
.svg__pmuq3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__oi2N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__poYTk:global(.__wab_instance) {
  position: relative;
}
.svg__oVnQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__mZw6Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__hPlHl:global(.__wab_instance) {
  position: relative;
}
.svg__nsiz3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__cOmV2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__e2Puy:global(.__wab_instance) {
  position: relative;
}
.energyConsumptionNumber:global(.__wab_instance) {
  width: 100%;
  grid-column-end: span 2;
  min-width: 0;
}
.svg___183Yr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wq3W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  min-width: 0;
}
.text__fPgoi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__esoVi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___4Hpj7:global(.__wab_instance) {
  position: relative;
}
.energyConsumptionUnit:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 4;
  min-width: 0;
}
.select3:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  min-width: 0;
}
.svg__rMnb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.option__tFUhp:global(.__wab_instance) {
  position: relative;
}
.svg__fngps {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__sfgSs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__fKq0A:global(.__wab_instance) {
  position: relative;
}
.svg__sRxuf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__aMEnE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__ov5MS:global(.__wab_instance) {
  position: relative;
}
.svg___8DB3G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__l6Rn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__rHUfu:global(.__wab_instance) {
  position: relative;
}
.svg__iG7S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__hZ5Ij {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__faJgE:global(.__wab_instance) {
  position: relative;
}
.svg__ntpP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__r5K8T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__dsuoe:global(.__wab_instance) {
  position: relative;
}
.svg___2RuZl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__yrzUl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__svag:global(.__wab_instance) {
  position: relative;
}
.svg__sP0NF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__qv2P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__idvLe:global(.__wab_instance) {
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__h7Whi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
