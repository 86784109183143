.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 48px;
  min-width: 0;
}
.roottransparent {
  background: none;
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.freeBox__qGlO {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 32px 48px;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__qGlO > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__qGlO {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__qGlO > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: stretch;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 24px);
    height: calc(100% + 24px);
  }
}
@media (max-width: 480px) {
  .freeBox__qGlO > :global(.__wab_flex-container) > *,
  .freeBox__qGlO > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qGlO > :global(.__wab_flex-container) > picture > img,
  .freeBox__qGlO
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 24px;
  }
}
.freeBoxtransparent__qGlOG4TVi {
  border-top-style: none;
}
.freeBox__tEjoz {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__tEjoz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__tEjoz > :global(.__wab_flex-container) > *,
.freeBox__tEjoz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tEjoz > :global(.__wab_flex-container) > picture > img,
.freeBox__tEjoz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (max-width: 480px) {
  .freeBox__tEjoz {
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__tEjoz > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .freeBox__tEjoz > :global(.__wab_flex-container) > *,
  .freeBox__tEjoz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__tEjoz > :global(.__wab_flex-container) > picture > img,
  .freeBox__tEjoz
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.img___73MoO {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 32px;
}
.img___73MoO > picture > img {
  object-fit: cover;
}
.freeBox__p7Bb {
  display: flex;
  position: relative;
  flex-direction: column;
}
.text__l9TpZ {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
@media (max-width: 480px) {
  .text__l9TpZ {
    text-align: center;
  }
}
.freeBox__x9COa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
@media (max-width: 480px) {
  .freeBox__x9COa {
    justify-content: center;
  }
}
.text__mfRit {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
@media (max-width: 480px) {
  .text__mfRit {
    text-align: center;
  }
}
.dateRange {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
@media (max-width: 480px) {
  .dateRange {
    text-align: center;
  }
}
.img__wQnUm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 36px;
}
.img__wQnUm > picture > img {
  object-fit: cover;
}
@media (max-width: 480px) {
  .img__wQnUm {
    width: auto;
    align-self: center;
  }
}
