.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: 20px;
  background: var(--token-nJQnmTwmF4SPdl6);
  border-radius: 200px;
  padding: 2px 7px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-nPzqyQpH_);
}
.rootcolor_emerald {
  background: #c6ecd480;
}
.rootcolor_teal {
  background: #c2e3f080;
}
.rootcolor_purple {
  background: #cfd3ed80;
}
.rootcolor_eggshell {
  background: #efdfb280;
}
.rootcolor_clay {
  background: #efc8b280;
}
.rootisRemovable {
  padding-right: 5px;
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-top: 1px;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-nPzqyQpH_);
}
.slotTargetChildren {
  font-weight: 500;
  color: var(--token-PcBCP-f11VpIoWX);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  font-size: 11px;
  letter-spacing: 0.2px;
}
.slotTargetChildrencolor_emerald {
  color: #20713e;
}
.slotTargetChildrencolor_teal {
  color: #2f687e;
}
.slotTargetChildrencolor_purple {
  color: #545d92;
}
.slotTargetChildrencolor_eggshell {
  color: #594e2e;
}
.slotTargetChildrencolor_clay {
  color: #71442a;
}
.svg {
  object-fit: cover;
  width: var(--token-uoDiu2hxc);
  height: var(--token-uoDiu2hxc);
  color: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
}
.svgcolor_emerald {
  color: #20713e;
}
.svgcolor_teal {
  color: #2f687e;
}
.svgcolor_purple {
  color: #545d92;
}
.svgcolor_eggshell {
  color: #594e2e;
}
.svgcolor_clay {
  color: #71442a;
}
.svgisRemovable {
  display: block;
}
