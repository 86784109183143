.labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: auto;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox__pWAq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  padding: 8px;
}
.text__zYgoZ {
  width: auto;
  height: auto;
  max-width: 100%;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  font-size: var(--token-Jhhay0F31-lTY91);
}
.text__p9Q3E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  min-width: 0;
}
.h5 {
  margin-bottom: 0px;
}
.freeBox__zhJoC {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-MZ_89NRuN);
  padding-bottom: var(--token-MZ_89NRuN);
  min-width: 0;
}
.publishLabels:global(.__wab_instance) {
  max-width: 100%;
}
.svg___2G9WO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.labelsGrid {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.labelsGridisProduction {
  display: none;
}
.freeBox__ux66X {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      var(--token-nJQnmTwmF4SPdl6),
      var(--token-nJQnmTwmF4SPdl6)
    ),
    #ffffff;
  min-width: 0;
  padding: 8px;
}
.freeBoxisProduction__ux66X6Nb8X {
  display: none;
}
.text__q9Wii {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  min-width: 0;
}
.freeBox__rxYqx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.plasmic {
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  min-width: 25%;
}
.plasmicJsonUpload {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__nRzMh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
  min-width: 0;
  margin: var(--token-hFcnMUlxM);
}
.freeBox__piZfT {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.plasmic2 {
  height: auto;
  color: var(--token-5eiHu7b54PkQh5O);
  min-width: 25%;
  max-width: 100%;
  width: 25%;
}
.dropDownJsonUpload {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
  margin: var(--token-MZ_89NRuN);
}
.text__fbz3U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
  min-width: 0;
  margin: var(--token-hFcnMUlxM);
}
