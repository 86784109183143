.root {
  display: flex;
  flex-direction: row;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 6px;
  margin-top: 12px;
}
.buttonGroup:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button__uk4th:global(.__wab_instance) {
  max-width: 100%;
}
.svg___9PctD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yXjJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__prkh6:global(.__wab_instance) {
  max-width: 100%;
}
.svg__bO3Ru {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox {
  padding-left: 0px;
  display: flex;
  flex-direction: row;
}
.svg__e5DG7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
