.root {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-row-gap: var(--token-SAmzyWXiq);
  grid-column-gap: var(--token-cTBZAIcMp);
  position: relative;
  min-width: 0;
}
.hoverBg {
  display: block;
  position: relative;
  width: auto;
  height: auto;
  left: auto;
  top: auto;
  background: var(--token-NPNMQf7VB8u7Kel);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  right: auto;
  bottom: auto;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 4px;
}
.root:hover .hoverBg {
  background: var(--token-enYJIO6w3sEL0NO);
  left: auto;
  right: auto;
}
.freeBox__nmLa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 24;
  min-width: 0;
}
.freeBox__kZa0U {
  display: flex;
  flex-direction: row;
  width: 280px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.freeBox__gaVfY {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.slotTargetTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.freeBox__bgDh3 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell__ky948:global(.__wab_instance) {
  position: relative;
}
.text__nXgAm {
  padding-left: 0px;
}
.algoButton__xoU72:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ntpsZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tQmVn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__egRVu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bsd5K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__iCuDk:global(.__wab_instance) {
  max-width: 100%;
}
.svg___8Unw2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6QSp3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__ms5FN {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell__j0Hl:global(.__wab_instance) {
  position: relative;
}
.text__cXdw9 {
  padding-left: 0px;
}
.algoButton__vBelk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___8Zoqr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__w9BkU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2VBSw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7P04Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___71W2H:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3ZuwZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yzoeo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___7PlCr {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell__xSlEs:global(.__wab_instance) {
  position: relative;
}
.text___1Oe9P {
  padding-left: 0px;
}
.algoButton__pZq6E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vcmyB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tQxSy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dNyR4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8RqT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__alOIj:global(.__wab_instance) {
  max-width: 100%;
}
.svg__z25EP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iRnxw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__pLnAh {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell__vseK:global(.__wab_instance) {
  position: relative;
}
.text___6YRbC {
  padding-left: 0px;
}
.algoButton__zrvke:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gwfA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kcAZl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aba9T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sfXsi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__uRatC:global(.__wab_instance) {
  max-width: 100%;
}
.svg__q6BPi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8LqTs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__ousY {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell___0Ajtc:global(.__wab_instance) {
  position: relative;
}
.text__vrLzi {
  padding-left: 0px;
}
.algoButton__htTNe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__jp1P1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wCk9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___20Ba6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rwu6J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__qex28:global(.__wab_instance) {
  max-width: 100%;
}
.svg__iZbEe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tXurL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___2C6Iu {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 80px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell___9ZxO5:global(.__wab_instance) {
  position: relative;
}
.text__y9H1H {
  padding-left: 0px;
}
.algoButton___8DpMf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___7DHt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___46Pcz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4P3PK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___9OnKb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__yNlCc:global(.__wab_instance) {
  max-width: 100%;
}
.svg__coiAe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5ZoJd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__k79J {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 110px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell___9JkJf:global(.__wab_instance) {
  position: relative;
}
.text__nze3J {
  padding-left: 0px;
}
.algoButton__k3Dty:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wZed {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__anHq9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mtie8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__c7S2K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___3DOkC:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gtZsz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nZ4Xv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___1R8N1 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 110px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell__osKod:global(.__wab_instance) {
  position: relative;
}
.text__z9SkH {
  padding-left: 0px;
}
.algoButton__b9BcB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput___08IY9:global(.__wab_instance) {
  position: relative;
}
.svg___0Qiii {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lO8Yl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1Br6D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__od0Ry {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___0CpW:global(.__wab_instance) {
  max-width: 100%;
}
.svg__l7Ttd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uit4G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__k09Pk {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 110px;
  height: auto;
  flex-shrink: 0;
}
.herleitungTableCell__lGkPq:global(.__wab_instance) {
  position: relative;
}
.text__bMfe2 {
  padding-left: 0px;
}
.algoButton___55Qsp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6Xhy4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__miM6M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__njKSv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gXAnB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button__ju0WG:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dFnrY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hqyUe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__kpWma {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.herleitungTableCell__eVlEz:global(.__wab_instance) {
  position: relative;
}
.text__bxFt0 {
  padding-left: 0px;
}
.algoButton__r3GL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sE0Wa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oMUg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__w7Ukz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___616U7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.button___69Bsm:global(.__wab_instance) {
  max-width: 100%;
}
.svg__j0Gx3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uprxY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
