.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-Lymfn4M1YIjdOgD);
  min-width: 0;
  min-height: 0;
}
.navigation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__y2UJc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 88px 24px;
}
.freeBox__y2UJc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 56px);
  height: calc(100% + 56px);
}
.freeBox__y2UJc > :global(.__wab_flex-container) > *,
.freeBox__y2UJc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__y2UJc > :global(.__wab_flex-container) > picture > img,
.freeBox__y2UJc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 56px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__y2UJc {
    display: flex;
  }
}
@media (max-width: 480px) {
  .freeBox__y2UJc {
    display: flex;
    position: relative;
    padding: 40px 16px;
  }
}
.freeBox__iMb4U {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__iMb4U > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 80px);
  height: calc(100% + 80px);
}
.freeBox__iMb4U > :global(.__wab_flex-container) > *,
.freeBox__iMb4U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__iMb4U > :global(.__wab_flex-container) > picture > img,
.freeBox__iMb4U
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 80px;
}
.freeBox__mXe9Y {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__mXe9Y > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__mXe9Y > :global(.__wab_flex-container) > *,
.freeBox__mXe9Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mXe9Y > :global(.__wab_flex-container) > picture > img,
.freeBox__mXe9Y
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.freeBox__bqrpq {
  display: flex;
  flex-direction: column;
}
.freeBox__bqrpq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__bqrpq > :global(.__wab_flex-container) > *,
.freeBox__bqrpq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bqrpq > :global(.__wab_flex-container) > picture > img,
.freeBox__bqrpq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.svg__v7KNp {
  object-fit: cover;
  max-width: 100%;
  height: 40px;
  width: 40px;
  color: var(--token-2N8zcUvyUXwuOJv);
  flex-shrink: 0;
}
.text___8CsHg {
  position: relative;
  text-align: center;
  color: var(--token-IOs4wk1cx7qv8eT);
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
}
.freeBox__ln1U {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__ln1U > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 14px);
  height: calc(100% + 14px);
}
.freeBox__ln1U > :global(.__wab_flex-container) > *,
.freeBox__ln1U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ln1U > :global(.__wab_flex-container) > picture > img,
.freeBox__ln1U
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 14px;
}
.text___5Xt6R {
  text-align: center;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__fgpWt {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__fgpWt > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__fgpWt > :global(.__wab_flex-container) > *,
.freeBox__fgpWt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fgpWt > :global(.__wab_flex-container) > picture > img,
.freeBox__fgpWt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.link__tSjnm {
  display: flex;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.root .link__tSjnm:hover {
  color: var(--token-jDfd0ASagPOGtqY);
}
.divider__rqreJ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  max-height: 80%;
  flex-shrink: 0;
}
.link__xl9WA {
  display: flex;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.root .link__xl9WA:hover {
  color: var(--token-jDfd0ASagPOGtqY);
}
.freeBox__sUuN {
  display: block;
  position: relative;
  height: 10px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__oe5Yd {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__oe5Yd > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__oe5Yd > :global(.__wab_flex-container) > *,
.freeBox__oe5Yd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oe5Yd > :global(.__wab_flex-container) > picture > img,
.freeBox__oe5Yd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__w6F8G {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-IgvLORwjhxLX5NA);
}
.errorId {
  font-size: var(--token-c5GLNJb7VW_H03Z);
}
.divider__iwX1O:global(.__wab_instance) {
  max-width: 40%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .divider__iwX1O:global(.__wab_instance) {
    max-width: 100%;
    flex-shrink: 0;
  }
}
.backButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dOb6E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__otqG5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
