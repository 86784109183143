.root {
  display: flex;
  flex-direction: column;
  background: var(--token-nJQnmTwmF4SPdl6);
  position: relative;
  border-radius: 0px 0px 12px 12px;
  padding: var(--token-MZ_89NRuN);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-top: calc(0px - var(--token-uoDiu2hxc));
  height: calc(100% + var(--token-uoDiu2hxc));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-uoDiu2hxc);
}
.freeBox___5TnBc {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 4px;
  min-width: 0;
}
.freeBox___5TnBc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox___5TnBc > :global(.__wab_flex-container) > *,
.freeBox___5TnBc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5TnBc > :global(.__wab_flex-container) > picture > img,
.freeBox___5TnBc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-IgvLORwjhxLX5NA);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}
.navigateButtonArchive:global(.__wab_instance) {
  position: relative;
}
.svg__ra9P0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lmhEl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__c8Gl {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__c8Gl {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.objectPreviewCard___9IBbi:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
  grid-row-end: span 1;
}
.objectPreviewCard__ysAxt:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
.objectPreviewCard___00OG:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
