.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-top: var(--token-3S5TLMpb5);
  padding-right: var(--token-j8nt-mARd);
  padding-left: var(--token-j8nt-mARd);
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 480px) {
  .root {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.freeBox__yYA {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-EdVWKDB6CssMfRE);
  min-width: 0;
  border-radius: 6px;
  padding: 12px 12px 12px 16px;
}
.freeBox__yYA > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__yYA > :global(.__wab_flex-container) > *,
.freeBox__yYA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yYA > :global(.__wab_flex-container) > picture > img,
.freeBox__yYA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 480px) {
  .freeBox__yYA {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
}
@media (max-width: 480px) {
  .freeBox__yYA > :global(.__wab_flex-container) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (max-width: 480px) {
  .freeBox__yYA > :global(.__wab_flex-container) > *,
  .freeBox__yYA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__yYA > :global(.__wab_flex-container) > picture > img,
  .freeBox__yYA
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.text__vMVsG {
  position: relative;
  height: auto;
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-wZK1o5uz59Brqbf);
  width: auto;
}
.freeBox___06He2 {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.freeBox___06He2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox___06He2 > :global(.__wab_flex-container) > *,
.freeBox___06He2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___06He2 > :global(.__wab_flex-container) > picture > img,
.freeBox___06He2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.svg__jx6Km {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k9S9A {
  height: auto;
}
.svg__eCndC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zOBl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xDjw6 {
  height: auto;
}
.svg___7EwiB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
