.root {
  position: relative;
  justify-self: flex-start;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.rootshowDetail {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 0;
  min-height: 0;
}
.supportButton:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  position: fixed;
  bottom: 23px;
  right: 23px;
}
.supportButtonshowDetail:global(.__wab_instance) {
  right: auto;
  bottom: auto;
  display: none;
}
.svg__qzDR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yzrNx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0px;
  top: 0px;
  position: fixed;
  min-width: 0;
  min-height: 0;
}
.contactInfoshowDetail {
  display: flex;
}
.freeBox__qXm4I {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.freeBox__sencx {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #0f182acc;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.freeBox___8NNuu {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 10vh;
  left: auto;
  max-width: 800px;
  width: 100%;
  min-width: 0;
  padding: 16px;
}
.freeBox___8NNuu > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.freeBox___8NNuu > :global(.__wab_flex-container) > *,
.freeBox___8NNuu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8NNuu > :global(.__wab_flex-container) > picture > img,
.freeBox___8NNuu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
.freeBox___9IGe8 {
  background: var(--token-nJQnmTwmF4SPdl6);
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
  border-radius: 12px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.closeButton:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: 16px;
  right: 16px;
  z-index: 1;
}
.svg___5ZyCq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rXeXv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__ox9F {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 32px 40px 40px;
}
.freeBox__ox9F > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.freeBox__ox9F > :global(.__wab_flex-container) > *,
.freeBox__ox9F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ox9F > :global(.__wab_flex-container) > picture > img,
.freeBox__ox9F
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
@media (max-width: 480px) {
  .freeBox__ox9F {
    padding: 24px;
  }
}
.freeBox__xwJvg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.freeBox__xwJvg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__xwJvg > :global(.__wab_flex-container) > *,
.freeBox__xwJvg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xwJvg > :global(.__wab_flex-container) > picture > img,
.freeBox__xwJvg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox__gI4M {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__cvAZ {
  width: 100%;
  height: auto;
  max-width: 90%;
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
  min-width: 0;
}
.text___7J0Co {
  position: relative;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__sXhBa {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__sXhBa > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__sXhBa > :global(.__wab_flex-container) > *,
.freeBox__sXhBa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sXhBa > :global(.__wab_flex-container) > picture > img,
.freeBox__sXhBa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__sXhBa {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__sXhBa > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__sXhBa > :global(.__wab_flex-container) > *,
  .freeBox__sXhBa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__sXhBa > :global(.__wab_flex-container) > picture > img,
  .freeBox__sXhBa
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__yv3IT {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__yv3IT > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__yv3IT > :global(.__wab_flex-container) > *,
.freeBox__yv3IT > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yv3IT > :global(.__wab_flex-container) > picture > img,
.freeBox__yv3IT
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__l12Td {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  max-width: 100%;
  background: var(--token-EdVWKDB6CssMfRE);
  flex-shrink: 0;
  border-radius: 200px;
  padding: 8px;
}
.svg__aAq9 {
  object-fit: cover;
  max-width: 100%;
  width: 20px;
  height: 20px;
  color: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
}
.freeBox__x0OW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.link__uXUcc {
  font-size: var(--token-FutXsu2VTAR30Ez);
  padding-bottom: 0px;
  font-weight: 500;
  padding-right: 0px;
}
.text__jeLFd {
  font-size: var(--token-wZK1o5uz59Brqbf);
  padding-bottom: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__ka2Zl {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ka2Zl > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__ka2Zl > :global(.__wab_flex-container) > *,
.freeBox__ka2Zl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ka2Zl > :global(.__wab_flex-container) > picture > img,
.freeBox__ka2Zl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__i8UK0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  max-width: 100%;
  background: var(--token-EdVWKDB6CssMfRE);
  flex-shrink: 0;
  border-radius: 200px;
  padding: 8px;
}
.svg__kQqD {
  object-fit: cover;
  max-width: 100%;
  width: 20px;
  height: 20px;
  color: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
}
.freeBox__dotWg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.link__pjBxD {
  font-size: var(--token-FutXsu2VTAR30Ez);
  padding-bottom: 0px;
  font-weight: 500;
}
.text___7P48 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  padding-bottom: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
