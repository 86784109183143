.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.banner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.bannerhasBanner:global(.__wab_instance) {
  display: flex;
}
.text__cZXvQ {
  color: var(--token-PcBCP-f11VpIoWX);
}
.freeBox___1EoNl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  max-width: 100%;
  min-width: 0;
}
.freeBox__tGpq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__tGpq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__tGpq > :global(.__wab_flex-container) > *,
.freeBox__tGpq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tGpq > :global(.__wab_flex-container) > picture > img,
.freeBox__tGpq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.addButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  height: auto;
}
.svg___6DiFd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.itemsCounter:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
