.root {
  display: grid;
  grid-template-columns: repeat(32, minmax(0, 1fr));
  width: 100%;
  transition-property: background;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  background: #ffffff00;
  position: relative;
  min-height: 52px;
  height: auto;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
@media (max-width: 480px) {
  .root {
    padding-bottom: 12px;
  }
}
.freeBox__yzRmV {
  display: flex;
  position: relative;
  flex-direction: row;
  grid-column-end: span 10;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}
.freeBox__yzRmV > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox__yzRmV > :global(.__wab_flex-container) > *,
.freeBox__yzRmV > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yzRmV > :global(.__wab_flex-container) > picture > img,
.freeBox__yzRmV
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
@media (max-width: 480px) {
  .freeBox__yzRmV {
    grid-column-end: span 32;
    padding-bottom: 0px;
    padding-right: 0px;
  }
}
.slotTargetTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.slotTargetTitleisTotal {
  font-weight: 600;
}
.freeBox__ceRVu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-column-end: span 10;
}
@media (max-width: 480px) {
  .freeBox__ceRVu {
    grid-column-end: span 32;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
.freeBoxcolumns__1__ceRVutE5Jx {
  grid-column-end: span 22;
}
@media (max-width: 480px) {
  .freeBoxcolumns__1__ceRVutE5Jx {
    grid-column-end: span 32;
  }
}
.formTextTableCell__bvRij:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.freeBox__hiNna {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-column-end: span 12;
}
@media (max-width: 480px) {
  .freeBox__hiNna {
    grid-column-end: span 32;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
.formTextTableCell__y9Vki:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
