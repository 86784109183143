.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: var(--token-j8nt-mARd);
  padding-bottom: var(--token-7dIrE6133);
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    display: flex;
    flex-direction: column;
    padding: 0px 8px 40px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
@media (max-width: 480px) {
  .root {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.freeBox__ajGFj {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ajGFj > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__ajGFj > :global(.__wab_flex-container) > *,
.freeBox__ajGFj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ajGFj > :global(.__wab_flex-container) > picture > img,
.freeBox__ajGFj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.freeBox__fkkp4 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
}
.slotTargetTitle {
  font-size: var(--token-3jOrWkEDNu9ZZIv);
  font-weight: 600;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .slotTargetTitle {
    font-size: var(--token-Jhhay0F31-lTY91);
  }
}
@media (max-width: 480px) {
  .slotTargetTitle {
    font-size: var(--token-Jhhay0F31-lTY91);
  }
}
.sectionToggleButton:global(.__wab_instance) {
  position: relative;
}
@media (max-width: 480px) {
  .sectionToggleButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__x8JQv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  height: auto;
}
.textisClosed {
  height: auto;
}
.svg__lqMnO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
