.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 1px 3px -1px #09235914;
  position: relative;
  transition-property: all;
  transition-duration: 1.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  min-width: 0;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 1.2s;
  border-radius: 12px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
@media (max-width: 480px) {
  .root {
    box-shadow: none;
    border-right-style: none;
    border-left-style: none;
    padding-bottom: 16px;
    border-radius: 0px;
  }
}
.rootcontentCanOverflow {
  overflow: visible;
}
.freeBox__phlKg {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-hFcnMUlxM);
}
.freeBox__phlKg > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__phlKg > :global(.__wab_flex-container) > *,
.freeBox__phlKg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__phlKg > :global(.__wab_flex-container) > picture > img,
.freeBox__phlKg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox__phlKg {
    padding: var(--token-3S5TLMpb5);
  }
}
@media (max-width: 480px) {
  .freeBox__phlKg > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-H3JPdTVdV));
    width: calc(100% + var(--token-H3JPdTVdV));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__phlKg > :global(.__wab_flex-container) > *,
  .freeBox__phlKg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__phlKg > :global(.__wab_flex-container) > picture > img,
  .freeBox__phlKg
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-H3JPdTVdV);
    margin-top: 0px;
  }
}
.freeBoxhasTabGroup__phlKgl8TF9 {
  padding-bottom: var(--token-MZ_89NRuN);
}
.freeBox__yD3Q {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  max-width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  flex-shrink: 0;
  border-radius: 100%;
  padding: 8px;
}
.freeBoxisChecked__yD3Q1Q6O {
  background: var(--token-CXh3DsvYUDbb4NE);
}
.freeBox__zztEr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.text__yvmen {
  font-weight: 500;
}
.svg__kXMyz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-enYJIO6w3sEL0NO);
  height: 1em;
  flex-shrink: 0;
  display: none;
}
.svgisChecked__kXMyz1Q6O {
  display: block;
}
.freeBox__rzdpK {
  position: relative;
  padding-top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.freeBox__rzdpK > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox__rzdpK > :global(.__wab_flex-container) > *,
.freeBox__rzdpK > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rzdpK > :global(.__wab_flex-container) > picture > img,
.freeBox__rzdpK
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.text__vqLe {
  font-size: 20px;
  font-weight: 500;
}
.cardHelpLink:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.cardHelpLinkhasHelpLink:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.svg__hvfNm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dCqOl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___0FoKs {
  position: relative;
  padding-top: 0px;
  width: auto;
  display: flex;
  flex-direction: row;
}
.freeBox___0FoKs > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox___0FoKs > :global(.__wab_flex-container) > *,
.freeBox___0FoKs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0FoKs > :global(.__wab_flex-container) > picture > img,
.freeBox___0FoKs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.freeBoxhasPercentageValue___0FoKsjir9H {
  display: flex;
}
.formGroup__bbbct:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput__vOg3W:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__ypD1G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vhBsC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dBsYv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__m9P2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___89FQl:global(.__wab_instance) {
  position: relative;
}
.text__il2Cl {
  font-weight: 400;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.freeBox__pGVf {
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  min-width: 0;
  display: none;
}
.freeBoxhasNavButton__pGVfINsWs {
  display: flex;
}
.navButton__uuddE:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__q0Yq {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.svghasNavButton__q0YqINsWs {
  display: block;
}
.text__sV6WX {
  height: auto;
}
.toggleButton:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .toggleButton:global(.__wab_instance) {
    height: 36px;
    flex-shrink: 0;
  }
}
.toggleButtonisClosed:global(.__wab_instance) {
  flex-shrink: 0;
}
.toggleButtonhasHelpLink:global(.__wab_instance) {
  flex-shrink: 0;
}
.toggleButtonhasPercentageValue:global(.__wab_instance) {
  flex-shrink: 0;
}
.toggleButtonhasNavButton:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__wkBsz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vLaoH {
  height: auto;
}
.svg__jMe2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__k9Wmg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBoxhasPercentageValue__k9WmGjir9H {
    display: flex;
  }
}
.formGroup__fwpYe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__smAbR:global(.__wab_instance) {
  position: relative;
}
.svg__iGRt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__varfX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pqgxC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3U6EJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__nzNt9:global(.__wab_instance) {
  position: relative;
}
.text__fgkrF {
  padding-right: 0px;
}
.freeBox__zw0Nl {
  display: flex;
  flex-direction: row;
  position: sticky;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  z-index: 1;
  right: 0px;
  min-width: 0;
}
.freeBox__hGKb {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding-top: var(--token-3S5TLMpb5);
  padding-left: var(--token-j8nt-mARd);
  padding-bottom: var(--token-3S5TLMpb5);
  background: var(--token-NPNMQf7VB8u7Kel);
  overflow: visible;
  min-width: 0;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__hGKb {
    padding-left: var(--token-hFcnMUlxM);
  }
}
@media (max-width: 480px) {
  .freeBox__hGKb {
    padding-top: var(--token-MZ_89NRuN);
    padding-bottom: var(--token-MZ_89NRuN);
    padding-left: var(--token-3S5TLMpb5);
  }
}
.tabGroup:global(.__wab_instance) {
  max-width: 100%;
}
.svg__wcs1N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cft5O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i4VbF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__iCbqc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBoxisClosed__iCbqcKyLfr {
  display: none;
}
.freeBoxhasTabGroup__iCbqcl8TF9 {
  padding-top: var(--token-MZ_89NRuN);
}
.processCardSection__j4S0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__nIwFy {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
.formGroup__ly7U4:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.textInput__pdmI:global(.__wab_instance) {
  position: relative;
}
.svg__jEgw3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bp3U1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dFwh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rf04W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___5RfPp:global(.__wab_instance) {
  position: relative;
}
.formGroup__ntWQo:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__j2MHi {
  height: auto;
}
.textInput__tyyYn:global(.__wab_instance) {
  position: relative;
}
.svg__bx34C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__o2Mf5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kxg3H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lbUvx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__jdjSs:global(.__wab_instance) {
  position: relative;
}
.formGroup__o1DmR:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.textInput__gzgo5:global(.__wab_instance) {
  position: relative;
}
.svg__xhCwb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pYoJn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__f8GUf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dFg0T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__b5Ut8:global(.__wab_instance) {
  position: relative;
}
.freeBox__iftC4 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-MZ_89NRuN);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__iftC4 {
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox__iftC4 {
    padding-bottom: 8px;
  }
}
.freeBoxisClosed__iftC4KyLfr {
  display: none;
}
.freeBoxhasOptionalContent__iftC47TbJf {
  display: flex;
}
.toggleOptionalFieldsButton:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__uM9N {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.svg__l9IxZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
