.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 40px;
}
.freeBox__d6WW {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__d6WW > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__d6WW {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__d6WW > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-8IFSbVVlb));
    height: calc(100% + var(--token-8IFSbVVlb));
  }
}
@media (max-width: 480px) {
  .freeBox__d6WW > :global(.__wab_flex-container) > *,
  .freeBox__d6WW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__d6WW > :global(.__wab_flex-container) > picture > img,
  .freeBox__d6WW
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-8IFSbVVlb);
  }
}
.text {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 500;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  min-width: 0;
}
.freeBox__xKry5 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__xKry5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__xKry5 > :global(.__wab_flex-container) > *,
.freeBox__xKry5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xKry5 > :global(.__wab_flex-container) > picture > img,
.freeBox__xKry5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
@media (max-width: 480px) {
  .freeBox__xKry5 > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.applyAlgoValuesButton:global(.__wab_instance) {
  position: relative;
  align-self: flex-end;
}
@media (max-width: 480px) {
  .applyAlgoValuesButton:global(.__wab_instance) {
    position: relative;
    align-self: flex-start;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__miR2Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.sliders {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.sliders > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.sliders > :global(.__wab_flex-container) > *,
.sliders > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sliders > :global(.__wab_flex-container) > picture > img,
.sliders
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.slider__fVsAw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__rkDef:global(.__wab_instance) {
  position: relative;
}
.formGroup___0Tigr:global(.__wab_instance) {
  position: relative;
}
.slider__iFoWg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter___09J8S:global(.__wab_instance) {
  position: relative;
}
.formGroup__b1AEl:global(.__wab_instance) {
  position: relative;
}
.slider__iWmn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__fwlfS:global(.__wab_instance) {
  position: relative;
}
.formGroup__mEwT:global(.__wab_instance) {
  position: relative;
}
.slider__iSrQy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__yQeXl:global(.__wab_instance) {
  position: relative;
}
.formGroup__t0WDo:global(.__wab_instance) {
  position: relative;
}
.slider__gJa7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__t8Eg5:global(.__wab_instance) {
  position: relative;
}
