.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: var(--token-5K6-m7eB7) var(--token-8IFSbVVlb);
}
.freeBox {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  transition-property: background;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  border-radius: 4px;
  padding: var(--token-MhDGai0bW) var(--token-uoDiu2hxc);
}
.freeBoxisSelected {
  background: var(--token-WYpGQpgya-mDjv5);
}
.labelContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 21px;
  min-width: 0;
}
.labelContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.labelContainer > :global(.__wab_flex-container) > *,
.labelContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.labelContainer > :global(.__wab_flex-container) > picture > img,
.labelContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
.labelContainerisRegion > :global(.__wab_flex-container) {
  justify-content: space-between;
}
.slotTargetChildren {
  font-size: 14px;
}
.slotTargetChildrenisSelected {
  color: var(--token-_4263rh0uaUoehz);
}
.tag:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.tagisRegion:global(.__wab_instance) {
  display: flex;
}
