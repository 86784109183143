.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
}
.freeBox__qlmsF {
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
  background: var(--token-vejaCN-6u7zUCAr);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-MZ_89NRuN);
  border: 1px solid #0f182a14;
}
.freeBox__qlmsF > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__qlmsF > :global(.__wab_flex-container) > *,
.freeBox__qlmsF > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qlmsF > :global(.__wab_flex-container) > picture > img,
.freeBox__qlmsF
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox__mTxCh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.freeBox__mTxCh > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__mTxCh > :global(.__wab_flex-container) > *,
.freeBox__mTxCh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mTxCh > :global(.__wab_flex-container) > picture > img,
.freeBox__mTxCh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__xwsVy {
  display: flex;
  flex-direction: row;
}
.freeBox__xwsVy > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__xwsVy > :global(.__wab_flex-container) > *,
.freeBox__xwsVy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xwsVy > :global(.__wab_flex-container) > picture > img,
.freeBox__xwsVy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.freeBox___9EioZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___9EioZ > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___9EioZ > :global(.__wab_flex-container) > *,
.freeBox___9EioZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9EioZ > :global(.__wab_flex-container) > picture > img,
.freeBox___9EioZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__qUxks {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__qUxks > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__qUxks > :global(.__wab_flex-container) > *,
.freeBox__qUxks > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qUxks > :global(.__wab_flex-container) > picture > img,
.freeBox__qUxks
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .button:global(.__wab_instance) {
    height: 100%;
    flex-shrink: 0;
    min-height: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cz4MR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___8Rhkz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.text {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-NPNMQf7VB8u7Kel);
  font-weight: 500;
  opacity: 0.5;
}
.freeBox__fvBmg {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__fvBmg > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-uoDiu2hxc));
  width: calc(100% + var(--token-uoDiu2hxc));
}
.freeBox__fvBmg > :global(.__wab_flex-container) > *,
.freeBox__fvBmg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fvBmg > :global(.__wab_flex-container) > picture > img,
.freeBox__fvBmg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-uoDiu2hxc);
}
.slotTargetAddress {
  font-size: var(--token-FutXsu2VTAR30Ez);
  font-weight: 600;
  color: var(--token-NPNMQf7VB8u7Kel);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.tag:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
