.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  min-width: 0;
  padding: 12px 40px;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    padding: 20px 40px;
  }
}
@media (max-width: 480px) {
  .root {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.freeBox__tmNgw {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__tmNgw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 48px);
  width: calc(100% + 48px);
}
.freeBox__tmNgw > :global(.__wab_flex-container) > *,
.freeBox__tmNgw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tmNgw > :global(.__wab_flex-container) > picture > img,
.freeBox__tmNgw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 48px;
}
@media (max-width: 480px) {
  .freeBox__tmNgw > :global(.__wab_flex-container) {
    margin-left: calc(0px - 24px);
    width: calc(100% + 24px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__tmNgw > :global(.__wab_flex-container) > *,
  .freeBox__tmNgw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__tmNgw > :global(.__wab_flex-container) > picture > img,
  .freeBox__tmNgw
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 24px;
    margin-top: 0px;
  }
}
.freeBox__yvUf5 {
  position: relative;
  flex-direction: column;
  display: none;
}
.freeBox__yvUf5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__yvUf5 > :global(.__wab_flex-container) > *,
.freeBox__yvUf5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yvUf5 > :global(.__wab_flex-container) > picture > img,
.freeBox__yvUf5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBoxhasModvers__yvUf5Qk3Nk {
  display: flex;
}
.text__z7RfV {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
@media (max-width: 480px) {
  .text__z7RfV {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.modvers {
  position: relative;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
@media (max-width: 480px) {
  .modvers {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.freeBox__edpdI {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: var(--token-uoU4lPaiz);
  overflow: visible;
}
.freeBox__edpdI > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__edpdI > :global(.__wab_flex-container) > *,
.freeBox__edpdI > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__edpdI > :global(.__wab_flex-container) > picture > img,
.freeBox__edpdI
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.text__tEbG {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
  padding-right: 0px;
}
@media (max-width: 480px) {
  .text__tEbG {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.freeBox__u1FuP {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.slotTargetCurrentObjectId {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  white-space: pre;
}
.slotTargetCurrentObjectId > :global(.__wab_text),
.slotTargetCurrentObjectId > :global(.__wab_expr_html_text),
.slotTargetCurrentObjectId > :global(.__wab_slot-string-wrapper),
.slotTargetCurrentObjectId > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetCurrentObjectId > *,
.slotTargetCurrentObjectId > :global(.__wab_slot) > *,
.slotTargetCurrentObjectId > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetCurrentObjectId > picture > img,
.slotTargetCurrentObjectId > :global(.__wab_slot) > picture > img,
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetCurrentObjectId
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
@media (max-width: 480px) {
  .slotTargetCurrentObjectId {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.idCopyButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.idCopyButtonhasModvers:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qJwV5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__hcsQs {
  position: relative;
  flex-direction: column;
  display: none;
}
.freeBox__hcsQs > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__hcsQs > :global(.__wab_flex-container) > *,
.freeBox__hcsQs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hcsQs > :global(.__wab_flex-container) > picture > img,
.freeBox__hcsQs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text___975Qc {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
@media (max-width: 480px) {
  .text___975Qc {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.statusText {
  position: relative;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .statusText {
    align-self: flex-end;
  }
}
@media (max-width: 480px) {
  .statusText {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.freeBox__rcTwA {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__rcTwA > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__rcTwA > :global(.__wab_flex-container) > *,
.freeBox__rcTwA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rcTwA > :global(.__wab_flex-container) > picture > img,
.freeBox__rcTwA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.text__xvrwn {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
@media (max-width: 480px) {
  .text__xvrwn {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.stateDisplay:global(.__wab_instance) {
  position: relative;
}
.freeBox__k3LHl {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  min-width: 0;
  display: none;
}
@media (max-width: 480px) {
  .freeBox__k3LHl {
    display: none;
  }
}
.freeBoxhasDebugMode__k3LHlyjIxn {
  display: flex;
}
.debugFlag:global(.__wab_instance):global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__rvTee {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
.freeBox__gnlA {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox__gnlA > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__gnlA > :global(.__wab_flex-container) > *,
.freeBox__gnlA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gnlA > :global(.__wab_flex-container) > picture > img,
.freeBox__gnlA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.button___4U4Op:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rnCng {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__k76Mq {
  height: auto;
}
.svg__zCBnj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__xeVqf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__iyKll {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__tB8Mh {
  height: auto;
}
.svg___5ZpsF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
