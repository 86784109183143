.uploadedTemplatesRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.uploadedTemplatesRowisProduction {
  display: none;
}
.accountId {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 0px;
  min-width: 0;
}
.accountName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.fileName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.lastModified {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
