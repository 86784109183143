.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  min-width: 160px;
  min-height: 36px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
@media (max-width: 480px) {
  .root {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: stretch;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.roothasIntervals {
  padding-bottom: 12px;
  min-height: 48px;
}
.roothasLabels {
  min-height: 56px;
  padding-top: 12px;
}
.freeBox__ezYNu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.labelsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  width: auto;
  height: auto;
}
.labelsContainerhasLabels {
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: flex;
}
.labelsContainerhasIntervals_hasLabels {
  left: auto;
  top: auto;
  width: auto;
  align-items: center;
  bottom: auto;
  right: auto;
}
.slotTargetStartLabel {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetEndLabel {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-5eiHu7b54PkQh5O);
}
.sliderBase:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__eEmnw {
  position: relative;
  height: auto;
  max-width: 100%;
  background: none;
  display: none;
}
.freeBox__eEmnw > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__eEmnw > :global(.__wab_flex-container) > *,
.freeBox__eEmnw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eEmnw > :global(.__wab_flex-container) > picture > img,
.freeBox__eEmnw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
.freeBoxhasInput__eEmnwje3Zb {
  display: flex;
}
.sliderInput:global(.__wab_instance) {
  width: 60px;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .sliderInput:global(.__wab_instance) {
    width: 90%;
  }
}
.svg__uZhKq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wRnei {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1ICeQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uy5Jk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.clearButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .clearButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-5eiHu7b54PkQh5O);
}
.text {
  background: #ffffff00;
}
.svg__yiSYj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
