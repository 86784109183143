.root {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-row-gap: var(--token-SAmzyWXiq);
  grid-column-gap: var(--token-cTBZAIcMp);
  padding-top: var(--token-H3JPdTVdV);
  padding-bottom: var(--token-H3JPdTVdV);
  position: relative;
  min-width: 0;
}
.hoverBg {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: -8px;
  top: 0;
  background: var(--token-NPNMQf7VB8u7Kel);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  right: -8px;
  bottom: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 4px;
}
.root:hover .hoverBg {
  background: var(--token-enYJIO6w3sEL0NO);
}
.freeBox__hrsA3 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 5;
  min-width: 0;
}
.rena:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.select:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.svg__mwSoj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.option__zyyna:global(.__wab_instance) {
  position: relative;
}
.svg__g84Qj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__qg2FQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__yhORp:global(.__wab_instance) {
  position: relative;
}
.svg__qvdUz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__xNyCi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter___1A4Jl:global(.__wab_instance) {
  position: relative;
}
.freeBox__tEuBu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.zeitraum:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.dateSetter:global(.__wab_instance) {
  max-width: 100%;
}
.symbolCounter___7Hhxy:global(.__wab_instance) {
  position: relative;
}
.freeBox__iUZnA {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 4;
  min-width: 0;
}
.freeBox__iUZnA > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__iUZnA > :global(.__wab_flex-container) > *,
.freeBox__iUZnA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__iUZnA > :global(.__wab_flex-container) > picture > img,
.freeBox__iUZnA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.investitionsbetrag:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.textInput:global(.__wab_instance) {
  position: relative;
}
.svg__qlSq2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qvVu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gO5YW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__urrBj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__wtM0:global(.__wab_instance) {
  position: relative;
}
.freeBox__dkzfo {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 7;
  min-width: 0;
}
.freeBox__dkzfo > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__dkzfo > :global(.__wab_flex-container) > *,
.freeBox__dkzfo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dkzfo > :global(.__wab_flex-container) > picture > img,
.freeBox__dkzfo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-nPzqyQpH_);
}
.objekte:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.multiSelect:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__qg2Ky:global(.__wab_instance) {
  position: relative;
}
.freeBox___2JjjN {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 3;
  min-width: 0;
}
.freeBox___2JjjN > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___2JjjN > :global(.__wab_flex-container) > *,
.freeBox___2JjjN > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2JjjN > :global(.__wab_flex-container) > picture > img,
.freeBox___2JjjN
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.ertragsausfall:global(.__wab_instance) {
  max-width: 100%;
}
.textInput2:global(.__wab_instance) {
  position: relative;
}
.svg__eu2Iw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uWxO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__twLns {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ffmS0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__zyEzP:global(.__wab_instance) {
  position: relative;
}
.freeBox__ng8Ul {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  grid-column-end: span 1;
}
.freeBox__ng8Ul > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-klOkpNg9M));
  width: calc(100% + var(--token-klOkpNg9M));
}
.freeBox__ng8Ul > :global(.__wab_flex-container) > *,
.freeBox__ng8Ul > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ng8Ul > :global(.__wab_flex-container) > picture > img,
.freeBox__ng8Ul
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-klOkpNg9M);
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vyrO0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
