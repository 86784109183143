.root {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: var(--token-8IFSbVVlb);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox__gZwP {
  padding-right: 0px;
  display: flex;
  flex-direction: row;
}
.text__djitN {
  color: var(--token-IOs4wk1cx7qv8eT);
}
.freeBox__p8OCg {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__p8OCg > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__p8OCg > :global(.__wab_flex-container) > *,
.freeBox__p8OCg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__p8OCg > :global(.__wab_flex-container) > picture > img,
.freeBox__p8OCg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.yesButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 60px;
  flex-shrink: 0;
}
.svg__vw8Z7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kSwf1 {
  margin-right: 0px;
}
.svg__dph9E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.noButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 60px;
  flex-shrink: 0;
}
.svg___4OKy3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qsbfS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
