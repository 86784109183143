.root {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 12;
  background: var(--token-nJQnmTwmF4SPdl6);
  position: relative;
  border-radius: 8px;
  padding: var(--token-cTBZAIcMp) var(--token-3S5TLMpb5) var(--token-cTBZAIcMp)
    var(--token-cTBZAIcMp);
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .root {
    padding: 12px;
  }
}
.roottype_info {
  background: var(--token-WYpGQpgya-mDjv5);
  border-color: var(--token-TMjhy7b3EKBSbc2);
}
.roottype_error {
  background: var(--token-Lymfn4M1YIjdOgD);
  border-color: var(--token-zzo8jryirB3foEj);
}
.roottype_warning {
  background: var(--token-zn-VjztWD);
  border-color: var(--token-rsLFZ8cZG);
}
.roottype_success {
  background: var(--token-yQ5PNjYizuW9slV);
  border-color: var(--token-bYBIsaiSK_SL0wu);
}
.rootisElevated {
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
}
.roothasDismissButton {
  padding-right: 56px;
}
.svg___4Ii1F {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-IgvLORwjhxLX5NA);
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.svgtype_info___4Ii1FjJmv1 {
  color: var(--token-PjtuKumWd61Gyyy);
}
.svgtype_error___4Ii1FcDbHs {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.svgtype_warning___4Ii1FH2CVl {
  color: var(--token-mOJuRJhlC);
}
.svgtype_success___4Ii1FNQzXu {
  color: var(--token-eoOGiUMJC4PLiTG);
}
.freeBox__jt790 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__jt790 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__jt790 > :global(.__wab_flex-container) > *,
.freeBox__jt790 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jt790 > :global(.__wab_flex-container) > picture > img,
.freeBox__jt790
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.freeBox__lJWg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__kxXfP {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBoxhasNoTitle__kxXfPe9Bps {
  display: none;
}
.slotTargetTitle {
  font-weight: 500;
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.slotTargetTitletype_info {
  color: var(--token-4Vy3YOrYIv8ljp2);
}
.slotTargetTitletype_error {
  color: var(--token-jW34YHQobNMSD0Y);
}
.slotTargetTitletype_warning {
  color: var(--token-ZpmSrRJAo);
}
.slotTargetTitletype_success {
  color: var(--token--1wl_F9deuezOld);
}
.freeBox__m5RFs {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.freeBoxhasDescription__m5RFsZpOXu {
  display: flex;
}
.freeBoxhasNoTitle__m5RFse9Bps {
  display: flex;
}
.slotTargetText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  line-height: var(--token-f2GFyMCUtiJjuKU);
  color: var(--token-IgvLORwjhxLX5NA);
}
.slotTargetTexttype_info {
  color: var(--token-_4263rh0uaUoehz);
}
.slotTargetTexttype_error {
  color: var(--token-oT7tnXfbXJAOlYI);
}
.slotTargetTexttype_warning {
  color: var(--token-6NXubVByI);
}
.slotTargetTexttype_success {
  color: var(--token-CXh3DsvYUDbb4NE);
}
.freeBox__path0 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__path0 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__path0 > :global(.__wab_flex-container) > *,
.freeBox__path0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__path0 > :global(.__wab_flex-container) > picture > img,
.freeBox__path0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
  margin-top: 8px;
}
.freeBoxhasActions__path0AjEY {
  display: flex;
}
.button__os7Az:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__kiffA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xHpZi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__oAivL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__e2E2X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uNxaE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.dismissButton:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: 16px;
  right: 16px;
  flex-shrink: 0;
}
.dismissButtontype_info:global(.__wab_instance) {
  flex-shrink: 0;
}
.dismissButtontype_error:global(.__wab_instance) {
  flex-shrink: 0;
}
.dismissButtontype_warning:global(.__wab_instance) {
  flex-shrink: 0;
}
.dismissButtontype_success:global(.__wab_instance) {
  flex-shrink: 0;
}
.dismissButtonhasDismissButton:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.svg__ra2Cn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-IgvLORwjhxLX5NA);
}
.svgtype_info__ra2CnjJmv1 {
  color: var(--token-AZBC5tFK07a8DV3);
}
.svgtype_error__ra2CncDbHs {
  color: var(--token-Y_1QycPpMrs0BhH);
}
.svgtype_warning__ra2CnH2CVl {
  color: var(--token-Hken4BJDt);
}
.svgtype_success__ra2CnNQzXu {
  color: var(--token-vejaCN-6u7zUCAr);
}
.svg___0B8R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
