.root {
  display: block;
  width: 100%;
  background: var(--token-IOs4wk1cx7qv8eT);
  height: 1px;
  position: relative;
  opacity: 0.08;
  min-width: 0;
}
.rootisVertical {
  height: 100%;
  width: 1px;
  min-height: 0;
}
