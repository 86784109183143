.root:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
  position: relative;
}
.svg__fj7XS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jJrqS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
