.root {
  display: block;
  flex-direction: column;
  position: relative;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  cursor: grab;
}
.rootisDragging {
  cursor: grabbing;
}
.rootisAlgo {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.thumb {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: var(--token-6yTcrF4BA3JOPWz);
  box-shadow: 0px 0px 0px 0px var(--token-6yTcrF4BA3JOPWz),
    0px 0px 0px 0px #3b85ed33, 0px 2px 4px -1px #0f182a33;
  transition-property: background, box-shadow;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  left: 0px;
  top: 0px;
  -webkit-transition-property: background, box-shadow;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 100%;
}
.thumbisDragging {
  box-shadow: 0px 0px 0px 1px var(--token-6yTcrF4BA3JOPWz),
    0px 0px 0px 12px #3b85ed24, 0px 2px 4px -1px #0f182a33;
}
.thumbisAlgo {
  width: 24px;
  height: 24px;
  background: var(--token-N7ZH9ZZ89u1SktH);
  box-shadow: 0px 0px 0px 0px var(--token-6yTcrF4BA3JOPWz),
    0px 0px 0px 0px #3b85ed33, 0px 0px 0px 0px #ffffff;
  border: 2px solid var(--token-XVEsAlbFfHq6FLt);
}
.thumbcolor_gold {
  background: var(--token-QYymIwPX-bo_uMH);
  box-shadow: 0px 0px 0px 0px var(--token-QYymIwPX-bo_uMH),
    0px 0px 0px 0px #edbe3b33, 0px 2px 4px -1px #0f182a33;
}
.thumbcolor_gray {
  background: var(--token-5eiHu7b54PkQh5O);
}
.root:hover .thumb {
  background: var(--token-AZBC5tFK07a8DV3);
  box-shadow: 0px 0px 0px 1px var(--token-AZBC5tFK07a8DV3),
    0px 0px 0px 12px #3b85ed24, 0px 2px 4px -1px #0f182a33;
}
.rootisAlgo:hover .thumbisAlgo {
  background: var(--token-vQIHntGSmUGoi3x);
  box-shadow: none;
}
.rootcolor_gold:hover .thumbcolor_gold {
  background: var(--token-NBmi1EBeYuE24RY);
  box-shadow: 0px 0px 0px 1px var(--token-NBmi1EBeYuE24RY),
    0px 0px 0px 12px #edbe3b24, 0px 2px 4px -1px #0f182a33;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-cb_Nd6iAoCFyn8W);
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.svgisAlgo {
  color: var(--token-XVEsAlbFfHq6FLt);
  display: block;
}
.tooltipContainer {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--token-NPNMQf7VB8u7Kel);
  margin-top: -32px;
  margin-bottom: 4px;
  box-shadow: 0px 4px 16px -1px #1e2a3b2b;
  left: 0px;
  top: 0px;
  display: none;
  border-radius: 4px;
  padding: 2px 6px;
}
.tooltipContainerisDragging {
  display: flex;
}
.slotTargetTooltip {
  font-weight: 500;
  text-align: center;
}
