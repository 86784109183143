.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-MhDGai0bW));
    height: calc(100% + var(--token-MhDGai0bW));
  }
}
@media (max-width: 480px) {
  .freeBox > :global(.__wab_flex-container) > *,
  .freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox > :global(.__wab_flex-container) > picture > img,
  .freeBox
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-MhDGai0bW);
  }
}
.text {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 500;
  min-width: 0;
}
.applyAlgoValuesButton:global(.__wab_instance) {
  position: relative;
  align-self: flex-end;
}
@media (max-width: 480px) {
  .applyAlgoValuesButton:global(.__wab_instance) {
    align-self: flex-start;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__s0Zwf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.sliders {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.sliders > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.sliders > :global(.__wab_flex-container) > *,
.sliders > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sliders > :global(.__wab_flex-container) > picture > img,
.sliders
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.formGroup__dMgN1:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__i4Nt1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter___8Ol4:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__e6B0W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__eiKMw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__hCh0F:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__ncarh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___3Q7SC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__lbNUi:global(.__wab_instance) {
  position: relative;
}
.formGroup__vVjc4:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__zZmZf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__s9Rv4:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___64AY:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__e7ZFp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___459Mf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__gnmfx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___446Dm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock___23Os:global(.__wab_instance) {
  position: relative;
}
.formGroup__hcJpX:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__z2Rn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__cgYcs:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___9YSbk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__tSizR:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__nTaHl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___52U0D:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___3OH4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__ji4P8:global(.__wab_instance) {
  position: relative;
}
.formGroup__v2F8Y:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__oPr4W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__iiSkO:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__otR5C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___3BhQi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___0ZpRw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__eu4No:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___66KzA {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__kiPz0:global(.__wab_instance) {
  position: relative;
}
.formGroup__s7Xeg:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__gvDav:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__klyFv:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___1TUgs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___7L6H5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___3GmGc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__h5JP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___8DK5Z {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock___29JaR:global(.__wab_instance) {
  position: relative;
}
.formGroup___9AU5:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__eqVni:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__hmxLu:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__dh7Id:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__f9Mz9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__gZw2E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__i0GsB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__y6YnP {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__bytJj:global(.__wab_instance) {
  position: relative;
}
.formGroup__oVlzX:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__joGIs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__ip90T:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__cpSi2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__u5Ba1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__v5E1Q:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___2LaZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___9G385 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__ur6IL:global(.__wab_instance) {
  position: relative;
}
.formGroup__gL3HM:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__fMkGk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__syy7C:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___6OSm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__aQkEy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___7TvXn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__bsEv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vAIxO {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
