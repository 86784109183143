.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  max-width: 100%;
  position: relative;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.rootisLast {
  border-bottom-style: none;
}
.freeBox__e1Sgd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
  height: 100%;
  min-width: 66px;
  max-width: 66px;
  min-height: 0;
}
.freeBox__vWUhb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  overflow: hidden;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBoxisCurrent__vWUhbDBX {
  background: var(--token-N7ZH9ZZ89u1SktH);
}
.rangSlot {
  position: relative;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 600;
  color: var(--token-IgvLORwjhxLX5NA);
}
.rangSlotisCurrent {
  display: none;
}
.rangSlotisAvarage__default {
  display: none;
}
.rangSlotisAvarage_top10 {
  display: none;
}
.rangSlotisAvarage_top20 {
  display: none;
}
.text {
  position: relative;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 600;
  color: var(--token-IgvLORwjhxLX5NA);
}
.textisCurrent {
  display: none;
}
.textisAvarage__default {
  display: block;
}
.textisAvarage_top10 {
  display: block;
}
.textisAvarage_top20 {
  display: block;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-0_AU74ENX98Rg_i);
  height: 1em;
  display: none;
}
.svgisCurrent {
  display: block;
}
.freeBox__nPUzi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 130px;
  height: 100%;
  min-height: 60px;
  max-width: 130px;
  padding: 8px 16px 8px 8px;
}
.freeBox___8Uanz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--token-cTBZAIcMp);
  overflow: hidden;
  background: var(--token-vQIHntGSmUGoi3x);
  min-width: 80px;
  flex-shrink: 0;
  border-radius: 4px;
}
.comparabilityBox {
  display: block;
  position: absolute;
  width: 80%;
  height: auto;
  max-width: 100%;
  background: var(--token-0_AU74ENX98Rg_i);
  left: 0;
  top: 0;
  right: auto;
  bottom: 0;
}
.comparabilityBoxisCurrent {
  width: 100%;
  background: var(--token-EdVWKDB6CssMfRE);
  display: none;
}
.comparabilityBoxisAvarage__default {
  display: none;
}
.comparabilityBoxisAvarage_top10 {
  display: none;
}
.comparabilityBoxisAvarage_top20 {
  display: none;
}
.vergleichText {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 100%;
  color: var(--token-o919cMZe3pTxZb-);
  font-weight: 600;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  margin-left: var(--token-MhDGai0bW);
  line-height: var(--token-SG9f8vFCBSVNq8a);
  bottom: 3px;
  top: 4px;
}
.freeBox__nxh62 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__qhfGh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.comparablesCell__v94Kk:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.preisSlot {
  font-weight: 600;
}
.freeBox__qo7Sb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 100px;
  max-width: 100px;
}
.comparablesCell__p4WQ4:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.preisM2Slot {
  font-weight: 600;
}
.freeBox__sgeOm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 112px;
  max-width: 112px;
}
.freeBoxisCurrent__sgeOmDBX {
  display: flex;
}
.freeBoxhideDistanz__sgeOm0SpnI {
  display: none;
}
.comparablesCell__zMLpq:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.freeBox__y1ARs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 160px;
  padding-right: 12px;
  max-width: 160px;
}
.freeBoxisCurrent__y1ARsDBX {
  display: flex;
}
.freeBoxhideStrlieg__y1ARs8Lkp0 {
  display: none;
}
.comparablesCell__acGqz:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.strliegSlot {
  width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden;
  min-width: 0;
}
.freeBox__g6Jnf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 80px;
  width: auto;
  max-width: 80px;
  padding-right: 12px;
}
.freeBoxisCurrent__g6JnfDBX {
  display: flex;
}
.comparablesCell__nSiCb:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.freeBox___0Wms {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: auto;
  min-width: 140px;
  max-width: 140px;
  padding: 0px 12px 0px 0px;
}
.freeBoxisCurrent___0WmsDBX {
  display: flex;
}
.comparablesCell__yr3Y2:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.freeBox__npGx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 80px;
  width: auto;
  max-width: 80px;
  padding: 0px 12px 0px 0px;
}
.comparablesCell___0R4Zc:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.freeBox__nLy4L {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 130px;
  width: auto;
  max-width: 130px;
  padding: 0px 12px 0px 0px;
}
.freeBoxisCurrent__nLy4LDBX {
  display: flex;
}
.freeBoxhideTyp__nLy4LLiixI {
  display: none;
}
.comparablesCell___7Ps3R:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.freeBox__nmisO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBoxhideNf__nmisOtsAdX {
  display: none;
}
.freeBox__aEsUo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBoxhideVol__aEsUOmz3Vp {
  display: none;
}
.freeBox__pck0L {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBoxhideLand__pck0LFz60V {
  display: none;
}
.freeBox___6CIK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBox__aZoku {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBox__xSbpj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBoxhideZust__xSbpJnvq3L {
  display: none;
}
.freeBox__eJNdj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBox___8Uv9Z {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBoxhideStand___8Uv9ZPY7F {
  display: none;
}
.freeBox__mPw6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBoxhideEtage__mPw6JxJk2 {
  display: none;
}
.freeBox__je8T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  width: auto;
  max-width: 110px;
}
.freeBoxhideId__je8TsnpvH {
  display: none;
}
