.gebhuelStack {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.gebhuelStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-H3JPdTVdV));
  height: calc(100% + var(--token-H3JPdTVdV));
}
.gebhuelStack > :global(.__wab_flex-container) > *,
.gebhuelStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.gebhuelStack > :global(.__wab_flex-container) > picture > img,
.gebhuelStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-H3JPdTVdV);
}
.freeBox___6P2V7 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___6P2V7 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3wiInXIIr));
  width: calc(100% + var(--token-3wiInXIIr));
}
.freeBox___6P2V7 > :global(.__wab_flex-container) > *,
.freeBox___6P2V7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6P2V7 > :global(.__wab_flex-container) > picture > img,
.freeBox___6P2V7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3wiInXIIr);
}
.freeBox__iGg6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.titleText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  padding-bottom: 0px;
}
.titleTextnoAction {
  display: none;
}
.freeBox___3Kbb9 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___3Kbb9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox___3Kbb9 > :global(.__wab_flex-container) > *,
.freeBox___3Kbb9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3Kbb9 > :global(.__wab_flex-container) > picture > img,
.freeBox___3Kbb9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3S5TLMpb5);
}
@media (max-width: 480px) {
  .freeBox___3Kbb9 > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: calc(0px - var(--token-uoDiu2hxc));
    height: calc(100% + var(--token-uoDiu2hxc));
  }
}
@media (max-width: 480px) {
  .freeBox___3Kbb9 > :global(.__wab_flex-container) > *,
  .freeBox___3Kbb9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___3Kbb9 > :global(.__wab_flex-container) > picture > img,
  .freeBox___3Kbb9
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: var(--token-uoDiu2hxc);
  }
}
.nettoinvestInfoCard:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.nettoinvestInfoCardnoAction:global(.__wab_instance) {
  display: none;
}
.svg__jfYdw {
  position: relative;
  object-fit: cover;
  height: 32px;
  width: 32px;
  color: var(--token-og7KBFmjrPhZ3dc);
}
.text {
  padding-bottom: 0px;
}
.co2InfoCard:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.co2InfoCardnoAction:global(.__wab_instance) {
  display: none;
}
.svg__hNzB0 {
  position: relative;
  object-fit: cover;
  height: 32px;
  width: 32px;
  color: var(--token-og7KBFmjrPhZ3dc);
}
.banner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.bannernoAction:global(.__wab_instance) {
  display: flex;
}
