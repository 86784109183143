.root {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBox__qzlJu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__qzlJu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__qzlJu > :global(.__wab_flex-container) > *,
.freeBox__qzlJu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qzlJu > :global(.__wab_flex-container) > picture > img,
.freeBox__qzlJu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBoxhasExternalLabel__qzlJuUfxQn > :global(.__wab_flex-container) {
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasExternalLabel__qzlJuUfxQn > :global(.__wab_flex-container) > *,
.freeBoxhasExternalLabel__qzlJuUfxQn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasExternalLabel__qzlJuUfxQn
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxhasExternalLabel__qzlJuUfxQn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
  margin-top: 0px;
}
.freeBoxisVertical__qzlJuQe5M {
  display: flex;
  flex-direction: column;
}
.freeBoxisVertical__qzlJuQe5M > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.freeBoxisVertical__qzlJuQe5M > :global(.__wab_flex-container) > *,
.freeBoxisVertical__qzlJuQe5M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxisVertical__qzlJuQe5M > :global(.__wab_flex-container) > picture > img,
.freeBoxisVertical__qzlJuQe5M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 2px;
}
.freeBoxmobileDisplay_stacked__qzlJu20YSq {
  width: 100%;
  flex-direction: row-reverse;
  min-width: 0;
}
.freeBoxmobileDisplay_stacked__qzlJu20YSq > :global(.__wab_flex-container) {
  flex-direction: row-reverse;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxmobileDisplay_stacked__qzlJu20YSq > :global(.__wab_flex-container) > *,
.freeBoxmobileDisplay_stacked__qzlJu20YSq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxmobileDisplay_stacked__qzlJu20YSq
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxmobileDisplay_stacked__qzlJu20YSq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.freeBoxisFullWidth__qzlJu9GsfF {
  width: 100%;
  min-width: 0;
}
.freeBoxisFullWidth__qzlJu9GsfF > :global(.__wab_flex-container) {
  min-width: 0;
}
.freeBoxhasNoAlgoButton_hasNoUnit__qzlJuW3Zr2GqUvS
  > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasNoAlgoButton_hasNoUnit__qzlJuW3Zr2GqUvS
  > :global(.__wab_flex-container)
  > *,
.freeBoxhasNoAlgoButton_hasNoUnit__qzlJuW3Zr2GqUvS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasNoAlgoButton_hasNoUnit__qzlJuW3Zr2GqUvS
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxhasNoAlgoButton_hasNoUnit__qzlJuW3Zr2GqUvS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.freeBox___8Wgq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  min-width: 0;
}
.freeBoxisConstrained___8WgqMduwk {
  max-width: 100px;
}
.svg___74Ttc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nBtEm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yZxN4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6Ry50 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__uA0R5 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  min-width: var(--token-j8nt-mARd);
}
.freeBox__uA0R5 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__uA0R5 > :global(.__wab_flex-container) > *,
.freeBox__uA0R5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__uA0R5 > :global(.__wab_flex-container) > picture > img,
.freeBox__uA0R5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBoxhasExternalLabel__uA0R5UfxQn {
  display: flex;
}
.dynamicText:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
}
.dynamicTexthasExternalLabel:global(.__wab_instance) {
  display: flex;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__rnEwP {
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
.slotTargetLabelSuffix {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.freeBox__c467W {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
.slotTargetUnitLabel {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.freeBox__w9LlS {
  display: flex;
  position: relative;
  width: auto;
  flex-direction: column;
}
.freeBox__w9LlS > :global(.__wab_flex-container) {
  flex-direction: column;
}
@media (max-width: 480px) {
  .freeBox__w9LlS {
    min-width: 25%;
  }
}
.freeBoxhasLink__w9LlSWgduP {
  display: flex;
  flex-direction: row;
}
.freeBoxhasLink__w9LlSWgduP > :global(.__wab_flex-container) {
  flex-direction: row;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasLink__w9LlSWgduP > :global(.__wab_flex-container) > *,
.freeBoxhasLink__w9LlSWgduP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasLink__w9LlSWgduP > :global(.__wab_flex-container) > picture > img,
.freeBoxhasLink__w9LlSWgduP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
  margin-top: 0px;
}
@media (max-width: 480px) {
  .freeBoxhasLink__w9LlSWgduP {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 480px) {
  .freeBoxhasLink__w9LlSWgduP > :global(.__wab_flex-container) {
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-8IFSbVVlb));
    height: calc(100% + var(--token-8IFSbVVlb));
  }
}
@media (max-width: 480px) {
  .freeBoxhasLink__w9LlSWgduP > :global(.__wab_flex-container) > *,
  .freeBoxhasLink__w9LlSWgduP
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .freeBoxhasLink__w9LlSWgduP > :global(.__wab_flex-container) > picture > img,
  .freeBoxhasLink__w9LlSWgduP
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-8IFSbVVlb);
  }
}
.freeBoxhasNoAlgoButton__w9LlSw3Zr2 {
  min-width: none;
}
@media (max-width: 480px) {
  .freeBoxhasNoAlgoButton__w9LlSw3Zr2 {
    display: none;
  }
}
.freeBoxmobileDisplay_stacked__w9LlS20YSq {
  flex-direction: row-reverse;
}
.freeBoxmobileDisplay_stacked__w9LlS20YSq > :global(.__wab_flex-container) {
  flex-direction: row-reverse;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxmobileDisplay_stacked__w9LlS20YSq > :global(.__wab_flex-container) > *,
.freeBoxmobileDisplay_stacked__w9LlS20YSq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxmobileDisplay_stacked__w9LlS20YSq
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxmobileDisplay_stacked__w9LlS20YSq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
@media (max-width: 480px) {
  .freeBoxhasNoAlgoButton_hasLink__w9LlSw3Zr2WgduP {
    display: flex;
  }
}
.freeBoxisStacked_hasNoUnit__w9LlSDJifGqUvS {
  width: 100%;
  min-width: 0;
}
.freeBoxisStacked_hasNoUnit__w9LlSDJifGqUvS > :global(.__wab_flex-container) {
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBoxmobileDisplay_stacked_hasExternalLabel_hasLink_isConstrained__w9LlS20YSqUfxQnWgduPMduwk {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBoxmobileDisplay_stacked_hasExternalLabel_hasLink_isConstrained__w9LlS20YSqUfxQnWgduPMduwk
    > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBoxmobileDisplay_stacked_hasExternalLabel_hasLink_isConstrained__w9LlS20YSqUfxQnWgduPMduwk
    > :global(.__wab_flex-container)
    > *,
  .freeBoxmobileDisplay_stacked_hasExternalLabel_hasLink_isConstrained__w9LlS20YSqUfxQnWgduPMduwk
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .freeBoxmobileDisplay_stacked_hasExternalLabel_hasLink_isConstrained__w9LlS20YSqUfxQnWgduPMduwk
    > :global(.__wab_flex-container)
    > picture
    > img,
  .freeBoxmobileDisplay_stacked_hasExternalLabel_hasLink_isConstrained__w9LlS20YSqUfxQnWgduPMduwk
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.algoButton:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .algoButton:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.slotTargetAlgoValue {
  color: var(--token-NBmi1EBeYuE24RY);
}
.linkButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.linkButtonhasLink:global(.__wab_instance) {
  display: flex;
}
.svg__dOnup {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ghsor {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
