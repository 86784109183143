.root {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--token-NPNMQf7VB8u7Kel);
  cursor: pointer;
  transition-property: background, box-shadow;
  transition-duration: 0.2s, 0.1s;
  transition-timing-function: ease-in-out, ease-in-out;
  box-shadow: 0px 0px 0px 0px #2571eb03;
  width: auto;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 36px;
  -webkit-transition-property: background, box-shadow;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.1s;
  border-radius: 6px;
  padding: 0px 14px;
  border: 1px solid var(--token-sHkWBDcvLab64_p);
}
.rootshowStartIcon {
  padding-left: 10px;
}
.rootshowEndIcon {
  padding-right: 10px;
}
.rootisIconOnly {
  width: 36px;
  padding: 0px;
}
.rootisDisabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.rootshape_rounded {
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 999px;
}
.rootshape_sharp {
  border-radius: 2px;
}
.rootsize_extraSmall {
  min-height: 22px;
  padding: 0px 6px;
}
.rootsize_small {
  min-height: 28px;
  padding: 0px 8px;
}
.rootsize_medium {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rootsize_large {
  min-height: 44px;
  padding: 11px 18px;
}
.rootcolor_blue {
  background: var(--token-AZBC5tFK07a8DV3);
  border-width: 0px;
  border-color: var(--token-dw-C2qwAAM28VJ9);
}
.rootcolor_gold {
  background: var(--token-QYymIwPX-bo_uMH);
  border-width: 0px;
  border-color: var(--token-cb_Nd6iAoCFyn8W);
}
.rootcolor_green {
  background: var(--token-djHL9A1ISxE7ONr);
  border-width: 0px;
  border-color: var(--token-og7KBFmjrPhZ3dc);
}
.rootcolor_red {
  background: var(--token-2N8zcUvyUXwuOJv);
  border-width: 0px;
  border-color: var(--token-8Upm2qPCoH9Sb-9);
}
.rootcolor_grey {
  background: var(--token-IgvLORwjhxLX5NA);
  border-width: 0px;
}
.rootcolor_white {
  background: #ffffff;
  border-width: 0px;
  border-color: var(--token-NPNMQf7VB8u7Kel);
}
.rootcolor_clear {
  background: none;
  border-width: 0px;
}
.rootcolor_link {
  background: #ffffff00;
  height: auto;
  min-height: auto;
  padding: 0px 2px;
  border-width: 0px;
}
.rootcolor_orange {
  background: var(--token-G449N6G1x);
  border-width: 0px;
  border-color: var(--token-p6OGFuM2O);
}
.rootbuttonType_outline {
  border-width: 1px;
}
.rootbuttonType_dashed {
  background: none;
  border-width: 1px;
  border-style: dashed;
}
.rootbuttonType_solid {
  border-width: 0px;
}
.rootbuttonType_soft {
  background: none;
  border-width: 0px;
}
.rootbuttonType_transparent {
  background: #ffffff00;
  border-style: none;
  border-color: #ffffff00;
}
.rootalign_left {
  justify-content: flex-start;
  align-items: center;
}
.rootalign_right {
  justify-content: flex-end;
  align-items: center;
}
.rootshape_rounded_showStartIcon {
  padding-left: 12px;
}
.rootshowEndIcon_shape_rounded {
  padding-right: 12px;
}
.rootisIconOnly_size_extraSmall {
  width: 22px;
  height: 22px;
  padding: 0px;
}
.rootisIconOnly_size_small {
  width: 30px;
  height: 30px;
  padding: 0px;
}
.rootshowStartIcon_size_large {
  padding-left: 14px;
}
.rootshowEndIcon_size_large {
  padding-right: 14px;
}
.rootisIconOnly_size_large {
  width: 44px;
  height: 44px;
}
.rootbuttonType_outline_color_orange {
  border-width: 1px;
}
.root___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px #2571eb4d;
  outline: none;
}
.root:focus-within {
  outline: none;
}
.rootcolor_link:hover {
  background: #ffffff00;
}
.rootcolor_link:active {
  background: #ffffff00;
}
.softColor {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  background: var(--token-NPNMQf7VB8u7Kel);
  border-radius: 5px;
}
.softColorcolor_blue {
  background: var(--token-TMjhy7b3EKBSbc2);
}
.softColorcolor_gold {
  background: var(--token-vQIHntGSmUGoi3x);
}
.softColorcolor_green {
  background: var(--token-bYBIsaiSK_SL0wu);
}
.softColorcolor_red {
  background: var(--token-zzo8jryirB3foEj);
}
.softColorcolor_grey {
  background: var(--token-nJQnmTwmF4SPdl6);
}
.softColorcolor_white {
  opacity: 0.3;
}
.softColorcolor_orange {
  background: var(--token-rsLFZ8cZG);
}
.softColorbuttonType_outline {
  display: block;
}
.softColorbuttonType_soft {
  display: block;
}
.softColorbuttonType_transparent {
  background: none;
  display: block;
}
.hoverWrapper {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: opacity;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.root:hover .hoverWrapper {
  opacity: 0.75;
}
.root:active .hoverWrapper {
  opacity: 1;
}
.rootcolor_link:hover .hoverWrappercolor_link {
  display: none;
}
.rootcolor_link:active .hoverWrappercolor_link {
  display: none;
}
.hoverColor {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: var(--token-IOs4wk1cx7qv8eT);
  transition-property: background, opacity;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  opacity: 0.06;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: background, opacity;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 5px;
}
.hoverColorcolor_blue {
  background: var(--token-4Vy3YOrYIv8ljp2);
}
.hoverColorcolor_gold {
  background: var(--token-Wc2tr2MR59RbrGz);
}
.hoverColorcolor_green {
  background: var(--token--1wl_F9deuezOld);
}
.hoverColorcolor_red {
  background: var(--token-jW34YHQobNMSD0Y);
}
.hoverColorcolor_orange {
  background: var(--token-ZpmSrRJAo);
}
.hoverColorbuttonType_solid {
  background: #21489680;
  opacity: 0.5;
}
.loaderIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.loaderIconContainerisLoading {
  position: absolute;
  left: auto;
  top: auto;
  display: flex;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  height: 1em;
}
.svgcolor_blue {
  color: var(--token-WYpGQpgya-mDjv5);
}
.svgcolor_gold {
  color: var(--token-N7ZH9ZZ89u1SktH);
}
.svgcolor_green {
  color: var(--token-yQ5PNjYizuW9slV);
}
.buttonContent:global(.__wab_instance) {
  position: relative;
}
.svg___4MEkD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lFv8C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
