.root {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: stretch;
  transition-property: all;
  transition-duration: 1s;
  background: var(--token-nJQnmTwmF4SPdl6);
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.processHeader:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
.scrollNav__dfebi:global(.__wab_instance) {
  position: relative;
  align-self: auto;
  left: auto;
  top: auto;
  z-index: 1;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__dfebi:global(.__wab_instance) {
    left: auto;
    top: auto;
    z-index: 1;
    position: relative;
  }
}
@media (max-width: 480px) {
  .scrollNav__dfebi:global(.__wab_instance) {
    left: auto;
    top: auto;
  }
}
.scrollNavGroup___8FfYe:global(.__wab_instance) {
  position: relative;
}
.navButton___3FbxV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0Okam {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___78Ld2 {
  height: auto;
}
.navButton__meJqI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ugtr7 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__uJvSd {
  height: auto;
}
.navButton__reT:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___8Wn4M {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__es9Sq {
  height: auto;
}
.navButton__azkTi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rnt63 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__eNft {
  position: relative;
}
.navButton___4A0F:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__jGWc0 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__if506 {
  height: auto;
}
.navButton__pphnO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___9GyZu {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__uHwOi {
  height: auto;
}
.navButton__r2Gr2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__jEwzf {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___6NFe0 {
  height: auto;
}
.navButton___9SMk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__itFna {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__iiu75 {
  height: auto;
  padding-left: 0px;
}
.navButton__x80J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__toBm {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__e955Z {
  height: auto;
}
.navButton__bepWu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___2O1Yo {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___5VmjM {
  height: auto;
}
.navButton__fGoZv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__h1Us5 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__mzpaW {
  height: auto;
}
.freeBox__aaYuF {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__aaYuF {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__aaYuF {
    display: flex;
    flex-direction: column;
  }
}
.scrollNav__lKMxO:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__lKMxO:global(.__wab_instance) {
    left: 0px;
    top: 0px;
    z-index: 1;
    position: sticky;
  }
}
.scrollNavGroup__fFUm1:global(.__wab_instance) {
  position: relative;
}
.remmsNavButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__eRp9W {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__lGj1M {
  height: auto;
}
.navButton___798T6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vEycP {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__mv6I2 {
  height: auto;
}
.navButton__fjRWo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pPoSr {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__ng1Ai {
  height: auto;
}
.navButton__wih1P:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__txQjw {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___5IGg {
  height: auto;
}
.navButton__nooS7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cyo35 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__nhJ6S {
  position: relative;
}
.navButton__jc6Q:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wa6Ay {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__hOyUm {
  height: auto;
}
.navButton___4C7E9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tHIqz {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__dHbZe {
  height: auto;
}
.navButton__bgJn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___3I9PT {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__umKhr {
  height: auto;
}
.navButton__ft6OB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__jCPnu {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__yT6Et {
  height: auto;
  padding-left: 0px;
}
.navButton__yBOqN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__p03Kz {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__vkxMl {
  height: auto;
}
.navButton__ijIPg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uLNe {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__orrFe {
  height: auto;
}
.navButton__fhibj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__amga {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__tuN2K {
  height: auto;
}
.processPageContent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.freeBox__gLucd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.reveal:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.processSectionHeader:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___72NhU {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 88px;
  padding-bottom: 40px;
  padding-right: 88px;
  min-width: 0;
}
.freeBox___72NhU > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox___72NhU > :global(.__wab_flex-container) > *,
.freeBox___72NhU > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___72NhU > :global(.__wab_flex-container) > picture > img,
.freeBox___72NhU
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox___72NhU {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 40px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox___72NhU > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox___72NhU > :global(.__wab_flex-container) > *,
  .freeBox___72NhU > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___72NhU > :global(.__wab_flex-container) > picture > img,
  .freeBox___72NhU
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox___72NhU {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox___72NhU > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .freeBox___72NhU > :global(.__wab_flex-container) > *,
  .freeBox___72NhU > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___72NhU > :global(.__wab_flex-container) > picture > img,
  .freeBox___72NhU
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.freeBox__qcxg3 {
  display: flex;
  position: relative;
  flex-direction: column;
  background: none;
  width: 100%;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
  border-color: var(--token-XVEsAlbFfHq6FLt);
}
.freeBox__qcxg3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 14px);
  height: calc(100% + 14px);
}
.freeBox__qcxg3 > :global(.__wab_flex-container) > *,
.freeBox__qcxg3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qcxg3 > :global(.__wab_flex-container) > picture > img,
.freeBox__qcxg3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 14px;
}
.freeBox__hOc6O {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__hOc6O > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__hOc6O > :global(.__wab_flex-container) > *,
.freeBox__hOc6O > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hOc6O > :global(.__wab_flex-container) > picture > img,
.freeBox__hOc6O
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
@media (max-width: 480px) {
  .freeBox__hOc6O > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-8IFSbVVlb));
    width: calc(100% + var(--token-8IFSbVVlb));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__hOc6O > :global(.__wab_flex-container) > *,
  .freeBox__hOc6O > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__hOc6O > :global(.__wab_flex-container) > picture > img,
  .freeBox__hOc6O
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-8IFSbVVlb);
    margin-top: 0px;
  }
}
.freeBox__wjHh {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.text__si1FX {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-0_AU74ENX98Rg_i);
  text-align: left;
}
.freeBox__aowAw {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.freeBox__aowAw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__aowAw > :global(.__wab_flex-container) > *,
.freeBox__aowAw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aowAw > :global(.__wab_flex-container) > picture > img,
.freeBox__aowAw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.dynamicText__eYrcz:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__baTV {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-c5GLNJb7VW_H03Z);
  font-weight: 600;
  color: var(--token-o919cMZe3pTxZb-);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
@media (max-width: 480px) {
  .text__baTV {
    font-size: var(--token-FutXsu2VTAR30Ez);
  }
}
.freeBox___6JuPh {
  display: flex;
  flex-direction: column;
}
.freeBox___6JuPh > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.freeBox___6JuPh > :global(.__wab_flex-container) > *,
.freeBox___6JuPh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6JuPh > :global(.__wab_flex-container) > picture > img,
.freeBox___6JuPh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2px;
}
.text___4MmIr {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-0_AU74ENX98Rg_i);
  text-align: center;
}
.freeBox__zn9SS {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.freeBox__zn9SS > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__zn9SS > :global(.__wab_flex-container) > *,
.freeBox__zn9SS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zn9SS > :global(.__wab_flex-container) > picture > img,
.freeBox__zn9SS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.dynamicText__jbTl3:global(.__wab_instance) {
  max-width: 800px;
  object-fit: cover;
  position: relative;
  width: auto;
  height: auto;
  font-size: var(--token-3jOrWkEDNu9ZZIv);
  font-weight: 600;
  color: var(--token-o919cMZe3pTxZb-);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.text__aqsVx {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-3jOrWkEDNu9ZZIv);
  font-weight: 600;
  color: var(--token-o919cMZe3pTxZb-);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
@media (max-width: 480px) {
  .text__aqsVx {
    font-size: var(--token-ueN_0TwhBMb9CkO);
  }
}
.freeBox__dht3M {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.text__lu3Fk {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-0_AU74ENX98Rg_i);
  text-align: right;
}
.freeBox__ywVlg {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.freeBox__ywVlg > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__ywVlg > :global(.__wab_flex-container) > *,
.freeBox__ywVlg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ywVlg > :global(.__wab_flex-container) > picture > img,
.freeBox__ywVlg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.dynamicText__abupA:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__gFhOo {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-c5GLNJb7VW_H03Z);
  font-weight: 600;
  color: var(--token-o919cMZe3pTxZb-);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
@media (max-width: 480px) {
  .text__gFhOo {
    font-size: var(--token-FutXsu2VTAR30Ez);
  }
}
.freeBox__lu4Tp {
  display: block;
  position: relative;
  width: 100%;
  height: 16px;
  background: var(--token-XVEsAlbFfHq6FLt);
  min-width: 0;
  flex-shrink: 0;
  border-radius: 4px;
}
.freeBox__zBq8R {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 50%;
  width: 100%;
  height: 0px;
  bottom: auto;
  min-width: 0;
}
.indicator2 {
  display: flex;
  position: absolute;
  height: auto;
  max-width: 100%;
  left: 50%;
  top: auto;
  width: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.freeBox__zLuL {
  display: block;
  position: relative;
  width: 2px;
  height: 28px;
  max-width: 100%;
  background: var(--token-QYymIwPX-bo_uMH);
  flex-shrink: 0;
  border-radius: 100px;
}
.freeBox___0Ov5G {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--token-vQIHntGSmUGoi3x);
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid var(--token-QYymIwPX-bo_uMH);
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
@media (max-width: 480px) {
  .button:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.svg__r0Bhe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kfboN {
  padding-left: 0px;
}
.svg__iWw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__u0Pju:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard__u0Pju:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
@media (max-width: 480px) {
  .processCard__u0Pju:global(.__wab_instance) {
    margin-bottom: 16px;
  }
}
.processCardSection__ygdg0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__a5Trj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__a5Trj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__a5Trj > :global(.__wab_flex-container) > *,
.freeBox__a5Trj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__a5Trj > :global(.__wab_flex-container) > picture > img,
.freeBox__a5Trj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__a5Trj > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__a5Trj > :global(.__wab_flex-container) > *,
  .freeBox__a5Trj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__a5Trj > :global(.__wab_flex-container) > picture > img,
  .freeBox__a5Trj
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__uo75U {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__gWqDl:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__eeheu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__zhDsk:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__nLw9R:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___0DmXr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__cWlJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nTl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__v3By7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sCCsH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__bSknu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__yaxlD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__fg2HU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___4WYjh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7ZW7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dcC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__toRgz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__xfw72:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__bbYe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__aCaCt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__xu0Ss {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vkNj3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7QuI3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xFNk0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__sy9W {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.freeBox__sy9W > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__sy9W > :global(.__wab_flex-container) > *,
.freeBox__sy9W > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sy9W > :global(.__wab_flex-container) > picture > img,
.freeBox__sy9W
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.formGroup__c9H7U:global(.__wab_instance) {
  max-width: 100%;
}
.formSuggestionGroup:global(.__wab_instance) {
  max-width: 100%;
}
.textInput25:global(.__wab_instance) {
  width: 100px;
}
.svg__wMr68 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fO5TR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hHxxt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gGx0E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__uo2Iz:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__klxtv:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___1Yuuv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___2Dmwn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__bn4Eo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ogZjr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jVVf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__v5EBz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__mAhEt:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__jCg2L:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__jvpl1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__uQxmz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oAa2T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bTQel {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__p8DR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__vpi3G:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__zhwHa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formInputTableCell__vh9Wk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__m1OJm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__giv9R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vz6Ba {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zFijW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__nahUa:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__eX9Dn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___3Qi3M:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__rwEs0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fRumm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ep8R8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cWh6E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__zdzo8:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__cyAj5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__cShBa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__iblql {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tx0Cg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__c1BqI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5Y3Kw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__qIg3Q:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__bwkBt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__upKem:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__vObNa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__m9Woa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1CChm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xVeXr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___2IEdq:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___9TnOr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__q3Knk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__sHy4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__svcqz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__osYX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kfGw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__ulP0D:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__fd5Kt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__pHNf8:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__dlzOz:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__wgHqI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__tge0V:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___0NwN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__a3Dns {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oKbPl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__is2Mg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__zmtQl:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__hkoTi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__rtKjP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__mO8F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xDu5M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__u3QTu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__hYkz:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__go4Co:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__aqqHr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__qQf9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kyDx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pDb7C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__u6JgV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__x7G8:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___87OiW:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__mmAAs:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__hx3A9:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___78I38:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__arPRo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__jwHsg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tiIhw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__krGGf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lVv2A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__weCSg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__qj5AC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__czpdN:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__eQsVf:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___2Q7JO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formGroup__ehLzp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput14:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__pdPsC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ii1Bn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ecEhe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4CKjn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___2Dlsw:global(.__wab_instance) {
  position: relative;
}
.formInputTableCell__pfvn0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___5A5T4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__q41CR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__p9Uqu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rhjj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__jWg8R:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__kjSe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__ypAay:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell___9SZaL:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__mzch8:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formGroup__uvyYp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput16:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__y45 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jNE4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__x2Vbs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rUju {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__yHRx:global(.__wab_instance) {
  position: relative;
}
.text__zDq0R {
  font-weight: 400;
}
.formGroup__gpg4S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__qJnjc:global(.__wab_instance) {
  position: relative;
}
.svg__yAxQ4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__q3YMa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zcjgx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3BLR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__a6Gv5:global(.__wab_instance) {
  position: relative;
}
.text__kcsKm {
  padding-right: 0px;
}
.svg__g9IG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tKybs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__umsRa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__gzjd:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard__gzjd:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
@media (max-width: 480px) {
  .processCard__gzjd:global(.__wab_instance) {
    margin-bottom: 16px;
  }
}
.processCardSection__hHhv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___8EZcm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.marktwertTableRow__fYqj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.formInputTableCell__c1Kg0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: auto;
}
.svg__y0Uez {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uyhs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___3GLms {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__udxe9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__kyxq:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formGroup__kdkNn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput18:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__ucNRc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xDwys {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rsCw9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qdfY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__ebqf2:global(.__wab_instance) {
  position: relative;
}
.text__bmGni {
  font-weight: 400;
}
.formGroup__f4Ext:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__tanGf:global(.__wab_instance) {
  position: relative;
}
.svg__dogL3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yZouk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__h0Rt3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cCAz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__yuKLu:global(.__wab_instance) {
  position: relative;
}
.text__k7ZI4 {
  padding-right: 0px;
}
.svg__owda {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lc4Op {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__txU2E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard___1Gyay:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard___1Gyay:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
@media (max-width: 480px) {
  .processCard___1Gyay:global(.__wab_instance) {
    margin-bottom: 16px;
  }
}
.processCardSection__mAzMb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.hinweisGebaudevolumen:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  min-width: 0;
}
.text__vbkwp {
  color: var(--token-PcBCP-f11VpIoWX);
}
.freeBox__shDxe {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  min-width: 0;
}
.freeBox__shDxe > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__shDxe > :global(.__wab_flex-container) > *,
.freeBox__shDxe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__shDxe > :global(.__wab_flex-container) > picture > img,
.freeBox__shDxe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.volsia03 {
  color: var(--token-PcBCP-f11VpIoWX);
  width: auto;
}
.text___53G5I {
  color: var(--token-PcBCP-f11VpIoWX);
}
.text___4QsHm {
  width: auto;
}
.freeBox___4EVrQ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.marktwertTableRow__ug4Jr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
}
.formTextTableCell__sKyah:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__nlakC:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow___6Ot7Y:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
}
.text__dVugK {
  height: auto;
}
.formTextTableCell__jghah:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__ea6Ha:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__ny31:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
}
.text__thR5U {
  height: auto;
}
.formTextTableCell__dbcNf:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__xzC6S:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__slmUm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.formTextTableCell__imEZd:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__loBuw:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow__o2Xb9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell___79UkC:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___2Wpor:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__ak1Sh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  flex-shrink: 0;
}
.marktwertTableRow__koSpo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.formTextTableCell___9AJRe:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__dQuu:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.processCardSection__pM7K:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__oqZfi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  min-width: 0;
}
.freeBox___0NIbL {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___0NIbL > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___0NIbL > :global(.__wab_flex-container) > *,
.freeBox___0NIbL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0NIbL > :global(.__wab_flex-container) > picture > img,
.freeBox___0NIbL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.marktwertTableRow___8IyEu:global(.__wab_instance) {
  max-width: 100%;
}
.text__gPtct {
  font-weight: 600;
}
.freeBox__t4B7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator___1DtFr:global(.__wab_instance) {
  position: relative;
}
.dynamicText__cPjc9:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__kWghx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__oWF2:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.freeBox__icq5 {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  margin-top: 24px;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__icq5 {
    margin-top: 8px;
  }
}
.card:global(.__wab_instance) {
  position: relative;
}
.freeBox__jhiwS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 32px 32px 24px;
}
.text__oNDgd {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.freeBox__leTsw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  min-width: 0;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.marktwertTableRow__u0Fsf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__xQevl {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__jQx24:global(.__wab_instance) {
  position: relative;
}
.dynamicText__hykNr:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__rdona {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__sxLrc:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__nhR1J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__cDLxT {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px 0px;
}
.statusIndicator__ycwX9:global(.__wab_instance) {
  position: relative;
}
.dynamicText___2G1Bz:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__gpm1E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__kfWQh:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__esB60:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__b3J2B {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px 0px;
}
.statusIndicator__xOq3E:global(.__wab_instance) {
  position: relative;
}
.dynamicText__qdLah:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__hgDp2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___91Vy:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.freeBox__wNOup {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  min-width: 0;
  padding: 32px 32px 24px;
}
.text__m7I32 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.freeBox__rKReW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  min-width: 0;
}
.marktwertTableRow___8QT2F:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox___5B06U {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__lkR7H:global(.__wab_instance) {
  position: relative;
}
.dynamicText__gmEpi:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__o5Mwk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__jSbAa:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__qdHlf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__t9YqN {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__eYst:global(.__wab_instance) {
  position: relative;
}
.dynamicText__ciJ5H:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text___7No9W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__zxsCl:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__lkUAe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__rkHxa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__fJaRg:global(.__wab_instance) {
  position: relative;
}
.dynamicText__pJ1SH:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__jjMiP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__sBHbP:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__jOd0Z:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox___03Q9L {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__pc4Fu:global(.__wab_instance) {
  position: relative;
}
.dynamicText___5XxkB:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__r3Nk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__yoFK:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__wjbbH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__wFLeY {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator___2Hk4F:global(.__wab_instance) {
  position: relative;
}
.dynamicText__zdjzy:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__zqx4K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__vXEqC:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___9ARt8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__c7Raq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__tpIuh:global(.__wab_instance) {
  position: relative;
}
.dynamicText___7Dnw8:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__a4UZs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell___9Bg1:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__m2Hx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__xIbTr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator___0FRcc:global(.__wab_instance) {
  position: relative;
}
.dynamicText__xXjot:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__dHdDn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__uDcxg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__dErt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__hm2Cy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__fn2W2:global(.__wab_instance) {
  position: relative;
}
.dynamicText___7YrOv:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__tAySt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__wdGi7:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__ncnX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox___9Qxl0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 0;
}
.statusIndicator__twQyz:global(.__wab_instance) {
  position: relative;
}
.dynamicText___0PMQe:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__vQqS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__byQhF:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.button__soAvJ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___5HQif {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zx620 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__dn2Hb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sviBs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lyQOl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__kyBdN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dbUla {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7AtNv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.formGroup___43Iqe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.istAnalysePercentageInput:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__zPsLx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5KCyH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2NOl6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wbNQn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__ayitS:global(.__wab_instance) {
  position: relative;
}
.text__yghM {
  font-weight: 400;
}
.formGroup__pgziW:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.istAnalysePercentageInput2:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___8T80G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nPsgd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jNxCt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wvsnk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__uxeB2:global(.__wab_instance) {
  position: relative;
}
.text__hsi4 {
  font-weight: 400;
}
.svg__d5Igu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kMjMr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yTw6M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__hevrh:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard__hevrh:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection___3LrxU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__j9DIs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__j9DIs > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__j9DIs > :global(.__wab_flex-container) > *,
.freeBox__j9DIs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__j9DIs > :global(.__wab_flex-container) > picture > img,
.freeBox__j9DIs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__j9DIs > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__j9DIs > :global(.__wab_flex-container) > *,
  .freeBox__j9DIs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__j9DIs > :global(.__wab_flex-container) > picture > img,
  .freeBox__j9DIs
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__tnXb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__pSmik:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__wnm3:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__xhfnF:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow__ziBuD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___2UlrP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__bm4VZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ftjZt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nEene {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yArld {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__bGw79:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__inHmz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__droNg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__z6Vnf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ykEqk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lfxgr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rGMqv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__t8RLl:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__hDjt5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__dtDlp:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__sQpql:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__v3Mop:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell___6R3Du:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__jaZq:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__oIvRw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__pu73Q {
  height: auto;
}
.formTextTableCell___4OrQp:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__jPU:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow__z19R:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__odexg:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell___8EVsM:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__sztMo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.marktwertTableRow___0Hih1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__vFncC:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__ofj68:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formGroup__npxE6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.neubauRenovationsbedarfPercentageInput:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__mPguA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kMl6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fZfhP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__pi4UV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__tBtNn:global(.__wab_instance) {
  position: relative;
}
.text__zhkPg {
  font-weight: 400;
}
.formGroup___0HO3G:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.neubauRenovationsbedarfPercentageInput2:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__d2CzX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hZNfi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pQhkx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mquZg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__jvX4:global(.__wab_instance) {
  position: relative;
}
.text__vBolC {
  font-weight: 400;
}
.svg__kJnNr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vo1Uo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uhvM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardSachwert:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCardSachwert:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection__j3NwP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___2Fgit {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___2Fgit > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___2Fgit > :global(.__wab_flex-container) > *,
.freeBox___2Fgit > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2Fgit > :global(.__wab_flex-container) > picture > img,
.freeBox___2Fgit
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox___2Fgit > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox___2Fgit > :global(.__wab_flex-container) > *,
  .freeBox___2Fgit > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___2Fgit > :global(.__wab_flex-container) > picture > img,
  .freeBox___2Fgit
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__oDzlL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__sQufa:global(.__wab_instance) {
  max-width: 100%;
}
.formInputTableCell__nes5C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.erstellungskostenInput:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__etVXp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vbBk9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ufMk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4Y1SZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__hvZdT:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___1Finf:global(.__wab_instance) {
  max-width: 100%;
}
.formInputTableCell__on11:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.erstellungskostenInput2:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__lbYuw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7XaQg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kb0As {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1Brpq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__ettex:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___76EZb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__wrHp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__qqDpZ {
  height: auto;
}
.renovationsbedarfInput2:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__prIMh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nZdwL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4H6NV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bCaql {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__sa3W1:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__y8U7J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__z4S2W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__nuYhn {
  height: auto;
}
.renovationsbedarfInput5:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg___901Eb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pmrIo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wZgxC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___9NVu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__v47Qb:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__khQh6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__gUmj2:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__wez1S:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__bj6G3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__citty:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__bgx89:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__zk9W4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  flex-shrink: 0;
}
.marktwertTableRow___7YiEj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__s03NG:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.landwertInput:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__huFqB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sPbS8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rheNh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rFXg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__laoK6:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__mnqD8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__lRjd {
  height: auto;
}
.formTextTableCell__y2UYd:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___7AxLn:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___4FPxj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__hSRat:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell___0Lqm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__sbGVj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__qKcUo:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__raPXm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___2YZkU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.text__gNJgl {
  height: auto;
}
.formTextTableCell___6Uik0:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__ieGx:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__c52Im:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.text__nEai {
  height: auto;
}
.formTextTableCell__gfi8G:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__hIrN:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__zy6Z9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  flex-shrink: 0;
}
.marktwertTableRow__hjXc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__zf8Ma:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell___9DngM:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow__k5W6T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__g7ZEp:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__a6ARs:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__wOt9U:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__nGqb:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__vis99:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow__lzHtZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__noovP:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__xRb7:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider___6LApN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.marktwertTableRow__czPsk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__b1:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__eC9C8:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formGroup___0YiYq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.sachwertPercentageInput:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__luwrM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6VWc5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__e7BE5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aaD60 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__pg2Tr:global(.__wab_instance) {
  position: relative;
}
.text__gylXn {
  font-weight: 400;
}
.formGroup__iWhtI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.sachwertPercentageInput2:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__lkco8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__znWOc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__u1KAx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ozCBt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___1L3Lm:global(.__wab_instance) {
  position: relative;
}
.text__cUqoR {
  font-weight: 400;
}
.svg__gWwd6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yyLdX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__if5J9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardSachwertImmowertv:global(.__wab_instance) {
  max-width: 100%;
  margin: 0px 0px 40px;
}
.formGroup__asbOu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput32:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__o2TWe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ucGcv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cVwok {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rm0HB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__pOzPf:global(.__wab_instance) {
  position: relative;
}
.text__a7Jhg {
  font-weight: 400;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.formGroup__i2RBq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__gCeNs:global(.__wab_instance) {
  position: relative;
}
.svg__t9X9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__b2RMt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hwZz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ycpIl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__wP70N:global(.__wab_instance) {
  position: relative;
}
.text__aMubF {
  padding-right: 0px;
}
.processCardSection__rfeel:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__inXqS {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__inXqS > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__inXqS > :global(.__wab_flex-container) > *,
.freeBox__inXqS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__inXqS > :global(.__wab_flex-container) > picture > img,
.freeBox__inXqS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__inXqS > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__inXqS > :global(.__wab_flex-container) > *,
  .freeBox__inXqS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__inXqS > :global(.__wab_flex-container) > picture > img,
  .freeBox__inXqS
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__zLeRb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__kkuOo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___62Wc4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__fkZpe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4PQT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kvzRg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__t6J1V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__eG070:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__ybhpn:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__f2LqK:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__bl4TY:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__mrvjO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__qjIkI {
  height: auto;
}
.formTextTableCell__dlIR:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___93Gxi:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__b9NAt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__yxkzv:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__tngOa:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__s2W1T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__wUhE:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__t1F7Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hmh0A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cw1UE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xDuTy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__dtKmn:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__mWqx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__o0L8P:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__ws32U:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__vutM:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__pgn4G:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__qYoH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__ujKzx:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__lb7Hg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__wKqjn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__c7Ef {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__c7Ef > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__c7Ef > :global(.__wab_flex-container) > *,
.freeBox__c7Ef > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__c7Ef > :global(.__wab_flex-container) > picture > img,
.freeBox__c7Ef
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__c7Ef > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__c7Ef > :global(.__wab_flex-container) > *,
  .freeBox__c7Ef > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__c7Ef > :global(.__wab_flex-container) > picture > img,
  .freeBox__c7Ef
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox___8XGy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__zy7ZW:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__n1Zrw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__ccbcZ:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__lKYnK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__gq2F4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__fyi5Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qeaBn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uDis {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hRsOb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__rFurB:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__lEmKf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__tlbjv:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__q19A6:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__boeTr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__kxsPs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow___6PcDv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__mh4S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__gb2Xi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tPdtU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pwInh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dhFyj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__mosFp:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__c823C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__m7Ocf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__rS8Yo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i6Nna {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xY8Yp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ugKua {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__f0Axo:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__dXkL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__ixzw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___3Pt7H:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__q79R:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__fVxpH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__dTUkk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__izUry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___5Ovou {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rHked {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__amIaj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hHmE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__yeVmd:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__jqB24:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__cb8H5:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__aqlTo:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__uxRuh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__cGf8Y:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__rAgr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jMsZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k6PoJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zyOKz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__gXv6W:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__fFfle:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell___901KI:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__bopt:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__yqshv:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__xQj3R:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__yQiyc:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__cfE4Z:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox___9KqBf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__tzsCx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__ouBTg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__rvQVt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7JguF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tqH9T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__foxB9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__px9L:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___7SThc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__l0M5F:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__xgEdW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__s7T9L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___01InF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aNcJo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__qoUc4:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__gYFdw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__yCmQ:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__cejf:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__cVnsE:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__rQcGc:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__oryIc:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__pwI5T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__xm5V4:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__yZunu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider___4Ycx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__ztCv {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.marktwertTableRow__ooMTq:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__jMadp:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__w8J:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__wWmyj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__ujNiw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__m5Hlp:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__wiLbq:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__jkSe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText___9YWmf:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__gXqbz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__sRlc3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___5AQfy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___5AQfy > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___5AQfy > :global(.__wab_flex-container) > *,
.freeBox___5AQfy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5AQfy > :global(.__wab_flex-container) > picture > img,
.freeBox___5AQfy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox___5AQfy > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox___5AQfy > :global(.__wab_flex-container) > *,
  .freeBox___5AQfy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___5AQfy > :global(.__wab_flex-container) > picture > img,
  .freeBox___5AQfy
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__mSu0W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__n00No:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___8Qz2P:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__sg4Ba {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aSpqr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6Ldlt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__odlew {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___9R4HS:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__la57L:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__ez0XK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__pR0Yh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7CbE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pxZty {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gi2H4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__u1Skx:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__dw16S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__txt5X:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__s4DLh:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__omu5B:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__cLd7Z {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__r8VKk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__vmjEt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__sitSm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aZrL5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bfKxu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xZfNd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__rvmX9:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___9Dzs2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__wNfGg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___2A5Di {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__v67O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gcCg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hmShS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__g56Hg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__vmX6U:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__tz3H0:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__aR4Or:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__oWoDc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox___11Cz2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__ocy2B:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__apeJi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___6HBjg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dsMu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bXcSe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5MWa8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__fqiaR:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__qAYt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__p0KhQ:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__qGqu3:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__o2H5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__aahLc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__xBrhc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qh2Jb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aSeoV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mWY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___4Wy8V:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__gPjxa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__bjGwt:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__c6Lyu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__wy8Gf:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__hlvQi:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__ayhw0:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider___8BF7A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__frsBk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__oq29J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__eAxbd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__s6WK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zsBef {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nue {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__oAQaQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__vhxe4:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__v2Qsa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__cwYi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__pspDt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ul74F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hbEmf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__alSxR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__keb0C:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__zgOal:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__cHsjN:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__bpZDt:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__qz0Mf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell___3Hwu5:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__oMu3:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__tTEb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell___3RT:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__pk6Be:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider___9TiH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__rs5Ow {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.marktwertTableRow__po9Ym:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__wf2Mz:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__l5Fi5:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__zvFwv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell___7Yd4D:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__dEtar:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__awPfH:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__yjD1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__vfWBc:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__htJN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__u8Qri:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__mbJ2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__mbJ2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__mbJ2 > :global(.__wab_flex-container) > *,
.freeBox__mbJ2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mbJ2 > :global(.__wab_flex-container) > picture > img,
.freeBox__mbJ2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__mbJ2 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__mbJ2 > :global(.__wab_flex-container) > *,
  .freeBox__mbJ2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mbJ2 > :global(.__wab_flex-container) > picture > img,
  .freeBox__mbJ2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.marktwertTableRow__hNb3Z:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__zlMqW:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__igjFf:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider___13BCo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__cc8W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__cs59Q:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__gXghu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__lePl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__al4CA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0Sz92 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__y3V58 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___4UxxR:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__lrRl8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell___8R8DC:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__hEggm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__fon16:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__d4IyK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__vnxqm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__wix:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__uGeWa:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__yvmOj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__drMpv:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__flb1L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__sqh73:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__l6AOm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__l6AOm > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__l6AOm > :global(.__wab_flex-container) > *,
.freeBox__l6AOm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__l6AOm > :global(.__wab_flex-container) > picture > img,
.freeBox__l6AOm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__l6AOm > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__l6AOm > :global(.__wab_flex-container) > *,
  .freeBox__l6AOm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__l6AOm > :global(.__wab_flex-container) > picture > img,
  .freeBox__l6AOm
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__r9RHo {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.marktwertTableRow__nmE7M:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__cJK6:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__cbaVu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__hHVh4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__tIob2:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__oztXq:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__jZiLs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__oeZLf {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.marktwertTableRow___1BlSx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__hTCyC:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__u25Bl:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__iTz34:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__mAZe:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__qZMfu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__thQmi:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__i5Ngr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText___0K1Ft:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__toTb2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__w9Srx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__wSXxw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__wSXxw > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__wSXxw > :global(.__wab_flex-container) > *,
.freeBox__wSXxw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wSXxw > :global(.__wab_flex-container) > picture > img,
.freeBox__wSXxw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__wSXxw > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__wSXxw > :global(.__wab_flex-container) > *,
  .freeBox__wSXxw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__wSXxw > :global(.__wab_flex-container) > picture > img,
  .freeBox__wSXxw
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox___2SmrF {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.marktwertTableRow___6E3L:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__oo3N:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__cimnB:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__aqOtq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__oA9FL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__oHcFa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__r1Y5W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ebzN6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ebV3I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__a6QP:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__iulj5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__wFjyw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__vAiJ:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__v8FNf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox___4Whyw {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.marktwertTableRow__cEpK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__oIs2T:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__iQegH:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__w9Pnm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__ofPrG:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__b2FbM:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__yoRC:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__fNiBj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__zuP0V:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__fyiAr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__agH9E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__dx5Db {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__dx5Db > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__dx5Db > :global(.__wab_flex-container) > *,
.freeBox__dx5Db > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dx5Db > :global(.__wab_flex-container) > picture > img,
.freeBox__dx5Db
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__dx5Db > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__dx5Db > :global(.__wab_flex-container) > *,
  .freeBox__dx5Db > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__dx5Db > :global(.__wab_flex-container) > picture > img,
  .freeBox__dx5Db
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__bQAwm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__uUeUc:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__xGyCc:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___21D2Z:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__rlwWe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__cst7N:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___4Zkpw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__s8KKw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rbAm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ehcn1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__jV9G1:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__ysPrv:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__nGxA:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell___02Gzz:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__pf4Gd:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__bbUvr:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__zpPHh:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___4LcpB:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__jg1BQ:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__qpu62:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__fjq9H:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__lOrVt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___3EGaO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fhlzs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__coYtj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qCaSh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__kFrh7:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__f6XhJ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__wLqZh:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__sVsXg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___5EZ9O:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__va3Ij:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text___0W2Ui {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__htfYc:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__xvKi2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.svg__dTzTc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jz2G8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fUc5G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardErtragswert:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCardErtragswert:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection__aKkCw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__myWUv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__myWUv > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__myWUv > :global(.__wab_flex-container) > *,
.freeBox__myWUv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__myWUv > :global(.__wab_flex-container) > picture > img,
.freeBox__myWUv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__myWUv > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__myWUv > :global(.__wab_flex-container) > *,
  .freeBox__myWUv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__myWUv > :global(.__wab_flex-container) > picture > img,
  .freeBox__myWUv
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__x8T8O {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__qWb94:global(.__wab_instance) {
  max-width: 100%;
}
.formInputTableCell__wZc7R:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__tRucw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tWf2L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uNgPp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dqc13 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__zdlYu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__alpxw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__n0BSs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__bPbzq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jGa6Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mk2FO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__m66 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__wNaBw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__bfzDh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__vxE8R:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__zMqtq:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow__sOnJ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__b6UJz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.renovationsbedarfInput3:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__k5Vyv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ocqYz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__murje {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__be9Wj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__jJbua:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__d6U6T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__yoU0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.renovationsbedarfInput6:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__bUsLq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__g5ASg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dVEme {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__j9Ilc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__ngk6S:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__inPRq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__panW {
  height: auto;
}
.formTextTableCell___38GVh:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__wzxhN:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__uBwMv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell__byYeh:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__zgRfB:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.marktwertTableRow__neFdm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell__w5WDe:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__jfNsP:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__r1CC1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.marktwertTableRow__bluFz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__tWvu:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__wqZ3W:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formGroup__hihIh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.ertragswertPercentageInput:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__a8Dw1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i0Ok3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rd9K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aImn2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__wuhc:global(.__wab_instance) {
  position: relative;
}
.text__gj8Qq {
  font-weight: 400;
}
.formGroup__gQiln:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.ertragswertPercentageInput2:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__hp29S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hndUe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rkdJm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uXbXc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__mRix:global(.__wab_instance) {
  position: relative;
}
.text__sx5S3 {
  font-weight: 400;
}
.svg__iNh04 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cYBi1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ue0K4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardErtragswertImmowertv:global(.__wab_instance) {
  max-width: 100%;
  margin: 0px 0px 40px;
}
.formGroup__be1Jo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.ertragswertPercentageInput3:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__k9EWd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hon4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dFxKm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__smUz7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__byzQ:global(.__wab_instance) {
  position: relative;
}
.text__w8A27 {
  font-weight: 400;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.formGroup__sq0KB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__tbDuA:global(.__wab_instance) {
  position: relative;
}
.svg___0PK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zQgVm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mI29B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sgsF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__aO0Lt:global(.__wab_instance) {
  position: relative;
}
.text__hta39 {
  padding-right: 0px;
}
.processCardSection__glHl0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__lohnR {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__lohnR > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__lohnR > :global(.__wab_flex-container) > *,
.freeBox__lohnR > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lohnR > :global(.__wab_flex-container) > picture > img,
.freeBox__lohnR
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__lohnR > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__lohnR > :global(.__wab_flex-container) > *,
  .freeBox__lohnR > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__lohnR > :global(.__wab_flex-container) > picture > img,
  .freeBox__lohnR
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__hs1Az {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__e5Jiw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___0Ayrf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__i0SR1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zk8R8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__o64Zt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jpeL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__oPlXm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__jn0Y8:global(.__wab_instance) {
  max-width: 100%;
}
.miteigentumsanteilTableCell2:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__aA6Z:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__d5H7I:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__xlPwC {
  height: auto;
}
.grundstueckTableCell3:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__bAhFq:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__iuDuL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formTextTableCell___4CuZu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__lu0Lk:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__ndRpE:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__y37Rz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__fyCoW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xqBN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pGbog {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4G11W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__fYtui:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__qw4QL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__bG8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__rdfuu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__wtIdq:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__hzP5C:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__ufp8V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__hMyhQ:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__o6J0R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__v1Fk6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___5EuPu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___5EuPu > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___5EuPu > :global(.__wab_flex-container) > *,
.freeBox___5EuPu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5EuPu > :global(.__wab_flex-container) > picture > img,
.freeBox___5EuPu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox___5EuPu > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox___5EuPu > :global(.__wab_flex-container) > *,
  .freeBox___5EuPu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___5EuPu > :global(.__wab_flex-container) > picture > img,
  .freeBox___5EuPu
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__hAwod {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow___82R7:global(.__wab_instance) {
  max-width: 100%;
}
.wohnflaecheTableCell:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__v6EfH:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__kSn9D:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__ceqi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___3ZaaX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gn94C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4Iwp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kv6Wf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__ioWw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__omp4S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
}
.formTextTableCell___7RJme:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__y6Jpm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__bz3O5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__bjP4A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__qX1Lf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4Uknb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__y94Wx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gKgp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___2Tv:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.cardBewirtschaftungskostenHerleiten:global(.__wab_instance):global(
    .__wab_instance
  ) {
  margin-top: var(--token-H3JPdTVdV);
}
.divider__si6Wx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox__zuiU1 {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.marktwertTableRow__lIYxQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__cQh9Z:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__e2CcI:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.text__uqLp6 {
  padding-left: 0px;
}
.dynamicText__nZAw:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__gc9Jt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__duXjz:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text___8FkS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__vgc8R:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__mO3Hl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__mO3Hl > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__mO3Hl > :global(.__wab_flex-container) > *,
.freeBox__mO3Hl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mO3Hl > :global(.__wab_flex-container) > picture > img,
.freeBox__mO3Hl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__mO3Hl > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__mO3Hl > :global(.__wab_flex-container) > *,
  .freeBox__mO3Hl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mO3Hl > :global(.__wab_flex-container) > picture > img,
  .freeBox__mO3Hl
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__av5Yu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__sRlY:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__fmguX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__llaOy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eSnh0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yuzxO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yCeUf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__lIfEl:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__kpHjG:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__gAnPj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__rzR0F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eWoEy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__b3SL2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__b9Sng {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__puChg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider___0VjA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__z3A73:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell___2K9Ke:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__iU8Bk:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__lWfhx:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__lhu4E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__q0W66:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__mAiU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection___6CpZt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___3Mwul {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___3Mwul > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___3Mwul > :global(.__wab_flex-container) > *,
.freeBox___3Mwul > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3Mwul > :global(.__wab_flex-container) > picture > img,
.freeBox___3Mwul
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox___3Mwul > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox___3Mwul > :global(.__wab_flex-container) > *,
  .freeBox___3Mwul > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___3Mwul > :global(.__wab_flex-container) > picture > img,
  .freeBox___3Mwul
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__eXx0J {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow___5Kht8:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__dpwWz:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell___2E8P:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__etYks:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell___4CuVs:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell___76Mq2:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__wgg9T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__uRChb:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__r7NHn:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__xqirn:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.text___2Msl9 {
  padding-right: 0px;
}
.dynamicText__yt772:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text___9HpAr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__n86Wf:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__bkv5Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection___1SBuK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__nlySy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__nlySy > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__nlySy > :global(.__wab_flex-container) > *,
.freeBox__nlySy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nlySy > :global(.__wab_flex-container) > picture > img,
.freeBox__nlySy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__nlySy > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__nlySy > :global(.__wab_flex-container) > *,
  .freeBox__nlySy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__nlySy > :global(.__wab_flex-container) > picture > img,
  .freeBox__nlySy
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__gvfYb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__oRoAs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell___6OJkW:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__us7A3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nxkte {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pxbPi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rcglY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__smTpf:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__p2A6G:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__dlVl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__j6E6R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hfnxd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ybcZj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__m29Hy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___5BOhW:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.processCardSection__rJpOz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___92Xwf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___92Xwf > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___92Xwf > :global(.__wab_flex-container) > *,
.freeBox___92Xwf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___92Xwf > :global(.__wab_flex-container) > picture > img,
.freeBox___92Xwf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox___92Xwf > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox___92Xwf > :global(.__wab_flex-container) > *,
  .freeBox___92Xwf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___92Xwf > :global(.__wab_flex-container) > picture > img,
  .freeBox___92Xwf
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__w48Sd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__ysAkg:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__jQmkZ:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__nHGj9:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow___3U4BS:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__jjuB:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__pFteO:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__vGVi9:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__hsFec:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___9ORhg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__kyo1T:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__bCNr:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell___2Dpvv:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__xmaYj:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__piBXm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__wlmrw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__frY7K:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__oFqB6:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__rfB7N:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__uXjLk:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__k6Htl:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__eSp9S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.freeBox___4Jfv {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.marktwertTableRow__usFrq:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell___8JWym:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__fQpOg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__xffnS:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__cf6Oy:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell___6TU0W:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__lHFl4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__l1Wfi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__dFubN:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__suT2K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.processCardSection__bs0VR:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__ruT45 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ruT45 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__ruT45 > :global(.__wab_flex-container) > *,
.freeBox__ruT45 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ruT45 > :global(.__wab_flex-container) > picture > img,
.freeBox__ruT45
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__ruT45 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__ruT45 > :global(.__wab_flex-container) > *,
  .freeBox__ruT45 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ruT45 > :global(.__wab_flex-container) > picture > img,
  .freeBox__ruT45
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__ujafo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__bTyHw:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell___8ZBKy:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__qrlHm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__y6Lif:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__swVzl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__t4DO9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2AZcI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4OhnO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xmCVy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__eInpv:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__u0Kil:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__aeJ8:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__i4Gyw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__qr6Od:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__zMg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__hri7X:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__d7C5:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__mGkQn:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__nDZw:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__dmUlO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__nTnN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___2Wu6N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yLfwr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nfHa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6BFpZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___2OAQ:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.divider__kaexv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.marktwertTableRow__c1SyP:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__kvH6I:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__jUdYs:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.dynamicText__lnkqp:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  margin-right: 9px;
}
.text__nlBFj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.dynamicText__sT1HZ:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__gCgMx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.svg__bWyv5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6LWiN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pr63I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__rgLg4:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard__rgLg4:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection__tTs7B:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__r1Cg6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__r1Cg6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__r1Cg6 > :global(.__wab_flex-container) > *,
.freeBox__r1Cg6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__r1Cg6 > :global(.__wab_flex-container) > picture > img,
.freeBox__r1Cg6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__r1Cg6 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__r1Cg6 > :global(.__wab_flex-container) > *,
  .freeBox__r1Cg6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__r1Cg6 > :global(.__wab_flex-container) > picture > img,
  .freeBox__r1Cg6
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__j7BjW {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
}
.freeBox__j7BjW > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__j7BjW > :global(.__wab_flex-container) > *,
.freeBox__j7BjW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__j7BjW > :global(.__wab_flex-container) > picture > img,
.freeBox__j7BjW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__j7BjW > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__j7BjW > :global(.__wab_flex-container) > *,
  .freeBox__j7BjW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__j7BjW > :global(.__wab_flex-container) > picture > img,
  .freeBox__j7BjW
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.marktwertTableRow__gQoT:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formInputTableCell__hPrtU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: auto;
}
.svg__dkf6R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ybrHq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ydD0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mtzlH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__cs8Zc:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formGroup__adzx6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.andererPercentageInput:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__nynMd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mqc30 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zGlo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___38Ixi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__mYvu0:global(.__wab_instance) {
  position: relative;
}
.text__goHds {
  font-weight: 400;
}
.formGroup__aRzCw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.andererPercentageInput2:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___7Kwrc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pt6Bi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dVkRp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yWz0P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__v3Oj:global(.__wab_instance) {
  position: relative;
}
.text__oUbgf {
  font-weight: 400;
}
.svg__x6Km8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nSGkF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__c0FU0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__i7Id:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard__i7Id:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection__ikfru:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___61MuG {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___61MuG > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___61MuG > :global(.__wab_flex-container) > *,
.freeBox___61MuG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___61MuG > :global(.__wab_flex-container) > picture > img,
.freeBox___61MuG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox___61MuG > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox___61MuG > :global(.__wab_flex-container) > *,
  .freeBox___61MuG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___61MuG > :global(.__wab_flex-container) > picture > img,
  .freeBox___61MuG
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__saB72 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marktwertTableRow__foJTg:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__u81N:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__rrmQd:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__ySnY0:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 9;
}
.marktwertTableRow__x77Z0:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__qakzJ:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__fwFb:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__h34A:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__pylFf:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__n2AIk:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell___51QF2:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell___2Vs81:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__fAeL:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__uZyM:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__oBgm2:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__hcdLu:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__e1GtI:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__kyVpG:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__qt4BQ:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__aZpBs:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.validationMessage:global(.__wab_instance) {
  position: relative;
}
.divider__cYvuN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.marktwertTableRow__bb7Zc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.formTextTableCell__wHyvr:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formTextTableCell__i3YF:global(.__wab_instance) {
  position: relative;
  width: auto;
  grid-column-end: span 9;
}
.formGroup__egC5U:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput27:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__tEsRc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zca1X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cCTc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__y8Zgh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___5Wb0U:global(.__wab_instance) {
  position: relative;
}
.text___4TgGz {
  font-weight: 400;
}
.formGroup___1Hkmv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__u0Ss:global(.__wab_instance) {
  position: relative;
}
.svg__u67D3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fhatV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__l95A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gerL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___3AhH:global(.__wab_instance) {
  position: relative;
}
.text___7BkLt {
  padding-right: 0px;
}
.svg__rSh8L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vefsn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___21E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__tqwsu:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard__tqwsu:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection___8Lwzm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__emJkc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__emJkc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__emJkc > :global(.__wab_flex-container) > *,
.freeBox__emJkc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__emJkc > :global(.__wab_flex-container) > picture > img,
.freeBox__emJkc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__emJkc > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .freeBox__emJkc > :global(.__wab_flex-container) > *,
  .freeBox__emJkc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__emJkc > :global(.__wab_flex-container) > picture > img,
  .freeBox__emJkc
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.flaechenpreiseBoxPlot:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
  min-width: 0;
}
.stueckpreiseBoxplot:global(.__wab_instance) {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.formGroup__mdWm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput28:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___6VdrN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bIvp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1Velk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__v5YvV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__wbvX:global(.__wab_instance) {
  position: relative;
}
.text__c4RY {
  font-weight: 400;
}
.formGroup___2QA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__gaN96:global(.__wab_instance) {
  position: relative;
}
.svg__tYbXz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gAiIn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qWqgm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7TSq3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__yZqb:global(.__wab_instance) {
  position: relative;
}
.text__tCoQu {
  padding-right: 0px;
}
.svg__wQgiq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i9FFm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__un1Tc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__bXhq1:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCard__bXhq1:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection__vjlNp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__msBx2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__qWmE {
  width: 100%;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  min-width: 0;
}
.freeBox__bvEiZ {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  margin-top: 24px;
  min-width: 0;
}
.freeBox__duLf1 {
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 12;
  overflow: hidden;
  min-width: 0;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.comparableSwitcher:global(.__wab_instance) {
  position: relative;
}
.freeBox___4JrIg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  padding-top: 32px;
  padding-bottom: 16px;
  background: var(--token-NPNMQf7VB8u7Kel);
  min-width: 0;
}
.freeBox__l0Dy4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__l0Dy4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.freeBox__l0Dy4 > :global(.__wab_flex-container) > *,
.freeBox__l0Dy4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__l0Dy4 > :global(.__wab_flex-container) > picture > img,
.freeBox__l0Dy4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
.freeBox__ugrVm {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: auto;
}
.comparables:global(.__wab_instance) {
  width: auto;
}
.freeBox__nxwNv {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 80px;
  left: auto;
  top: -40px;
  background: linear-gradient(
      90deg,
      #ffffff00 0%,
      var(--token-NPNMQf7VB8u7Kel) 90%
    )
    0% 0% / 100% 100% repeat;
  right: 0;
  bottom: -16px;
  pointer-events: none;
  min-width: 0;
}
.comparablesMap {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  min-width: 0;
  padding: 32px;
}
.img {
  position: relative;
  object-fit: cover;
  max-height: 320px;
  width: 100%;
  min-width: 0;
  border-radius: 6px;
}
.img > picture > img {
  object-fit: cover;
}
.formGroup__n7FS:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput29:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__t8B35 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xXVf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qOQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8Gp89 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__tSmYt:global(.__wab_instance) {
  position: relative;
}
.text__phGRn {
  font-weight: 400;
}
.formGroup__xjiWz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__oggNo:global(.__wab_instance) {
  position: relative;
}
.svg__enBf4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lUitZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__i5JvV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wQGbX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__pbP0W:global(.__wab_instance) {
  position: relative;
}
.text__w8ZNm {
  padding-right: 0px;
}
.svg__bUb8F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iBgLd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xIcBj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardPreisentwicklung:global(.__wab_instance) {
  position: relative;
  margin-bottom: 40px;
  flex-grow: 0;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processCardPreisentwicklung:global(.__wab_instance) {
    margin-bottom: 32px;
  }
}
.processCardSection__bozHv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.shinyApp:global(.__wab_instance) {
  width: 100%;
  height: 725px;
  min-width: 0;
}
.formGroup__lT9Qj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput30:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___0Ky99 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__avLh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zxL7U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__exg3O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__cpEu7:global(.__wab_instance) {
  position: relative;
}
.text__jHhTs {
  font-weight: 400;
}
.formGroup__hCult:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__jUpjc:global(.__wab_instance) {
  position: relative;
}
.svg__dJgFt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9Kna {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0Ovij {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uaPy8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__xqAza:global(.__wab_instance) {
  position: relative;
}
.text__rrxdt {
  padding-right: 0px;
}
.svg__luat {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sGt1U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aZbWw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCardRemms:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin: 0px 0px 40px;
}
.formGroup__e98Ne:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput__r19W:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___0KiLi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__onNZe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kRj32 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__tgLi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__dZehB:global(.__wab_instance) {
  position: relative;
}
.text__jxCx2 {
  font-weight: 400;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.formGroup__gnScb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__sNoG:global(.__wab_instance) {
  position: relative;
}
.svg__xHnz8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__npbBx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__d2Kzi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vOvi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__vz6WL:global(.__wab_instance) {
  position: relative;
}
.text__bdOod {
  padding-right: 0px;
}
.processCardSection__q7Ll:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.remmsComponent:global(.__wab_instance) {
  position: relative;
}
.svg__iBkZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xrj9G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__azLC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processBottomNav:global(.__wab_instance) {
  position: fixed;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processBottomNav:global(.__wab_instance) {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
@media (max-width: 480px) {
  .processBottomNav:global(.__wab_instance) {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.submitButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__atrVa {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___6Pygj {
  height: auto;
}
.svg__dsRb7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.submitAndDownloadButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__o9NOg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__cgcnj {
  height: auto;
}
.svg__uNyz2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.alert {
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  left: auto;
  top: 140px;
  z-index: 1;
  right: 80px;
  display: none;
}
.banner:global(.__wab_instance) {
  position: relative;
  min-width: 400px;
}
.comparablesModal:global(.__wab_instance) {
  max-width: 100%;
  position: fixed;
  left: 0;
  z-index: 20;
  right: 0;
  display: none;
}
.comparablesModalshowComparablesModal:global(.__wab_instance) {
  display: flex;
}
