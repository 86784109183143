.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  min-width: 0;
  padding: var(--token-3S5TLMpb5) var(--token-7dIrE6133);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    padding-right: var(--token-3S5TLMpb5);
    padding-left: var(--token-3S5TLMpb5);
  }
}
@media (max-width: 480px) {
  .root {
    padding-top: var(--token-MZ_89NRuN);
    padding-bottom: var(--token-MZ_89NRuN);
  }
}
.freeBox {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3S5TLMpb5);
}
.button___2Vl8W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qF6Y {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__qwWH {
  height: auto;
}
.svg__tuNwK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
