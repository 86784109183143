.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MhDGai0bW));
  height: calc(100% + var(--token-MhDGai0bW));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-MhDGai0bW);
}
.freeBox__adpGl {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__adpGl > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__adpGl > :global(.__wab_flex-container) > *,
.freeBox__adpGl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__adpGl > :global(.__wab_flex-container) > picture > img,
.freeBox__adpGl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
.freeBox__wfmz4 {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.freeBox__wfmz4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__wfmz4 > :global(.__wab_flex-container) > *,
.freeBox__wfmz4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wfmz4 > :global(.__wab_flex-container) > picture > img,
.freeBox__wfmz4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-nPzqyQpH_);
}
.ratingText {
  font-size: var(--token-Jhhay0F31-lTY91);
  font-weight: 500;
  color: var(--token-PcBCP-f11VpIoWX);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.ratingTextstate_danger {
  color: var(--token-2N8zcUvyUXwuOJv);
}
.text__ooOz5 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.textstate_danger__ooOz566Uoy {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.text___7Anbi {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.textstate_danger___7Anbi66Uoy {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
.tag:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.freeBox__fOpqB {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-nPzqyQpH_);
  background: var(--token-EdVWKDB6CssMfRE);
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 200px;
}
.freeBoxstate_danger__fOpqB66Uoy {
  background: var(--token-gUr8y4ZPkdFjJ7H);
}
.ratingProgress {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 80%;
  height: var(--token-nPzqyQpH_);
  background: var(--token-5eiHu7b54PkQh5O);
}
.ratingProgressstate_danger {
  background: var(--token-k8MBdjDfsyp6Vsa);
}
