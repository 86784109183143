.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: var(--token-MZ_89NRuN) var(--token-j8nt-mARd) var(--token-hFcnMUlxM);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 480px) {
  .root {
    padding: 12px 24px;
  }
}
.rootcolor_error {
  background: var(--token-Lymfn4M1YIjdOgD);
  padding-top: 32px;
  padding-bottom: 32px;
}
.rootcolor_algo {
  background: var(--token-N7ZH9ZZ89u1SktH);
  padding-top: 32px;
  padding-bottom: 32px;
}
.rootcolor_blue {
  padding-top: 32px;
  padding-bottom: 32px;
  background: var(--token-WYpGQpgya-mDjv5);
}
.rootcolor_gray {
  background: var(--token-enYJIO6w3sEL0NO);
  padding-top: 32px;
  padding-bottom: 32px;
}
.rootcolor_success {
  padding-top: 32px;
  padding-bottom: 32px;
  background: var(--token-yQ5PNjYizuW9slV);
}
.rootisLast {
  border-radius: 0px 0px 12px 12px;
}
@media (max-width: 480px) {
  .rootisLast {
    border-radius: 0px;
  }
}
.roothasDivider {
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.rootisFullWidth {
  padding-right: var(--token-hFcnMUlxM);
  padding-left: var(--token-hFcnMUlxM);
}
.rootisClosed {
  padding-bottom: var(--token-MZ_89NRuN);
}
.freeBox__xSc8E {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: var(--token-3S5TLMpb5) 0px var(--token-7dIrE6133);
}
.freeBox__xSc8E > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__xSc8E > :global(.__wab_flex-container) > *,
.freeBox__xSc8E > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xSc8E > :global(.__wab_flex-container) > picture > img,
.freeBox__xSc8E
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.freeBoxhasTitle__xSc8Enh70A {
  display: flex;
}
.freeBoxhasResult__xSc8EhebZl {
  display: flex;
}
.freeBoxisExpandable__xSc8E0Cmuc {
  display: flex;
}
.freeBoxisClosed__xSc8EsDg6V {
  padding-bottom: var(--token-H3JPdTVdV);
  padding-top: var(--token-H3JPdTVdV);
  display: flex;
}
.freeBox__azYmU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___3JZvA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  position: relative;
}
.freeBoxhasTitle___3JZvAnh70A {
  display: flex;
}
.freeBoxhasResult___3JZvAhebZl {
  display: flex;
}
.freeBoxisClosed___3JZvAsDg6V {
  display: flex;
}
.slotTargetTitle {
  font-size: var(--token-FutXsu2VTAR30Ez);
  font-weight: 600;
}
.freeBox__e01F0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
.freeBoxhasResult__e01F0HebZl {
  display: flex;
}
.slotTargetResult {
  font-size: var(--token-FutXsu2VTAR30Ez);
  color: var(--token-IgvLORwjhxLX5NA);
}
.expandButton:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.expandButtonhasTitle:global(.__wab_instance) {
  flex-shrink: 0;
}
.expandButtonhasResult:global(.__wab_instance) {
  flex-shrink: 0;
}
.expandButtonisExpandable:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.expandButtonisClosed:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.svg__yKgfk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pRnjx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__smYb2 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__wBhbt:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.textInput___7QGcB:global(.__wab_instance) {
  position: relative;
}
.svg___23Q0E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bh2Il {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pllsd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jpIbP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__q9Iui:global(.__wab_instance) {
  position: relative;
}
.formGroup___3UK5R:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__dl7JZ {
  height: auto;
}
.textInput__hDWn:global(.__wab_instance) {
  position: relative;
}
.svg__doLbb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qAc5V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kaFdU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eOcQu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__xla59:global(.__wab_instance) {
  position: relative;
}
.formGroup__oowb2:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.textInput__iqTlc:global(.__wab_instance) {
  position: relative;
}
.svg__d4Kyk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__reyLs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__b5O53 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4H52L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__jDbN:global(.__wab_instance) {
  position: relative;
}
.freeBox___5JuR {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: var(--token-7dIrE6133);
  min-width: 0;
}
.freeBoxhasCloseButton___5JuR83586 {
  display: flex;
}
.closeButton:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__lBDjO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aEUh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
