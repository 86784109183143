.root {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  grid-row-gap: var(--token-3S5TLMpb5);
  grid-column-gap: var(--token-3S5TLMpb5);
  position: relative;
  min-width: 0;
}
.freeBox__gEzTd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBoxisEmpty__gEzTdwSFlf {
  display: none;
}
.dynamicDocumentListItem__tt3Rp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__kijfQ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox___33SZr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___33SZr > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___33SZr > :global(.__wab_flex-container) > *,
.freeBox___33SZr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___33SZr > :global(.__wab_flex-container) > picture > img,
.freeBox___33SZr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.addDocument:global(.__wab_instance) {
  max-width: none;
  position: relative;
  width: auto;
  align-self: auto;
}
.svg__qCwft {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  height: auto;
}
.svg__w1SU8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__s3Yig:global(.__wab_instance) {
  position: relative;
}
.svg__ggOxQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___8NbHf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__xliaI:global(.__wab_instance) {
  position: relative;
}
.svg___0Kfq9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__yyTcc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__dhWNi:global(.__wab_instance) {
  position: relative;
}
.svg__dKtzW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__uihR9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__vDck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__j75X7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___6FUSv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__oV8Jb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vWeNr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__djSe2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__lpk0R:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__xl6Re {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___9RsGa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__oaCwG:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dHexN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___0QaEy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__eTdFu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___2RrP8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__uh9LX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__nhsff:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hh92D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___7Xp9N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__dz0Fo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hBjso {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__hL12K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___9LfnD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__okVjC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__dUvrL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
