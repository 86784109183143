.shinyApp {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-width: 0;
  border-style: none;
}
