.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 2px 8px;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  transition-property: background;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  border-radius: 4px;
  padding: 6px 10px;
}
.freeBoxisSelected {
  background: var(--token-WYpGQpgya-mDjv5);
}
.freeBoxisHighlighted {
  background: var(--token-nJQnmTwmF4SPdl6);
}
.root:hover .freeBox {
  background: var(--token-WYpGQpgya-mDjv5);
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.labelContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.labelContainer > :global(.__wab_flex-container) > *,
.labelContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.labelContainer > :global(.__wab_flex-container) > picture > img,
.labelContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.slotTargetStartIcon {
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetStartIcon > *,
.slotTargetStartIcon > :global(.__wab_slot) > *,
.slotTargetStartIcon > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetStartIcon > picture > img,
.slotTargetStartIcon > :global(.__wab_slot) > picture > img,
.slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetStartIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.slotTargetStartIconisSelected {
  color: var(--token-6yTcrF4BA3JOPWz);
}
.slotTargetStartIconisHighlighted {
  color: var(--token-IgvLORwjhxLX5NA);
}
.slotTargetStartIconisDisabled {
  color: var(--token-sHkWBDcvLab64_p);
}
.root:hover .slotTargetStartIcon {
  color: var(--token-PjtuKumWd61Gyyy);
}
.svg__fB5Kg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.slotTargetChildren {
  white-space: pre;
  font-size: 14px;
  letter-spacing: 0.2px;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.slotTargetChildrenisSelected {
  color: var(--token-_4263rh0uaUoehz);
}
.slotTargetChildrenisDisabled {
  color: var(--token-5eiHu7b54PkQh5O);
}
.root:hover .slotTargetChildren {
  color: var(--token-_4263rh0uaUoehz);
}
.slotTargetEndIcon {
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetEndIcon > *,
.slotTargetEndIcon > :global(.__wab_slot) > *,
.slotTargetEndIcon > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetEndIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetEndIcon > picture > img,
.slotTargetEndIcon > :global(.__wab_slot) > picture > img,
.slotTargetEndIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetEndIcon
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.slotTargetEndIconisSelected {
  color: var(--token-6yTcrF4BA3JOPWz);
}
.slotTargetEndIconisHighlighted {
  color: var(--token-IgvLORwjhxLX5NA);
}
.slotTargetEndIconisDisabled {
  color: var(--token-EdVWKDB6CssMfRE);
}
.root:hover .slotTargetEndIcon {
  color: var(--token-PjtuKumWd61Gyyy);
}
.svg__lf58 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
