.root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-3S5TLMpb5);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  position: relative;
  min-width: 0;
}
.freeBox___1CwDi {
  display: flex;
  position: relative;
  flex-direction: column;
  grid-column-end: span 6;
}
.freeBox___1CwDi > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___1CwDi > :global(.__wab_flex-container) > *,
.freeBox___1CwDi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1CwDi > :global(.__wab_flex-container) > picture > img,
.freeBox___1CwDi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.formGroup___9Zzmg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput33:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svg__zkA1M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nDOo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cn14H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qOm6T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter:global(.__wab_instance) {
  position: relative;
}
.freeBox___8FKql {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox___8FKql > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-SAmzyWXiq));
  width: calc(100% + var(--token-SAmzyWXiq));
}
.freeBox___8FKql > :global(.__wab_flex-container) > *,
.freeBox___8FKql > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8FKql > :global(.__wab_flex-container) > picture > img,
.freeBox___8FKql
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-SAmzyWXiq);
}
@media (max-width: 480px) {
  .freeBox___8FKql {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox___8FKql > :global(.__wab_flex-container) {
    flex-direction: column-reverse;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-MZ_89NRuN));
    height: calc(100% + var(--token-MZ_89NRuN));
  }
}
@media (max-width: 480px) {
  .freeBox___8FKql > :global(.__wab_flex-container) > *,
  .freeBox___8FKql > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___8FKql > :global(.__wab_flex-container) > picture > img,
  .freeBox___8FKql
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-MZ_89NRuN);
  }
}
.freeBox__k12A4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.freeBox__k12A4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MhDGai0bW));
  height: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__k12A4 > :global(.__wab_flex-container) > *,
.freeBox__k12A4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__k12A4 > :global(.__wab_flex-container) > picture > img,
.freeBox__k12A4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MhDGai0bW);
}
.freeBox__hLchc {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
}
.algoButton:global(.__wab_instance) {
  max-width: 100%;
}
.svg__z2Uja {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ac3Kc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__runkz {
  position: relative;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-IgvLORwjhxLX5NA);
  font-weight: 400;
}
.freeBox___2Khqd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.freeBox___2Khqd > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MhDGai0bW));
  height: calc(100% + var(--token-MhDGai0bW));
}
.freeBox___2Khqd > :global(.__wab_flex-container) > *,
.freeBox___2Khqd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2Khqd > :global(.__wab_flex-container) > picture > img,
.freeBox___2Khqd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MhDGai0bW);
}
.formGroup__snDrP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .formGroup__snDrP:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
._switch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter___9LEc:global(.__wab_instance) {
  position: relative;
}
.text___1TLNa {
  position: relative;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-IgvLORwjhxLX5NA);
  font-weight: 400;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .button:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg___9AKyI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0SXyS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
