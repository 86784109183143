.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--token-xZRZ7hK_d);
  max-width: 100%;
  overflow: visible;
  position: relative;
  background: var(--token-enYJIO6w3sEL0NO);
  min-width: 0;
  padding: 16px 40px;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    display: flex;
  }
}
@media (max-width: 480px) {
  .root {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    overflow: auto;
    min-width: 0;
    padding: 12px 24px;
    border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.freeBox__i8HJh {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__i8HJh > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__i8HJh > :global(.__wab_flex-container) > *,
.freeBox__i8HJh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__i8HJh > :global(.__wab_flex-container) > picture > img,
.freeBox__i8HJh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.navButtonErfassung:global(.__wab_instance) {
  max-width: 100%;
}
.text__h49Ee {
  height: auto;
}
.svg__dMkBv {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.svg__fBn6X {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.navButtonMarktwert:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___2X3Jy {
  height: auto;
}
.svg__cvmIn {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.svg__rQnq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.select:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__jiiEx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.freeBox__adrNi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  background: #f8fafc00;
  cursor: pointer;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 6px;
  padding: 6px 8px;
}
.svg__p54AO {
  object-fit: cover;
  height: 1em;
  color: var(--token-5eiHu7b54PkQh5O);
}
.option__j92Fs:global(.__wab_instance) {
  position: relative;
}
.svg__n3Hx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___1MnxB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__oiG:global(.__wab_instance) {
  position: relative;
}
.svg___0RsSd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__to4QI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__iafzC:global(.__wab_instance) {
  position: relative;
}
.svg__gRVcE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___5VjTp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uWaeo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  height: 1em;
}
.navButtonBericht:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___06Yp {
  height: auto;
}
.svg__coxtD {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
