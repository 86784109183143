.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.buttonisIconOnly:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg___4Vk1O {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__dlIs9 {
  height: auto;
}
.svg__wHkWd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
