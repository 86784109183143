.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox___4XlUu {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  grid-row-gap: var(--token-MZ_89NRuN);
  min-width: 0;
}
.freeBox__arkex {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 5;
  min-width: 0;
}
.text___0IU1K {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__h2B4I {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.text__ldU {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__ubob3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.text__ukQ3E {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__fXUzS {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 7;
  min-width: 0;
}
.text__uRiTg {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__knCx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.text__sfk2M {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__ueMt9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.dcfMeasureRow__p2LZr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dcfMeasureRow__kcqV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dcfMeasureRow__scbPa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__aocfB {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding-top: var(--token-3S5TLMpb5);
  min-width: 0;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__aocfB > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__aocfB > :global(.__wab_flex-container) > *,
.freeBox__aocfB > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aocfB > :global(.__wab_flex-container) > picture > img,
.freeBox__aocfB
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.addMeasure:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lsGhD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.button:global(.__wab_instance) {
  max-width: 100%;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xz8B {
  height: auto;
}
.svg__yiaPk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__jywep:global(.__wab_instance) {
  position: relative;
}
.svg___3ZsSu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__r1KU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__qe8Em:global(.__wab_instance) {
  position: relative;
}
.svg__gEanR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__tfug5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
