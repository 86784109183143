.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  cursor: pointer;
  transition-property: background, border-color;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  min-height: var(--token-uoU4lPaiz);
  min-width: 0;
  -webkit-transition-property: background, border-color;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 6px;
  padding: 20px 16px 16px;
  border: 1px dashed var(--token-sHkWBDcvLab64_p);
}
.rootisActive {
  background: var(--token-WYpGQpgya-mDjv5);
  cursor: wait;
  pointer-events: auto;
  border-color: var(--token-PjtuKumWd61Gyyy);
}
.rootisLoading {
  cursor: auto;
}
.roothasError {
  background: var(--token-NPNMQf7VB8u7Kel);
  width: 100%;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  border-radius: 6px;
  padding: 20px 16px 16px;
  border: 1px dashed var(--token-sHkWBDcvLab64_p);
}
.roothasFile {
  cursor: auto;
  padding-top: var(--token-cTBZAIcMp);
  padding-bottom: var(--token-cTBZAIcMp);
  max-height: var(--token-uoU4lPaiz);
}
.rootsize_medium {
  height: 36px;
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
}
.rootisLoading_isActive {
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  cursor: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  border-radius: 6px;
  padding: 20px 16px 16px;
  border: 1px dashed var(--token-sHkWBDcvLab64_p);
}
.roothasError_isActive {
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-WYpGQpgya-mDjv5);
  cursor: wait;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  border-radius: 6px;
  padding: 20px 16px 16px;
  border: 1px dashed var(--token-PjtuKumWd61Gyyy);
}
.rootisActive_hasFile {
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  cursor: default;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  border-radius: 6px;
  padding: 20px 16px 16px;
  border: 1px dashed var(--token-sHkWBDcvLab64_p);
}
.root:hover {
  background: var(--token-WYpGQpgya-mDjv5);
  border-color: var(--token-PjtuKumWd61Gyyy);
}
.rootisLoading:hover {
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  cursor: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  border-radius: 6px;
  padding: 20px 16px 16px;
  border: 1px dashed var(--token-sHkWBDcvLab64_p);
}
.roothasFile:hover {
  background: var(--token-NPNMQf7VB8u7Kel);
  border: 1px dashed var(--token-sHkWBDcvLab64_p);
}
.freeBox___2UtSp {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___2UtSp > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox___2UtSp > :global(.__wab_flex-container) > *,
.freeBox___2UtSp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2UtSp > :global(.__wab_flex-container) > picture > img,
.freeBox___2UtSp
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBoxisLoading___2UtSpCq3J {
  opacity: 0;
}
.freeBoxsize_medium___2UtSpNof9Z {
  display: flex;
  flex-direction: row;
}
.freeBoxsize_medium___2UtSpNof9Z > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxsize_medium___2UtSpNof9Z > :global(.__wab_flex-container) > *,
.freeBoxsize_medium___2UtSpNof9Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxsize_medium___2UtSpNof9Z
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxsize_medium___2UtSpNof9Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.slotTargetIcon {
  font-size: 24px;
  color: var(--token-sHkWBDcvLab64_p);
}
.slotTargetIconisActive {
  color: var(--token-PjtuKumWd61Gyyy);
}
.slotTargetIconhasError {
  color: var(--token-sHkWBDcvLab64_p);
}
.slotTargetIconsize_medium {
  font-size: 20px;
}
.slotTargetIconhasError_isActive {
  color: var(--token-PjtuKumWd61Gyyy);
}
.root:hover .slotTargetIcon {
  color: var(--token-PjtuKumWd61Gyyy);
}
.svg__mqYvv {
  object-fit: cover;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  height: 1em;
  flex-shrink: 0;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.text__hKxog {
  position: relative;
  text-align: center;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  font-weight: 500;
  font-size: var(--token-wZK1o5uz59Brqbf);
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.textisActive__hKxoGaaLc8 {
  color: var(--token-6yTcrF4BA3JOPWz);
}
.texthasError__hKxogoVk15 {
  color: var(--token-eKcWWhfvZ4_f9Lh);
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  text-align: center;
}
.textsize_medium__hKxognof9Z {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.texthasError_isActive__hKxogoVk15AaLc8 {
  color: var(--token-6yTcrF4BA3JOPWz);
}
.textisActive_hasFile__hKxoGaaLc8IpGmD {
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.root:hover .text__hKxog {
  color: var(--token-6yTcrF4BA3JOPWz);
}
.roothasFile:hover .texthasFile__hKxoGipGmD {
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.slotTargetInfo {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: center;
}
.slotTargetInfo > *,
.slotTargetInfo > :global(.__wab_slot) > *,
.slotTargetInfo > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetInfo
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetInfo > picture > img,
.slotTargetInfo > :global(.__wab_slot) > picture > img,
.slotTargetInfo > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetInfo
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.slotTargetInfoisActive {
  color: var(--token-6yTcrF4BA3JOPWz);
}
.root:hover .slotTargetInfo {
  color: var(--token-6yTcrF4BA3JOPWz);
}
.freeBox___6HuTj {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___6HuTj > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox___6HuTj > :global(.__wab_flex-container) > *,
.freeBox___6HuTj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6HuTj > :global(.__wab_flex-container) > picture > img,
.freeBox___6HuTj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.freeBoxhasFile___6HuTJipGmD {
  width: 100%;
  min-width: 0;
  display: flex;
  padding: 0px;
}
.freeBoxhasFile___6HuTJipGmD > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__ayAkj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  max-height: 100px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 1px var(--token-EdVWKDB6CssMfRE);
  min-width: 0;
  border-radius: 4px;
}
.freeBoxhasFile__ayAkJipGmD {
  justify-content: center;
  align-items: center;
  max-width: 100px;
}
.slotTargetFilePreview {
  color: var(--token-sHkWBDcvLab64_p);
  font-size: var(--token-3jOrWkEDNu9ZZIv);
}
.svg__ozGxv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  width: var(--token-hFcnMUlxM);
  height: var(--token-hFcnMUlxM);
  flex-shrink: 0;
  display: none;
}
.img__cAp1M {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__cAp1M > picture > img {
  object-fit: cover;
}
.freeBox__zrQAz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBoxhasFile__zrQAzipGmD {
  align-items: flex-start;
  justify-content: center;
}
.freeBox__l8XvG {
  width: 100%;
  height: auto;
  max-width: 800px;
  flex-direction: row;
  min-width: 0;
  display: none;
}
.freeBoxhasFile__l8XvGipGmD {
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
}
.slotTargetFileNamehasFile {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  white-space: pre;
}
.slotTargetFileNamehasFile > :global(.__wab_text),
.slotTargetFileNamehasFile > :global(.__wab_expr_html_text),
.slotTargetFileNamehasFile > :global(.__wab_slot-string-wrapper),
.slotTargetFileNamehasFile > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetFileNamehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.freeBox__o9HlH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  flex-direction: row;
  min-width: 0;
  display: none;
}
.freeBoxhasFile__o9HlHipGmD {
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
}
.slotTargetFileSizehasFile {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-IgvLORwjhxLX5NA);
  white-space: pre;
}
.slotTargetFileSizehasFile > :global(.__wab_text),
.slotTargetFileSizehasFile > :global(.__wab_expr_html_text),
.slotTargetFileSizehasFile > :global(.__wab_slot-string-wrapper),
.slotTargetFileSizehasFile > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetFileSizehasFile
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  align-self: flex-start;
  top: -8px;
  left: -16px;
  flex-shrink: 0;
  display: none;
}
.deleteButtonhasFile:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.svg__phMrH {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svg__etJK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.downloadButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.downloadButtonhasFile:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg___7MfhJ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svghasFile___7MfhJipGmD {
  display: none;
}
.svg__aIoQ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svg__nTcBa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__e9ANa {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
  padding: 24px;
}
.freeBox__e9ANa > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__e9ANa > :global(.__wab_flex-container) > *,
.freeBox__e9ANa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__e9ANa > :global(.__wab_flex-container) > picture > img,
.freeBox__e9ANa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBoxisLoading__e9ANaCq3J {
  display: flex;
}
.freeBoxsize_medium__e9ANaNof9Z {
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px 8px;
}
.freeBoxsize_medium__e9ANaNof9Z > :global(.__wab_flex-container) {
  flex-direction: row;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxsize_medium__e9ANaNof9Z > :global(.__wab_flex-container) > *,
.freeBoxsize_medium__e9ANaNof9Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxsize_medium__e9ANaNof9Z
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxsize_medium__e9ANaNof9Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.freeBoxisLoading_isActive__e9ANaCq3JAaLc8 {
  width: auto;
  height: auto;
  flex-direction: column;
  padding: 24px;
}
.freeBoxisLoading_isActive__e9ANaCq3JAaLc8 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBoxisLoading_isActive__e9ANaCq3JAaLc8 > :global(.__wab_flex-container) > *,
.freeBoxisLoading_isActive__e9ANaCq3JAaLc8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxisLoading_isActive__e9ANaCq3JAaLc8
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxisLoading_isActive__e9ANaCq3JAaLc8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.rootisLoading:hover .freeBoxisLoading__e9ANaCq3J {
  width: auto;
  height: auto;
  flex-direction: column;
  padding: 24px;
}
.rootisLoading:hover
  .freeBoxisLoading__e9ANaCq3J
  > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.rootisLoading:hover
  .freeBoxisLoading__e9ANaCq3J
  > :global(.__wab_flex-container)
  > *,
.rootisLoading:hover
  .freeBoxisLoading__e9ANaCq3J
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisLoading:hover
  .freeBoxisLoading__e9ANaCq3J
  > :global(.__wab_flex-container)
  > picture
  > img,
.rootisLoading:hover
  .freeBoxisLoading__e9ANaCq3J
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.text__yTmiY {
  position: relative;
  text-align: center;
  font-weight: 500;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.textisActive__yTmiYaaLc8 {
  color: var(--token-PjtuKumWd61Gyyy);
}
.textisLoading__yTmiYCq3J {
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.textsize_medium__yTmiYnof9Z {
  display: none;
}
.textisLoading_isActive__yTmiYCq3JAaLc8 {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  text-align: center;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.root:hover .text__yTmiY {
  color: var(--token-PjtuKumWd61Gyyy);
}
.rootisLoading:hover .textisLoading__yTmiYCq3J {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  text-align: center;
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.freeBox__s9M4 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 6px;
  background: var(--token-EdVWKDB6CssMfRE);
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 4px;
}
.freeBoxsize_medium__s9M4Nof9Z {
  max-width: 60%;
}
.progressBar {
  display: block;
  position: absolute;
  width: 40%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: auto;
  background: var(--token-PjtuKumWd61Gyyy);
  min-height: 0;
}
.cancelButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pbeqC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1KnUs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__iDBB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.freeBoxhasError__iDBBoVk15 {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 12px 0px 0px;
}
.slotTargetErrorMessagehasError {
  color: var(--token-2N8zcUvyUXwuOJv);
  font-weight: 500;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
}
