@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&display=swap");

.plasmic_tokens {
  --token-9gzL-AGTQ8md: #2563eb;
  --plasmic-token-brand-brand: var(--token-9gzL-AGTQ8md);
  --token-4UE5xgPwcGI4: 0.5rem;
  --plasmic-token-size-8: var(--token-4UE5xgPwcGI4);
  --token-XE6J6ecRPFne: Inter;
  --plasmic-token-sans-serif: var(--token-XE6J6ecRPFne);
  --token-BlFtiI3Wnb9v: 1rem;
  --plasmic-token-font-md: var(--token-BlFtiI3Wnb9v);
  --token-3j-V6kBYEk1k: 1.25;
  --plasmic-token-line-height-md: var(--token-3j-V6kBYEk1k);
  --token-VY2L-NV0MMaA: 0.375rem;
  --plasmic-token-size-6: var(--token-VY2L-NV0MMaA);
  --token-43eJYULxnDtg: #374151;
  --plasmic-token-neutral-neutral: var(--token-43eJYULxnDtg);
  --token-QroxYwz8hTt5: #dc2626;
  --plasmic-token-destructive-destructive: var(--token-QroxYwz8hTt5);
  --token-qYwr5MVyNmNy: #6b7280;
  --plasmic-token-muted-muted: var(--token-qYwr5MVyNmNy);
  --token-WQ33O6GKEqY4: 0.25rem;
  --plasmic-token-size-4: var(--token-WQ33O6GKEqY4);
  --token-lwAxyxNobdGg: 0.75rem;
  --plasmic-token-font-sm: var(--token-lwAxyxNobdGg);
  --token-eMtfO0HA9lE6: 1;
  --plasmic-token-line-height-sm: var(--token-eMtfO0HA9lE6);
  --token-lYJAPmZfQbyc: 1.25rem;
  --plasmic-token-font-lg: var(--token-lYJAPmZfQbyc);
  --token-dXr8h9pAuBLX: 1.4;
  --plasmic-token-line-height-lg: var(--token-dXr8h9pAuBLX);
  --token-FCLhQg5N0D1A: 0.125rem;
  --plasmic-token-size-2: var(--token-FCLhQg5N0D1A);
  --token-Df3bEMMMlRsp: #16a34a;
  --plasmic-token-success-success: var(--token-Df3bEMMMlRsp);
  --token-xIfX76bmk116: #facc15;
  --plasmic-token-warning-warning: var(--token-xIfX76bmk116);
  --token-JRaod9bgJrCt: #dbeafe;
  --plasmic-token-brand-brand-soft: var(--token-JRaod9bgJrCt);
  --token-qrVj-dtark5Z: #e5e7eb;
  --plasmic-token-neutral-neutral-soft: var(--token-qrVj-dtark5Z);
  --token-aY695e0lHxXu: #f3f4f6;
  --plasmic-token-muted-muted-soft: var(--token-aY695e0lHxXu);
  --token-mXSQjua9FhD-: #dcfce7;
  --plasmic-token-success-success-soft: var(--token-mXSQjua9FhD-);
  --token-g3tgjrIw1XHG: #fef9c3;
  --plasmic-token-warning-warning-soft: var(--token-g3tgjrIw1XHG);
  --token-u9Apb1lP_Ju6: #fee2e2;
  --plasmic-token-destructive-destructive-soft: var(--token-u9Apb1lP_Ju6);
  --token-qHPumein3Xio: #93c5fd;
  --plasmic-token-brand-brand-border: var(--token-qHPumein3Xio);
  --token-domHnIQP79Bf: #d1d5db;
  --plasmic-token-neutral-neutral-border: var(--token-domHnIQP79Bf);
  --token-Z_fjuxTJ-pr5: #e5e7eb;
  --plasmic-token-muted-muted-border: var(--token-Z_fjuxTJ-pr5);
  --token-xPAiydB9i-58: #86efac;
  --plasmic-token-success-success-border: var(--token-xPAiydB9i-58);
  --token--Pw_vhNbwaNt: #fde047;
  --plasmic-token-warning-warning-border: var(--token--Pw_vhNbwaNt);
  --token-qEAGurmo60Zg: #fca5a5;
  --plasmic-token-destructive-destructive-border: var(--token-qEAGurmo60Zg);
  --token-pUPtFRHrV4UP: #0000001a;
  --plasmic-token-interaction-hovered: var(--token-pUPtFRHrV4UP);
  --token-kDsFK5RboteM: #ffffff40;
  --plasmic-token-interaction-disabled: var(--token-kDsFK5RboteM);
  --token-o4C0s5jSQIW_: #00000033;
  --plasmic-token-interaction-pressed: var(--token-o4C0s5jSQIW_);
  --token-JZTOq0qYvXdr: 0.625rem;
  --plasmic-token-size-10: var(--token-JZTOq0qYvXdr);
  --token-hLWAldh4ji8t: 0.75rem;
  --plasmic-token-size-12: var(--token-hLWAldh4ji8t);
  --token-rowQZYZataWO: #eff6ff;
  --plasmic-token-brand-brand-foreground: var(--token-rowQZYZataWO);
  --token-6aXoHO-iwlPB: #f9fafb;
  --plasmic-token-neutral-neutral-foreground: var(--token-6aXoHO-iwlPB);
  --token-wfIdjGzFmtiP: #e5e7eb;
  --plasmic-token-muted-muted-foreground: var(--token-wfIdjGzFmtiP);
  --token--NHFBx40GsqU: #1e3a8a;
  --plasmic-token-brand-brand-soft-foreground: var(--token--NHFBx40GsqU);
  --token-F37QhkLWGN7W: #422006;
  --plasmic-token-warning-warning-foreground: var(--token-F37QhkLWGN7W);
  --token-RrAECOsV2if0: #f0fdf4;
  --plasmic-token-success-success-foreground: var(--token-RrAECOsV2if0);
  --token-oODsu_LsdfxZ: #fef2f2;
  --plasmic-token-destructive-destructive-foreground: var(--token-oODsu_LsdfxZ);
  --token-Fl-WMcWdZXXU: #111827;
  --plasmic-token-neutral-neutral-soft-foreground: var(--token-Fl-WMcWdZXXU);
  --token-lV2sLY4SaPIQ: #6b7280;
  --plasmic-token-muted-muted-soft-foreground: var(--token-lV2sLY4SaPIQ);
  --token-2XrEKtanQ8Mx: #14532d;
  --plasmic-token-success-success-soft-foreground: var(--token-2XrEKtanQ8Mx);
  --token-EAgX1bBzvdww: #713f12;
  --plasmic-token-warning-warning-soft-foreground: var(--token-EAgX1bBzvdww);
  --token-pZLZm_4WYk0q: #7f1d1d;
  --plasmic-token-destructive-destructive-soft-foreground: var(
    --token-pZLZm_4WYk0q
  );
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-HKD6-8wMQwxj_font-family: "Inter", sans-serif;
  --mixin-HKD6-8wMQwxj_font-size: 16px;
  --mixin-HKD6-8wMQwxj_font-weight: 400;
  --mixin-HKD6-8wMQwxj_font-style: normal;
  --mixin-HKD6-8wMQwxj_color: #535353;
  --mixin-HKD6-8wMQwxj_text-align: left;
  --mixin-HKD6-8wMQwxj_text-transform: none;
  --mixin-HKD6-8wMQwxj_line-height: 1.5;
  --mixin-HKD6-8wMQwxj_letter-spacing: normal;
  --mixin-HKD6-8wMQwxj_white-space: pre-wrap;
  --mixin-HKD6-8wMQwxj_user-select: text;
  --mixin-HKD6-8wMQwxj_text-decoration-line: none;
  --mixin-HKD6-8wMQwxj_text-overflow: clip;
  --mixin-CYvl7fJjo86c_color: #000000;
  --mixin-CYvl7fJjo86c_font-weight: 700;
  --mixin-CYvl7fJjo86c_font-size: 64px;
  --mixin-CYvl7fJjo86c_line-height: 1;
  --mixin-CYvl7fJjo86c_letter-spacing: -1px;
  --mixin-CYvl7fJjo86c_white-space: pre-wrap;
  --mixin-iylW3iPqBB1C_color: #000000;
  --mixin-iylW3iPqBB1C_font-size: 48px;
  --mixin-iylW3iPqBB1C_font-weight: 700;
  --mixin-iylW3iPqBB1C_letter-spacing: -0.5px;
  --mixin-iylW3iPqBB1C_line-height: 1.1;
  --mixin-iylW3iPqBB1C_white-space: pre-wrap;
  --mixin-9XvmU5PARdPi_color: #0070f3;
  --mixin-9XvmU5PARdPi_white-space: pre-wrap;
  --mixin-0Yc8MeTkqxvv_color: #000000;
  --mixin-0Yc8MeTkqxvv_font-size: 32px;
  --mixin-0Yc8MeTkqxvv_font-weight: 600;
  --mixin-0Yc8MeTkqxvv_line-height: 1.2;
  --mixin-0Yc8MeTkqxvv_white-space: pre-wrap;
  --mixin-6cojnVkkQ7Xj_color: #000000;
  --mixin-6cojnVkkQ7Xj_font-size: 24px;
  --mixin-6cojnVkkQ7Xj_font-weight: 600;
  --mixin-6cojnVkkQ7Xj_line-height: 1.3;
  --mixin-6cojnVkkQ7Xj_white-space: pre-wrap;
  --mixin-xRbwA8aLarnL_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-xRbwA8aLarnL_border-bottom-color: #dddddd;
  --mixin-xRbwA8aLarnL_border-bottom-style: solid;
  --mixin-xRbwA8aLarnL_border-bottom-width: 1px;
  --mixin-xRbwA8aLarnL_border-left-color: #dddddd;
  --mixin-xRbwA8aLarnL_border-left-style: solid;
  --mixin-xRbwA8aLarnL_border-left-width: 1px;
  --mixin-xRbwA8aLarnL_border-right-color: #dddddd;
  --mixin-xRbwA8aLarnL_border-right-style: solid;
  --mixin-xRbwA8aLarnL_border-right-width: 1px;
  --mixin-xRbwA8aLarnL_border-top-color: #dddddd;
  --mixin-xRbwA8aLarnL_border-top-style: solid;
  --mixin-xRbwA8aLarnL_border-top-width: 1px;
  --mixin-xRbwA8aLarnL_border-bottom-left-radius: 3px;
  --mixin-xRbwA8aLarnL_border-bottom-right-radius: 3px;
  --mixin-xRbwA8aLarnL_border-top-left-radius: 3px;
  --mixin-xRbwA8aLarnL_border-top-right-radius: 3px;
  --mixin-xRbwA8aLarnL_font-family: "Inconsolata";
  --mixin-xRbwA8aLarnL_padding-bottom: 1px;
  --mixin-xRbwA8aLarnL_padding-left: 4px;
  --mixin-xRbwA8aLarnL_padding-right: 4px;
  --mixin-xRbwA8aLarnL_padding-top: 1px;
  --mixin-xRbwA8aLarnL_white-space: pre-wrap;
  --mixin-c43P1d4lNp5p_border-left-color: #dddddd;
  --mixin-c43P1d4lNp5p_border-left-style: solid;
  --mixin-c43P1d4lNp5p_border-left-width: 3px;
  --mixin-c43P1d4lNp5p_color: #888888;
  --mixin-c43P1d4lNp5p_padding-left: 10px;
  --mixin-c43P1d4lNp5p_white-space: pre-wrap;
  --mixin-FawJdt8Nqlu0_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-FawJdt8Nqlu0_border-bottom-color: #dddddd;
  --mixin-FawJdt8Nqlu0_border-bottom-style: solid;
  --mixin-FawJdt8Nqlu0_border-bottom-width: 1px;
  --mixin-FawJdt8Nqlu0_border-left-color: #dddddd;
  --mixin-FawJdt8Nqlu0_border-left-style: solid;
  --mixin-FawJdt8Nqlu0_border-left-width: 1px;
  --mixin-FawJdt8Nqlu0_border-right-color: #dddddd;
  --mixin-FawJdt8Nqlu0_border-right-style: solid;
  --mixin-FawJdt8Nqlu0_border-right-width: 1px;
  --mixin-FawJdt8Nqlu0_border-top-color: #dddddd;
  --mixin-FawJdt8Nqlu0_border-top-style: solid;
  --mixin-FawJdt8Nqlu0_border-top-width: 1px;
  --mixin-FawJdt8Nqlu0_border-bottom-left-radius: 3px;
  --mixin-FawJdt8Nqlu0_border-bottom-right-radius: 3px;
  --mixin-FawJdt8Nqlu0_border-top-left-radius: 3px;
  --mixin-FawJdt8Nqlu0_border-top-right-radius: 3px;
  --mixin-FawJdt8Nqlu0_font-family: "Inconsolata";
  --mixin-FawJdt8Nqlu0_padding-bottom: 3px;
  --mixin-FawJdt8Nqlu0_padding-left: 6px;
  --mixin-FawJdt8Nqlu0_padding-right: 6px;
  --mixin-FawJdt8Nqlu0_padding-top: 3px;
  --mixin-FawJdt8Nqlu0_white-space: pre-wrap;
  --mixin-KwwLQ38hTq68_display: flex;
  --mixin-KwwLQ38hTq68_flex-direction: column;
  --mixin-KwwLQ38hTq68_align-items: stretch;
  --mixin-KwwLQ38hTq68_justify-content: flex-start;
  --mixin-KwwLQ38hTq68_list-style-position: outside;
  --mixin-KwwLQ38hTq68_padding-left: 40px;
  --mixin-KwwLQ38hTq68_position: relative;
  --mixin-KwwLQ38hTq68_list-style-type: disc;
  --mixin-KwwLQ38hTq68_white-space: pre-wrap;
  --mixin-6In7pvNDcKdS_display: flex;
  --mixin-6In7pvNDcKdS_flex-direction: column;
  --mixin-6In7pvNDcKdS_align-items: stretch;
  --mixin-6In7pvNDcKdS_justify-content: flex-start;
  --mixin-6In7pvNDcKdS_list-style-position: outside;
  --mixin-6In7pvNDcKdS_padding-left: 40px;
  --mixin-6In7pvNDcKdS_position: relative;
  --mixin-6In7pvNDcKdS_list-style-type: decimal;
  --mixin-6In7pvNDcKdS_white-space: pre-wrap;
  --mixin-q0GJK3IwO0UD_color: #000000;
  --mixin-q0GJK3IwO0UD_font-size: 20px;
  --mixin-q0GJK3IwO0UD_font-weight: 600;
  --mixin-q0GJK3IwO0UD_line-height: 1.5;
  --mixin-q0GJK3IwO0UD_white-space: pre-wrap;
  --mixin-jeJCh3OTQrYI_color: #000000;
  --mixin-jeJCh3OTQrYI_font-size: 16px;
  --mixin-jeJCh3OTQrYI_font-weight: 600;
  --mixin-jeJCh3OTQrYI_line-height: 1.5;
  --mixin-jeJCh3OTQrYI_white-space: pre-wrap;
  --mixin-93QWqM14CAC0_color: #3291ff;
  --mixin-93QWqM14CAC0_white-space: pre-wrap;
  --mixin-bpSjmbwjry_e_white-space: pre-wrap;
  --mixin-ts5LDEa6nOHS_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-HKD6-8wMQwxj_font-family);
  font-size: var(--mixin-HKD6-8wMQwxj_font-size);
  font-weight: var(--mixin-HKD6-8wMQwxj_font-weight);
  font-style: var(--mixin-HKD6-8wMQwxj_font-style);
  color: var(--mixin-HKD6-8wMQwxj_color);
  text-align: var(--mixin-HKD6-8wMQwxj_text-align);
  text-transform: var(--mixin-HKD6-8wMQwxj_text-transform);
  line-height: var(--mixin-HKD6-8wMQwxj_line-height);
  letter-spacing: var(--mixin-HKD6-8wMQwxj_letter-spacing);
  white-space: var(--mixin-HKD6-8wMQwxj_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-CYvl7fJjo86c_color);
  font-weight: var(--mixin-CYvl7fJjo86c_font-weight);
  font-size: var(--mixin-CYvl7fJjo86c_font-size);
  line-height: var(--mixin-CYvl7fJjo86c_line-height);
  letter-spacing: var(--mixin-CYvl7fJjo86c_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-iylW3iPqBB1C_color);
  font-size: var(--mixin-iylW3iPqBB1C_font-size);
  font-weight: var(--mixin-iylW3iPqBB1C_font-weight);
  letter-spacing: var(--mixin-iylW3iPqBB1C_letter-spacing);
  line-height: var(--mixin-iylW3iPqBB1C_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-9XvmU5PARdPi_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-0Yc8MeTkqxvv_color);
  font-size: var(--mixin-0Yc8MeTkqxvv_font-size);
  font-weight: var(--mixin-0Yc8MeTkqxvv_font-weight);
  line-height: var(--mixin-0Yc8MeTkqxvv_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-6cojnVkkQ7Xj_color);
  font-size: var(--mixin-6cojnVkkQ7Xj_font-size);
  font-weight: var(--mixin-6cojnVkkQ7Xj_font-weight);
  line-height: var(--mixin-6cojnVkkQ7Xj_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-xRbwA8aLarnL_font-family);
  border-radius: var(--mixin-xRbwA8aLarnL_border-top-left-radius)
    var(--mixin-xRbwA8aLarnL_border-top-right-radius)
    var(--mixin-xRbwA8aLarnL_border-bottom-right-radius)
    var(--mixin-xRbwA8aLarnL_border-bottom-left-radius);
  padding: var(--mixin-xRbwA8aLarnL_padding-top)
    var(--mixin-xRbwA8aLarnL_padding-right)
    var(--mixin-xRbwA8aLarnL_padding-bottom)
    var(--mixin-xRbwA8aLarnL_padding-left);
  border-top: var(--mixin-xRbwA8aLarnL_border-top-width)
    var(--mixin-xRbwA8aLarnL_border-top-style)
    var(--mixin-xRbwA8aLarnL_border-top-color);
  border-right: var(--mixin-xRbwA8aLarnL_border-right-width)
    var(--mixin-xRbwA8aLarnL_border-right-style)
    var(--mixin-xRbwA8aLarnL_border-right-color);
  border-bottom: var(--mixin-xRbwA8aLarnL_border-bottom-width)
    var(--mixin-xRbwA8aLarnL_border-bottom-style)
    var(--mixin-xRbwA8aLarnL_border-bottom-color);
  border-left: var(--mixin-xRbwA8aLarnL_border-left-width)
    var(--mixin-xRbwA8aLarnL_border-left-style)
    var(--mixin-xRbwA8aLarnL_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-c43P1d4lNp5p_color);
  padding-left: var(--mixin-c43P1d4lNp5p_padding-left);
  border-left: var(--mixin-c43P1d4lNp5p_border-left-width)
    var(--mixin-c43P1d4lNp5p_border-left-style)
    var(--mixin-c43P1d4lNp5p_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-FawJdt8Nqlu0_font-family);
  border-radius: var(--mixin-FawJdt8Nqlu0_border-top-left-radius)
    var(--mixin-FawJdt8Nqlu0_border-top-right-radius)
    var(--mixin-FawJdt8Nqlu0_border-bottom-right-radius)
    var(--mixin-FawJdt8Nqlu0_border-bottom-left-radius);
  padding: var(--mixin-FawJdt8Nqlu0_padding-top)
    var(--mixin-FawJdt8Nqlu0_padding-right)
    var(--mixin-FawJdt8Nqlu0_padding-bottom)
    var(--mixin-FawJdt8Nqlu0_padding-left);
  border-top: var(--mixin-FawJdt8Nqlu0_border-top-width)
    var(--mixin-FawJdt8Nqlu0_border-top-style)
    var(--mixin-FawJdt8Nqlu0_border-top-color);
  border-right: var(--mixin-FawJdt8Nqlu0_border-right-width)
    var(--mixin-FawJdt8Nqlu0_border-right-style)
    var(--mixin-FawJdt8Nqlu0_border-right-color);
  border-bottom: var(--mixin-FawJdt8Nqlu0_border-bottom-width)
    var(--mixin-FawJdt8Nqlu0_border-bottom-style)
    var(--mixin-FawJdt8Nqlu0_border-bottom-color);
  border-left: var(--mixin-FawJdt8Nqlu0_border-left-width)
    var(--mixin-FawJdt8Nqlu0_border-left-style)
    var(--mixin-FawJdt8Nqlu0_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-KwwLQ38hTq68_display);
  flex-direction: var(--mixin-KwwLQ38hTq68_flex-direction);
  align-items: var(--mixin-KwwLQ38hTq68_align-items);
  justify-content: var(--mixin-KwwLQ38hTq68_justify-content);
  list-style-position: var(--mixin-KwwLQ38hTq68_list-style-position);
  padding-left: var(--mixin-KwwLQ38hTq68_padding-left);
  position: var(--mixin-KwwLQ38hTq68_position);
  list-style-type: var(--mixin-KwwLQ38hTq68_list-style-type);
  flex-column-gap: var(--mixin-KwwLQ38hTq68_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-6In7pvNDcKdS_display);
  flex-direction: var(--mixin-6In7pvNDcKdS_flex-direction);
  align-items: var(--mixin-6In7pvNDcKdS_align-items);
  justify-content: var(--mixin-6In7pvNDcKdS_justify-content);
  list-style-position: var(--mixin-6In7pvNDcKdS_list-style-position);
  padding-left: var(--mixin-6In7pvNDcKdS_padding-left);
  position: var(--mixin-6In7pvNDcKdS_position);
  list-style-type: var(--mixin-6In7pvNDcKdS_list-style-type);
  flex-column-gap: var(--mixin-6In7pvNDcKdS_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-q0GJK3IwO0UD_color);
  font-size: var(--mixin-q0GJK3IwO0UD_font-size);
  font-weight: var(--mixin-q0GJK3IwO0UD_font-weight);
  line-height: var(--mixin-q0GJK3IwO0UD_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-jeJCh3OTQrYI_color);
  font-size: var(--mixin-jeJCh3OTQrYI_font-size);
  font-weight: var(--mixin-jeJCh3OTQrYI_font-weight);
  line-height: var(--mixin-jeJCh3OTQrYI_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-93QWqM14CAC0_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
