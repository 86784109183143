.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  position: relative;
  padding-top: var(--token-3S5TLMpb5);
  padding-bottom: var(--token-3S5TLMpb5);
  min-width: 0;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: stretch;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox___8Qld {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___8Qld > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox___8Qld > :global(.__wab_flex-container) > *,
.freeBox___8Qld > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8Qld > :global(.__wab_flex-container) > picture > img,
.freeBox___8Qld
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
}
.freeBox__mkiVu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__mkiVu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__mkiVu > :global(.__wab_flex-container) > *,
.freeBox__mkiVu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mkiVu > :global(.__wab_flex-container) > picture > img,
.freeBox__mkiVu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mkiVu {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mkiVu > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-MZ_89NRuN));
    height: calc(100% + var(--token-MZ_89NRuN));
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__mkiVu > :global(.__wab_flex-container) > *,
  .freeBox__mkiVu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mkiVu > :global(.__wab_flex-container) > picture > img,
  .freeBox__mkiVu
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-MZ_89NRuN);
  }
}
@media (max-width: 480px) {
  .freeBox__mkiVu > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-H3JPdTVdV));
    height: calc(100% + var(--token-H3JPdTVdV));
  }
}
@media (max-width: 480px) {
  .freeBox__mkiVu > :global(.__wab_flex-container) > *,
  .freeBox__mkiVu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mkiVu > :global(.__wab_flex-container) > picture > img,
  .freeBox__mkiVu
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-H3JPdTVdV);
  }
}
.documentType:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .documentType:global(.__wab_instance) {
    width: 100%;
  }
}
.select:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.svg__rZ6Qr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.option__mF71U:global(.__wab_instance) {
  position: relative;
}
.svg__aXpyS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__d5CBs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__oow2L:global(.__wab_instance) {
  position: relative;
}
.svg__y8LXu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___0O6OJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__e1Jlv:global(.__wab_instance) {
  position: relative;
}
.svg__ow6FU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__jHke {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__oIsE:global(.__wab_instance) {
  position: relative;
}
.svg__rN3AS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__lgDw7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__kW24Q:global(.__wab_instance) {
  position: relative;
}
.svg__u7Sa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__xniA9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___2NvR:global(.__wab_instance) {
  position: relative;
}
.svg__np5Jw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__esBnk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__ggtrQ:global(.__wab_instance) {
  position: relative;
}
.svg__gjOgx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__jvE66 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__om3Hc:global(.__wab_instance) {
  position: relative;
}
.svg__qGD8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___00BqK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__od79R:global(.__wab_instance) {
  position: relative;
}
.svg__zGfH5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__lz2Va {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__fuogh:global(.__wab_instance) {
  position: relative;
}
.svg__veYiy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__uNgf7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__dJoXo:global(.__wab_instance) {
  position: relative;
}
.svg__mppo9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__hdonr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__a6GRc:global(.__wab_instance) {
  position: relative;
}
.freeBox__zy9Mf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__zy9Mf > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__zy9Mf > :global(.__wab_flex-container) > *,
.freeBox__zy9Mf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zy9Mf > :global(.__wab_flex-container) > picture > img,
.freeBox__zy9Mf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
}
.freeBox__l0Yhv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__l0Yhv > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__l0Yhv > :global(.__wab_flex-container) > *,
.freeBox__l0Yhv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__l0Yhv > :global(.__wab_flex-container) > picture > img,
.freeBox__l0Yhv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__l0Yhv {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__l0Yhv > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - var(--token-MZ_89NRuN));
    width: calc(100% + var(--token-MZ_89NRuN));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__l0Yhv > :global(.__wab_flex-container) > *,
  .freeBox__l0Yhv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__l0Yhv > :global(.__wab_flex-container) > picture > img,
  .freeBox__l0Yhv
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-MZ_89NRuN);
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__l0Yhv {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__l0Yhv > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-H3JPdTVdV));
    height: calc(100% + var(--token-H3JPdTVdV));
  }
}
@media (max-width: 480px) {
  .freeBox__l0Yhv > :global(.__wab_flex-container) > *,
  .freeBox__l0Yhv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__l0Yhv > :global(.__wab_flex-container) > picture > img,
  .freeBox__l0Yhv
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-H3JPdTVdV);
  }
}
.freeBox__ieWc {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ieWc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__ieWc > :global(.__wab_flex-container) > *,
.freeBox__ieWc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ieWc > :global(.__wab_flex-container) > picture > img,
.freeBox__ieWc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox__ieWc > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - var(--token-H3JPdTVdV));
    height: calc(100% + var(--token-H3JPdTVdV));
  }
}
@media (max-width: 480px) {
  .freeBox__ieWc > :global(.__wab_flex-container) > *,
  .freeBox__ieWc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ieWc > :global(.__wab_flex-container) > picture > img,
  .freeBox__ieWc
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: var(--token-H3JPdTVdV);
  }
}
.documentMediator:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 120px;
}
.svg___5Ft7H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___83Jxu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bwCr4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  min-width: 0;
}
.text__hQ6S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lrt9X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___7Xvis:global(.__wab_instance) {
  position: relative;
}
.documentAuthor:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 120px;
}
.svg__vqg7L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aiJxz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sr1Hf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  min-width: 0;
}
.text__yQSb6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uIyBu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__kBpD0:global(.__wab_instance) {
  position: relative;
}
.documentDate:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .documentDate:global(.__wab_instance) {
    max-width: none;
  }
}
.dateSetter:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__magD:global(.__wab_instance) {
  position: relative;
}
.documentItemDeleteButton:global(.__wab_instance) {
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .documentItemDeleteButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__jeVf8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
