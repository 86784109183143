.root {
  display: flex;
  flex-direction: column;
  position: relative;
}
.root:focus-within {
  outline: none;
}
.root___focusVisibleWithin {
  outline: none;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: -12px;
  top: 100%;
  z-index: 1000;
  margin: 2px 12px;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  overflow: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  min-width: 0;
}
.optionsContainerhasCustomTrigger {
  width: 100%;
  min-width: 0;
}
.option__ehZqn:global(.__wab_instance) {
  position: relative;
}
.svg__l9Ujm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__jtQa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__tT8S:global(.__wab_instance) {
  position: relative;
}
.svg___7A0Li {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__raknl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.trigger {
  position: relative;
  display: flex;
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 0px 0px 0px var(--token-jHG8H2_EchV1WW_);
  transition-property: box-shadow, background;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  min-height: 36px;
  height: auto;
  cursor: pointer;
  -webkit-transition-property: box-shadow, background;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 6px;
  padding: 7px 6px 7px 10px;
  border: 1px solid var(--token-sHkWBDcvLab64_p);
}
.trigger > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.trigger > :global(.__wab_flex-container) > *,
.trigger > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.trigger > :global(.__wab_flex-container) > picture > img,
.trigger
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.triggerisOpen {
  background: var(--token-nJQnmTwmF4SPdl6);
}
.triggerisDisabled {
  opacity: 0.5;
}
.triggercolor_softBlue {
  background: #edf6ff;
  border-color: #ffffff00;
}
.triggercolor_softYellow {
  background: #fffbd1;
  border-color: #ffffff00;
}
.triggercolor_softGray {
  background: var(--token-nJQnmTwmF4SPdl6);
  border-style: none;
}
.triggercolor_clear {
  background: none;
}
.triggersize_extraSmall {
  min-height: 22px;
  padding: 0px 2px 0px 6px;
}
.triggersize_extraSmall > :global(.__wab_flex-container) {
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.triggersize_extraSmall > :global(.__wab_flex-container) > *,
.triggersize_extraSmall
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.triggersize_extraSmall > :global(.__wab_flex-container) > picture > img,
.triggersize_extraSmall
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
  margin-top: 0px;
}
.triggersize_small {
  min-height: 28px;
  padding: 3px 4px 3px 8px;
}
.triggersize_small > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.triggersize_small > :global(.__wab_flex-container) > *,
.triggersize_small > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.triggersize_small > :global(.__wab_flex-container) > picture > img,
.triggersize_small
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.triggersize_large {
  padding-left: 18px;
  padding-right: 14px;
  min-height: 44px;
}
.triggerstate_success {
  background: var(--token-yQ5PNjYizuW9slV);
  border-color: var(--token-djHL9A1ISxE7ONr);
}
.triggerstate_error {
  background: var(--token-Lymfn4M1YIjdOgD);
  border-color: var(--token-2N8zcUvyUXwuOJv);
}
.triggerhasCustomTrigger {
  min-height: 0px;
  width: 100%;
  height: auto;
  background: none;
  min-width: 0;
  border-radius: 0px;
  padding: 0px;
  border-style: none;
}
.triggerhasCustomTrigger > :global(.__wab_flex-container) {
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.triggerhasCustomTrigger > :global(.__wab_flex-container) > *,
.triggerhasCustomTrigger
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.triggerhasCustomTrigger > :global(.__wab_flex-container) > picture > img,
.triggerhasCustomTrigger
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.triggerhasCustomTrigger_isOpen {
  background: none;
}
.root:focus-within .trigger {
  box-shadow: 0px 0px 0px 3px var(--token-jHG8H2_EchV1WW_);
  outline: none;
}
.root .trigger___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px var(--token-jHG8H2_EchV1WW_);
  outline: none;
}
.hoverWrapper {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: auto;
  left: -8px;
  right: auto;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: opacity;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.hoverWrappersize_extraSmall {
  left: -2px;
}
.hoverWrappersize_small {
  left: -4px;
}
.root:hover .hoverWrapper {
  opacity: 0.75;
}
.root:active .hoverWrapper {
  opacity: 1;
}
.hoverColor {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: var(--token-4Vy3YOrYIv8ljp2);
  transition-property: background, transform, opacity;
  transition-duration: 0.1s, 0.1s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  opacity: 0.06;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: background, transform, opacity;
  -webkit-transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.1s, 0.1s, 0.2s;
  border-radius: 5px;
}
.root:hover .hoverColor {
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
.root:active .hoverColor {
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
.startIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
.startIconContainershowStartIcon {
  display: flex;
}
.slotTargetStartIcon {
  color: var(--token-5eiHu7b54PkQh5O);
}
.svg___8Lj0E {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.root:focus-within .contentContainer {
  outline: none;
}
.slotTargetSelectedContent {
  white-space: pre;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.1px;
}
.slotTargetSelectedContent > :global(.__wab_text),
.slotTargetSelectedContent > :global(.__wab_expr_html_text),
.slotTargetSelectedContent > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetSelectedContent > *,
.slotTargetSelectedContent > :global(.__wab_slot) > *,
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSelectedContent > picture > img,
.slotTargetSelectedContent > :global(.__wab_slot) > picture > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetSelectedContentcolor_softBlue {
  color: #006adc;
}
.slotTargetSelectedContentcolor_softYellow {
  color: #946800;
}
.slotTargetSelectedContentstate_success {
  color: var(--token--1wl_F9deuezOld);
}
.slotTargetSelectedContentstate_error {
  color: var(--token-jW34YHQobNMSD0Y);
}
.root:focus-within .slotTargetSelectedContent > *,
.root:focus-within .slotTargetSelectedContent > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetSelectedContent > picture > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.slotTargetPlaceholder {
  white-space: pre;
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.1px;
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.slotTargetPlaceholdercolor_softBlue_showPlaceholder {
  color: #006adc;
}
.slotTargetPlaceholdercolor_softYellow_showPlaceholder {
  color: #946800;
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  color: var(--token-IgvLORwjhxLX5NA);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  -webkit-transition-property: transform;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.dropdownIconisOpen {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-180deg);
}
.dropdownIconcolor_softBlue {
  color: #0091ff;
}
.dropdownIconcolor_softYellow {
  color: #f5d90a;
}
.dropdownIconstate_success {
  color: var(--token-CXh3DsvYUDbb4NE);
}
.dropdownIconstate_error {
  color: var(--token-oT7tnXfbXJAOlYI);
}
.root .dropdownIcon___focusVisibleWithin {
  outline: none;
}
.root:focus-within .dropdownIcon {
  outline: none;
}
.customTriggerWrapper {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  display: none;
}
.customTriggerWrapperhasCustomTrigger {
  width: 100%;
  min-width: 0;
  display: flex;
}
