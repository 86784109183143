.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  min-height: 140px;
  min-width: 0;
  padding: 32px 24px;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (max-width: 480px) {
  .root {
    padding-top: var(--token-MZ_89NRuN);
    padding-bottom: var(--token-MZ_89NRuN);
    min-height: var(--token-j8nt-mARd);
  }
}
.rootisActive {
  background: var(--token-vejaCN-6u7zUCAr);
  border-bottom-style: none;
  padding: 46px 24px;
}
@media (max-width: 480px) {
  .rootisActive {
    padding-top: var(--token-MZ_89NRuN);
    padding-bottom: var(--token-MZ_89NRuN);
  }
}
.freeBox__edgIx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.freeBox__edgIx > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__edgIx > :global(.__wab_flex-container) > *,
.freeBox__edgIx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__edgIx > :global(.__wab_flex-container) > picture > img,
.freeBox__edgIx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBoxisActive__edgIx2LFAb {
  display: none;
}
@media (max-width: 480px) {
  .freeBoxisActive__edgIx2LFAb {
    display: none;
  }
}
.freeBoxisLoading__edgIxkv37M {
  display: none;
}
.freeBox__yj5Cv {
  display: flex;
  flex-direction: row;
}
.freeBox__yj5Cv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__yj5Cv > :global(.__wab_flex-container) > *,
.freeBox__yj5Cv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yj5Cv > :global(.__wab_flex-container) > picture > img,
.freeBox__yj5Cv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
@media (max-width: 480px) {
  .freeBox__yj5Cv {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 480px) {
  .freeBox__yj5Cv > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - var(--token-H3JPdTVdV));
    width: calc(100% + var(--token-H3JPdTVdV));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__yj5Cv > :global(.__wab_flex-container) > *,
  .freeBox__yj5Cv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__yj5Cv > :global(.__wab_flex-container) > picture > img,
  .freeBox__yj5Cv
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-H3JPdTVdV);
    margin-top: 0px;
  }
}
.freeBox__fzb5D {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__fzb5D > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__fzb5D > :global(.__wab_flex-container) > *,
.freeBox__fzb5D > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fzb5D > :global(.__wab_flex-container) > picture > img,
.freeBox__fzb5D
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox__d40Im {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__d40Im > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-B5jiJYDEm));
  width: calc(100% + var(--token-B5jiJYDEm));
}
.freeBox__d40Im > :global(.__wab_flex-container) > *,
.freeBox__d40Im > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__d40Im > :global(.__wab_flex-container) > picture > img,
.freeBox__d40Im
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-B5jiJYDEm);
}
.mapButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .mapButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .mapButtonisActive:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__voubf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__g6AnU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.divider:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .divider:global(.__wab_instance) {
    flex-shrink: 0;
    display: none;
  }
}
.freeBox__dcBc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  max-width: 420px;
  width: 100%;
  min-width: 0;
}
.searchInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.autocompleteResult__aMqyo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.autocompleteResult___2IhKq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.autocompleteResult__jFh56:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.autocompleteResult__bQcPs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__h9Oy9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__gePeL {
  display: flex;
  flex-direction: row;
  height: auto;
  align-self: flex-start;
}
.freeBox__gePeL > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__gePeL > :global(.__wab_flex-container) > *,
.freeBox__gePeL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gePeL > :global(.__wab_flex-container) > picture > img,
.freeBox__gePeL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 480px) {
  .freeBox__gePeL {
    align-self: flex-end;
  }
}
.countrySwitcher:global(.__wab_instance):global(.__wab_instance) {
  position: relative;
}
.freeBox__ynfsn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.freeBoxhasRecentAdresses__ynfsNom3Di {
  display: flex;
}
.freeBox__ihqQq {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: auto;
  min-width: 0;
}
.freeBox__ihqQq > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__ihqQq > :global(.__wab_flex-container) > *,
.freeBox__ihqQq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ihqQq > :global(.__wab_flex-container) > picture > img,
.freeBox__ihqQq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox___9NbYr {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
}
.freeBox___9NbYr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox___9NbYr > :global(.__wab_flex-container) > *,
.freeBox___9NbYr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9NbYr > :global(.__wab_flex-container) > picture > img,
.freeBox___9NbYr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.svg___69Ml7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.text__kQqXe {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.freeBox___3GxDv {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox___3GxDv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox___3GxDv > :global(.__wab_flex-container) > *,
.freeBox___3GxDv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3GxDv > :global(.__wab_flex-container) > picture > img,
.freeBox___3GxDv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.button__yTua5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__l2I9T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tKgpV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__p5Am:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uRfmv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gzMmJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__abwwq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__goOqN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xaRuk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__z3PJs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.freeBox__z3PJs > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__z3PJs > :global(.__wab_flex-container) > *,
.freeBox__z3PJs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__z3PJs > :global(.__wab_flex-container) > picture > img,
.freeBox__z3PJs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__go6Ns {
  display: flex;
  flex-direction: row;
}
.freeBox__go6Ns > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__go6Ns > :global(.__wab_flex-container) > *,
.freeBox__go6Ns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__go6Ns > :global(.__wab_flex-container) > picture > img,
.freeBox__go6Ns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.freeBox__n1C8F {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__n1C8F > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__n1C8F > :global(.__wab_flex-container) > *,
.freeBox__n1C8F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__n1C8F > :global(.__wab_flex-container) > picture > img,
.freeBox__n1C8F
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__ay7M {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ay7M > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__ay7M > :global(.__wab_flex-container) > *,
.freeBox__ay7M > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ay7M > :global(.__wab_flex-container) > picture > img,
.freeBox__ay7M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.mapButtonIsActive:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.mapButtonIsActiveisActive:global(.__wab_instance) {
  width: auto;
}
@media (max-width: 480px) {
  .mapButtonIsActiveisActive:global(.__wab_instance) {
    height: 36px;
    width: 44px;
    flex-shrink: 0;
  }
}
.svg__whb8D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kBiTc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__jLFgW {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__jLFgW > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBox__jLFgW > :global(.__wab_flex-container) > *,
.freeBox__jLFgW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jLFgW > :global(.__wab_flex-container) > picture > img,
.freeBox__jLFgW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.text__qNrQ {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-NPNMQf7VB8u7Kel);
  font-weight: 500;
  opacity: 0.5;
}
.freeBox__qFgIr {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__qFgIr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-uoDiu2hxc));
  width: calc(100% + var(--token-uoDiu2hxc));
}
.freeBox__qFgIr > :global(.__wab_flex-container) > *,
.freeBox__qFgIr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qFgIr > :global(.__wab_flex-container) > picture > img,
.freeBox__qFgIr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-uoDiu2hxc);
}
.slotTargetAddress {
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 600;
  color: var(--token-NPNMQf7VB8u7Kel);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
@media (max-width: 480px) {
  .slotTargetAddress {
    font-size: var(--token-FutXsu2VTAR30Ez);
  }
}
.tag:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.tagisActive_isInaccurate:global(.__wab_instance) {
  display: flex;
}
.resetButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .resetButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .resetButtonisActive:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg___2Uif {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cSl86 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__ynLo {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  display: none;
}
@media (max-width: 480px) {
  .freeBoxisActive__ynLo2LFAb {
    display: none;
  }
}
.freeBoxisLoading__ynLokv37M {
  display: flex;
}
.banner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
