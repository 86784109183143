.root {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  position: relative;
  min-width: 0;
}
.renovationItems {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.renovationItems > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  margin-top: calc(0px - var(--token-H3JPdTVdV));
  height: calc(100% + var(--token-H3JPdTVdV));
}
.renovationItems > :global(.__wab_flex-container) > *,
.renovationItems > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.renovationItems > :global(.__wab_flex-container) > picture > img,
.renovationItems
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-H3JPdTVdV);
}
.renovationItemsisEmpty {
  display: none;
}
.divider:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.dividerisEmpty:global(.__wab_instance) {
  display: none;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.addRenovation:global(.__wab_instance) {
  max-width: none;
  position: relative;
  width: auto;
  align-self: auto;
}
.svg__f61Tb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.addRenovationButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__khbPf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__q5Qlc:global(.__wab_instance) {
  position: relative;
}
.svg__zd2Qb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__qnCPz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__kbglf:global(.__wab_instance) {
  position: relative;
}
.svg__tnwPf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__lQLbf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__mr026:global(.__wab_instance) {
  position: relative;
}
.svg__xcVl0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__lQahv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___5PJ9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__snubl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__ogAj5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__auOej:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vx262 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__iuwjI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__nJr0U:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__d76D9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__bdq3Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__k5MUg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ejJjY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__c4Stb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___1Dzqw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___3Gsh6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__qGopZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__rdcL5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tkVb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__hWa09 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___2ISnC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cBiYy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__bEgii {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
