.stao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: auto;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox__twT16 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  margin-bottom: 42px;
  min-width: 0;
  padding: 8px;
}
.text__mad2N {
  width: auto;
  height: auto;
  max-width: 100%;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  font-size: var(--token-Jhhay0F31-lTY91);
}
.text__aaaml {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  padding-top: 0px;
  min-width: 0;
}
.freeBox__x6Kn0 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      var(--token-nJQnmTwmF4SPdl6),
      var(--token-nJQnmTwmF4SPdl6)
    ),
    #ffffff;
  margin-bottom: 34px;
  min-width: 0;
  padding: 8px;
}
.freeBoxisProduction__x6Kn0VelJl {
  display: none;
}
.text__rp3Tf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  min-width: 0;
}
.freeBox__mk5He {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.plasmic {
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  min-width: 25%;
}
.freeBox___9ZUfY {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.staoTemplateUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.uploadState {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: center;
  min-width: 0;
}
.text__tqrAi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
  min-width: 0;
  margin: var(--token-hFcnMUlxM);
}
.freeBox__alJ {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-bottom: 38px;
  min-width: 0;
}
.text__mfBxq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h4 {
  margin-bottom: 20px;
}
.uploadedTemplatesTitel {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.uploadedTemplatesTitelisProduction {
  display: none;
}
.text__tsn1F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___8TDmV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text___1DzXl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__eQ15J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.uploadedTemplates {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-bottom: 39px;
  min-width: 0;
}
.templateRowisProduction__hhmievelJl:global(.__wab_instance) {
  display: none;
}
.templateRowisProduction___8NNlVelJl:global(.__wab_instance) {
  display: none;
}
.templateRowisProduction__oYrLwvelJl:global(.__wab_instance) {
  display: none;
}
.freeBox__m9Vni {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  min-width: 0;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: auto;
  object-position: left 50%;
}
.img > picture > img {
  object-fit: cover;
  object-position: left 50%;
}
