.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-MhDGai0bW);
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-k8MBdjDfsyp6Vsa);
  margin-top: var(--token-5K6-m7eB7);
  height: 1em;
}
.freeBox {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.errorMessage {
  color: var(--token-k8MBdjDfsyp6Vsa);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
