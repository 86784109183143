.plasmic_tokens {
  --token-pRs3-4jrQ4XQ: var(--antd-colorPrimary);
  --plasmic-token-system-primary: var(--token-pRs3-4jrQ4XQ);
  --token-sn6Zb6km7CqW: var(--antd-colorSuccess);
  --plasmic-token-system-success: var(--token-sn6Zb6km7CqW);
  --token-47ovROB-Xr2C: var(--antd-colorWarning);
  --plasmic-token-system-warning: var(--token-47ovROB-Xr2C);
  --token--ypw6enySR1T: var(--antd-colorError);
  --plasmic-token-system-error: var(--token--ypw6enySR1T);
  --token-GEppKCdlxgWK: var(--antd-colorInfo);
  --plasmic-token-system-info: var(--token-GEppKCdlxgWK);
  --token-kzT08fQBAHgz: var(--antd-colorText);
  --plasmic-token-system-text: var(--token-kzT08fQBAHgz);
  --token-LqpCVPPZF4_r: var(--antd-colorTextSecondary);
  --plasmic-token-system-text-secondary: var(--token-LqpCVPPZF4_r);
  --token-RHb8DXPBtDBn: var(--antd-colorTextTertiary);
  --plasmic-token-system-text-tertiary: var(--token-RHb8DXPBtDBn);
  --token--rHJyFFIHSqx: var(--antd-colorTextQuaternary);
  --plasmic-token-system-text-quaternary: var(--token--rHJyFFIHSqx);
  --token-Pxe4wDL2kJpb: var(--antd-colorBorder);
  --plasmic-token-system-border: var(--token-Pxe4wDL2kJpb);
  --token-LN1YkB123lXQ: var(--antd-colorBorderSecondary);
  --plasmic-token-system-border-secondary: var(--token-LN1YkB123lXQ);
  --token-MjYUWCcX38Uk: var(--antd-colorFill);
  --plasmic-token-system-fill: var(--token-MjYUWCcX38Uk);
  --token-cjVB4i6JVY6v: var(--antd-colorFillSecondary);
  --plasmic-token-system-fill-secondary: var(--token-cjVB4i6JVY6v);
  --token-9ZrR1Kut5qQh: var(--antd-colorFillTertiary);
  --plasmic-token-system-fill-tertiary: var(--token-9ZrR1Kut5qQh);
  --token-5flkhAz6pTxg: var(--antd-colorFillQuaternary);
  --plasmic-token-system-fill-quaternary: var(--token-5flkhAz6pTxg);
  --token-DbGdjBpQ6R_X: var(--antd-colorBgLayout);
  --plasmic-token-system-bg-layout: var(--token-DbGdjBpQ6R_X);
  --token-jgohepLVeKvh: var(--antd-colorBgContainer);
  --plasmic-token-system-bg-container: var(--token-jgohepLVeKvh);
  --token-zy6xhYnWF8Y7: var(--antd-colorBgElevated);
  --plasmic-token-system-bg-elevated: var(--token-zy6xhYnWF8Y7);
  --token-RvPI6LyeIPmd: var(--antd-colorBgSpotlight);
  --plasmic-token-system-bg-spotlight: var(--token-RvPI6LyeIPmd);
  --token-6Lhw5mK6wolF: var(--antd-colorPrimaryBg);
  --plasmic-token-system-primary-bg: var(--token-6Lhw5mK6wolF);
  --token-6G9-A5vsJlrH: var(--antd-colorPrimaryBgHover);
  --plasmic-token-system-primary-bg-hover: var(--token-6G9-A5vsJlrH);
  --token-ukwKZMvHWx7C: var(--antd-colorPrimaryBorder);
  --plasmic-token-system-primary-border: var(--token-ukwKZMvHWx7C);
  --token-8lqFm4pa_96a: var(--antd-colorPrimaryBorderHover);
  --plasmic-token-system-primary-border-hover: var(--token-8lqFm4pa_96a);
  --token-0W4hE_aeKQkw: var(--antd-colorPrimaryHover);
  --plasmic-token-system-primary-hover: var(--token-0W4hE_aeKQkw);
  --token-zeOmhvqK1boB: var(--antd-colorPrimaryActive);
  --plasmic-token-system-primary-active: var(--token-zeOmhvqK1boB);
  --token-Y4FX9LFslfTe: var(--antd-colorPrimaryTextHover);
  --plasmic-token-system-primary-text-hover: var(--token-Y4FX9LFslfTe);
  --token-DxfYAkV7QVUJ: var(--antd-colorPrimaryText);
  --plasmic-token-system-primary-text: var(--token-DxfYAkV7QVUJ);
  --token-zXou5HA2hb1R: var(--antd-colorPrimaryTextActive);
  --plasmic-token-system-primary-text-active: var(--token-zXou5HA2hb1R);
  --token-TgVT8YipjX7B: var(--antd-colorSuccessBg);
  --plasmic-token-system-success-bg: var(--token-TgVT8YipjX7B);
  --token-R0SUfsNirUr0: var(--antd-colorSuccessBgHover);
  --plasmic-token-system-success-bg-hover: var(--token-R0SUfsNirUr0);
  --token-mIWA40Rvp-L3: var(--antd-colorSuccessBorder);
  --plasmic-token-system-success-border: var(--token-mIWA40Rvp-L3);
  --token-uumNIGVKomsI: var(--antd-colorSuccessBorderHover);
  --plasmic-token-system-success-border-hover: var(--token-uumNIGVKomsI);
  --token-Vb0i3yqgVWX8: var(--antd-colorSuccessHover);
  --plasmic-token-system-success-hover: var(--token-Vb0i3yqgVWX8);
  --token-JP-pI4-MAOWS: var(--antd-colorSuccessActive);
  --plasmic-token-system-success-active: var(--token-JP-pI4-MAOWS);
  --token-nJiUvSvijQl3: var(--antd-colorSuccessTextHover);
  --plasmic-token-system-success-text-hover: var(--token-nJiUvSvijQl3);
  --token-6u49iN2cxqzs: var(--antd-colorSuccessText);
  --plasmic-token-system-success-text: var(--token-6u49iN2cxqzs);
  --token-U2IgSncNyCpY: var(--antd-colorSuccessTextActive);
  --plasmic-token-system-success-text-active: var(--token-U2IgSncNyCpY);
  --token-KrBMMqlPClZi: var(--antd-colorWarningBg);
  --plasmic-token-system-warning-bg: var(--token-KrBMMqlPClZi);
  --token-AIwAzeyxiz1x: var(--antd-colorWarningBgHover);
  --plasmic-token-system-warning-bg-hover: var(--token-AIwAzeyxiz1x);
  --token-u21Gzl9DygXI: var(--antd-colorWarningBorder);
  --plasmic-token-system-warning-border: var(--token-u21Gzl9DygXI);
  --token-M_5fD3bsBbm4: var(--antd-colorWarningBorderHover);
  --plasmic-token-system-warning-border-hover: var(--token-M_5fD3bsBbm4);
  --token-IFDRLVnOGObf: var(--antd-colorWarningHover);
  --plasmic-token-system-warning-hover: var(--token-IFDRLVnOGObf);
  --token-Ycl7DlUSVY4S: var(--antd-colorWarningActive);
  --plasmic-token-system-warning-active: var(--token-Ycl7DlUSVY4S);
  --token-bHHJuMx2zyxz: var(--antd-colorWarningTextHover);
  --plasmic-token-system-warning-text-hover: var(--token-bHHJuMx2zyxz);
  --token-EueuB3d74YKA: var(--antd-colorWarningText);
  --plasmic-token-system-warning-text: var(--token-EueuB3d74YKA);
  --token-2ObYh10hZuCh: var(--antd-colorWarningTextActive);
  --plasmic-token-system-warning-text-active: var(--token-2ObYh10hZuCh);
  --token-pi_amP1jS-3_: var(--antd-colorInfoBg);
  --plasmic-token-system-info-bg: var(--token-pi_amP1jS-3_);
  --token-AkJsX7eWcpyM: var(--antd-colorInfoBgHover);
  --plasmic-token-system-info-bg-hover: var(--token-AkJsX7eWcpyM);
  --token-q9Q0revQu5u0: var(--antd-colorInfoBorder);
  --plasmic-token-system-info-border: var(--token-q9Q0revQu5u0);
  --token-Ynjs6ut2v3nV: var(--antd-colorInfoBorderHover);
  --plasmic-token-system-info-border-hover: var(--token-Ynjs6ut2v3nV);
  --token-Z9jDmLfGJ4ec: var(--antd-colorInfoHover);
  --plasmic-token-system-info-hover: var(--token-Z9jDmLfGJ4ec);
  --token-sD0iOLam3c1_: var(--antd-colorInfoActive);
  --plasmic-token-system-info-active: var(--token-sD0iOLam3c1_);
  --token-Z5g3UaQUYJKs: var(--antd-colorInfoTextHover);
  --plasmic-token-system-info-text-hover: var(--token-Z5g3UaQUYJKs);
  --token-_T1cyz1fvJ5i: var(--antd-colorInfoText);
  --plasmic-token-system-info-text: var(--token-_T1cyz1fvJ5i);
  --token-VY6DggwR9lvs: var(--antd-colorInfoTextActive);
  --plasmic-token-system-info-text-active: var(--token-VY6DggwR9lvs);
  --token-y67qtjHCZN4U: var(--antd-colorErrorBg);
  --plasmic-token-system-error-bg: var(--token-y67qtjHCZN4U);
  --token-0MrDlDLqtJFU: var(--antd-colorErrorBgHover);
  --plasmic-token-system-error-bg-hover: var(--token-0MrDlDLqtJFU);
  --token-a78v3Ul_J4it: var(--antd-colorErrorBorder);
  --plasmic-token-system-error-border: var(--token-a78v3Ul_J4it);
  --token-IbRLKagyRkT2: var(--antd-colorErrorBorderHover);
  --plasmic-token-system-error-border-hover: var(--token-IbRLKagyRkT2);
  --token-COF46JTyVgAT: var(--antd-colorErrorHover);
  --plasmic-token-system-error-hover: var(--token-COF46JTyVgAT);
  --token-Bo2_tT25lwh4: var(--antd-colorErrorActive);
  --plasmic-token-system-error-active: var(--token-Bo2_tT25lwh4);
  --token-nd9tWjNBRhuz: var(--antd-colorErrorTextHover);
  --plasmic-token-system-error-text-hover: var(--token-nd9tWjNBRhuz);
  --token-ZDcvRU4gUKFH: var(--antd-colorErrorText);
  --plasmic-token-system-error-text: var(--token-ZDcvRU4gUKFH);
  --token-8dHZNKgzL8aW: var(--antd-colorErrorTextActive);
  --plasmic-token-system-error-text-active: var(--token-8dHZNKgzL8aW);
  --token-Yq556PvPjGii: var(--antd-colorWhite);
  --plasmic-token-system-white: var(--token-Yq556PvPjGii);
  --token-Le07ngBUPl2h: var(--antd-colorBgMask);
  --plasmic-token-system-bg-mask: var(--token-Le07ngBUPl2h);
  --token-y50Rafqey4Ec: var(--antd-colorIcon);
  --plasmic-token-system-icon: var(--token-y50Rafqey4Ec);
  --token-0jflij2Drauk: var(--antd-colorIconHover);
  --plasmic-token-system-icon-hover: var(--token-0jflij2Drauk);
  --token-p9GbVc-07pR7: var(--antd-colorLink);
  --plasmic-token-system-link: var(--token-p9GbVc-07pR7);
  --token-CmNnmfJ0iNRo: var(--antd-colorLinkHover);
  --plasmic-token-system-link-hover: var(--token-CmNnmfJ0iNRo);
  --token-cSJ2BJk-9JCq: var(--antd-paddingXXS);
  --plasmic-token-system-padding-xxs: var(--token-cSJ2BJk-9JCq);
  --token-oYI7ebdZitcv: var(--antd-paddingXS);
  --plasmic-token-system-padding-xs: var(--token-oYI7ebdZitcv);
  --token-Tf_KtjkxGdsv: var(--antd-paddingSM);
  --plasmic-token-system-padding-sm: var(--token-Tf_KtjkxGdsv);
  --token-UEz8jpx4ZeEk: var(--antd-padding);
  --plasmic-token-system-padding-m: var(--token-UEz8jpx4ZeEk);
  --token-Kzvu_5ZcCKzb: var(--antd-paddingMD);
  --plasmic-token-system-padding-md: var(--token-Kzvu_5ZcCKzb);
  --token-02IfQLozsPKb: var(--antd-paddingLG);
  --plasmic-token-system-padding-lg: var(--token-02IfQLozsPKb);
  --token-oW80B2Q_Cufz: var(--antd-paddingXL);
  --plasmic-token-system-padding-xl: var(--token-oW80B2Q_Cufz);
  --token-mZAl1RAwSIao: var(--antd-marginXXS);
  --plasmic-token-system-margin-xxs: var(--token-mZAl1RAwSIao);
  --token-9EfSOc8y7ano: var(--antd-marginXS);
  --plasmic-token-system-margin-xs: var(--token-9EfSOc8y7ano);
  --token-2ZPCvclvmwq9: var(--antd-marginSM);
  --plasmic-token-system-margin-sm: var(--token-2ZPCvclvmwq9);
  --token-_m46Z7TakRfI: var(--antd-margin);
  --plasmic-token-system-margin-m: var(--token-_m46Z7TakRfI);
  --token-4njgnJV5KOkS: var(--antd-marginMD);
  --plasmic-token-system-margin-md: var(--token-4njgnJV5KOkS);
  --token-eRNxHqcEHlFY: var(--antd-marginLG);
  --plasmic-token-system-margin-lg: var(--token-eRNxHqcEHlFY);
  --token-_8m4SPxiIjg9: var(--antd-marginXL);
  --plasmic-token-system-margin-xl: var(--token-_8m4SPxiIjg9);
  --token-dT-owZu8-6an: var(--antd-marginXXL);
  --plasmic-token-system-margin-xxl: var(--token-dT-owZu8-6an);
  --token-7z5Kw5B89TZR: var(--antd-fontSize);
  --plasmic-token-system-m: var(--token-7z5Kw5B89TZR);
  --token-o0Nl0Hefncwa: var(--antd-fontSizeSM);
  --plasmic-token-system-sm: var(--token-o0Nl0Hefncwa);
  --token-hYndcje43OGL: var(--antd-fontSizeLG);
  --plasmic-token-system-lg: var(--token-hYndcje43OGL);
  --token-BwOcdtoxJOlu: var(--antd-fontSizeXL);
  --plasmic-token-system-xl: var(--token-BwOcdtoxJOlu);
  --token-EBGyLHrpfttb: var(--antd-fontSizeHeading1);
  --plasmic-token-system-heading-1: var(--token-EBGyLHrpfttb);
  --token-bucIARGmMoc6: var(--antd-fontSizeHeading2);
  --plasmic-token-system-heading-2: var(--token-bucIARGmMoc6);
  --token-UXPBeNJ4rrVx: var(--antd-fontSizeHeading3);
  --plasmic-token-system-heading-3: var(--token-UXPBeNJ4rrVx);
  --token-B10hUni6jzpI: var(--antd-fontSizeHeading4);
  --plasmic-token-system-heading-4: var(--token-B10hUni6jzpI);
  --token-BlA-iDkaAWvO: var(--antd-fontSizeHeading5);
  --plasmic-token-system-heading-5: var(--token-BlA-iDkaAWvO);
  --token-_K7eyous79ni: var(--antd-lineHeight);
  --plasmic-token-system-m: var(--token-_K7eyous79ni);
  --token-fklKQN0M3LP2: var(--antd-lineHeightLG);
  --plasmic-token-system-lg: var(--token-fklKQN0M3LP2);
  --token-wxMcxZBYnxHD: var(--antd-lineHeightSM);
  --plasmic-token-system-sm: var(--token-wxMcxZBYnxHD);
  --token-sYDi1zi84t3y: var(--antd-lineHeightHeading1);
  --plasmic-token-system-heading-1: var(--token-sYDi1zi84t3y);
  --token-OclCNN0pXGGm: var(--antd-lineHeightHeading2);
  --plasmic-token-system-heading-2: var(--token-OclCNN0pXGGm);
  --token-yxIE6w4M2bri: var(--antd-lineHeightHeading3);
  --plasmic-token-system-heading-3: var(--token-yxIE6w4M2bri);
  --token-OdRcIpB2LnUT: var(--antd-lineHeightHeading4);
  --plasmic-token-system-heading-4: var(--token-OdRcIpB2LnUT);
  --token-FixeSDxCoolc: var(--antd-lineHeightHeading5);
  --plasmic-token-system-heading-5: var(--token-FixeSDxCoolc);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
}
