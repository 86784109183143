.root {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 7;
  position: relative;
  padding: 12px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
@media (max-width: 480px) {
  .root {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 4px;
  }
}
.roothasAlgo {
  display: flex;
  flex-direction: row;
}
.roothasAlgo > :global(.__wab_flex-container) {
  flex-direction: row;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.roothasAlgo > :global(.__wab_flex-container) > *,
.roothasAlgo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.roothasAlgo > :global(.__wab_flex-container) > picture > img,
.roothasAlgo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
  margin-top: 0px;
}
.formGroup:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .formGroup:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.formSuggestionGroup:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .formSuggestionGroup:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.formSuggestionGrouphasHelperText:global(.__wab_instance) {
  width: auto;
}
.slotTargetLabelSuffix {
  white-space: pre;
}
.svg__mcfQw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qocWs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qKUbm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1AfIy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter:global(.__wab_instance) {
  position: relative;
}
