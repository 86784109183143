.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  min-height: 0;
}
.processHeader:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.scrollNav__qVbb1:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__qVbb1:global(.__wab_instance) {
    left: auto;
    top: auto;
    z-index: 1;
    position: relative;
  }
}
@media (max-width: 480px) {
  .scrollNav__qVbb1:global(.__wab_instance) {
    position: relative;
    top: auto;
    left: auto;
    z-index: 1;
  }
}
.scrollNavGroup__mMUbg:global(.__wab_instance) {
  position: relative;
}
.navButton___7Blln:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__pndi4 {
  height: auto;
}
.svg__d8Uab {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___8Hill:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__plLsQ {
  height: auto;
}
.svg___7Kbhm {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__jKhbb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__kkfLd {
  height: auto;
}
.svg___244N6 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__nWweb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__yNJuN {
  height: auto;
}
.svg__ej5R {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__clc0C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__tdpZw {
  height: auto;
}
.svg__qwF3Q {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__qHB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__anxhK {
  height: auto;
}
.svg__hxHyp {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___1VzRy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__c0Acw {
  height: auto;
}
.svg___97Rf {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__krtcC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__a5BpE {
  height: auto;
}
.svg__uar95 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___8HRij:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___5AdaV {
  height: auto;
}
.svg__i2XNz {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___7NwUj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__mKtfw {
  height: auto;
}
.svg__gaIwd {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__r8L4N:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__wd3K {
  height: auto;
}
.svg___8DsH {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__dn8Il:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__md8Io {
  height: auto;
}
.svg__tiaFa {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__kJn7U:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__lzy2K {
  height: auto;
}
.svg__q0V2T {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.freeBox__dR4Fg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__dR4Fg {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__dR4Fg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.scrollNav__o3Y7U:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__o3Y7U:global(.__wab_instance) {
    left: 0px;
    top: 0px;
    z-index: 1;
    position: sticky;
  }
}
@media (max-width: 480px) {
  .scrollNav__o3Y7U:global(.__wab_instance) {
    position: relative;
    top: auto;
    left: auto;
    z-index: 1;
  }
}
.scrollNavGroup__udZb:global(.__wab_instance) {
  position: relative;
}
.navButton__bn969:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__yzoxb {
  height: auto;
}
.svg__ciyTa {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__t3B9W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__wTyLk {
  height: auto;
}
.svg__byNpc {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___8N3X:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__zZug {
  height: auto;
}
.svg__gd0Go {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___3Md6C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__pLAz8 {
  height: auto;
}
.svg__wXkiH {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__l1We:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__aRlTm {
  height: auto;
}
.svg__jt68C {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__cfXhQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__oZg1X {
  height: auto;
}
.svg__gZHbO {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__mL2Gt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__hbuKx {
  height: auto;
}
.svg__u8Rxu {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__pEkBx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___9Kh3C {
  height: auto;
}
.svg__xbrIx {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__ioXjM:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__fBfX {
  height: auto;
}
.svg__cZwFm {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__ckTd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__lzi8R {
  height: auto;
}
.svg___9UxKe {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__bcGO:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__i0 {
  height: auto;
}
.svg__j97Ph {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___1Obz9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__cPpq7 {
  height: auto;
}
.svg__udQij {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__dj5Sb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___9TSrx {
  height: auto;
}
.svg__y7WA {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.processPageContent:global(.__wab_instance) {
  position: relative;
}
.processSection:global(.__wab_instance) {
  position: relative;
}
.processCard__vZj0R:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__rvkCy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__yBdX {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__yBdX {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.formGroup___1K2Fs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__obGl {
  height: auto;
}
.textInput:global(.__wab_instance) {
  position: relative;
}
.svg__b9Ukb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qKoVx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dlkpO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1DrJz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__bYnmE:global(.__wab_instance) {
  position: relative;
}
.freeBox__w7RJq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
.freeBox__w7RJq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-nPzqyQpH_));
  height: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__w7RJq > :global(.__wab_flex-container) > *,
.freeBox__w7RJq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__w7RJq > :global(.__wab_flex-container) > picture > img,
.freeBox__w7RJq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-nPzqyQpH_);
}
.text__lqa3Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  min-width: 0;
}
.freeBox__yaaJi {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  width: auto;
  align-self: flex-start;
  background: var(--token-nJQnmTwmF4SPdl6);
  border-radius: 6px;
  padding: var(--token-nPzqyQpH_) var(--token-uoDiu2hxc);
}
.freeBox__yaaJi > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__yaaJi > :global(.__wab_flex-container) > *,
.freeBox__yaaJi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yaaJi > :global(.__wab_flex-container) > picture > img,
.freeBox__yaaJi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-nPzqyQpH_);
}
.dynamicText__s2Ra:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 600;
}
.text__qkeTa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  align-self: auto;
  min-width: 0;
}
.dynamicText__r6Xuf:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
.text__ySop {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.checkbox:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter___1Sx6:global(.__wab_instance) {
  position: relative;
}
.freeBox__sLj3W {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 3;
  grid-column-start: 1;
  min-width: 0;
}
.freeBox__sLj3W > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__sLj3W > :global(.__wab_flex-container) > *,
.freeBox__sLj3W > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sLj3W > :global(.__wab_flex-container) > picture > img,
.freeBox__sLj3W
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.formGroup__ynBvu:global(.__wab_instance) {
  max-width: 100%;
}
.text__lO7Ix {
  height: auto;
}
.dateSetter:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.symbolCounter___7TM3E:global(.__wab_instance) {
  position: relative;
}
.formGroup__mO9S:global(.__wab_instance) {
  position: relative;
}
.checkbox2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__r6UIx:global(.__wab_instance) {
  position: relative;
}
.formGroup___2Jkpb:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  .formGroup___2Jkpb:global(.__wab_instance) {
    grid-column-end: span 3;
  }
}
.text__rLs8K {
  height: auto;
}
.fileUploadField:global(.__wab_instance) {
  position: relative;
}
.svg__db1Pb {
  object-fit: cover;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  height: 1em;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__x8HYl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  width: var(--token-hFcnMUlxM);
  height: var(--token-hFcnMUlxM);
  display: none;
}
.img__yaXhk {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__yaXhk > picture > img {
  object-fit: cover;
}
.symbolCounter__c35Bt:global(.__wab_instance) {
  position: relative;
}
.processCardSection__duLKz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__qfEZl {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
.formGroup__wu2Zx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
.text__oqzrf {
  height: auto;
}
.textInput3:global(.__wab_instance) {
  position: relative;
}
.svg___6Ahx9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uBay4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__n7Fxd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kWlad {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__gqQhj:global(.__wab_instance) {
  position: relative;
}
.formGroup___4YpUg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput4:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__e8Xx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___18MRp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uxhfs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__j0Pkf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__e0WIs:global(.__wab_instance) {
  position: relative;
}
.text__hsxGr {
  font-weight: 400;
}
.formGroup__ySn3Q:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__spgMs:global(.__wab_instance) {
  position: relative;
}
.svg__bi8Ko {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5OiVg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__s5QfZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kh3LQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__tcIxo:global(.__wab_instance) {
  position: relative;
}
.text__t4OhR {
  padding-right: 0px;
}
.svg__qfF2L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ofbiR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__isHzo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__tQnpN:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__c90Dv {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__qgo6L {
  height: auto;
}
.processCard__sCRbL:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__uhYNq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dynamicImpressionList:global(.__wab_instance) {
  position: relative;
}
.dynamicImpressionListItem__zAfWv:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
.dynamicImpressionListItem___1Xeb7:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
.formGroup__ybJjp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput11:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___0GlIc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gYgo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tHgU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ch1Ja {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__qUvPe:global(.__wab_instance) {
  position: relative;
}
.text___2Qnl7 {
  font-weight: 400;
}
.formGroup__yk7Os:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__usGrS:global(.__wab_instance) {
  position: relative;
}
.svg__mhcOu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ten3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__meUcx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aJf3M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__zmjEx:global(.__wab_instance) {
  position: relative;
}
.text__f2NXu {
  padding-right: 0px;
}
.svg__kgYx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tqUQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fbl7N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__i2Krs:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__yEc68 {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text___20H1 {
  height: auto;
}
.processCard__ehrYy:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__rgMkc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.reportDescriptionSuggestionGroup__f8NYf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__v5Tm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput27:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__bdtpv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__alp8W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0RlR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__drDgX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__alCrM:global(.__wab_instance) {
  position: relative;
}
.text__jKZjB {
  font-weight: 400;
}
.formGroup__lkb7Z:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__qrhCs:global(.__wab_instance) {
  position: relative;
}
.svg__djAxJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4OjOn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yggif {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wrxq1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__rzkp:global(.__wab_instance) {
  position: relative;
}
.text__moW3 {
  padding-right: 0px;
}
.svg__jARww {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__g8Ea {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sWjRn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__sbEsE:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__nwpeC {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__xT3YZ {
  height: auto;
}
.processCard__efSrk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processCardSection___4RxWr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.reportDescriptionSuggestionGroup___8OwWa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__eJamt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput29:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__isUwa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oqKyy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mLsmf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__q4JEs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__xoQ34:global(.__wab_instance) {
  position: relative;
}
.text__wNaTp {
  font-weight: 400;
}
.formGroup__ls6VI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput___9W4Ui:global(.__wab_instance) {
  position: relative;
}
.svg__csD1X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nBual {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0WDck {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__w4C8I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__te9Kg:global(.__wab_instance) {
  position: relative;
}
.text__tu2Oi {
  padding-right: 0px;
}
.svg__lOKy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1JHIt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nhJ5A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__bWnmz:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__zmA1K {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__loZp {
  height: auto;
}
.processCard__uUv0:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__z2JZ8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__hpgr3 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.reportLegendItem___9JUy5:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.reportLegendItem__oMu87:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.reportLegendItem__jk2Fc:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.reportLegendItem__wfSmM:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.formGroup__zxaZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput30:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__zcX0J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mHzq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__f7Nvt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bbc0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___3Sffo:global(.__wab_instance) {
  position: relative;
}
.text__iBa99 {
  font-weight: 400;
}
.formGroup__qa3FN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__neG2O:global(.__wab_instance) {
  position: relative;
}
.svg__fve6Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__h6Si7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cWvJn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bv5Zk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__nvrtx:global(.__wab_instance) {
  position: relative;
}
.text__xjoT1 {
  padding-right: 0px;
}
.svg__s6Oqs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ft1Qi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rWeF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__z1Tom:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__sIfO {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__yfgCa {
  height: auto;
}
.processCard__eviW1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processCardSection__egwR9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.reportDescriptionSuggestionGroup__qT2Vp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__ut6V7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput32:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___2GxKu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iuZal {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hKUcd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eQlFv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__ciy6:global(.__wab_instance) {
  position: relative;
}
.text___3C7VL {
  font-weight: 400;
}
.formGroup__jrIh5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__hUvhS:global(.__wab_instance) {
  position: relative;
}
.svg__zwfVv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__t8C8C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sgeB0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1Yyzh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___6I7K:global(.__wab_instance) {
  position: relative;
}
.text__ydOg {
  padding-right: 0px;
}
.svg__hvnCr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dzp4L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3EGPs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__td58H:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__gs7Dk {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__zxEHv {
  height: auto;
}
.processCard__aBv3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processCardSection__fwmNh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.reportDescriptionSuggestionGroup__lx7Ey:global(.__wab_instance) {
  position: relative;
}
.formGroup__vUHbz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput34:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___8FeLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4X7Ih {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___3Muo6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1MJph {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__sZmHh:global(.__wab_instance) {
  position: relative;
}
.text___72Dtr {
  font-weight: 400;
}
.formGroup__sTgt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__oOfaF:global(.__wab_instance) {
  position: relative;
}
.svg__xBu91 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sAfBf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jqFBg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sZ5Tq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__sjB2P:global(.__wab_instance) {
  position: relative;
}
.text___8T3Jt {
  padding-right: 0px;
}
.svg__x6K0N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___6En3T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pAyEw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton___0QfP7:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__qcGo {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__xoeKq {
  height: auto;
}
.processCard__e6QSw:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__joztg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__bm5Nm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-bottom: 0px;
  min-width: 0;
}
.freeBox__bm5Nm > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__bm5Nm > :global(.__wab_flex-container) > *,
.freeBox__bm5Nm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bm5Nm > :global(.__wab_flex-container) > picture > img,
.freeBox__bm5Nm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox__jvPx {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__jvPx > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox__jvPx > :global(.__wab_flex-container) > *,
.freeBox__jvPx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jvPx > :global(.__wab_flex-container) > picture > img,
.freeBox__jvPx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3S5TLMpb5);
}
@media (max-width: 480px) {
  .freeBox__jvPx {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__jvPx > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-3S5TLMpb5));
    height: calc(100% + var(--token-3S5TLMpb5));
  }
}
@media (max-width: 480px) {
  .freeBox__jvPx > :global(.__wab_flex-container) > *,
  .freeBox__jvPx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__jvPx > :global(.__wab_flex-container) > picture > img,
  .freeBox__jvPx
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-3S5TLMpb5);
  }
}
.reportRadioBoolean__ko0Iw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.reportRadioBoolean___2G1Iy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__oL2Jm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput18:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__jVqWd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5Z4Wu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__iek38 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fhR1B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__u9ZFu:global(.__wab_instance) {
  position: relative;
}
.text__rrTkH {
  font-weight: 400;
}
.formGroup___6H2Bl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput___2M6Im:global(.__wab_instance) {
  position: relative;
}
.svg__zybOl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ozm9U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__y55Cs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ziqEf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__sJimq:global(.__wab_instance) {
  position: relative;
}
.text__x64DE {
  padding-right: 0px;
}
.svg__z3GCi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___53KZr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__au6Gp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__ut7Wt:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg___4GiTh {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__w0VWr {
  height: auto;
}
.processCard__oQiW:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__m7Q0A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__v2AKb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__v2AKb > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__v2AKb > :global(.__wab_flex-container) > *,
.freeBox__v2AKb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__v2AKb > :global(.__wab_flex-container) > picture > img,
.freeBox__v2AKb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox___5ToX {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___5ToX > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox___5ToX > :global(.__wab_flex-container) > *,
.freeBox___5ToX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5ToX > :global(.__wab_flex-container) > picture > img,
.freeBox___5ToX
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3S5TLMpb5);
}
@media (max-width: 480px) {
  .freeBox___5ToX {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox___5ToX > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-3S5TLMpb5));
    height: calc(100% + var(--token-3S5TLMpb5));
  }
}
@media (max-width: 480px) {
  .freeBox___5ToX > :global(.__wab_flex-container) > *,
  .freeBox___5ToX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___5ToX > :global(.__wab_flex-container) > picture > img,
  .freeBox___5ToX
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-3S5TLMpb5);
  }
}
.reportRadioBoolean__tiWhk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.reportRadioBoolean__i8Yu1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__wU9X:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput25:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__ixffo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__o8QHl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ySs4Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ofk1F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__tef9:global(.__wab_instance) {
  position: relative;
}
.text__vz6RZ {
  font-weight: 400;
}
.formGroup__mpsTs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__iU13C:global(.__wab_instance) {
  position: relative;
}
.svg___0Oetw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nHRsF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__meFyg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vaJQi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___5TxUn:global(.__wab_instance) {
  position: relative;
}
.text__n2Brz {
  padding-right: 0px;
}
.svg__dyvQe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cCzj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vkObj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__uihBn:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__hrbvg {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__zmXv3 {
  height: auto;
}
.processCard__yKxoa:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__hvruT:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__dUzLo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__dUzLo > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-3wiInXIIr));
  height: calc(100% + var(--token-3wiInXIIr));
}
.freeBox__dUzLo > :global(.__wab_flex-container) > *,
.freeBox__dUzLo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dUzLo > :global(.__wab_flex-container) > picture > img,
.freeBox__dUzLo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-3wiInXIIr);
}
.unterlagenverzeichnisGrundstueck:global(.__wab_instance) {
  max-width: 100%;
}
.dynamicDocumentListItem__kiM0E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__clX7T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__sPjO5 {
  height: auto;
}
.textInput35:global(.__wab_instance) {
  position: relative;
}
.svg__kB7Xu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sb2U8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bzuLq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zYw3K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___9SJF:global(.__wab_instance) {
  position: relative;
}
.formGroup__rK2Vc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput36:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__aqWdi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uWbVn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vEdi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__j3GBc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___7AiYy:global(.__wab_instance) {
  position: relative;
}
.text__paWri {
  font-weight: 400;
}
.formGroup___6QpFg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__bwFAi:global(.__wab_instance) {
  position: relative;
}
.svg___9Xj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aVtYa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hs67F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__z8LJr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__yuqB9:global(.__wab_instance) {
  position: relative;
}
.text__fxqRf {
  padding-right: 0px;
}
.svg__qzLX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__n8Gco {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__izSp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__zHvo:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg___40J5R {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__eWxKi {
  height: auto;
}
.processCard__eacWe:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__j8SCi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__yr5Vv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__yr5Vv > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-3wiInXIIr));
  height: calc(100% + var(--token-3wiInXIIr));
}
.freeBox__yr5Vv > :global(.__wab_flex-container) > *,
.freeBox__yr5Vv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yr5Vv > :global(.__wab_flex-container) > picture > img,
.freeBox__yr5Vv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-3wiInXIIr);
}
.unterlagenverzeichnisGebaeude:global(.__wab_instance) {
  max-width: 100%;
}
.dynamicDocumentListItem__kMwza:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__gWfwm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text___2Y7B {
  height: auto;
}
.textInput37:global(.__wab_instance) {
  position: relative;
}
.svg__eIfg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0WjDz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lbg4Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ifqQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__j5Je:global(.__wab_instance) {
  position: relative;
}
.formGroup__wj9PB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput38:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__y2Jpq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vy7H8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nuNbY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qrhq7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__aLyp4:global(.__wab_instance) {
  position: relative;
}
.text__gPTr {
  font-weight: 400;
}
.formGroup__jz9DV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__vf9GJ:global(.__wab_instance) {
  position: relative;
}
.svg__clDjs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cTwuM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yeTyd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uyOtP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__bRqE:global(.__wab_instance) {
  position: relative;
}
.text__q2A45 {
  padding-right: 0px;
}
.svg__iCjc6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uaNt2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2FJIm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__dKaae:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__ulaS5 {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__c6SA {
  height: auto;
}
.processCard__hzGjl:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__a5XrW:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dynamicAttachmentList:global(.__wab_instance) {
  position: relative;
}
.text___5PaU {
  color: var(--token-PcBCP-f11VpIoWX);
}
.formGroup__xB2T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput41:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__na2Wf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uYfoX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0U7GP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qbyTq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__mQrb9:global(.__wab_instance) {
  position: relative;
}
.text__ntqTm {
  font-weight: 400;
}
.formGroup__dltNm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput___4POm1:global(.__wab_instance) {
  position: relative;
}
.svg__laCeD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yxPwN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gfxjz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rqTll {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__zORui:global(.__wab_instance) {
  position: relative;
}
.text__jdBu {
  padding-right: 0px;
}
.svg__vDfnn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eX31S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__r5DvT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__b99St:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__isKlv {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__eMxka {
  height: auto;
}
.processCard__pMoYc:global(.__wab_instance) {
  max-width: 100%;
}
.processCardSection__iSmyr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.reportPageSelect:global(.__wab_instance) {
  position: relative;
}
.formGroup__nasTb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput42:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__vfw44 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ooNnW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wscDi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__oYpDq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__hbXwh:global(.__wab_instance) {
  position: relative;
}
.text__vYlw2 {
  font-weight: 400;
}
.formGroup__chnNk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__wV4Mw:global(.__wab_instance) {
  position: relative;
}
.svg___0Nqy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eJaqA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fIzGe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jcM1W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__p9DbY:global(.__wab_instance) {
  position: relative;
}
.text__djj8N {
  padding-right: 0px;
}
.svg___9NvH4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__koIss {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rvzFi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__c7Jfy:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__g3Ied {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text___4Cf9Z {
  height: auto;
}
.processBottomNav:global(.__wab_instance) {
  position: fixed;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.svg__h0Kee {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__iBuOv {
  height: auto;
}
.svg__oTnhm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.submitAndDownloadButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qhSh6 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__kkqoh {
  height: auto;
}
.svg__oGsgk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
