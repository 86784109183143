.root {
  display: flex;
  position: relative;
  flex-direction: row;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-MhDGai0bW);
}
.text {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  padding-bottom: 0px;
}
@media (max-width: 480px) {
  .text {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.textstate_saving {
  color: var(--token-IgvLORwjhxLX5NA);
}
.textstate_notSaved {
  color: var(--token-Y_1QycPpMrs0BhH);
}
.textstate_error {
  color: var(--token-Y_1QycPpMrs0BhH);
}
.freeBox {
  display: block;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: var(--token-MhDGai0bW);
  height: var(--token-MhDGai0bW);
  background: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
  border-radius: 200px;
}
.freeBoxstate_saving {
  display: block;
}
.freeBoxstate_notSaved {
  background: var(--token-8Upm2qPCoH9Sb-9);
  display: block;
}
.freeBoxstate_error {
  background: var(--token-8Upm2qPCoH9Sb-9);
  display: block;
}
