.root {
  display: inline-flex;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 7;
  position: relative;
  height: auto;
  width: auto;
  justify-self: flex-start;
  padding: 0px 12px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-8IFSbVVlb);
}
@media (max-width: 480px) {
  .root {
    padding: var(--token-8IFSbVVlb) 0px 4px;
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-start;
    justify-content: flex-end;
  }
}
.roothasLink {
  padding: 12px;
}
.roothasLink > :global(.__wab_flex-container) {
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.roothasLink > :global(.__wab_flex-container) > *,
.roothasLink > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.roothasLink > :global(.__wab_flex-container) > picture > img,
.roothasLink
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
  margin-top: 0px;
}
@media (max-width: 480px) {
  .roothasLink {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .roothasLink > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-8IFSbVVlb));
    height: calc(100% + var(--token-8IFSbVVlb));
  }
}
@media (max-width: 480px) {
  .roothasLink > :global(.__wab_flex-container) > *,
  .roothasLink > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .roothasLink > :global(.__wab_flex-container) > picture > img,
  .roothasLink
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-8IFSbVVlb);
  }
}
.freeBox__xFmjT {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__xFmjT > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__xFmjT > :global(.__wab_flex-container) > *,
.freeBox__xFmjT > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xFmjT > :global(.__wab_flex-container) > picture > img,
.freeBox__xFmjT
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
@media (max-width: 480px) {
  .freeBox__xFmjT {
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 480px) {
  .freeBox__xFmjT > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.freeBoxhasLink__xFmjTs1RgE {
  width: auto;
  height: auto;
}
.freeBoxhasLink__xFmjTs1RgE > :global(.__wab_flex-container) {
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasLink__xFmjTs1RgE > :global(.__wab_flex-container) > *,
.freeBoxhasLink__xFmjTs1RgE
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasLink__xFmjTs1RgE > :global(.__wab_flex-container) > picture > img,
.freeBoxhasLink__xFmjTs1RgE
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
  margin-top: 0px;
}
@media (max-width: 480px) {
  .freeBoxhasLink__xFmjTs1RgE {
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 480px) {
  .freeBoxhasLink__xFmjTs1RgE > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.freeBox__pjxq4 {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 12px;
}
.freeBox__pjxq4 > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-left: calc(0px - var(--token-B5jiJYDEm));
  width: calc(100% + var(--token-B5jiJYDEm));
}
.freeBox__pjxq4 > :global(.__wab_flex-container) > *,
.freeBox__pjxq4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pjxq4 > :global(.__wab_flex-container) > picture > img,
.freeBox__pjxq4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-B5jiJYDEm);
}
@media (max-width: 480px) {
  .freeBox__pjxq4 {
    width: 100%;
    flex-direction: row-reverse;
    min-width: 0;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__pjxq4 > :global(.__wab_flex-container) {
    align-items: baseline;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__pjxq4 > :global(.__wab_flex-container) > *,
  .freeBox__pjxq4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__pjxq4 > :global(.__wab_flex-container) > picture > img,
  .freeBox__pjxq4
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
.freeBoxhasLink__pjxq4S1RgE {
  width: 100%;
  min-width: 0;
}
.freeBoxhasLink__pjxq4S1RgE > :global(.__wab_flex-container) {
  min-width: 0;
}
.freeBoxhasRange__pjxq4LbZ34 > :global(.__wab_flex-container) {
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasRange__pjxq4LbZ34 > :global(.__wab_flex-container) > *,
.freeBoxhasRange__pjxq4LbZ34
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasRange__pjxq4LbZ34 > :global(.__wab_flex-container) > picture > img,
.freeBoxhasRange__pjxq4LbZ34
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
  margin-top: 0px;
}
.freeBox__y8TXw {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
}
.freeBox__y8TXw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 480px) {
  .freeBox__y8TXw {
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__y8TXw > :global(.__wab_flex-container) {
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 480px) {
  .freeBoxhasLink__y8TXWs1RgE {
    width: auto;
  }
}
.freeBoxhasRange__y8TXWlbZ34 > :global(.__wab_flex-container) {
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasRange__y8TXWlbZ34 > :global(.__wab_flex-container) > *,
.freeBoxhasRange__y8TXWlbZ34
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasRange__y8TXWlbZ34 > :global(.__wab_flex-container) > picture > img,
.freeBoxhasRange__y8TXWlbZ34
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
  margin-top: 0px;
}
.dynamicText__e8Ooo:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: var(--token-wZK1o5uz59Brqbf);
  white-space: pre;
  display: none;
}
.dynamicTexthasRange__e8OoolbZ34:global(.__wab_instance) {
  display: flex;
}
.text__tu4Ke {
  position: relative;
  height: auto;
  font-size: var(--token-wZK1o5uz59Brqbf);
  white-space: pre;
}
.text__xIlqN {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  display: none;
}
.texthasRange__xIlqNlbZ34 {
  display: block;
}
.dynamicText___6H9Ad:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-size: var(--token-wZK1o5uz59Brqbf);
  white-space: pre;
}
.dynamicTextisBold___6H9Ad6ISyY:global(.__wab_instance) {
  font-weight: 600;
}
.text__swUl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  white-space: pre;
  text-align: right;
  min-width: 82px;
}
.texthasRange__swUlLbZ34 {
  width: auto;
  min-width: 0px;
}
.freeBox__yQ96Z {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  min-width: 5.5rem;
}
@media (max-width: 480px) {
  .freeBox__yQ96Z {
    padding-left: -10px;
  }
}
.freeBoxisVertical__yQ96ZnsSiD {
  max-width: none;
}
.dynamicText__w2T3N:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  white-space: pre;
  color: var(--token-5eiHu7b54PkQh5O);
  flex-direction: row;
  display: none;
}
.dynamicTexthasExternalLabel__w2T3NEaEjm:global(.__wab_instance) {
  display: flex;
}
.dynamicTextisBold__w2T3N6ISyY:global(.__wab_instance) {
  font-weight: 600;
}
.text___2ChRf {
  position: relative;
}
.texthasLink___2ChRFs1RgE {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
}
.freeBox__yt15S {
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.freeBox__ql7G4 {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.slotTargetUnitLabel {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.freeBox___26Uuv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBoxhasLink___26Uuvs1RgE {
  align-items: center;
}
@media (max-width: 480px) {
  .freeBoxhasLink___26Uuvs1RgE {
    justify-content: flex-end;
    align-items: center;
  }
}
.svg__b6Iii {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__v4SkG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
