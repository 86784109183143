.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  position: relative;
  min-width: 0;
  padding: var(--token-3S5TLMpb5) var(--token-hFcnMUlxM);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-nPzqyQpH_);
}
.transaktionenButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.transaktionenButtonhideTransaction:global(.__wab_instance) {
  display: none;
}
.svg__wtPxl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dhwva {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__md0UF {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__md0UF > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__md0UF > :global(.__wab_flex-container) > *,
.freeBox__md0UF > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__md0UF > :global(.__wab_flex-container) > picture > img,
.freeBox__md0UF
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.inserateButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__lqj49 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__je12B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.downloadDebugButton:global(.__wab_instance) {
  max-width: 100%;
}
.svg__of2Fi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9NAw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__nznV8 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox__nznV8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__nznV8 > :global(.__wab_flex-container) > *,
.freeBox__nznV8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nznV8 > :global(.__wab_flex-container) > picture > img,
.freeBox__nznV8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.exportButton:global(.__wab_instance) {
  max-width: 100%;
}
.exportButtonhideExportButton:global(.__wab_instance) {
  display: none;
}
.svg__fMrfH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2McM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.openModalButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .openModalButton:global(.__wab_instance) {
    display: none;
  }
}
.svg__eohVy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__l5P7F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
