.root {
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-height: var(--token-xZRZ7hK_d);
  width: 100%;
  min-width: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.roottype_text {
  padding-left: var(--token-MZ_89NRuN);
  padding-right: var(--token-MZ_89NRuN);
}
.rootalignment_right {
  justify-content: flex-end;
  align-items: center;
}
.rootalignment_center {
  justify-content: center;
  align-items: center;
}
.freeBox__p1AiZ {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.svg__o33Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__znixd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__eUFpF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ylA1U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__ww1Fq {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
.freeBoxtype_text__ww1FQfKqC {
  display: flex;
}
.slotTargetTextValue {
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.slotTargetTextValuetextBold {
  font-weight: 600;
}
.text__mKmO {
  padding-left: 0px;
}
.freeBox__cYw20 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBoxtype_algoButton__cYw20074W {
  display: flex;
}
.algoButton__a6H6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__k3HtZ {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBoxtype_button__k3HtZCxbef {
  display: flex;
}
.button__aLuOg:global(.__wab_instance) {
  max-width: 100%;
}
.svg___9DwHb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__d9UGw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
