.root {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-klOkpNg9M));
  width: calc(100% + var(--token-klOkpNg9M));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-klOkpNg9M);
}
.value {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.text {
  line-height: var(--token-f2GFyMCUtiJjuKU);
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
.maximumValue {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.svg {
  position: relative;
  object-fit: cover;
  color: var(--token-mOJuRJhlC);
  margin-left: var(--token-nPzqyQpH_) !important;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.svgwarning {
  margin-left: var(--token-nPzqyQpH_) !important;
  display: block;
}
