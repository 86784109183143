.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  max-width: 100%;
  position: relative;
  background: var(--token-nJQnmTwmF4SPdl6);
  justify-self: flex-start;
  border-radius: 6px;
  padding: 16px;
}
.freeBox__li3Yl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 52px;
  flex-shrink: 0;
}
.comparablesTableHeaderCell___1X0Ow:global(.__wab_instance) {
  position: relative;
  min-width: 66px;
  max-width: 66px;
}
.text__oqDke {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__hQ4Qb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 130px;
  max-width: 130px;
}
.comparablesTableHeaderCell__wm1I:global(.__wab_instance) {
  position: relative;
}
.text__v1Vlw {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox___9R9Ef {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__bfn0K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.comparablesTableHeaderCell__jQz5B:global(.__wab_instance) {
  position: relative;
}
.text__zmicw {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__ddi7A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 100px;
  max-width: 100px;
}
.comparablesTableHeaderCell__eMbBn:global(.__wab_instance) {
  position: relative;
}
.text___5O41S {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox___8Mkec {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 112px;
  max-width: 112px;
}
.freeBoxhideDistanz___8MkeckDHua {
  display: none;
}
.comparablesTableHeaderCell__iN9U4:global(.__wab_instance) {
  position: relative;
}
.text__pMxFb {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox___14Th {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 160px;
  width: 100%;
  max-width: 160px;
}
.freeBoxhideStrlieg___14ThbXpDd {
  display: none;
}
.comparablesTableHeaderCell__ocgYr:global(.__wab_instance) {
  position: relative;
}
.text__ox3Gz {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__tppU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 80px;
  max-width: 80px;
}
.comparablesTableHeaderCell__e5Eb0:global(.__wab_instance) {
  position: relative;
}
.text__pJ5Me {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__bFl4A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 140px;
  max-width: 140px;
}
.comparablesTableHeaderCell___4WFqi:global(.__wab_instance) {
  position: relative;
}
.text__zLhVu {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__ntNyk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 80px;
  max-width: 80px;
}
.comparablesTableHeaderCell__pf7Yr:global(.__wab_instance) {
  position: relative;
}
.text__pfsxc {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox___1YlWk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBoxhideTyp___1YlWk00DvV {
  display: none;
}
.comparablesTableHeaderCell___3KsfN:global(.__wab_instance) {
  position: relative;
}
.text__zjUgZ {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox___5RVh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.comparablesTableHeaderCell__nCrJz:global(.__wab_instance) {
  position: relative;
}
.text__puA7N {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__rttQo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBoxhideVol__rttQOpzlJ {
  display: none;
}
.comparablesTableHeaderCell__gG9X:global(.__wab_instance) {
  position: relative;
}
.text__cxbtF {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__reHmf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBoxhideLand__reHmFxKi9S {
  display: none;
}
.comparablesTableHeaderCell__k9CsT:global(.__wab_instance) {
  position: relative;
}
.text__hcLu6 {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__hcfd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.comparablesTableHeaderCell__zaQQv:global(.__wab_instance) {
  position: relative;
}
.text___6FRd1 {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__kBfg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.comparablesTableHeaderCell___0ZR1W:global(.__wab_instance) {
  position: relative;
}
.text__lHpLf {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__mTnfM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBoxhideZust__mTnfMfsTzK {
  display: none;
}
.comparablesTableHeaderCell__dkypb:global(.__wab_instance) {
  position: relative;
}
.text__dlmP {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox___87PVb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.comparablesTableHeaderCell__weCzd:global(.__wab_instance) {
  position: relative;
}
.text__yqRfJ {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__jnlso {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBoxhideStand__jnlsoxFwni {
  display: none;
}
.comparablesTableHeaderCell___2EsQb:global(.__wab_instance) {
  position: relative;
}
.text__hWuJ3 {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__hAsIb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBoxhideEtage__hAsIbOygcO {
  display: none;
}
.comparablesTableHeaderCell__jp47D:global(.__wab_instance) {
  position: relative;
}
.text__g8ZoP {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.freeBox__hZ2B8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-width: 110px;
  max-width: 110px;
}
.freeBoxhideId__hZ2B8FEdNn {
  display: none;
}
.comparablesTableHeaderCell__uXcTc:global(.__wab_instance) {
  position: relative;
}
.text___0BFv {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
