.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--token-enYJIO6w3sEL0NO);
  min-width: 0;
  min-height: 0;
}
.freeBox__otWs {
  display: flex;
  position: sticky;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  min-width: 0;
}
.navigation:global(.__wab_instance) {
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.addressSearchBar:global(.__wab_instance) {
  position: relative;
}
.button__mnflt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__juy9I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tCXm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__cMrFa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nbjx2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wdhmJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__htK6M:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__mmdc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tOik {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___47PiG {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-enYJIO6w3sEL0NO);
  min-width: 0;
  padding: 0px var(--token-3S5TLMpb5) var(--token-uoU4lPaiz);
}
.freeBox___47PiG > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___47PiG > :global(.__wab_flex-container) > *,
.freeBox___47PiG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___47PiG > :global(.__wab_flex-container) > picture > img,
.freeBox___47PiG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (max-width: 480px) {
  .freeBox___47PiG {
    padding-bottom: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.freeBox__oihNf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.freeBox__oihNf > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__oihNf > :global(.__wab_flex-container) > *,
.freeBox__oihNf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oihNf > :global(.__wab_flex-container) > picture > img,
.freeBox__oihNf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
@media (max-width: 480px) {
  .freeBox__oihNf > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 48px);
    height: calc(100% + 48px);
  }
}
@media (max-width: 480px) {
  .freeBox__oihNf > :global(.__wab_flex-container) > *,
  .freeBox__oihNf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__oihNf > :global(.__wab_flex-container) > picture > img,
  .freeBox__oihNf
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 48px;
  }
}
.newsBanner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.freeBox__hkCl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.text___6Zd4E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__tv9Fe {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-top: 10px;
  min-width: 0;
}
.link__vqCw7 {
  position: relative;
  margin-right: 15px;
}
.link__agZdt {
  position: relative;
}
.objectPreviewCard__ltvS:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
  grid-row-end: span 1;
}
.objectPreviewCard__koUe9:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
.objectPreviewCard__pbLuA:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
.freeBox__px7S8 {
  display: flex;
  flex-direction: column;
  padding-top: var(--token-7dIrE6133);
}
.freeBox__px7S8 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-hFcnMUlxM));
  height: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox__px7S8 > :global(.__wab_flex-container) > *,
.freeBox__px7S8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__px7S8 > :global(.__wab_flex-container) > picture > img,
.freeBox__px7S8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-hFcnMUlxM);
}
.freeBox__fZvZk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__fZvZk > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__fZvZk > :global(.__wab_flex-container) > *,
.freeBox__fZvZk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fZvZk > :global(.__wab_flex-container) > picture > img,
.freeBox__fZvZk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__rUfwj {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__rUfwj > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__rUfwj > :global(.__wab_flex-container) > *,
.freeBox__rUfwj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rUfwj > :global(.__wab_flex-container) > picture > img,
.freeBox__rUfwj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__ot81Z {
  position: relative;
  font-size: var(--token-Jhhay0F31-lTY91);
  height: auto;
  font-weight: 500;
}
.freeBox__dUtVj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  border-radius: 12px;
  padding: var(--token-cTBZAIcMp) var(--token-3S5TLMpb5) var(--token-3S5TLMpb5);
}
.freeBox__dUtVj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__dUtVj > :global(.__wab_flex-container) > *,
.freeBox__dUtVj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dUtVj > :global(.__wab_flex-container) > picture > img,
.freeBox__dUtVj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox__dUtVj {
    padding: var(--token-MZ_89NRuN);
  }
}
.freeBox__xiUr {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__xiUr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__xiUr > :global(.__wab_flex-container) > *,
.freeBox__xiUr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xiUr > :global(.__wab_flex-container) > picture > img,
.freeBox__xiUr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.svg__oYgBt {
  position: relative;
  object-fit: cover;
  color: var(--token-5eiHu7b54PkQh5O);
  width: var(--token-3S5TLMpb5);
  height: var(--token-3S5TLMpb5);
  flex-shrink: 0;
}
.text__qpmP {
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
}
.freeBox__uC8D {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-H3JPdTVdV);
  grid-column-gap: var(--token-H3JPdTVdV);
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__uC8D {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.bewertungEwg:global(.__wab_instance) {
  position: relative;
}
.text__feEox {
  height: auto;
}
.bewertungEfh:global(.__wab_instance) {
  position: relative;
}
.bewertungRenditeimmobilie:global(.__wab_instance) {
  position: relative;
}
.bewertungGrundstueck:global(.__wab_instance) {
  position: relative;
}
.bewertungMietwohnung:global(.__wab_instance) {
  position: relative;
}
.bewertungBueroflaeche:global(.__wab_instance) {
  position: relative;
}
.bewertungVerkaufsflaeche:global(.__wab_instance) {
  position: relative;
}
.bewertungPortfolioanalyse:global(.__wab_instance) {
  position: relative;
}
.freeBox__sfDOk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  border-radius: 12px;
  padding: var(--token-cTBZAIcMp) var(--token-3S5TLMpb5) var(--token-3S5TLMpb5);
}
.freeBox__sfDOk > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__sfDOk > :global(.__wab_flex-container) > *,
.freeBox__sfDOk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sfDOk > :global(.__wab_flex-container) > picture > img,
.freeBox__sfDOk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox__sfDOk {
    padding: var(--token-MZ_89NRuN);
  }
}
.freeBox__lX4Bf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__lX4Bf > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__lX4Bf > :global(.__wab_flex-container) > *,
.freeBox__lX4Bf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lX4Bf > :global(.__wab_flex-container) > picture > img,
.freeBox__lX4Bf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.svg__xeGha {
  position: relative;
  object-fit: cover;
  color: var(--token-5eiHu7b54PkQh5O);
  width: var(--token-cTBZAIcMp);
  height: var(--token-cTBZAIcMp);
  flex-shrink: 0;
}
.text__mK4S6 {
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
  width: 100%;
  min-width: 0;
}
.freeBox___9IIep {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-H3JPdTVdV);
  grid-column-gap: var(--token-H3JPdTVdV);
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox___9IIep {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.standortanalyseWohnen:global(.__wab_instance) {
  position: relative;
}
.standortanalyseGeschaeft:global(.__wab_instance) {
  position: relative;
}
.standortanalyseKomplett:global(.__wab_instance) {
  position: relative;
}
.standortanalyseUebersichtMakrolage:global(.__wab_instance) {
  position: relative;
}
.standortanalyseUebersichtMikrolage:global(.__wab_instance) {
  position: relative;
}
.standortanalyseKarten:global(.__wab_instance) {
  position: relative;
}
.freeBox__ePxwb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  border-radius: 12px;
  padding: var(--token-cTBZAIcMp) var(--token-3S5TLMpb5) var(--token-3S5TLMpb5);
}
.freeBox__ePxwb > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__ePxwb > :global(.__wab_flex-container) > *,
.freeBox__ePxwb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ePxwb > :global(.__wab_flex-container) > picture > img,
.freeBox__ePxwb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox__ePxwb {
    padding: var(--token-MZ_89NRuN);
  }
}
.freeBox__tODuc {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__tODuc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__tODuc > :global(.__wab_flex-container) > *,
.freeBox__tODuc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tODuc > :global(.__wab_flex-container) > picture > img,
.freeBox__tODuc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.svg__axW5K {
  position: relative;
  object-fit: cover;
  color: var(--token-5eiHu7b54PkQh5O);
  width: var(--token-cTBZAIcMp);
  height: var(--token-cTBZAIcMp);
  flex-shrink: 0;
}
.text__ccf5C {
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
}
.freeBox__qGScX {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-H3JPdTVdV);
  grid-column-gap: var(--token-H3JPdTVdV);
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__qGScX {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.gemeindecheckWohnen:global(.__wab_instance) {
  position: relative;
}
.gemeindecheckGeschaeft:global(.__wab_instance) {
  position: relative;
}
.freeBox___9WqkZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  border-radius: 12px;
  padding: var(--token-cTBZAIcMp) var(--token-3S5TLMpb5) var(--token-3S5TLMpb5);
}
.freeBox___9WqkZ > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox___9WqkZ > :global(.__wab_flex-container) > *,
.freeBox___9WqkZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9WqkZ > :global(.__wab_flex-container) > picture > img,
.freeBox___9WqkZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox___9WqkZ {
    padding: var(--token-MZ_89NRuN);
  }
}
.freeBox__wk8Mc {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__wk8Mc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__wk8Mc > :global(.__wab_flex-container) > *,
.freeBox__wk8Mc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wk8Mc > :global(.__wab_flex-container) > picture > img,
.freeBox__wk8Mc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.svg__on80 {
  position: relative;
  object-fit: cover;
  color: var(--token-5eiHu7b54PkQh5O);
  width: var(--token-cTBZAIcMp);
  height: var(--token-cTBZAIcMp);
  flex-shrink: 0;
}
.text__rmovG {
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
}
.freeBox__nOJx2 {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-H3JPdTVdV);
  grid-column-gap: var(--token-H3JPdTVdV);
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__nOJx2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.makrolageBuero:global(.__wab_instance) {
  position: relative;
}
.makrolageVerkauf:global(.__wab_instance) {
  position: relative;
}
.makrolageGewerbe:global(.__wab_instance) {
  position: relative;
}
.makrolageLogistik:global(.__wab_instance) {
  position: relative;
}
.makrolageMietwohnungen:global(.__wab_instance) {
  position: relative;
}
.freeBox__jp21I {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-width: 0;
  border-radius: 12px;
  padding: var(--token-cTBZAIcMp) var(--token-3S5TLMpb5) var(--token-3S5TLMpb5);
}
.freeBox__jp21I > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__jp21I > :global(.__wab_flex-container) > *,
.freeBox__jp21I > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jp21I > :global(.__wab_flex-container) > picture > img,
.freeBox__jp21I
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox__jp21I {
    padding: var(--token-MZ_89NRuN);
  }
}
.freeBox__zFmYc {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__zFmYc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__zFmYc > :global(.__wab_flex-container) > *,
.freeBox__zFmYc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zFmYc > :global(.__wab_flex-container) > picture > img,
.freeBox__zFmYc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.svg__v9Tj9 {
  position: relative;
  object-fit: cover;
  color: var(--token-5eiHu7b54PkQh5O);
  width: var(--token-cTBZAIcMp);
  height: var(--token-cTBZAIcMp);
  flex-shrink: 0;
}
.text__v8OFy {
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
}
.freeBox__krkxF {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-H3JPdTVdV);
  grid-column-gap: var(--token-H3JPdTVdV);
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__krkxF {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.weitereMetaanalysen:global(.__wab_instance) {
  position: relative;
}
.text__sdLtx {
  padding-bottom: 0px;
}
.weitereMonitorRegulierung:global(.__wab_instance) {
  position: relative;
}
.weitereRegionaleKarten:global(.__wab_instance) {
  position: relative;
}
.text___9Yjfe {
  padding-bottom: 0px;
}
.weitereRealmatch360:global(.__wab_instance) {
  position: relative;
}
.weitereUmgebungsanalyse:global(.__wab_instance) {
  position: relative;
}
.text__pjEx {
  height: auto;
}
.weitereMikrolage:global(.__wab_instance) {
  position: relative;
}
.weitereBewertungRenditeimmobilieV2:global(.__wab_instance) {
  max-width: 100%;
}
.footer:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .footer:global(.__wab_instance) {
    position: relative;
  }
}
@media (max-width: 480px) {
  .footer:global(.__wab_instance) {
    position: relative;
  }
}
.freeBox___9XMsk {
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  top: auto;
  left: 0;
  bottom: var(--token-cTBZAIcMp);
  right: 0;
  z-index: 1;
  display: none;
}
.support:global(.__wab_instance) {
  position: fixed;
  left: auto;
  top: auto;
  z-index: 1;
  right: 23px;
  bottom: 23px;
}
