.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-7dIrE6133);
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox___9VNMg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___9VNMg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-hFcnMUlxM));
  height: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox___9VNMg > :global(.__wab_flex-container) > *,
.freeBox___9VNMg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9VNMg > :global(.__wab_flex-container) > picture > img,
.freeBox___9VNMg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-hFcnMUlxM);
}
.freeBoxisFailed___9VNMgVTt8R {
  display: none;
}
.freeBoxisLoading___9VNMguMvd {
  display: none;
}
.freeBox__s4Csq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__s4Csq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-H3JPdTVdV));
  height: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__s4Csq > :global(.__wab_flex-container) > *,
.freeBox__s4Csq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__s4Csq > :global(.__wab_flex-container) > picture > img,
.freeBox__s4Csq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-H3JPdTVdV);
}
.text__vrAXa {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.freeBox__lpRW {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__lpRW > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__lpRW > :global(.__wab_flex-container) > *,
.freeBox__lpRW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lpRW > :global(.__wab_flex-container) > picture > img,
.freeBox__lpRW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
@media (max-width: 480px) {
  .freeBox__lpRW > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - var(--token-8IFSbVVlb));
    height: calc(100% + var(--token-8IFSbVVlb));
  }
}
@media (max-width: 480px) {
  .freeBox__lpRW > :global(.__wab_flex-container) > *,
  .freeBox__lpRW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__lpRW > :global(.__wab_flex-container) > picture > img,
  .freeBox__lpRW
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: var(--token-8IFSbVVlb);
  }
}
.text__sZlvY {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.freeBox__aE2L8 {
  display: flex;
  flex-direction: row;
  height: auto;
}
.freeBox__aE2L8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__aE2L8 > :global(.__wab_flex-container) > *,
.freeBox__aE2L8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aE2L8 > :global(.__wab_flex-container) > picture > img,
.freeBox__aE2L8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
.svg__d0Bie {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-Hken4BJDt);
  height: 1em;
}
.text__ekK8H {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-Hken4BJDt);
}
.text__ff7Bc {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.freeBox__ewXcd {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.freeBox__ewXcd > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__ewXcd > :global(.__wab_flex-container) > *,
.freeBox__ewXcd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ewXcd > :global(.__wab_flex-container) > picture > img,
.freeBox__ewXcd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
.svg__zgbNu {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  height: 1em;
}
.text__yLhgR {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-eKcWWhfvZ4_f9Lh);
}
.freeBox___7RBmY {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.egidRow:global(.__wab_instance) {
  position: relative;
}
.baujRow:global(.__wab_instance) {
  position: relative;
}
.baujtechRow:global(.__wab_instance) {
  position: relative;
}
.dachZustandLabelRow:global(.__wab_instance) {
  position: relative;
}
.text___2TmaR {
  padding-bottom: 0px;
}
.fassadeZustandLabelRow:global(.__wab_instance) {
  position: relative;
}
.fensterZustandLabelRow:global(.__wab_instance) {
  position: relative;
}
.gvM3Row:global(.__wab_instance) {
  position: relative;
}
.wohnfl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.gebAnzahlGeschosseOberirdischRow:global(.__wab_instance) {
  position: relative;
}
.gebkonRow:global(.__wab_instance) {
  position: relative;
}
.dachcRow:global(.__wab_instance) {
  position: relative;
}
.energieLabelRow:global(.__wab_instance) {
  position: relative;
}
.heatingsystemaCodeRow:global(.__wab_instance) {
  position: relative;
}
.text__mVxqf {
  text-align: right;
}
.hotwaterCodeRow:global(.__wab_instance) {
  position: relative;
}
.text__cA4G0 {
  text-align: right;
}
.ebfM2Row:global(.__wab_instance) {
  position: relative;
}
.labelGeakRow:global(.__wab_instance) {
  position: relative;
}
.geakenergieettigesRow:global(.__wab_instance) {
  position: relative;
}
.geakenergieettico2Row:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.geakenergieettihueRow:global(.__wab_instance) {
  position: relative;
}
.labelMinergiestandardRow:global(.__wab_instance) {
  position: relative;
}
.enrEntrUnbeknVerbRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.enrEntrWpVerbRow2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.enrEntrGasVerbRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.enrEntrOelVerbRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.enrEntrHlzVerbRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.enrEntrElekhzVerbRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.enrEntrSoltVerbRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.enrEntrFwaeVerbRow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.divider__riFY:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
  display: none;
}
.dividerisFailed__riFYvTt8R:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.dividerisLoading__riFYuMvd:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.freeBox__oTbyo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__oTbyo > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-hFcnMUlxM));
  height: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox__oTbyo > :global(.__wab_flex-container) > *,
.freeBox__oTbyo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oTbyo > :global(.__wab_flex-container) > picture > img,
.freeBox__oTbyo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-hFcnMUlxM);
}
.freeBoxisFailed__oTbyoVTt8R {
  display: none;
}
.freeBoxisLoading__oTbyouMvd {
  display: none;
}
.text__gsCOw {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.freeBox__xoaZe {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__xoaZe > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox__xoaZe > :global(.__wab_flex-container) > *,
.freeBox__xoaZe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xoaZe > :global(.__wab_flex-container) > picture > img,
.freeBox__xoaZe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3S5TLMpb5);
}
@media (max-width: 480px) {
  .freeBox__xoaZe {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox__xoaZe > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-3S5TLMpb5));
    height: calc(100% + var(--token-3S5TLMpb5));
  }
}
@media (max-width: 480px) {
  .freeBox__xoaZe > :global(.__wab_flex-container) > *,
  .freeBox__xoaZe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__xoaZe > :global(.__wab_flex-container) > picture > img,
  .freeBox__xoaZe
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-3S5TLMpb5);
  }
}
.freeBox__oaCp3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-cTBZAIcMp);
}
.freeBox__oaCp3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-3S5TLMpb5));
  height: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox__oaCp3 > :global(.__wab_flex-container) > *,
.freeBox__oaCp3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__oaCp3 > :global(.__wab_flex-container) > picture > img,
.freeBox__oaCp3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-3S5TLMpb5);
}
.freeBox__q5PGk {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__q5PGk > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3wiInXIIr));
  width: calc(100% + var(--token-3wiInXIIr));
}
.freeBox__q5PGk > :global(.__wab_flex-container) > *,
.freeBox__q5PGk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__q5PGk > :global(.__wab_flex-container) > picture > img,
.freeBox__q5PGk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3wiInXIIr);
}
.freeBox___70L5M {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.text__sQeRu {
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.freeBox__lglp2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__lglp2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__lglp2 > :global(.__wab_flex-container) > *,
.freeBox__lglp2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lglp2 > :global(.__wab_flex-container) > picture > img,
.freeBox__lglp2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.makroOekonomieRatingCard:global(.__wab_instance) {
  position: relative;
}
.mikroOekonomieRatingCard:global(.__wab_instance) {
  position: relative;
}
.objOekonomieRatingCard:global(.__wab_instance) {
  position: relative;
}
.freeBox__anm8X {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-cTBZAIcMp);
}
.freeBox__anm8X > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-3S5TLMpb5));
  height: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox__anm8X > :global(.__wab_flex-container) > *,
.freeBox__anm8X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__anm8X > :global(.__wab_flex-container) > picture > img,
.freeBox__anm8X
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-3S5TLMpb5);
}
.freeBox__tKbFp {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__tKbFp > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3wiInXIIr));
  width: calc(100% + var(--token-3wiInXIIr));
}
.freeBox__tKbFp > :global(.__wab_flex-container) > *,
.freeBox__tKbFp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tKbFp > :global(.__wab_flex-container) > picture > img,
.freeBox__tKbFp
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3wiInXIIr);
}
.freeBox___5E9Th {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.text___3CsLb {
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.freeBox__v3GBx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__v3GBx > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__v3GBx > :global(.__wab_flex-container) > *,
.freeBox__v3GBx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__v3GBx > :global(.__wab_flex-container) > picture > img,
.freeBox__v3GBx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.makroOekologieRatingCard:global(.__wab_instance) {
  position: relative;
}
.mikroOekologieRatingCard:global(.__wab_instance) {
  position: relative;
}
.objOekologieRatingCard:global(.__wab_instance) {
  position: relative;
}
.freeBox__pGvzr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-cTBZAIcMp);
}
.freeBox__pGvzr > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-3S5TLMpb5));
  height: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox__pGvzr > :global(.__wab_flex-container) > *,
.freeBox__pGvzr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pGvzr > :global(.__wab_flex-container) > picture > img,
.freeBox__pGvzr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-3S5TLMpb5);
}
.freeBox__xd5Zn {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__xd5Zn > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3wiInXIIr));
  width: calc(100% + var(--token-3wiInXIIr));
}
.freeBox__xd5Zn > :global(.__wab_flex-container) > *,
.freeBox__xd5Zn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xd5Zn > :global(.__wab_flex-container) > picture > img,
.freeBox__xd5Zn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3wiInXIIr);
}
.freeBox__jk9Nz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.text__oQlVb {
  font-size: var(--token-wZK1o5uz59Brqbf);
  padding-bottom: 0px;
}
.freeBox__wKuId {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__wKuId > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__wKuId > :global(.__wab_flex-container) > *,
.freeBox__wKuId > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wKuId > :global(.__wab_flex-container) > picture > img,
.freeBox__wKuId
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.makroSozialesRatingCard:global(.__wab_instance) {
  position: relative;
}
.mikroSozialesRatingCard:global(.__wab_instance) {
  position: relative;
}
.divider__jok5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.dividerisFailed__jok5VTt8R:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.dividerisLoading__jok5UMvd:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.freeBox__m2O7U {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__m2O7U > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-hFcnMUlxM));
  height: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox__m2O7U > :global(.__wab_flex-container) > *,
.freeBox__m2O7U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__m2O7U > :global(.__wab_flex-container) > picture > img,
.freeBox__m2O7U
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-hFcnMUlxM);
}
.freeBoxisFailed__m2O7UVTt8R {
  display: none;
}
.freeBoxisLoading__m2O7UuMvd {
  display: none;
}
.text__lDk {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.handlungsFelderListe {
  padding-left: var(--token-MZ_89NRuN);
  color: var(--token-6i7l1huu6);
  margin-bottom: var(--token-MZ_89NRuN);
  padding-top: 0px;
  display: none;
  border-left: 3px solid var(--token-p6OGFuM2O);
}
.freeBox___9TULd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___9TULd > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox___9TULd > :global(.__wab_flex-container) > *,
.freeBox___9TULd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9TULd > :global(.__wab_flex-container) > picture > img,
.freeBox___9TULd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.sanGebhuelActionField:global(.__wab_instance) {
  position: relative;
}
.erstzWaermerzActionField:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.einbaupvdachActionField:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.divider__eNmRs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  flex-shrink: 0;
}
.dividerisFailed__eNmRsvTt8R:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.dividerisLoading__eNmRSuMvd:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.freeBox___04Dzx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___04Dzx > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-hFcnMUlxM));
  height: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox___04Dzx > :global(.__wab_flex-container) > *,
.freeBox___04Dzx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___04Dzx > :global(.__wab_flex-container) > picture > img,
.freeBox___04Dzx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-hFcnMUlxM);
}
.freeBoxisFailed___04DzxvTt8R {
  display: none;
}
.freeBoxisLoading___04DzXuMvd {
  display: none;
}
.freeBox__xDJoB {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__xDJoB > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
  margin-top: calc(0px - var(--token-hFcnMUlxM));
  height: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox__xDJoB > :global(.__wab_flex-container) > *,
.freeBox__xDJoB > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xDJoB > :global(.__wab_flex-container) > picture > img,
.freeBox__xDJoB
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
  margin-top: var(--token-hFcnMUlxM);
}
.freeBox___5Qc4H {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox___5Qc4H > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-H3JPdTVdV));
  height: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox___5Qc4H > :global(.__wab_flex-container) > *,
.freeBox___5Qc4H > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5Qc4H > :global(.__wab_flex-container) > picture > img,
.freeBox___5Qc4H
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-H3JPdTVdV);
}
@media (max-width: 480px) {
  .freeBox___5Qc4H {
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 480px) {
  .freeBox___5Qc4H > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.remmsReportButton:global(.__wab_instance) {
  position: relative;
  max-width: 100%;
}
@media (max-width: 480px) {
  .remmsReportButton:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.svg__ocZal {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zKy1Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.r4AllButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .r4AllButton:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.svg__dMlj8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uTody {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__xoclc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: auto;
  height: auto;
}
@media (max-width: 480px) {
  .freeBox__xoclc {
    width: 100%;
    align-items: center;
    justify-content: center;
    min-width: 0;
  }
}
.text__hx6Ok {
  padding-right: 0px;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-5eiHu7b54PkQh5O);
}
.img {
  object-fit: cover;
  max-width: 96px;
  opacity: 0.5;
}
.img > picture > img {
  object-fit: cover;
}
.banner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
  display: none;
}
.bannerisFailed:global(.__wab_instance) {
  display: flex;
}
.bannerisLoading:global(.__wab_instance) {
  display: flex;
}
