@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600&display=swap");

.plasmic_tokens {
  --token-EYT_iKq9WuNh: #030712;
  --plasmic-token-basic-text-primary: var(--token-EYT_iKq9WuNh);
  --token-ImTrOtUuYWgv: Inter;
  --plasmic-token-sans-serif: var(--token-ImTrOtUuYWgv);
  --token-RclYU_VlzkyP: 1rem;
  --plasmic-token-font-md: var(--token-RclYU_VlzkyP);
  --token-AFHl4u6_5PHv: 1.25;
  --plasmic-token-line-height-md: var(--token-AFHl4u6_5PHv);
  --token-fvk47xreBizz: 0.25rem;
  --plasmic-token-size-4: var(--token-fvk47xreBizz);
  --token-ummBxPX5i2Gr: 0.5rem;
  --plasmic-token-size-8: var(--token-ummBxPX5i2Gr);
  --token-ZQy9dEPf_y49: 2rem;
  --plasmic-token-size-32: var(--token-ZQy9dEPf_y49);
  --token-4k-eItOAEqgN: #e5e7eb;
  --plasmic-token-neutral-neutral-soft: var(--token-4k-eItOAEqgN);
  --token-gFmnDAo7iMDi: 0.125rem;
  --plasmic-token-size-2: var(--token-gFmnDAo7iMDi);
  --token-4-rpAuV-p2G8: #2563eb;
  --plasmic-token-brand-brand: var(--token-4-rpAuV-p2G8);
  --token-vGSB0Jmu8Q_k: 1rem;
  --plasmic-token-size-16: var(--token-vGSB0Jmu8Q_k);
  --token-h5ZQ1Abo2ey-: #ffffff;
  --plasmic-token-basic-container-background: var(--token-h5ZQ1Abo2ey-);
  --token-BfUAEJ0LqbGz: #6b7280;
  --plasmic-token-basic-text-secondary: var(--token-BfUAEJ0LqbGz);
  --token-Y01XV8r-CYgD: 0.375rem;
  --plasmic-token-size-6: var(--token-Y01XV8r-CYgD);
  --token-9SrcuYOqhFKM: 0.625rem;
  --plasmic-token-size-10: var(--token-9SrcuYOqhFKM);
  --token-CPCnQ3sVgr8C: #d1d5db;
  --plasmic-token-basic-border: var(--token-CPCnQ3sVgr8C);
  --token-X4FiKkhgyz67: 0.75rem;
  --plasmic-token-font-sm: var(--token-X4FiKkhgyz67);
  --token-CO8RlAlGx0zx: 1;
  --plasmic-token-line-height-sm: var(--token-CO8RlAlGx0zx);
  --token-sI4elL_K_GlW: 1.25rem;
  --plasmic-token-font-lg: var(--token-sI4elL_K_GlW);
  --token-LZ3yPU9FOV91: 1.4;
  --plasmic-token-line-height-lg: var(--token-LZ3yPU9FOV91);
  --token-yPPVljTBraey: #dc2626;
  --plasmic-token-destructive-destructive: var(--token-yPPVljTBraey);
  --token-SJLKk4TI8Sd5: #e5e7eb;
  --plasmic-token-muted-muted-border: var(--token-SJLKk4TI8Sd5);
  --token-BRSM4MM0zBGC: #f3f4f6;
  --plasmic-token-muted-muted-soft: var(--token-BRSM4MM0zBGC);
  --token-RiXyNJl1lEpA: #dbeafe;
  --plasmic-token-brand-brand-soft: var(--token-RiXyNJl1lEpA);
}

.plasmic_default_styles {
  --mixin-KUDEXtKB62Fq5G2_font-family: "Inter", sans-serif;
  --mixin-KUDEXtKB62Fq5G2_font-size: 16px;
  --mixin-KUDEXtKB62Fq5G2_font-weight: 400;
  --mixin-KUDEXtKB62Fq5G2_font-style: normal;
  --mixin-KUDEXtKB62Fq5G2_color: var(--token-IOs4wk1cx7qv8eT);
  --mixin-KUDEXtKB62Fq5G2_text-align: left;
  --mixin-KUDEXtKB62Fq5G2_text-transform: none;
  --mixin-KUDEXtKB62Fq5G2_line-height: 1.5;
  --mixin-KUDEXtKB62Fq5G2_letter-spacing: normal;
  --mixin-KUDEXtKB62Fq5G2_white-space: pre-wrap;
  --mixin-KUDEXtKB62Fq5G2_user-select: text;
  --mixin-KUDEXtKB62Fq5G2_text-decoration-line: none;
  --mixin-KUDEXtKB62Fq5G2_text-overflow: clip;
  --mixin-h-uIRQQlrQsEqjM_font-weight: 600;
  --mixin-h-uIRQQlrQsEqjM_font-size: var(--token-Hi3tuh97XXyAuDz);
  --mixin-h-uIRQQlrQsEqjM_line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  --mixin-h-uIRQQlrQsEqjM_letter-spacing: -1px;
  --mixin-h-uIRQQlrQsEqjM_white-space: pre-wrap;
  --mixin-f1pWlBW4mYIzu6Z_font-size: var(--token-9G5SM2F6ADGgvTF);
  --mixin-f1pWlBW4mYIzu6Z_font-weight: 600;
  --mixin-f1pWlBW4mYIzu6Z_letter-spacing: -1px;
  --mixin-f1pWlBW4mYIzu6Z_line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  --mixin-f1pWlBW4mYIzu6Z_white-space: pre-wrap;
  --mixin-fICeg6ZY-KgtS0p_color: var(--token-AZBC5tFK07a8DV3);
  --mixin-fICeg6ZY-KgtS0p_transition-property: color;
  --mixin-fICeg6ZY-KgtS0p_transition-duration: 0.2s;
  --mixin-fICeg6ZY-KgtS0p_transition-timing-function: ease-in-out;
  --mixin-fICeg6ZY-KgtS0p_white-space: pre-wrap;
  --mixin-2dEJOUSrPK3vPRo_font-size: var(--token-yvx2q5VFKqvBxNc);
  --mixin-2dEJOUSrPK3vPRo_font-weight: 600;
  --mixin-2dEJOUSrPK3vPRo_letter-spacing: -0.8px;
  --mixin-2dEJOUSrPK3vPRo_line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  --mixin-2dEJOUSrPK3vPRo_white-space: pre-wrap;
  --mixin-fabbhbKNRcfCeGb_font-size: var(--token-Jhhay0F31-lTY91);
  --mixin-fabbhbKNRcfCeGb_font-weight: 600;
  --mixin-fabbhbKNRcfCeGb_letter-spacing: -0.5px;
  --mixin-fabbhbKNRcfCeGb_line-height: var(--token-f2GFyMCUtiJjuKU);
  --mixin-fabbhbKNRcfCeGb_white-space: pre-wrap;
  --mixin-IIJR1aDA0HdHwlf_background: linear-gradient(
    var(--token-nJQnmTwmF4SPdl6),
    var(--token-nJQnmTwmF4SPdl6)
  );
  --mixin-IIJR1aDA0HdHwlf_border-bottom-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-bottom-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-bottom-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-left-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-left-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-left-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-right-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-right-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-right-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-top-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-top-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-top-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-bottom-left-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_border-bottom-right-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_border-top-left-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_border-top-right-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_font-family: "Inconsolata";
  --mixin-IIJR1aDA0HdHwlf_padding-bottom: 4px;
  --mixin-IIJR1aDA0HdHwlf_padding-left: 8px;
  --mixin-IIJR1aDA0HdHwlf_padding-right: 8px;
  --mixin-IIJR1aDA0HdHwlf_padding-top: 4px;
  --mixin-IIJR1aDA0HdHwlf_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-IIJR1aDA0HdHwlf_color: var(--token-eKcWWhfvZ4_f9Lh);
  --mixin-IIJR1aDA0HdHwlf_white-space: pre-wrap;
  --mixin-pvMPPZ5C_Wmc5IA_border-left-color: var(--token-sHkWBDcvLab64_p);
  --mixin-pvMPPZ5C_Wmc5IA_border-left-style: solid;
  --mixin-pvMPPZ5C_Wmc5IA_border-left-width: 3px;
  --mixin-pvMPPZ5C_Wmc5IA_color: var(--token-PcBCP-f11VpIoWX);
  --mixin-pvMPPZ5C_Wmc5IA_padding-left: 10px;
  --mixin-pvMPPZ5C_Wmc5IA_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-pvMPPZ5C_Wmc5IA_white-space: pre-wrap;
  --mixin-qwhBWAvcBzcyxtd_background: linear-gradient(
    var(--token-nJQnmTwmF4SPdl6),
    var(--token-nJQnmTwmF4SPdl6)
  );
  --mixin-qwhBWAvcBzcyxtd_border-bottom-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-bottom-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-bottom-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-left-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-left-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-left-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-right-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-right-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-right-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-top-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-top-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-top-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-bottom-left-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_border-bottom-right-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_border-top-left-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_border-top-right-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_font-family: "Inconsolata";
  --mixin-qwhBWAvcBzcyxtd_padding-bottom: 4px;
  --mixin-qwhBWAvcBzcyxtd_padding-left: 8px;
  --mixin-qwhBWAvcBzcyxtd_padding-right: 8px;
  --mixin-qwhBWAvcBzcyxtd_padding-top: 4px;
  --mixin-qwhBWAvcBzcyxtd_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-qwhBWAvcBzcyxtd_white-space: pre-wrap;
  --mixin-mjShifBwQ_ME8gg_display: flex;
  --mixin-mjShifBwQ_ME8gg_flex-direction: column;
  --mixin-mjShifBwQ_ME8gg_align-items: stretch;
  --mixin-mjShifBwQ_ME8gg_justify-content: flex-start;
  --mixin-mjShifBwQ_ME8gg_list-style-position: outside;
  --mixin-mjShifBwQ_ME8gg_position: relative;
  --mixin-mjShifBwQ_ME8gg_list-style-type: disc;
  --mixin-mjShifBwQ_ME8gg_padding-left: 16px;
  --mixin-mjShifBwQ_ME8gg_white-space: pre-wrap;
  --mixin-medEuCdgXEHoGZV_display: flex;
  --mixin-medEuCdgXEHoGZV_flex-direction: column;
  --mixin-medEuCdgXEHoGZV_align-items: stretch;
  --mixin-medEuCdgXEHoGZV_justify-content: flex-start;
  --mixin-medEuCdgXEHoGZV_list-style-position: outside;
  --mixin-medEuCdgXEHoGZV_padding-left: 40px;
  --mixin-medEuCdgXEHoGZV_position: relative;
  --mixin-medEuCdgXEHoGZV_list-style-type: decimal;
  --mixin-medEuCdgXEHoGZV_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-medEuCdgXEHoGZV_white-space: pre-wrap;
  --mixin-2U3cIj4s81v7rqo_font-family: "Inter", sans-serif;
  --mixin-2U3cIj4s81v7rqo_font-size: var(--token-ueN_0TwhBMb9CkO);
  --mixin-2U3cIj4s81v7rqo_font-weight: 600;
  --mixin-2U3cIj4s81v7rqo_line-height: var(--token-btgzVWfPOZNm1sG);
  --mixin-2U3cIj4s81v7rqo_white-space: pre-wrap;
  --mixin-HGj53jAernv5xhH_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-HGj53jAernv5xhH_font-weight: 600;
  --mixin-HGj53jAernv5xhH_line-height: var(--token-btgzVWfPOZNm1sG);
  --mixin-HGj53jAernv5xhH_white-space: pre-wrap;
  --mixin-Q0IcMWzpenUJtNM_color: var(--token-PjtuKumWd61Gyyy);
  --mixin-Q0IcMWzpenUJtNM_text-decoration-line: underline;
  --mixin-Q0IcMWzpenUJtNM_white-space: pre-wrap;
  --mixin-zcecuYNRykTX4mY_white-space: pre-wrap;
  --mixin-t0PoiMIp13AOapI_white-space: pre-wrap;
  --mixin-2s_5IASZcfaX4LW_white-space: pre-wrap;
  --mixin-PA4NLZBtPK6nsS-_white-space: pre-wrap;
  --mixin-AU9KxcstOJWk9cK_white-space: pre-wrap;
  --mixin-ehQNbzktU_white-space: pre-wrap;
  --mixin-thUo4KsBx_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-KUDEXtKB62Fq5G2_font-family);
  font-size: var(--mixin-KUDEXtKB62Fq5G2_font-size);
  font-weight: var(--mixin-KUDEXtKB62Fq5G2_font-weight);
  font-style: var(--mixin-KUDEXtKB62Fq5G2_font-style);
  color: var(--mixin-KUDEXtKB62Fq5G2_color);
  text-align: var(--mixin-KUDEXtKB62Fq5G2_text-align);
  text-transform: var(--mixin-KUDEXtKB62Fq5G2_text-transform);
  line-height: var(--mixin-KUDEXtKB62Fq5G2_line-height);
  letter-spacing: var(--mixin-KUDEXtKB62Fq5G2_letter-spacing);
  white-space: var(--mixin-KUDEXtKB62Fq5G2_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  font-weight: var(--mixin-h-uIRQQlrQsEqjM_font-weight);
  font-size: var(--mixin-h-uIRQQlrQsEqjM_font-size);
  line-height: var(--mixin-h-uIRQQlrQsEqjM_line-height);
  letter-spacing: var(--mixin-h-uIRQQlrQsEqjM_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  font-size: var(--mixin-f1pWlBW4mYIzu6Z_font-size);
  font-weight: var(--mixin-f1pWlBW4mYIzu6Z_font-weight);
  letter-spacing: var(--mixin-f1pWlBW4mYIzu6Z_letter-spacing);
  line-height: var(--mixin-f1pWlBW4mYIzu6Z_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-fICeg6ZY-KgtS0p_color);
  transition-property: var(--mixin-fICeg6ZY-KgtS0p_transition-property);
  transition-duration: var(--mixin-fICeg6ZY-KgtS0p_transition-duration);
  transition-timing-function: var(
    --mixin-fICeg6ZY-KgtS0p_transition-timing-function
  );
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-size: var(--mixin-2dEJOUSrPK3vPRo_font-size);
  font-weight: var(--mixin-2dEJOUSrPK3vPRo_font-weight);
  letter-spacing: var(--mixin-2dEJOUSrPK3vPRo_letter-spacing);
  line-height: var(--mixin-2dEJOUSrPK3vPRo_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-size: var(--mixin-fabbhbKNRcfCeGb_font-size);
  font-weight: var(--mixin-fabbhbKNRcfCeGb_font-weight);
  letter-spacing: var(--mixin-fabbhbKNRcfCeGb_letter-spacing);
  line-height: var(--mixin-fabbhbKNRcfCeGb_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: var(--token-nJQnmTwmF4SPdl6);
  font-family: var(--mixin-IIJR1aDA0HdHwlf_font-family);
  font-size: var(--mixin-IIJR1aDA0HdHwlf_font-size);
  color: var(--mixin-IIJR1aDA0HdHwlf_color);
  border-radius: var(--mixin-IIJR1aDA0HdHwlf_border-top-left-radius)
    var(--mixin-IIJR1aDA0HdHwlf_border-top-right-radius)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-right-radius)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-left-radius);
  padding: var(--mixin-IIJR1aDA0HdHwlf_padding-top)
    var(--mixin-IIJR1aDA0HdHwlf_padding-right)
    var(--mixin-IIJR1aDA0HdHwlf_padding-bottom)
    var(--mixin-IIJR1aDA0HdHwlf_padding-left);
  border-top: var(--mixin-IIJR1aDA0HdHwlf_border-top-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-top-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-top-color);
  border-right: var(--mixin-IIJR1aDA0HdHwlf_border-right-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-right-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-right-color);
  border-bottom: var(--mixin-IIJR1aDA0HdHwlf_border-bottom-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-color);
  border-left: var(--mixin-IIJR1aDA0HdHwlf_border-left-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-left-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-pvMPPZ5C_Wmc5IA_color);
  padding-left: var(--mixin-pvMPPZ5C_Wmc5IA_padding-left);
  font-size: var(--mixin-pvMPPZ5C_Wmc5IA_font-size);
  border-left: var(--mixin-pvMPPZ5C_Wmc5IA_border-left-width)
    var(--mixin-pvMPPZ5C_Wmc5IA_border-left-style)
    var(--mixin-pvMPPZ5C_Wmc5IA_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: var(--token-nJQnmTwmF4SPdl6);
  font-family: var(--mixin-qwhBWAvcBzcyxtd_font-family);
  font-size: var(--mixin-qwhBWAvcBzcyxtd_font-size);
  border-radius: var(--mixin-qwhBWAvcBzcyxtd_border-top-left-radius)
    var(--mixin-qwhBWAvcBzcyxtd_border-top-right-radius)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-right-radius)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-left-radius);
  padding: var(--mixin-qwhBWAvcBzcyxtd_padding-top)
    var(--mixin-qwhBWAvcBzcyxtd_padding-right)
    var(--mixin-qwhBWAvcBzcyxtd_padding-bottom)
    var(--mixin-qwhBWAvcBzcyxtd_padding-left);
  border-top: var(--mixin-qwhBWAvcBzcyxtd_border-top-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-top-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-top-color);
  border-right: var(--mixin-qwhBWAvcBzcyxtd_border-right-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-right-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-right-color);
  border-bottom: var(--mixin-qwhBWAvcBzcyxtd_border-bottom-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-color);
  border-left: var(--mixin-qwhBWAvcBzcyxtd_border-left-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-left-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-mjShifBwQ_ME8gg_display);
  flex-direction: var(--mixin-mjShifBwQ_ME8gg_flex-direction);
  align-items: var(--mixin-mjShifBwQ_ME8gg_align-items);
  justify-content: var(--mixin-mjShifBwQ_ME8gg_justify-content);
  list-style-position: var(--mixin-mjShifBwQ_ME8gg_list-style-position);
  position: var(--mixin-mjShifBwQ_ME8gg_position);
  list-style-type: var(--mixin-mjShifBwQ_ME8gg_list-style-type);
  padding-left: var(--mixin-mjShifBwQ_ME8gg_padding-left);
  flex-column-gap: var(--mixin-mjShifBwQ_ME8gg_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-medEuCdgXEHoGZV_display);
  flex-direction: var(--mixin-medEuCdgXEHoGZV_flex-direction);
  align-items: var(--mixin-medEuCdgXEHoGZV_align-items);
  justify-content: var(--mixin-medEuCdgXEHoGZV_justify-content);
  list-style-position: var(--mixin-medEuCdgXEHoGZV_list-style-position);
  padding-left: var(--mixin-medEuCdgXEHoGZV_padding-left);
  position: var(--mixin-medEuCdgXEHoGZV_position);
  list-style-type: var(--mixin-medEuCdgXEHoGZV_list-style-type);
  font-size: var(--mixin-medEuCdgXEHoGZV_font-size);
  flex-column-gap: var(--mixin-medEuCdgXEHoGZV_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-2U3cIj4s81v7rqo_font-family);
  font-size: var(--mixin-2U3cIj4s81v7rqo_font-size);
  font-weight: var(--mixin-2U3cIj4s81v7rqo_font-weight);
  line-height: var(--mixin-2U3cIj4s81v7rqo_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-size: var(--mixin-HGj53jAernv5xhH_font-size);
  font-weight: var(--mixin-HGj53jAernv5xhH_font-weight);
  line-height: var(--mixin-HGj53jAernv5xhH_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-Q0IcMWzpenUJtNM_color);
  text-decoration-line: var(--mixin-Q0IcMWzpenUJtNM_text-decoration-line);
}

:where(.root_reset) a:where(.a):active,
a:where(.root_reset.a):active,
:where(.root_reset .__wab_expr_html_text) a:active,
:where(.root_reset_tags) a:active,
a:where(.root_reset_tags):active {
}

:where(.root_reset) a:where(.a):focus,
a:where(.root_reset.a):focus,
:where(.root_reset .__wab_expr_html_text) a:focus,
:where(.root_reset_tags) a:focus,
a:where(.root_reset_tags):focus {
}

:where(.root_reset) blockquote:where(.blockquote):focus,
blockquote:where(.root_reset.blockquote):focus,
:where(.root_reset .__wab_expr_html_text) blockquote:focus,
:where(.root_reset_tags) blockquote:focus,
blockquote:where(.root_reset_tags):focus {
}

:where(.root_reset) blockquote:where(.blockquote):hover,
blockquote:where(.root_reset.blockquote):hover,
:where(.root_reset .__wab_expr_html_text) blockquote:hover,
:where(.root_reset_tags) blockquote:hover,
blockquote:where(.root_reset_tags):hover {
}

:where(.root_reset) code:where(.code):hover,
code:where(.root_reset.code):hover,
:where(.root_reset .__wab_expr_html_text) code:hover,
:where(.root_reset_tags) code:hover,
code:where(.root_reset_tags):hover {
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
