.root {
  display: flex;
  width: 32px;
  height: 32px;
  background: #9becd7;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  border-radius: 100%;
}
.rootsize_small {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.rootsize_large {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
}
.roothasOutline {
  box-shadow: 0px 0px 0px 2px var(--token-NPNMQf7VB8u7Kel);
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  top: auto;
  mix-blend-mode: color-burn;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetInitials {
  font-size: 14px;
  font-weight: 500;
  color: var(--token-PcBCP-f11VpIoWX);
  text-align: center;
}
.slotTargetInitialssize_small {
  font-size: 11px;
  font-weight: 600;
}
.slotTargetInitialssize_large {
  font-size: 18px;
}
