.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.hoverBg {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: 0;
  top: 0;
  background: var(--token-NPNMQf7VB8u7Kel);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  right: 0;
  bottom: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 6px;
}
.root:hover .hoverBg {
  background: var(--token-enYJIO6w3sEL0NO);
}
.freeBox___30D0D {
  display: flex;
  flex-direction: row;
  width: 120px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.freeBox__hSobP {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.slotTargetUsageType {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
}
.slotTargetUsageType > :global(.__wab_text),
.slotTargetUsageType > :global(.__wab_expr_html_text),
.slotTargetUsageType > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetUsageType > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__p0ZBk {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 120px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__xBrq {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__xscVi {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__v49A7 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__ymR4R {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 120px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__redPo {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__cBh {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__vwk2U {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-6NXubVByI);
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__pKsu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.baseVacancy:global(.__wab_instance) {
  position: relative;
}
.svg__oq8N8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wdmsv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nLzwR {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__vfm9X {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__wyyfi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__du31 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox___6Khxv {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.algoButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__t6Rjk {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-NBmi1EBeYuE24RY);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.freeBox___45FTx {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___45FTx > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___45FTx > :global(.__wab_flex-container) > *,
.freeBox___45FTx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___45FTx > :global(.__wab_flex-container) > picture > img,
.freeBox___45FTx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.yearDuration:global(.__wab_instance) {
  position: relative;
}
.svg___1W4Qs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uSo0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xe1DM {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__suDbB {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__hcX12 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zA6IF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox___8Yqnf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___8Yqnf > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___8Yqnf > :global(.__wab_flex-container) > *,
.freeBox___8Yqnf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8Yqnf > :global(.__wab_flex-container) > picture > img,
.freeBox___8Yqnf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBoxshowYear_year1___8Yqnfj4IYp {
  display: flex;
}
.vacancyYear1:global(.__wab_instance) {
  position: relative;
}
.svg___8BYm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___22M3U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vRmp3 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___7Mbrm {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___96Tfv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dh6H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__kqT1 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__kqT1 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__kqT1 > :global(.__wab_flex-container) > *,
.freeBox__kqT1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kqT1 > :global(.__wab_flex-container) > picture > img,
.freeBox__kqT1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBoxshowYear_year2__kqT1UvVhi {
  display: flex;
}
.vacancyYear2:global(.__wab_instance) {
  position: relative;
}
.svg__syPl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uVvom {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fZbcQ {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__fdHbI {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__bfXAp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gPx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
