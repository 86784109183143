.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow: auto;
  position: relative;
  justify-self: flex-start;
  padding: var(--token-7dIrE6133);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    padding: var(--token-cTBZAIcMp);
  }
}
@media (max-width: 480px) {
  .root {
    padding: var(--token-nPzqyQpH_);
  }
}
.freeBox__utHo7 {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #0f182acc;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.freeBox__k1Gr {
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
  display: flex;
  flex-direction: column;
  padding-top: var(--token-3S5TLMpb5);
  padding-right: var(--token-3S5TLMpb5);
  padding-bottom: var(--token-3S5TLMpb5);
  left: auto;
  top: auto;
  position: relative;
  right: auto;
  width: 100%;
  min-width: 0;
  border-radius: 8px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__k1Gr > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-hFcnMUlxM));
  height: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox__k1Gr > :global(.__wab_flex-container) > *,
.freeBox__k1Gr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__k1Gr > :global(.__wab_flex-container) > picture > img,
.freeBox__k1Gr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-hFcnMUlxM);
}
.freeBox__zc2De {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-self: auto;
  min-width: 0;
}
.freeBox__zc2De > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__zc2De > :global(.__wab_flex-container) > *,
.freeBox__zc2De > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zc2De > :global(.__wab_flex-container) > picture > img,
.freeBox__zc2De
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox___7Xcqd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: var(--token-hFcnMUlxM);
  min-width: 0;
}
.text {
  width: 100%;
  height: auto;
  max-width: 98%;
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
  min-width: 0;
}
.closeButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mOuVc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__ps3Ef {
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.freeBox__u9S5U {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 0;
  min-height: 0;
}
.comparables:global(.__wab_instance) {
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__ywhyO {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 80px;
  left: auto;
  top: 0;
  background: linear-gradient(
      90deg,
      #ffffff00 0%,
      var(--token-NPNMQf7VB8u7Kel) 90%
    )
    0% 0% / 100% 100% repeat;
  pointer-events: none;
  z-index: 1;
  right: -1px;
  bottom: 0;
  min-width: 0;
}
