.root:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button___4RDxw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pHbNp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zmJ9C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__orUsK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ev0W8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rH6Ya {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___3DKm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tvDbl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bM8Hz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__ze7RM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-MZ_89NRuN) var(--token-hFcnMUlxM);
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.marktwertTableRow__ncxBg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__bDNkH:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__wqLfU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__syTk8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__thzHc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__oHn3X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell___7NnmN:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__draMo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__eb5Vq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg___0Ab8N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mDv0E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jSpM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__c1MwV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__fGpCx:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__tHwcx:global(.__wab_instance) {
  max-width: 100%;
}
.ernfondTableCell17:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__mlP11:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__vv7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formInputTableCell__eIg3W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__gRaE0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cKNxp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__c2XRx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xxgyT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.formTextTableCell__r9RV:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__hoEUc:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__u0Agv:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  max-width: 100%;
  min-width: 0;
}
.formTextTableCell__vRg7Y:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__lsVNc:global(.__wab_instance) {
  max-width: 100%;
}
.formTextTableCell__ev0EF:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.formTextTableCell__m88VP:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.freeBox___6GE5C {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  padding-top: var(--token-8IFSbVVlb);
  padding-bottom: var(--token-8IFSbVVlb);
  min-width: 0;
}
.bewirtschaftungskostenUebernehmen:global(.__wab_instance) {
  max-width: 100%;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  padding-bottom: 0px;
  padding-left: 0px;
}
.svg__kyH6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
