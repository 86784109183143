.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  padding-top: var(--token-H3JPdTVdV);
  padding-bottom: var(--token-3S5TLMpb5);
  width: 100%;
  position: relative;
  min-width: 0;
}
.rootstate_isLoading {
  display: none;
}
.rootstate_unavailable {
  display: none;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  font-weight: 500;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-IgvLORwjhxLX5NA);
  text-transform: uppercase;
  letter-spacing: 2px;
  min-width: 0;
}
.avarageComparables:global(.__wab_instance) {
  max-width: 100%;
}
.avarageComparablestop20Avarage:global(.__wab_instance) {
  display: none;
}
.avarageComparablesTop10:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.avarageComparablesTop10top20Avarage:global(.__wab_instance) {
  display: flex;
}
.avarageComparablesTop20:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.avarageComparablesTop20top20Avarage:global(.__wab_instance) {
  display: flex;
}
