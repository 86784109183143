.root {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-row-gap: var(--token-SAmzyWXiq);
  grid-column-gap: var(--token-cTBZAIcMp);
  padding-top: var(--token-H3JPdTVdV);
  padding-bottom: var(--token-H3JPdTVdV);
  position: relative;
  min-width: 0;
}
.hoverBg {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: -8px;
  top: 0;
  background: var(--token-NPNMQf7VB8u7Kel);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  right: -8px;
  bottom: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
}
.root:hover .hoverBg {
  background: var(--token-enYJIO6w3sEL0NO);
}
.freeBox___2Pn6A {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 8;
  min-width: 0;
}
.referenz:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.textInput:global(.__wab_instance) {
  position: relative;
}
.svg__ddO5D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__m4DdL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ka4Fc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3N8Wo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__jt57R:global(.__wab_instance) {
  position: relative;
}
.freeBox___2NVfx {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 4;
  min-width: 0;
}
.egrid:global(.__wab_instance) {
  max-width: 100%;
}
.textInput2:global(.__wab_instance) {
  position: relative;
}
.svg__hWqWg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__b8UgW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nsyf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___0BkeR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__k5B83:global(.__wab_instance) {
  position: relative;
}
.freeBox__tpUfi {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 5;
  min-width: 0;
}
.land:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.textInput3:global(.__wab_instance) {
  position: relative;
}
.svg__miDjj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__d9X8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__j4UtJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__rClTf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__plR6X:global(.__wab_instance) {
  position: relative;
}
.freeBox__kdVnw {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 5;
  min-width: 0;
}
.oberirdischeGf:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.textInput4:global(.__wab_instance) {
  position: relative;
}
.svg__gwh0B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kDkH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nXgAq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__egmbl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__taSg:global(.__wab_instance) {
  position: relative;
}
.freeBox__nWnH {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  grid-column-end: span 2;
}
.freeBox__nWnH > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: calc(0px - var(--token-klOkpNg9M));
  width: calc(100% + var(--token-klOkpNg9M));
}
.freeBox__nWnH > :global(.__wab_flex-container) > *,
.freeBox__nWnH > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nWnH > :global(.__wab_flex-container) > picture > img,
.freeBox__nWnH
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-klOkpNg9M);
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.svg___4ESx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2NYoN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.svg__rlVnR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yDjGz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
