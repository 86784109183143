.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  cursor: pointer;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
}
.rootisDisabled {
  cursor: not-allowed;
}
.roottextAlignement_left {
  flex-direction: row-reverse;
}
.roottextAlignement_left > :global(.__wab_flex-container) {
  flex-direction: row-reverse;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.roottextAlignement_left > :global(.__wab_flex-container) > *,
.roottextAlignement_left
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.roottextAlignement_left > :global(.__wab_flex-container) > picture > img,
.roottextAlignement_left
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.root___focusVisibleWithin {
  outline: none;
}
.root:focus-within {
  outline: none;
}
.toggle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 24px;
  flex-shrink: 0;
}
.root .toggle___focusVisibleWithin {
  outline: none;
}
.root:focus-within .toggle {
  outline: none;
}
.track {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 20px;
  transition-property: background;
  transition-duration: 0.2s;
  background: var(--token-sHkWBDcvLab64_p);
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.2s;
  border-radius: 12px;
  border: 1px solid #ffffff00;
}
.trackisDisabled {
  background: var(--token-EdVWKDB6CssMfRE);
}
.trackisChecked {
  background: var(--token-AZBC5tFK07a8DV3);
}
.root:hover .track {
  background: var(--token-5eiHu7b54PkQh5O);
}
.root .track___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.root:focus-within .track {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.root:active .track {
  background: var(--token-IgvLORwjhxLX5NA);
}
.rootisChecked:hover .trackisChecked {
  background: #0081f1;
}
.thumb {
  display: block;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: 3px;
  top: 5px;
  width: 14px;
  height: 14px;
  transition-property: all;
  transition-duration: 0.2s;
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 1px 1px 0px #1e2a3b1a;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
}
.thumbisDisabled {
  background: var(--token-nJQnmTwmF4SPdl6);
}
.thumbisChecked {
  left: 17px;
  right: auto;
  border-style: none;
}
.root .thumb___focusVisibleWithin {
  outline: none;
}
.root:focus-within .thumb {
  outline: none;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 8px;
  max-width: auto;
  overflow: visible;
  flex-wrap: wrap;
}
.root .labelContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetChildren {
  white-space: pre-wrap;
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.slotTargetChildrenisDisabled {
  color: var(--token-5eiHu7b54PkQh5O);
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
