.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.freeBox___5AXz {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-MZ_89NRuN);
  grid-row-gap: var(--token-MZ_89NRuN);
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox___5AXz {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
.renovationType:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 5;
}
@media (max-width: 480px) {
  .renovationType:global(.__wab_instance) {
    grid-column-end: span 6;
  }
}
.select:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  min-width: 0;
}
.svg___0YLjd {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.option__epa6:global(.__wab_instance) {
  position: relative;
}
.svg__ySqU4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__aM5A3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___6PMd7:global(.__wab_instance) {
  position: relative;
}
.svg__yYuoU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__nd5Qa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__a0NY9:global(.__wab_instance) {
  position: relative;
}
.svg__lRfJc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__ytvSy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__jkHUw:global(.__wab_instance) {
  position: relative;
}
.svg__mrFKk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__aHDb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___7Pzqo:global(.__wab_instance) {
  position: relative;
}
.svg__d4C1H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___2CYj8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___3Zz9F:global(.__wab_instance) {
  position: relative;
}
.svg__mLqg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___3HLy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__pgEz:global(.__wab_instance) {
  position: relative;
}
.svg__uCuEs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__s5U3J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__qmul2:global(.__wab_instance) {
  position: relative;
}
.svg__jh2Ok {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__vxP8L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___8IAos:global(.__wab_instance) {
  position: relative;
}
.svg__ytW8L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__dFhqa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__j4BAy:global(.__wab_instance) {
  position: relative;
}
.svg___97BOq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___2WIf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__nuWkD:global(.__wab_instance) {
  position: relative;
}
.freeBox__lq8J7 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 7;
  min-width: 0;
}
.freeBox__lq8J7 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MZ_89NRuN));
  width: calc(100% + var(--token-MZ_89NRuN));
}
.freeBox__lq8J7 > :global(.__wab_flex-container) > *,
.freeBox__lq8J7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lq8J7 > :global(.__wab_flex-container) > picture > img,
.freeBox__lq8J7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MZ_89NRuN);
}
@media (max-width: 480px) {
  .freeBox__lq8J7 {
    grid-column-end: span 6;
  }
}
.renovationYear:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.svg__sw8AV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8Fv1C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lJvhM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  min-width: 0;
}
.text__s7Im {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dJpZd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__bjgGp:global(.__wab_instance) {
  position: relative;
}
.renovationAmount:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.svg__vVaph {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__l29Uo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5RxQy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  min-width: 0;
}
.text__oclIi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__n7Htu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__dqHxU:global(.__wab_instance) {
  position: relative;
}
.deleteButton:global(.__wab_instance) {
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .deleteButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .deleteButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ibEts {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
