.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox__cfn9K {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  grid-row-gap: var(--token-MZ_89NRuN);
  min-width: 0;
}
.freeBox__vcWgs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.text__gu21 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__iS4Bz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
  min-width: 0;
}
.text__rsjS {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__es58E {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 6;
  min-width: 0;
}
.text___7Dbd9 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox___8WGhH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
  min-width: 0;
}
.text__dSfA {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__db7Dw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 5;
  min-width: 0;
}
.text__yfZrQ {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__xTfNw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
  min-width: 0;
}
.text___8G0A {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__rYaLr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.dcfObjectRow__fmpRr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dcfObjectRow__iwmej:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dcfObjectRow__yGcWt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__weasj {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding-top: var(--token-3S5TLMpb5);
  min-width: 0;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__weasj > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__weasj > :global(.__wab_flex-container) > *,
.freeBox__weasj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__weasj > :global(.__wab_flex-container) > picture > img,
.freeBox__weasj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.addButton:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3VnNm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sveDi {
  height: auto;
}
.svg__pw95 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.objectsFromGwrButton:global(.__wab_instance) {
  max-width: 100%;
}
.svg__wsioB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1V5Ij {
  height: auto;
}
.svg__mf5Ui {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
