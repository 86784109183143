.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox__pPtT {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  grid-row-gap: var(--token-MZ_89NRuN);
  min-width: 0;
}
.freeBox__u260E {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 8;
  min-width: 0;
}
.text__qJHf1 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__nlM3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.text__aKnrd {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
}
.freeBox__sy36I {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  grid-column-end: span 5;
  min-width: 0;
}
.text__q3Dfj {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox___4Pq39 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  grid-column-end: span 5;
  min-width: 0;
}
.text___5XfNl {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.freeBox__paqke {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__paqke > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-nPzqyQpH_));
  height: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__paqke > :global(.__wab_flex-container) > *,
.freeBox__paqke > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__paqke > :global(.__wab_flex-container) > picture > img,
.freeBox__paqke
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-nPzqyQpH_);
}
.freeBox__z73Yf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__fs85G {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  grid-row-gap: var(--token-SAmzyWXiq);
  padding-top: var(--token-H3JPdTVdV);
  padding-bottom: var(--token-nPzqyQpH_);
  min-width: 0;
}
.freeBox__d1476 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 8;
  min-width: 0;
}
.freeBox__sxDUn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.freeBox__jTd0N {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 5;
  padding-right: var(--token-H3JPdTVdV);
  min-width: 0;
}
.freeBox__jTd0N > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  min-width: 0;
  margin-left: calc(0px - var(--token-B5jiJYDEm));
  width: calc(100% + var(--token-B5jiJYDEm));
}
.freeBox__jTd0N > :global(.__wab_flex-container) > *,
.freeBox__jTd0N > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jTd0N > :global(.__wab_flex-container) > picture > img,
.freeBox__jTd0N
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-B5jiJYDEm);
}
.text__dM0Hr {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.text__noTq {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.span__pGxv7 {
  font-weight: 500;
}
.freeBox__fS76K {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 5;
  padding-right: var(--token-B5jiJYDEm);
  min-width: 0;
}
.freeBox__fS76K > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__fS76K > :global(.__wab_flex-container) > *,
.freeBox__fS76K > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fS76K > :global(.__wab_flex-container) > picture > img,
.freeBox__fS76K
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.text___7XaBk {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.text__hxv9O {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.span__a8MZt {
  font-weight: 500;
}
.freeBox__ad35J {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  height: auto;
  grid-column-gap: var(--token-cTBZAIcMp);
  grid-row-gap: var(--token-SAmzyWXiq);
  padding-top: var(--token-H3JPdTVdV);
  padding-bottom: var(--token-nPzqyQpH_);
  width: 100%;
  min-width: 0;
}
.freeBox__vlSto {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 8;
  min-width: 0;
}
.freeBox__qjv1H {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
  min-width: 0;
}
.freeBox__zah5Q {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 5;
  padding-right: var(--token-H3JPdTVdV);
  min-width: 0;
}
.freeBox__zah5Q > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  min-width: 0;
  margin-left: calc(0px - var(--token-B5jiJYDEm));
  width: calc(100% + var(--token-B5jiJYDEm));
}
.freeBox__zah5Q > :global(.__wab_flex-container) > *,
.freeBox__zah5Q > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zah5Q > :global(.__wab_flex-container) > picture > img,
.freeBox__zah5Q
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-B5jiJYDEm);
}
.text__f0Hnw {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.endSquareMeter3 {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.span__qrWEj {
  font-weight: 500;
}
.freeBox__lrXsm {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 5;
  padding-right: var(--token-B5jiJYDEm);
  min-width: 0;
}
.freeBox__lrXsm > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox__lrXsm > :global(.__wab_flex-container) > *,
.freeBox__lrXsm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lrXsm > :global(.__wab_flex-container) > picture > img,
.freeBox__lrXsm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.text__c5Jb {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
}
.endSquareMeter4 {
  position: relative;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.span__uVp5X {
  font-weight: 500;
}
.freeBox__xxpjU {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding-top: var(--token-3S5TLMpb5);
  min-width: 0;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.addButton:global(.__wab_instance) {
  max-width: 100%;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wsKmV {
  height: auto;
}
.svg__o7LCp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
