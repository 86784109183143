.root {
  display: inline-flex;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.statusIndicatorTag:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.statusIndicatorTagtype_success:global(.__wab_instance) {
  flex-shrink: 0;
}
.statusIndicatorTagtype_warning:global(.__wab_instance) {
  flex-shrink: 0;
}
.statusIndicatorTagtype_error:global(.__wab_instance) {
  flex-shrink: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.slotTargetTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-PcBCP-f11VpIoWX);
}
.slotTargetTitletype_success {
  color: var(--token-CXh3DsvYUDbb4NE);
}
.slotTargetTitletype_warning {
  color: var(--token-Hken4BJDt);
}
.slotTargetTitletype_error {
  color: var(--token-Y_1QycPpMrs0BhH);
}
.slotTargetInfoText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
}
.slotTargetInfoTexttype_success {
  color: var(--token-eoOGiUMJC4PLiTG);
}
.slotTargetInfoTexttype_warning {
  color: var(--token-mOJuRJhlC);
}
.slotTargetInfoTexttype_error {
  color: var(--token-k8MBdjDfsyp6Vsa);
}
