.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.freeBox__wYhq0 {
  display: flex;
  flex-direction: row;
  width: 140px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  flex-shrink: 0;
}
.freeBox___9Yd6P {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBoxisEditable___9Yd6PmKgpA {
  display: none;
}
.slotTargetUsageType {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
}
.slotTargetUsageType > :global(.__wab_text),
.slotTargetUsageType > :global(.__wab_expr_html_text),
.slotTargetUsageType > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetUsageType > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__bbmGh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.freeBoxisEditable__bbmGHmKgpA {
  display: flex;
}
.usageTypeInput:global(.__wab_instance) {
  position: relative;
}
.svg___3UdrN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bGu7E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wQkf {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__p6L90 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___50EHf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aVwRo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__k78H {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 120px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.units:global(.__wab_instance) {
  position: relative;
}
.svg__e4GoA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hlEvb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rkL {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___4PU4A {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__lWs8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qirFx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox___6N7B5 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 80px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.area:global(.__wab_instance) {
  position: relative;
}
.svg__fcVxY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5YxaC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__h35Dd {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__r7BAl {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___66Z7A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mUi7X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox___1DLpX {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.rentActual:global(.__wab_instance) {
  position: relative;
}
.svg__hborl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wulCt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fV9Z {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__unLw9 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__tbykk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bQlgt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__ulBj6 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.rentTarget:global(.__wab_instance) {
  position: relative;
}
.svg__ekbMu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__k5L0S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7Ag {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__jHxX {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__ouMho {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yYfVw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__ad4P {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 80px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__dOckM {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__jzhLi {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100px;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__jzhLi > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__jzhLi > :global(.__wab_flex-container) > *,
.freeBox__jzhLi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jzhLi > :global(.__wab_flex-container) > picture > img,
.freeBox__jzhLi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.rentMarket:global(.__wab_instance) {
  position: relative;
}
.svg__od5Yj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qnqfV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ssxAe {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___5HwNy {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__jPzr5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__epU70 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__vsugr {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-enYJIO6w3sEL0NO);
  width: 110px;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.algoButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__jUhpY {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-NBmi1EBeYuE24RY);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.freeBox___9Tjl2 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 80px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__by488 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__hqBo1 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.text__g9VhD {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  padding-left: 0px;
  color: var(--token-6NXubVByI);
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox__wzsd {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 90px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__wzsd > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__wzsd > :global(.__wab_flex-container) > *,
.freeBox__wzsd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wzsd > :global(.__wab_flex-container) > picture > img,
.freeBox__wzsd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.yearDuration:global(.__wab_instance) {
  position: relative;
}
.svg__xDb2E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uNmR9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__laTmG {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___22NdT {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__tVEyJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__agKa3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__osBhZ {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__osBhZ > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-klOkpNg9M));
  width: calc(100% + var(--token-klOkpNg9M));
}
.freeBox__osBhZ > :global(.__wab_flex-container) > *,
.freeBox__osBhZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__osBhZ > :global(.__wab_flex-container) > picture > img,
.freeBox__osBhZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-klOkpNg9M);
}
.freeBoxisEditable__osBhZmKgpA {
  display: flex;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sUiS4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
