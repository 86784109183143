.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background: var(--token-nJQnmTwmF4SPdl6);
  position: relative;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  max-width: 1000px;
  min-width: 0;
  padding: 72px 40px 104px;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-xZRZ7hK_d));
  height: calc(100% + var(--token-xZRZ7hK_d));
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-xZRZ7hK_d);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox {
    max-width: none;
    padding-top: 56px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 72px);
    height: calc(100% + 72px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox > :global(.__wab_flex-container) > *,
  .freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox > :global(.__wab_flex-container) > picture > img,
  .freeBox
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 72px;
  }
}
@media (max-width: 480px) {
  .freeBox {
    padding: 0px 0px 72px;
  }
}
@media (max-width: 480px) {
  .freeBox > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 48px);
    height: calc(100% + 48px);
  }
}
@media (max-width: 480px) {
  .freeBox > :global(.__wab_flex-container) > *,
  .freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox > :global(.__wab_flex-container) > picture > img,
  .freeBox
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 48px;
  }
}
.freeBoxnoPaddingTop {
  padding-top: 0px;
}
.freeBoxfullWidth {
  max-width: 2000px;
}
