.root {
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 1px 3px -1px #00000014;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  transition-property: box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  min-width: 0;
  -webkit-transition-property: box-shadow;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  border-radius: 8px;
  padding: var(--token-MZ_89NRuN);
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-xZRZ7hK_d));
  width: calc(100% + var(--token-xZRZ7hK_d));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-xZRZ7hK_d);
}
.root:hover {
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
}
.freeBox__ls8FC {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__ls8FC > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__ls8FC > :global(.__wab_flex-container) > *,
.freeBox__ls8FC > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ls8FC > :global(.__wab_flex-container) > picture > img,
.freeBox__ls8FC
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBox__sr5Qw {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 2px;
  min-width: 0;
}
.freeBox__sr5Qw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__sr5Qw > :global(.__wab_flex-container) > *,
.freeBox__sr5Qw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sr5Qw > :global(.__wab_flex-container) > picture > img,
.freeBox__sr5Qw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.slotTargetAdress {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-IOs4wk1cx7qv8eT);
  white-space: pre;
}
.slotTargetAdress > :global(.__wab_text),
.slotTargetAdress > :global(.__wab_expr_html_text),
.slotTargetAdress > :global(.__wab_slot-string-wrapper),
.slotTargetAdress > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetAdress > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetAdress > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetAdress > *,
.slotTargetAdress > :global(.__wab_slot) > *,
.slotTargetAdress > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetAdress > picture > img,
.slotTargetAdress > :global(.__wab_slot) > picture > img,
.slotTargetAdress > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetAdress
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.freeBox__y4WbZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 22px;
  max-width: 100%;
  background: var(--token-nJQnmTwmF4SPdl6);
  border-radius: 200px;
  padding: 4px 8px;
}
.slotTargetType {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.freeBox__sLrpa {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__sLrpa > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__sLrpa > :global(.__wab_flex-container) > *,
.freeBox__sLrpa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sLrpa > :global(.__wab_flex-container) > picture > img,
.freeBox__sLrpa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__adn4R {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  overflow: hidden;
  min-width: 0;
}
.text___41OGf {
  position: relative;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
  white-space: pre;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.slotTargetRefNr {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  white-space: pre;
}
.slotTargetRefNr > :global(.__wab_text),
.slotTargetRefNr > :global(.__wab_expr_html_text),
.slotTargetRefNr > :global(.__wab_slot-string-wrapper),
.slotTargetRefNr > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetRefNr > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetRefNr > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetRefNr > *,
.slotTargetRefNr > :global(.__wab_slot) > *,
.slotTargetRefNr > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetRefNr > picture > img,
.slotTargetRefNr > :global(.__wab_slot) > picture > img,
.slotTargetRefNr > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetRefNr
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.freeBox___6Nuiy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  overflow: hidden;
  min-width: 0;
}
.text__fO2I8 {
  position: relative;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
  white-space: pre;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
}
.slotTargetVersion {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  white-space: pre;
}
.slotTargetVersion > :global(.__wab_text),
.slotTargetVersion > :global(.__wab_expr_html_text),
.slotTargetVersion > :global(.__wab_slot-string-wrapper),
.slotTargetVersion > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetVersion > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetVersion > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetVersion > *,
.slotTargetVersion > :global(.__wab_slot) > *,
.slotTargetVersion > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetVersion > picture > img,
.slotTargetVersion > :global(.__wab_slot) > picture > img,
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetVersion
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.freeBox__fxRob {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__fxRob > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__fxRob > :global(.__wab_flex-container) > *,
.freeBox__fxRob > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fxRob > :global(.__wab_flex-container) > picture > img,
.freeBox__fxRob
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.slotTargetDate {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
