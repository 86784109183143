.root {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 945px;
  padding-right: var(--token-7dIrE6133);
  padding-left: var(--token-hFcnMUlxM);
  position: relative;
  justify-self: flex-start;
  overflow: visible;
}
.rootstate_unavailable {
  height: auto;
}
.comparablesTableHeader:global(.__wab_instance) {
  position: sticky;
  margin-bottom: 8px;
  left: 0px;
  top: 0px;
  align-self: flex-start;
  z-index: 1;
}
.freeBox___66IXx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding-top: var(--token-3S5TLMpb5);
  padding-bottom: var(--token-H3JPdTVdV);
  min-width: 0;
}
.freeBoxstate_isLoading___66IXxSWG {
  display: none;
}
.freeBoxstate_unavailable___66IXxe2Usm {
  display: none;
}
.text__abemz {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  color: var(--token-IgvLORwjhxLX5NA);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.currentRating:global(.__wab_instance) {
  max-width: 100%;
}
.comparablesAvarage:global(.__wab_instance) {
  position: relative;
}
.comparablesAvaragestate_isLoading:global(.__wab_instance) {
  display: none;
}
.comparablesAvaragestate_unavailable:global(.__wab_instance) {
  display: none;
}
.text__i4Kpy {
  position: relative;
  width: 100%;
  height: auto;
  font-weight: 500;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-IgvLORwjhxLX5NA);
  text-transform: uppercase;
  letter-spacing: 2px;
  min-width: 0;
}
.textstate_isLoading__i4KpySWG {
  display: none;
}
.textstate_unavailable__i4KpYe2Usm {
  display: none;
}
.comparables {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.comparablesstate_isLoading {
  display: none;
}
.comparablesstate_unavailable {
  display: none;
}
.comparablesRow___5PKsE:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow__c4PQr:global(.__wab_instance) {
  max-width: 100%;
}
.comparablesRow__rr2Cm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow__jRqwp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow___1AScM:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow__utinC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow__lz2LS:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow__nEAiw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow__p0Ry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.comparablesRow___37CDp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___9U2Hx {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-3S5TLMpb5);
  padding-bottom: var(--token-3S5TLMpb5);
  min-width: 0;
}
.freeBox___9U2Hx > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-H3JPdTVdV));
  width: calc(100% + var(--token-H3JPdTVdV));
}
.freeBox___9U2Hx > :global(.__wab_flex-container) > *,
.freeBox___9U2Hx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9U2Hx > :global(.__wab_flex-container) > picture > img,
.freeBox___9U2Hx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-H3JPdTVdV);
}
.freeBoxstate_isLoading___9U2HxSWG {
  display: flex;
}
.freeBoxstate_unavailable___9U2Hxe2Usm {
  display: flex;
}
.banner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: auto;
}
