.select:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.navProfile:global(.__wab_instance) {
  position: relative;
}
.option__s47C:global(.__wab_instance) {
  position: relative;
}
.svg___7CxTe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rad4N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-5eiHu7b54PkQh5O);
}
.option__zvd1F:global(.__wab_instance) {
  position: relative;
}
.svg__cZdlL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qu4Y9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-5eiHu7b54PkQh5O);
}
.languageSelect:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option__t0U35:global(.__wab_instance) {
  position: relative;
}
.svg___8VtK1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__i2W1Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__mvWzx:global(.__wab_instance) {
  position: relative;
}
.svg__sLiO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__ohz3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__p9864:global(.__wab_instance) {
  position: relative;
}
.svg___2TbMj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__jJgWi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__a6CP5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .option__a6CP5:global(.__wab_instance) {
    height: 100%;
    min-height: 0;
  }
}
.svg__b2CVh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__a6Ced {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__nw3W {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
