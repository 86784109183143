.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding-top: var(--token-MZ_89NRuN);
  padding-bottom: var(--token-MZ_89NRuN);
  position: relative;
  min-width: 0;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.rootisLast {
  border-bottom-style: none;
}
.freeBox__pfwit {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBoxstate_declared__pfwiTnJxrM {
  width: 100%;
  min-width: 0;
}
.freeBox__bNo5A {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__bNo5A > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-MhDGai0bW));
  width: calc(100% + var(--token-MhDGai0bW));
}
.freeBox__bNo5A > :global(.__wab_flex-container) > *,
.freeBox__bNo5A > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bNo5A > :global(.__wab_flex-container) > picture > img,
.freeBox__bNo5A
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-MhDGai0bW);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__bNo5A {
    padding-left: var(--token-MZ_89NRuN);
  }
}
@media (max-width: 480px) {
  .freeBox__bNo5A {
    padding-left: var(--token-MZ_89NRuN);
  }
}
.svg___3LbVm {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-5eiHu7b54PkQh5O);
  left: -32px;
  top: auto;
  height: 1em;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .svg___3LbVm {
    left: -14px;
  }
}
@media (max-width: 480px) {
  .svg___3LbVm {
    width: 16px;
    height: 16px;
    position: absolute;
    left: -12px;
    top: auto;
    flex-shrink: 0;
  }
}
.svgstate_declared___3LbVMnJxrM {
  display: none;
}
.svgstate_undeclared___3LbVmeAo5Q {
  color: var(--token-Hken4BJDt);
}
.slotTargetTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.slotTargetTitlestate_undeclared {
  color: var(--token-Hken4BJDt);
}
.freeBox__pLXqw {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__pLXqw > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-hFcnMUlxM));
  width: calc(100% + var(--token-hFcnMUlxM));
}
.freeBox__pLXqw > :global(.__wab_flex-container) > *,
.freeBox__pLXqw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pLXqw > :global(.__wab_flex-container) > picture > img,
.freeBox__pLXqw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-hFcnMUlxM);
}
@media (max-width: 480px) {
  .freeBox__pLXqw > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-nPzqyQpH_));
    width: calc(100% + var(--token-nPzqyQpH_));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__pLXqw > :global(.__wab_flex-container) > *,
  .freeBox__pLXqw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__pLXqw > :global(.__wab_flex-container) > picture > img,
  .freeBox__pLXqw
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-nPzqyQpH_);
    margin-top: 0px;
  }
}
.freeBox__aQlT {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__aQlT > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__aQlT > :global(.__wab_flex-container) > *,
.freeBox__aQlT > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aQlT > :global(.__wab_flex-container) > picture > img,
.freeBox__aQlT
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-nPzqyQpH_);
}
.slotTargetValue {
  font-size: var(--token-wZK1o5uz59Brqbf);
}
.slotTargetValuestate_undeclared {
  color: var(--token-Hken4BJDt);
}
.freeBox___4Z2B {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  min-width: 40px;
}
.text {
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  color: var(--token-5eiHu7b54PkQh5O);
}
.textstate_undeclared {
  color: var(--token-mOJuRJhlC);
}
.texthasAddon {
  display: block;
}
.adjustButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .adjustButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__eJwT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__k8MXa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
