.root {
  display: flex;
  width: 16px;
  height: 16px;
  background: var(--token-bYBIsaiSK_SL0wu);
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 200px;
}
.rootstate_warning {
  background: var(--token-rsLFZ8cZG);
}
.rootstate_error {
  background: var(--token-zzo8jryirB3foEj);
}
.rootstate_neutral {
  background: var(--token-nJQnmTwmF4SPdl6);
}
.freeBox {
  display: block;
  position: relative;
  background: var(--token-eoOGiUMJC4PLiTG);
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 200px;
}
.freeBoxstate_warning {
  background: var(--token-mOJuRJhlC);
}
.freeBoxstate_error {
  background: var(--token-k8MBdjDfsyp6Vsa);
}
.freeBoxstate_neutral {
  background: var(--token-5eiHu7b54PkQh5O);
}
