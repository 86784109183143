.root {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  width: 100%;
  height: auto;
  grid-row-gap: var(--token-SAmzyWXiq);
  grid-column-gap: var(--token-cTBZAIcMp);
  padding-top: var(--token-H3JPdTVdV);
  padding-bottom: var(--token-H3JPdTVdV);
  position: relative;
  min-width: 0;
}
.hoverBg {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: -8px;
  top: 0;
  background: var(--token-NPNMQf7VB8u7Kel);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  right: -8px;
  bottom: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
}
.root:hover .hoverBg {
  background: var(--token-enYJIO6w3sEL0NO);
}
.freeBox__aNNbV {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 4;
  min-width: 0;
}
.referenz:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.textInput:global(.__wab_instance) {
  position: relative;
}
.svg__ld0Md {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__js5Sc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6Ma2B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fJiqn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__jKpQy:global(.__wab_instance) {
  position: relative;
}
.freeBox__tF3L3 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 2;
  min-width: 0;
}
.egid:global(.__wab_instance) {
  max-width: 100%;
}
.textInput2:global(.__wab_instance) {
  position: relative;
}
.svg__anoYk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mR3AJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pyRjg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3H297 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__uSfi:global(.__wab_instance) {
  position: relative;
}
.freeBox__n7AIo {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 6;
  min-width: 0;
}
.addressSuggestor:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__fnx1 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 2;
  min-width: 0;
}
.baujahr:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.textInput3:global(.__wab_instance) {
  position: relative;
}
.svg___8CbOm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aGlUw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lGKb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jkMxo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___9Pw8A:global(.__wab_instance) {
  position: relative;
}
.freeBox__ahBL {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  grid-column-end: span 5;
}
.freeBox__ahBL > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-nPzqyQpH_));
  width: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox__ahBL > :global(.__wab_flex-container) > *,
.freeBox__ahBL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ahBL > :global(.__wab_flex-container) > picture > img,
.freeBox__ahBL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-nPzqyQpH_);
}
.selectedHauptnutzungen:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.multiSelect:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter___3IdVu:global(.__wab_instance) {
  position: relative;
}
.freeBox___2Vjg {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  grid-column-end: span 2;
  min-width: 0;
}
.isEwg:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
._switch:global(.__wab_instance) {
  max-width: 100%;
}
.symbolCounter___0BgY:global(.__wab_instance) {
  position: relative;
}
.freeBox__fiaOp {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  grid-column-end: span 2;
}
.freeBox__fiaOp > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: calc(0px - var(--token-klOkpNg9M));
  width: calc(100% + var(--token-klOkpNg9M));
}
.freeBox__fiaOp > :global(.__wab_flex-container) > *,
.freeBox__fiaOp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fiaOp > :global(.__wab_flex-container) > picture > img,
.freeBox__fiaOp
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-klOkpNg9M);
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.svg__ltrzc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xvmgJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.svg__cJnKv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iwwhp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
