.root:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svgisActive_isAlgo {
  color: var(--token-cb_Nd6iAoCFyn8W);
}
.svg__tqgec {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
