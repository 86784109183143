.root:global(.__wab_instance) {
  position: relative;
}
.processCardSection:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__km9N9:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .slider:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.symbolCounter__qqd4G:global(.__wab_instance) {
  position: relative;
}
.formGroup__nyCwa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput42:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___3Wvqr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mVvsE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__razAy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mhaoP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__qxJqx:global(.__wab_instance) {
  position: relative;
}
.text {
  font-weight: 400;
}
.formGroup___70I3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__itWrm:global(.__wab_instance) {
  position: relative;
}
.svg___5EkFn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uyCe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qbnB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6MucL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__t6RTa:global(.__wab_instance) {
  position: relative;
}
.text___0ZSmm {
  padding-right: 0px;
}
.svg__gTLhW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rZyVz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__a5Kmg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__u4A9K:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__coNqI {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__svgBz {
  height: auto;
}
