.root {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--token-nJQnmTwmF4SPdl6);
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.processHeader:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processHeader:global(.__wab_instance) {
    position: relative;
    left: auto;
    top: auto;
  }
}
.scrollNav__veFj2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__veFj2:global(.__wab_instance) {
    left: auto;
    top: auto;
    z-index: 1;
    position: relative;
  }
}
@media (max-width: 480px) {
  .scrollNav__veFj2:global(.__wab_instance) {
    left: auto;
    top: auto;
  }
}
.scrollNavGroup__rSi3O:global(.__wab_instance) {
  position: relative;
}
.navButton__vv2X:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__zuLWx {
  height: auto;
}
.svg__e8Gq {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__ryBq5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___6Y9KR {
  height: auto;
}
.svg___2R5Gh {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__hJjm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___1OJw {
  height: auto;
}
.svg__v0Gpk {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__dx1Tc:global(.__wab_instance) {
  position: relative;
}
.navButton__aVi3C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ivPdR {
  height: auto;
}
.svg__h5Og0 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__nvUr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__t20Hk {
  height: auto;
}
.svg__nCefA {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__sBTbS:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__vvNlO {
  height: auto;
}
.svg__ji1Ce {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__d2LQx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__hzkta {
  height: auto;
}
.svg___5XnLa {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__pVb3H:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__pHb {
  height: auto;
}
.svg__tsHKc {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__h48Me:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__pZxlH {
  height: auto;
}
.svg__pxXm {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__b66Oh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___9AMzM {
  height: auto;
}
.svg__cgf46 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__pw3Gl:global(.__wab_instance) {
  position: relative;
}
.navButton__jxpar:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__atzpn {
  height: auto;
}
.svg__oDgX {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__fea0W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__yZtAg {
  height: auto;
}
.svg___77Zt7 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__nuV3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__h4LU {
  height: auto;
}
.svg__uEZjG {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__ydXy5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___8IQG {
  height: auto;
}
.svg___4PcAg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___13Wd4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__omitg {
  height: auto;
}
.svg___8NJbO {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__h5Lu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__d2PTh {
  height: auto;
}
.svg___4ZiOk {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__zAowP:global(.__wab_instance) {
  position: relative;
}
.navButton__wRFK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__s0Oop {
  height: auto;
}
.svg__bitE {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__btov2:global(.__wab_instance) {
  position: relative;
}
.navButton___20LZs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__tjrJw {
  height: auto;
}
.svg__h0MMk {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.freeBox__wbtZi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  background: var(--token-nJQnmTwmF4SPdl6);
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  margin: 0px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__wbtZi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0px;
    margin: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__wbtZi {
    display: flex;
    flex-direction: column;
  }
}
.scrollNav__hRpKy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__hRpKy:global(.__wab_instance) {
    left: 0px;
    top: 0px;
    z-index: 1;
    position: sticky;
  }
}
.scrollNavGroup__nfccg:global(.__wab_instance) {
  position: relative;
}
.navButton___1ONln:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__kqcLm {
  height: auto;
}
.svg__cHu {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__ha3M8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__np0Y {
  height: auto;
}
.svg___6FacS {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__gjfh7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__xkVt {
  height: auto;
}
.svg__yYnK {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__wZoIh:global(.__wab_instance) {
  position: relative;
}
.navButton__tNxnb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__w1HI {
  height: auto;
}
.svg___1U0Al {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__eT2MZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__tzZSr {
  height: auto;
}
.svg__gCNo {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__zIDkh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__vKmrI {
  height: auto;
}
.svg__xbpvx {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.processPageContent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processPageActions:global(.__wab_instance) {
  position: relative;
}
.processSection:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processCard__ubzws:global(.__wab_instance) {
  position: relative;
}
.processCardSection__bBdv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__jciD {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__jciD {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.formGroup__mKpNq:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 3;
}
.textInput:global(.__wab_instance) {
  position: relative;
}
.svg__rQipJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ukW1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__izkOg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uIdMr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__w6Qq3:global(.__wab_instance) {
  position: relative;
}
.formGroup__e5FdS:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__mbfor {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.multiSelect:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__zSzwg:global(.__wab_instance) {
  position: relative;
}
.formGroup__bzswy:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text___9ZdNt {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._15:global(.__wab_instance) {
  position: relative;
}
.svg__qSbDd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__inIdr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vyVUj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gyeFd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__or8Bv:global(.__wab_instance) {
  position: relative;
}
.formGroup___4Ez0B:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__kSd4V {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cJfB8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__c5Mzo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__hs2BJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg___0Oky2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__kRrP:global(.__wab_instance) {
  position: relative;
}
.formGroup__oi1Np:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__wTowb {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__n2Sgn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jlcfu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wY7W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg___4Cmxh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter___0ClB:global(.__wab_instance) {
  position: relative;
}
.formGroup__xEIg:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text___6J09O {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__kNcY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k0BhL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__efkcg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg___66CQ6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter___3UwGo:global(.__wab_instance) {
  position: relative;
}
.formGroup___8UlIm:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__dRfrp {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hzzlu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lqPs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__snRzL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg__q2Trs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__sjv7T:global(.__wab_instance) {
  position: relative;
}
.formGroup__rCfJi:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__wyPn0 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__esPhk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kGngb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bz70L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg__x1Udg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__gHpZi:global(.__wab_instance) {
  position: relative;
}
.formGroup___9Agqe:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__xD1WR {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ayzUe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gdQhN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__n6Q1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg__rtwVk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__fB3A:global(.__wab_instance) {
  position: relative;
}
.formGroup__cC5Yl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput20:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__towln {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4DapT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lgFup {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___14YXz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__kYjRn:global(.__wab_instance) {
  position: relative;
}
.text___1AfA {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput10:global(.__wab_instance) {
  position: relative;
}
.svg__lROeM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lhLgP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bjfzd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__nHcX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__cpoqe:global(.__wab_instance) {
  position: relative;
}
.text__nyEpd {
  padding-right: 0px;
}
.svg__hRy5D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rJ3Zs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xzJs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__qwT7P:global(.__wab_instance) {
  position: relative;
}
.processCardSection__sZpSg:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__lcXxk {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__tb0Ci:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.booleanSelectButtonGroup__eksxI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__tF4W:global(.__wab_instance) {
  position: relative;
}
.formGroup__htcyl:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text___9Drs8 {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.brz:global(.__wab_instance) {
  position: relative;
}
.svg__h7S6K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yg8R8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5W2RT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__oPs9O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__tN4Jk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8VDsK {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__pehGa:global(.__wab_instance) {
  position: relative;
}
.formGroup__pybAh:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text___31SwG {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.heimf:global(.__wab_instance) {
  position: relative;
}
.svg__gZjWl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hoaom {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kDuzz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__ae4Ne {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__iR5Ut {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jxlX {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__xsVs:global(.__wab_instance) {
  position: relative;
}
.formGroup__mDsse:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__hMkA {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.heimEchf:global(.__wab_instance) {
  position: relative;
}
.svg__kktrY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__r0U1K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aYQgP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__c47CP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dmdq5 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__vg4AG:global(.__wab_instance) {
  position: relative;
}
.formGroup__lfKma:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__tCSi0 {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.heimEPro:global(.__wab_instance) {
  position: relative;
}
.svg__cPhLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wfeLz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qJ0Hx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__augF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___3Fi4M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__erxRg {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__nMvt:global(.__wab_instance) {
  position: relative;
}
.formGroup__gSw3J:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__cs2DQ {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  padding-top: 0px;
}
._10:global(.__wab_instance) {
  position: relative;
}
.svg__lRgp7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wzg5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__d02Ot {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__cU8R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__y9WzZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__n8Khe {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__rdQes:global(.__wab_instance) {
  position: relative;
}
.formGroup__dy0Ra:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__n2K {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  padding-top: 0px;
}
._7:global(.__wab_instance) {
  position: relative;
}
.svg__vRfPz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zzAd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aK0Td {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__jZlGd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__f9Oym {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__gmRx4 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__vDib:global(.__wab_instance) {
  position: relative;
}
.formGroup__wqAPx:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__umbK {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  padding-top: 0px;
}
._12:global(.__wab_instance) {
  position: relative;
}
.svg__gbZoj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yEtU3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___01Xn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__m3Vew {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yJvbK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__iXqQ {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__alGzD:global(.__wab_instance) {
  position: relative;
}
.formGroup__frLy6:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text__vQkpk {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.gebbem:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__q9W9Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qPkE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vhPRu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__po899 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__lhL1U:global(.__wab_instance) {
  position: relative;
}
.formGroup__iYbvw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput7:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__tNLiU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__y8HC0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__d3Fzk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aOol {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__mKked:global(.__wab_instance) {
  position: relative;
}
.text__xnpuf {
  font-weight: 400;
}
.formGroup___7CPnb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput___8N0S:global(.__wab_instance) {
  position: relative;
}
.svg__xzMxW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gzayE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gQada {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__iKYiK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___9AWfy:global(.__wab_instance) {
  position: relative;
}
.text__bzRtm {
  padding-right: 0px;
}
.svg__hn4Vy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eDlnT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3DGnq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__irnqt:global(.__wab_instance) {
  position: relative;
}
.processCardSection__m99T9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__ry66E {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
.formGroup__sc6F7:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text___2Oo42 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-Hken4BJDt);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.textInput115:global(.__wab_instance) {
  position: relative;
}
.svg__rXdo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i4XTc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fDn4V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___1267I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bR2H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__x6LBo:global(.__wab_instance) {
  position: relative;
}
.formGroup__caD0K:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__vkSOb {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  padding-right: 0px;
}
.textInput30:global(.__wab_instance) {
  position: relative;
}
.svg__t5FPq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__scaOl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ibsEt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5Xpmg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__mYoYg:global(.__wab_instance) {
  position: relative;
}
.formGroup__drSHx:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text___8F0Pi {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.singleSelectButtonGroup__hJsnn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button__kzhgN:global(.__wab_instance) {
  max-width: 100%;
}
.svg__no8Cd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xoY6X {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__kN3Bk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__h2PMh:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ihqyp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uN5Fm {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__yNdqP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__qMtes:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xlpov {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vRhIn {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__c56Yh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__ivNnC:global(.__wab_instance) {
  max-width: 100%;
}
.svg__otXjn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wdCad {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___1QiHy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___1KhM:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ygsRb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dqz2N {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ppzsz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___12KKu:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hg2Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__skeiE {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ptrwc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___8OIdQ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__y03Rk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__viv6M {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___7FZIz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___0VNps:global(.__wab_instance) {
  max-width: 100%;
}
.svg__t8Nuj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yGkSn {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__lsUuc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter___9D2Hn:global(.__wab_instance) {
  position: relative;
}
.formGroup__eitYo:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__wMdam {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.svg__sAds {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wtM29 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___9Wcsq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__eooFf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4Zt3E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__t3ApA:global(.__wab_instance) {
  position: relative;
}
.formGroup__vijm:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text___2ZUf8 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.booleanSelectButtonGroup__inbSr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__xsDjf:global(.__wab_instance) {
  position: relative;
}
.formGroup__l0G9J:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__iHxzc {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.textInput54:global(.__wab_instance) {
  position: relative;
}
.svg__ez3Xu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1SEuw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wEcM1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___19HWu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___9IfAz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__dofi:global(.__wab_instance) {
  position: relative;
}
.formGroup___8OiwB:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text__d5T08 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.singleSelectButtonGroup__kMcN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button__py6Sw:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ah51R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__koU6 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__kfysY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__yNmTo:global(.__wab_instance) {
  max-width: 100%;
}
.svg__h7Wh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___3Pl4P {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__fzuGp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__o5NCf:global(.__wab_instance) {
  max-width: 100%;
}
.svg__t6EKe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xmEft {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__tRux {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__nxXWr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__iydTi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__siSi0 {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__tt2Tl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__vfyxU:global(.__wab_instance) {
  position: relative;
}
.formGroup__soGja:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text__hpRKu {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.singleSelectButtonGroup__rUfix:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button__aAkrO:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hqZiJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___64IIv {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__kMwv4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__e3C5J:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kXefp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2AIym {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___2RZd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__muVfc:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mAqQg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kkAf {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__sPyq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__wRmoR:global(.__wab_instance) {
  max-width: 100%;
}
.svg__wl6Pv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hlhPj {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__fhee {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__owXsQ:global(.__wab_instance) {
  position: relative;
}
.formGroup__hf6P:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text__m3Swg {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.button__txNsV:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uxAhX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jgDvV {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__vqeVc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__jpWsu:global(.__wab_instance) {
  max-width: 100%;
}
.svg__b775B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5Fe0E {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___7IaM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__mwk3P:global(.__wab_instance) {
  max-width: 100%;
}
.svg__oTtg4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___8CnF3 {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___2OfkJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__hn4Lp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ueykR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vUpAl {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__moyZj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__gEaci:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yySxh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0VwiL {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__zuy12 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__pSuP:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kyzGy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1Vb7J {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___3K1O0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__oYjUo:global(.__wab_instance) {
  max-width: 100%;
}
.svg__wi1T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__y1Gug {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__gwWj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__igYc:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mKdra {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aCqNg {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__nhEx6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__ucGu:global(.__wab_instance) {
  position: relative;
}
.formGroup__r4UIx:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text__wXiRb {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.button__xba75:global(.__wab_instance) {
  max-width: 100%;
}
.svg__tiMqj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__q7FbU {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__f5YOl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__aZpJ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lTeZl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__scyyk {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__mUCx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__jdShE:global(.__wab_instance) {
  max-width: 100%;
}
.svg__riyXv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___8Jy8W {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__icLjb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__lJqm3:global(.__wab_instance) {
  max-width: 100%;
}
.svg__pCg1E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__gkpHm {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__a3HO9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__laKVk:global(.__wab_instance) {
  max-width: 100%;
}
.svg___64WLp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zeBup {
  padding-left: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ba2SH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__sOcWq:global(.__wab_instance) {
  position: relative;
}
.formGroup__j5Z8L:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__hykwZ {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.svg__yh6J7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__u6SLk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wAjd3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__vRvUe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5KBse {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___1OwF:global(.__wab_instance) {
  position: relative;
}
.formGroup__ziBSf:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__zOD2 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.button__zToW:global(.__wab_instance) {
  max-width: 100%;
}
.svg__b0Bp6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nr7Pr {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-NBmi1EBeYuE24RY);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__bIp4W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__jolLm:global(.__wab_instance) {
  max-width: 100%;
}
.svg__thwM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tjLcZ {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__bkswm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__jy3Va:global(.__wab_instance) {
  max-width: 100%;
}
.svg__twXn3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dfvIu {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__bzVzc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___0El7J:global(.__wab_instance) {
  max-width: 100%;
}
.svg__jxIy8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6Fvvt {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__c7Xk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__l3U71:global(.__wab_instance) {
  position: relative;
}
.formGroup__oTeb:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__oNqxP {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.svg___9U6F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__orRl6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ch1TN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__vt0Je {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fQaj3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__d0HkV:global(.__wab_instance) {
  position: relative;
}
.formGroup__qgCkH:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text___4JPjb {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.svg__uKp2S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ywIE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__c00QQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__seBn7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___7HqJd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__mz3J0:global(.__wab_instance) {
  position: relative;
}
.formGroup__o13ZN:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text__ru1Ky {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.textInput57:global(.__wab_instance) {
  position: relative;
}
.svg__zo2XY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mmUqF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zKi2T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vkCuu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__zw9Iv:global(.__wab_instance) {
  position: relative;
}
.formGroup__bvaGw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput29:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__w1Ldv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bKjpz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jb7Oj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kxIG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___9Wywl:global(.__wab_instance) {
  position: relative;
}
.text__ayo9F {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput10:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput35:global(.__wab_instance) {
  position: relative;
}
.svg__kr6RZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__e1SCo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pbKDm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dy1AX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___00Wn2:global(.__wab_instance) {
  position: relative;
}
.text__ajKch {
  padding-right: 0px;
}
.svg__u7Ca0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ypM2Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ces9L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__g39I:global(.__wab_instance) {
  position: relative;
}
.processCardSection__hzvwr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__zjAme {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__zjAme {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.freeBox___5NkYk {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox___5NkYk > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox___5NkYk > :global(.__wab_flex-container) > *,
.freeBox___5NkYk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___5NkYk > :global(.__wab_flex-container) > picture > img,
.freeBox___5NkYk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.formGroup__kmLBm:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__u6FW4:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__b2P8:global(.__wab_instance) {
  position: relative;
}
.processCardSection___3Th3O:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__yyJyt {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__yyJyt {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.freeBox__mfA9P {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__mfA9P > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__mfA9P > :global(.__wab_flex-container) > *,
.freeBox__mfA9P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mfA9P > :global(.__wab_flex-container) > picture > img,
.freeBox__mfA9P
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.formGroup__cDwSy:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__gdoam:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__ry1Op:global(.__wab_instance) {
  position: relative;
}
.formGroup__eyzue:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__wxi1B:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__a4Vnw:global(.__wab_instance) {
  position: relative;
}
.formGroup__xcrlU:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__mkZ0X:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__ohdiw:global(.__wab_instance) {
  position: relative;
}
.formGroup__lUtwR:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__cQkK:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__yg4XP:global(.__wab_instance) {
  position: relative;
}
.formGroup__jBghb:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__gyi9P:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__j5IJf:global(.__wab_instance) {
  position: relative;
}
.formGroup__jayAb:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__kG8Bd {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._16:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__u3ExF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__a4Iee {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xmHuk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wzaxn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__rm0TR:global(.__wab_instance) {
  position: relative;
}
.formGroup__rNBu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput37:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__dAtS6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hNlxl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hsrYl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qgMn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___9KLcL:global(.__wab_instance) {
  position: relative;
}
.text__lx1S {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput11:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput38:global(.__wab_instance) {
  position: relative;
}
.svg__xAuOp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gbqCf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__axqXz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qGfKm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__dTbaE:global(.__wab_instance) {
  position: relative;
}
.text__mAgJ {
  padding-right: 0px;
}
.svg__n43I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zeEn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qgUfd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__ojxC:global(.__wab_instance) {
  position: relative;
}
.processCardSection__xvJ5S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__uxbqX {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__uxbqX {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.freeBox__wgcvi {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__wgcvi > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__wgcvi > :global(.__wab_flex-container) > *,
.freeBox__wgcvi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wgcvi > :global(.__wab_flex-container) > picture > img,
.freeBox__wgcvi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.formGroup__reT1Z:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__ttf:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__gfjvs:global(.__wab_instance) {
  position: relative;
}
.processCardSection___6Ji5P:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.optionalFieldsGebaeude {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  margin: 0px 0px var(--token-3wiInXIIr);
}
.optionalFieldsGebaeude > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.optionalFieldsGebaeude > :global(.__wab_flex-container) > *,
.optionalFieldsGebaeude
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.optionalFieldsGebaeude > :global(.__wab_flex-container) > picture > img,
.optionalFieldsGebaeude
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.zustandHerleitung:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__pdlag {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.slider__rJmRv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__qc9Oe {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__j53Hx {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__rrjfO:global(.__wab_instance) {
  position: relative;
}
.formGroup__flwVc:global(.__wab_instance) {
  position: relative;
}
.text__qa7Yn {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.slider__mvVGg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text___2LV1 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__wSnxL {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter___7EDwn:global(.__wab_instance) {
  position: relative;
}
.formGroup__lU8E1:global(.__wab_instance) {
  position: relative;
}
.text__aJcde {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.slider__tc4Ls:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__drGhv {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__cu5Hj {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter___6N7Fm:global(.__wab_instance) {
  position: relative;
}
.formGroup__kyy6K:global(.__wab_instance) {
  position: relative;
}
.text___8FqFz {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.slider__ueRvT:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text___44YbD {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__vas5X {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__jLwO:global(.__wab_instance) {
  position: relative;
}
.formGroup__sHiQv:global(.__wab_instance) {
  position: relative;
}
.text__vSUg7 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.slider__puFln:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__iVx2N {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___0So7Y {
  font-family: "Inter", sans-serif;
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  font-weight: 500;
  font-style: normal;
  color: var(--token-sHkWBDcvLab64_p);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__amdQz:global(.__wab_instance) {
  position: relative;
}
.divider:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.zustandGebauedeRenovationenen:global(.__wab_instance) {
  position: relative;
}
.formGroup__pXvTs:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__koM74 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.textInput87:global(.__wab_instance) {
  position: relative;
}
.svg__q3Uqb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2YQhw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__w4C7E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yxAez {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___001Pv:global(.__wab_instance) {
  position: relative;
}
.formGroup__aEcW7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput43:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__uawih {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__r1BjB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yb4CA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__bJ13 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__wcQxd:global(.__wab_instance) {
  position: relative;
}
.text__fAkI {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput14:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput44:global(.__wab_instance) {
  position: relative;
}
.svg__sIavt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9Bl8E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oi7Nr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ppAu9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___0AiCk:global(.__wab_instance) {
  position: relative;
}
.text__hRgn {
  padding-right: 0px;
}
.svg__yZDU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7Jvvh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__s1Skx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__jT1Hy:global(.__wab_instance) {
  position: relative;
}
.processCardSection__jsEsP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__yrzZp {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__yrzZp {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.formGroup__i9UqY:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.slider__doD0:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__gmUV:global(.__wab_instance) {
  position: relative;
}
.processCardSection__ap1Jx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__sw0NJ {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__sw0NJ {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.freeBox___0CJb2 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox___0CJb2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox___0CJb2 > :global(.__wab_flex-container) > *,
.freeBox___0CJb2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0CJb2 > :global(.__wab_flex-container) > picture > img,
.freeBox___0CJb2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.formGroup__cLJmb:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider___4OmYj:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__mdftJ:global(.__wab_instance) {
  position: relative;
}
.formGroup__ey5Gb:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__vkyLk:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__wgpJv:global(.__wab_instance) {
  position: relative;
}
.formGroup__y3ASv:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__wyRoH:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__kzb3F:global(.__wab_instance) {
  position: relative;
}
.formGroup__pFsD:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__wMxfD:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__jnacs:global(.__wab_instance) {
  position: relative;
}
.formGroup__vQxz3:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider___6PaP9:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__rtfTs:global(.__wab_instance) {
  position: relative;
}
.formGroup__vdzfX:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__luf5B {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._13:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6A3CV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zyL5H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__viKBx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dAzL2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__tkzEv:global(.__wab_instance) {
  position: relative;
}
.formGroup___8TE2S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput45:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__ot6Nw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__q6N31 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sldPu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__yhbQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__dVs43:global(.__wab_instance) {
  position: relative;
}
.text__gzeUw {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput15:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput46:global(.__wab_instance) {
  position: relative;
}
.svg__fWc89 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tu4A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1GBbi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__z463J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__fplF:global(.__wab_instance) {
  position: relative;
}
.text__eloh1 {
  padding-right: 0px;
}
.svg__bfFew {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___32PbL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wNw5J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__hGezm:global(.__wab_instance) {
  position: relative;
}
.processCardSection___2J8Yq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__la7Wa {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__la7Wa {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox__la7Wa {
    grid-template-columns: 1fr;
  }
}
.formGroup__pqYlg:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 6;
}
.button__skhBr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__zZoaV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tbsZq {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__v4Eiq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___4TXjZ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__u3B5J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__zeCpb {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-right: 0px;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__jypPu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__tg7N3:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kbuP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5SzK2 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ihj2R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__ciy8Y:global(.__wab_instance) {
  max-width: 100%;
}
.svg__e8R0W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2RiiP {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ugrq1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__cjTe:global(.__wab_instance) {
  position: relative;
}
.processCardSection__b6N:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__ojVQi {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__ojVQi {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox__ojVQi {
    grid-template-columns: 1fr;
  }
}
.formGroup__lv69:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 6;
}
.button__o7J6O:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uHuUa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pW7Kn {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___5Vu0J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__g9Hs:global(.__wab_instance) {
  max-width: 100%;
}
.svg__c7FLd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__y0JUm {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-right: 0px;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__aSoNy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___4WYgA:global(.__wab_instance) {
  max-width: 100%;
}
.svg___8T1Ch {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hwAl {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__d0P5Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___41IAx:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fq2Xe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__o7Mu2 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__hMjA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__da7Gh:global(.__wab_instance) {
  position: relative;
}
.formGroup__v7Kyz:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 6;
}
.button__zkxJj:global(.__wab_instance) {
  max-width: 100%;
}
.svg__tzUxL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lTiZr {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ww64N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__t7Wqh:global(.__wab_instance) {
  max-width: 100%;
}
.svg___1BKN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7ALGm {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-right: 0px;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__uOp7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__tWxk:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qbjAj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__baNs {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__zGtT6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__gZwy:global(.__wab_instance) {
  max-width: 100%;
}
.svg__sbbCu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xQSef {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__iTqmj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__jW8Ki:global(.__wab_instance) {
  position: relative;
}
.formGroup___18BlL:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 6;
}
.button__tyOV:global(.__wab_instance) {
  max-width: 100%;
}
.svg__mPfLz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__my6F8 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__sJSc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__yotNv:global(.__wab_instance) {
  max-width: 100%;
}
.svg__czEz1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oHhSz {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-right: 0px;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__uiQp7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__oklN1:global(.__wab_instance) {
  max-width: 100%;
}
.svg__egh1D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sj7PX {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__e3Kq1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__wwmmb:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hfRaQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__heVvX {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ify0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__usW2H:global(.__wab_instance) {
  position: relative;
}
.formGroup__nmmXw:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 6;
}
.button__tT8ZU:global(.__wab_instance) {
  max-width: 100%;
}
.svg__ph4KO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dUmZr {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__jQ1S4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__lesBj:global(.__wab_instance) {
  max-width: 100%;
}
.svg__alwF1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__z80AF {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-right: 0px;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___9Deqb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__r5Lxt:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uGrme {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jK6Zx {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__tee7K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__dphFy:global(.__wab_instance) {
  max-width: 100%;
}
.svg___6Oujj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__iXxPt {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__mnjog {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__spf6W:global(.__wab_instance) {
  position: relative;
}
.formGroup___8Tzt:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 6;
}
.button___6JkU3:global(.__wab_instance) {
  max-width: 100%;
}
.svg__n1YEa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__g1MIp {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__te4Zi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__ryoN3:global(.__wab_instance) {
  max-width: 100%;
}
.svg__aLsZf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__v7WDp {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-right: 0px;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__rupsa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__wK7EJ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uzUK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__efDyx {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__j9Vcu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__ygpex:global(.__wab_instance) {
  max-width: 100%;
}
.svg__nWPwG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__blRj {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__nY8H2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__q1Alp:global(.__wab_instance) {
  position: relative;
}
.formGroup__dqyyj:global(.__wab_instance) {
  max-width: 100%;
  grid-column-end: span 6;
}
.button__aGlGn:global(.__wab_instance) {
  max-width: 100%;
}
.svg___68ADg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tvryi {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__xcUwp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__xPbIb:global(.__wab_instance) {
  max-width: 100%;
}
.svg__oq82G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qkIn {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-right: 0px;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___1M3Fc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__aVede:global(.__wab_instance) {
  max-width: 100%;
}
.svg__pc3Hs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__e1Zq1 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__xvzen {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__xaz6I:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dSoCa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__yT6Fw {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__zfIjj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__sgjax:global(.__wab_instance) {
  position: relative;
}
.formGroup__wefKo:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.text__a4P84 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._17:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___647Fq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__csz83 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4PbzS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kfio5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___2QkFl:global(.__wab_instance) {
  position: relative;
}
.formGroup__wejU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput26:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__cpOn4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__i6Yai {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___3IzJb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5ASYt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__tmcL8:global(.__wab_instance) {
  position: relative;
}
.text__gTiy1 {
  font-weight: 400;
}
.formGroup__hqNnV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__j3KeO:global(.__wab_instance) {
  position: relative;
}
.svg__z1SEl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wOwv3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ozhh5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wj9Tx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__bIlOq:global(.__wab_instance) {
  position: relative;
}
.text__gfAx {
  padding-right: 0px;
}
.svg__cOrKz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mxrdw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xpyas {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__h0YAh:global(.__wab_instance) {
  position: relative;
}
.processCardSection___3BJ77:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__v8KqK {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__v8KqK {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.freeBox__jQvHe {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__jQvHe > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__jQvHe > :global(.__wab_flex-container) > *,
.freeBox__jQvHe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jQvHe > :global(.__wab_flex-container) > picture > img,
.freeBox__jQvHe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.formGroup__yk27:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__pSpFh:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__surdr:global(.__wab_instance) {
  position: relative;
}
.processCardSection__i2R5Z:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___2NxpE {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox___2NxpE {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.freeBox__lGNoq {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.freeBox__lGNoq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__lGNoq > :global(.__wab_flex-container) > *,
.freeBox__lGNoq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lGNoq > :global(.__wab_flex-container) > picture > img,
.freeBox__lGNoq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.formGroup__dmahr:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider___5ZeZu:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__rgpSp:global(.__wab_instance) {
  position: relative;
}
.formGroup__oLV:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__teIaz:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter___9IvlI:global(.__wab_instance) {
  position: relative;
}
.formGroup__ai5Xh:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__d4Uzi:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__zpPmh:global(.__wab_instance) {
  position: relative;
}
.formGroup__essgG:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__rlytO:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__g6Ied:global(.__wab_instance) {
  position: relative;
}
.formGroup___2DaK4:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.slider__oyJ5:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__t0Jsq:global(.__wab_instance) {
  position: relative;
}
.formGroup__ozPFo:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__rivFz {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._18:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___4OUmz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oz7Ka {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pnHqD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8IfjQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__yL8Sc:global(.__wab_instance) {
  position: relative;
}
.formGroup__njgRn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput47:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___6W2E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__j2Bvh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__n42A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jqvZk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__aimcf:global(.__wab_instance) {
  position: relative;
}
.text__vmNkn {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput16:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput48:global(.__wab_instance) {
  position: relative;
}
.svg___9YXD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zeuEl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2Evfr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cNOe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__yY8Q8:global(.__wab_instance) {
  position: relative;
}
.text__bgtll {
  padding-right: 0px;
}
.svg__u8DaL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zt1Vs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sgVxp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__uzCyv:global(.__wab_instance) {
  position: relative;
}
.processCardSection__repL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__zGZiF {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__zGZiF {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.formGroup__pw6Ho:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__iyLqs {
  color: var(--token-IOs4wk1cx7qv8eT);
}
.slider__g6Aih:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__ofK66:global(.__wab_instance) {
  position: relative;
}
.formGroup__iFwfO:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__isJsm {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._14:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__udq2P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tf93Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__klu5I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4Xxxd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__nImQb:global(.__wab_instance) {
  position: relative;
}
.formGroup__mTfbk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput39:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__inHwl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ouUl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2DkKw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___8Jz5X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__qyDsy:global(.__wab_instance) {
  position: relative;
}
.text__mfqX5 {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput12:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput40:global(.__wab_instance) {
  position: relative;
}
.svg__jYzfl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eeSR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qPx59 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dXvvZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__mm8Fd:global(.__wab_instance) {
  position: relative;
}
.text___5B6Kx {
  padding-right: 0px;
}
.svg__ns2S5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__x1NBd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wUtoo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__zKoDe:global(.__wab_instance) {
  position: relative;
}
.processCardSection___0FZ0M:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__gvDf1 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__gvDf1 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.formGroup__yXdjI:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__rYz0H {
  color: var(--token-IOs4wk1cx7qv8eT);
}
.slider__sVgH:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__pGt05:global(.__wab_instance) {
  position: relative;
}
.processCardSection__gYcu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__z2H55 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__vHad:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.slider__peUal:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__ks3Ru:global(.__wab_instance) {
  position: relative;
}
.freeBox__dVm1 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  padding-top: var(--token-7dIrE6133);
  min-width: 0;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__dVm1 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__dVm1 > :global(.__wab_flex-container) > *,
.freeBox__dVm1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dVm1 > :global(.__wab_flex-container) > picture > img,
.freeBox__dVm1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.mikrolageSubratings___3WzBv:global(.__wab_instance) {
  max-width: 100%;
}
.formGroup__dsnPq:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__aMaq1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__gxDz:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__ey1Ew:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__iIoag:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__xxbWp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__xBWtn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__aNl5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__nzrEs:global(.__wab_instance) {
  position: relative;
}
.formGroup__tYq9K:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__hw9Vg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__xHwXj:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__vjtmu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__yev6T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___4Etfa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__h6Nc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zfr8Z {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__atFk:global(.__wab_instance) {
  position: relative;
}
.formGroup__j7LD:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__od8Td:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__tOqP4:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__hPkfd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__nHoxk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__yiS0W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__ehfB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__y8LXz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__ygWoh:global(.__wab_instance) {
  position: relative;
}
.formGroup__eOlqJ:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__migae:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__zyYy5:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__mGCv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__zIn9S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__el4I:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__h1QkC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cO7MY {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock___8XLkN:global(.__wab_instance) {
  position: relative;
}
.formGroup__wQByE:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__slVf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__hmwOi:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__pbVrj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___6I9XL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__x9IRp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___14Rq5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0XSm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__alk77:global(.__wab_instance) {
  position: relative;
}
.formGroup__n8JEg:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider___9W1Lz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__hreLg:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__hyW7H:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__kmSnb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___4Ocwo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___6XFeu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___7HxhE {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__zgOc2:global(.__wab_instance) {
  position: relative;
}
.formGroup__v3SNk:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__jch4F:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__tRYqH:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___3Wntc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__noPg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__q2OlK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__dt2By:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__uuEZg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__ik1Mj:global(.__wab_instance) {
  position: relative;
}
.formGroup__nh6Fa:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__vm1M7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__rao1I:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___56SYj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__nVhqG:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__tb8Vb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__ljVZa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hCOeL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.formGroup__yIvTt:global(.__wab_instance) {
  position: relative;
}
.text__hXtGg {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.singleSelectButtonGroup__o9P9L:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button___1XxL:global(.__wab_instance) {
  max-width: 100%;
}
.svg__gzMbN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rP9Ay {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__hwk5X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__lGrpA:global(.__wab_instance) {
  max-width: 100%;
}
.svg__azuKh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__g4Ztg {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__rVsjx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__oyyQo:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3STfp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__i9Reh {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__uWrsJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___9MwAp:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qaoeR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wqSgD {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__kj5Kt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__x7NAw:global(.__wab_instance) {
  max-width: 100%;
}
.svg___34YCj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__iBnls {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__kehil {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__nRjW:global(.__wab_instance) {
  max-width: 100%;
}
.svg__xc52 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___6VyIj {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__qheHd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__tkrb3:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dHFp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lr89S {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__fihYj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__qjTfl:global(.__wab_instance) {
  position: relative;
}
.formGroup__siYrO:global(.__wab_instance) {
  max-width: 100%;
}
.textInput___5RUL:global(.__wab_instance) {
  position: relative;
}
.svg__gDdmA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3Qujo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nxnQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__onEb6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__gS5T2:global(.__wab_instance) {
  position: relative;
}
.processCardSection__hn7M:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__jaUj5 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__yCz9Y:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.slider__pAvWn:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter___0BceJ:global(.__wab_instance) {
  position: relative;
}
.freeBox__fohxg {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  padding-top: var(--token-7dIrE6133);
  min-width: 0;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__fohxg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__fohxg > :global(.__wab_flex-container) > *,
.freeBox__fohxg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fohxg > :global(.__wab_flex-container) > picture > img,
.freeBox__fohxg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.mikrolageSubratings__w5Sav:global(.__wab_instance) {
  max-width: 100%;
}
.ratingCardBlock__vodf6:global(.__wab_instance) {
  position: relative;
}
.formGroup__hqwp:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__gxEm3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__fo2F3:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__guyiW:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__sPiVn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__jh9Ez:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__txIwl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rsih5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__dFjmg:global(.__wab_instance) {
  position: relative;
}
.formGroup__sdRiJ:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__vo4Hn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__p4L6B:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__cGaG:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__kFXf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__oojm4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__vu18W:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__wKpe5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__zQz7J:global(.__wab_instance) {
  position: relative;
}
.formGroup__vAo8E:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__hCcXy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__xvK7U:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__qk4Pi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__gtUd2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___7Lwjt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__o10SV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gh8XN {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__xT0Nc:global(.__wab_instance) {
  position: relative;
}
.formGroup__r3NJl:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__ndYKn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__d0AxN:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___3STuN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__ws1C4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__odIuu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__grudQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rq3Tx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__yjha7:global(.__wab_instance) {
  position: relative;
}
.formGroup__zae5M:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__nC8T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__nQl4M:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__uwpM:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__sc4Pj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__tHhb9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__l4Wt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ebzWq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.formGroup__zjPz8:global(.__wab_instance) {
  position: relative;
}
.text__mutxv {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.singleSelectButtonGroup__obtGy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button__ryxFr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__eCcNj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jjzB6 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__nzYil {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__qlA6U:global(.__wab_instance) {
  max-width: 100%;
}
.svg__x7Hi3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vweH {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__ugKP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__lnXsD:global(.__wab_instance) {
  max-width: 100%;
}
.svg___8JUl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__saKgh {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___0G48V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button___3Xktl:global(.__wab_instance) {
  max-width: 100%;
}
.svg__sBqLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___8WdRb {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__iyZns {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__tj0Fj:global(.__wab_instance) {
  max-width: 100%;
}
.svg__anFCc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jlz84 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__nnyA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__vPg4V:global(.__wab_instance) {
  max-width: 100%;
}
.svg__hpyq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vjtJ5 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___6QzAa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__wcSrN:global(.__wab_instance) {
  max-width: 100%;
}
.svg__l7Gbx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k8VB {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__xAzNf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__sxmes:global(.__wab_instance) {
  position: relative;
}
.formGroup__lU5:global(.__wab_instance) {
  max-width: 100%;
}
.textInput__i3Wum:global(.__wab_instance) {
  position: relative;
}
.svg__ayAv7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___40Wrr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__f32G3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__h7ZmW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__pqlC:global(.__wab_instance) {
  position: relative;
}
.processCardSection___7FeqQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__uiUlY {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__p514N:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text___874 {
  padding-right: 0px;
}
.slider__wp8Vo:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter___133H:global(.__wab_instance) {
  position: relative;
}
.processCardSection__rsiC7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__od5 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__pL3W:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__vUs2B {
  padding-right: 0px;
}
.slider__hjBgv:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__k2GFy:global(.__wab_instance) {
  position: relative;
}
.processCardSection__adqZn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__idkaG {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__nYbqK:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.text__w9Zrh {
  padding-right: 0px;
}
.slider___85Uk2:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.symbolCounter__p1Sw:global(.__wab_instance) {
  position: relative;
}
.freeBox__zUS1 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  padding-top: var(--token-7dIrE6133);
  min-width: 0;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__zUS1 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.freeBox__zUS1 > :global(.__wab_flex-container) > *,
.freeBox__zUS1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zUS1 > :global(.__wab_flex-container) > picture > img,
.freeBox__zUS1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
.mikrolageSubratings__olvGw:global(.__wab_instance) {
  max-width: 100%;
}
.ratingCardBlock__fYdNk:global(.__wab_instance) {
  position: relative;
}
.formGroup__bE8Yh:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__fMogI:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__nDcPp:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__nsFbm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__aok7A:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__xnWSo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__ymvt2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__macjU {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__f5D3F:global(.__wab_instance) {
  position: relative;
}
.formGroup__y99R:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__x8UkD:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__cj4Au:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__omaxj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___3Xo7E:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__ooHdQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__htyvQ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__u1Vg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__sbwKf:global(.__wab_instance) {
  position: relative;
}
.formGroup__tMyw:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__y183U:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__rndgi:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__vI8Xy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__d1INn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__cIm5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___3TIbq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___7VNm4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__hExJl:global(.__wab_instance) {
  position: relative;
}
.formGroup__dGjmN:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__jNBtf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__zzoN1:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment__fNfow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__opTsK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__dgiNz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__sStvC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__putq1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.ratingCardBlock__gKhbR:global(.__wab_instance) {
  position: relative;
}
.formGroup__iwRWf:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.slider__gYFk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__iLmFr:global(.__wab_instance) {
  position: relative;
}
.dataBarSegment___2RTaN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment__nYiuU:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___8Iqna:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dataBarSegment___5H99Q:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__pggLs {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  height: 1em;
}
.formGroup__c6UTs:global(.__wab_instance) {
  position: relative;
}
.text__viB4O {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.singleSelectButtonGroup___5Wkny:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.button__e7Ois:global(.__wab_instance) {
  max-width: 100%;
}
.svg__cuaKk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uqQhJ {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___7IJ5E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__pxb0N:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uxpCc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__k7YZa {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__opJrl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__jJd32:global(.__wab_instance) {
  max-width: 100%;
}
.svg___2Kvbf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__eVgpa {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__m3YLm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__n9YKz:global(.__wab_instance) {
  max-width: 100%;
}
.svg__uIiBb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2HqZs {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__qeMzG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__ch8Ms:global(.__wab_instance) {
  max-width: 100%;
}
.svg___3EFiE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__o2ZiS {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__dZtLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__f1OEk:global(.__wab_instance) {
  max-width: 100%;
}
.svg__yTl7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__dk8Yb {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__tvYsS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__aQl9Y:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qRpnK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tCYu {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: var(--token-eKcWWhfvZ4_f9Lh);
  text-align: left;
  text-transform: none;
  line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  letter-spacing: 0.2px;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: ellipsis !important;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg___902Pb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__auZ:global(.__wab_instance) {
  position: relative;
}
.formGroup__tdcGe:global(.__wab_instance) {
  max-width: 100%;
}
.textInput__mLXj:global(.__wab_instance) {
  position: relative;
}
.svg__yZncF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__hfb6K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__qm0W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zntm2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__fDeyt:global(.__wab_instance) {
  position: relative;
}
.formGroup__kJp14:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput41:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__js82P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zkCz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___1IkWu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___4LfYn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__zuEtj:global(.__wab_instance) {
  position: relative;
}
.text__whZy9 {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput13:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput42:global(.__wab_instance) {
  position: relative;
}
.svg__ekNmx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1FYkW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pa5T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__r7Er1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___0YeHk:global(.__wab_instance) {
  position: relative;
}
.text___5FAFg {
  padding-right: 0px;
}
.svg__iHiXi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lHc1N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__n3DTi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processBottomNav:global(.__wab_instance) {
  max-width: 100%;
  position: fixed;
  left: 0;
  top: auto;
  z-index: 3;
  right: 0;
  bottom: 0;
}
.button__kAyRe:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__mWnhn {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__gk5Jk {
  height: auto;
}
.svg___1Vrid {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__yDuBd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___7KdvC {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___3HOgL {
  height: auto;
}
.svg__ma9Ib {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.submitButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__noL9O {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__kljs5 {
  height: auto;
}
.svg__dnEtu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
