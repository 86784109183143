.root {
  background: var(--token-enYJIO6w3sEL0NO);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-cTBZAIcMp) var(--token-3S5TLMpb5) var(--token-3S5TLMpb5);
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - var(--token-MZ_89NRuN));
  height: calc(100% + var(--token-MZ_89NRuN));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-MZ_89NRuN);
}
.freeBox__mUYgZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__muJi1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.text___2OTIs {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
  color: var(--token-IgvLORwjhxLX5NA);
  margin-right: 4px;
}
.slotTargetAttachmentIndex {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
  color: var(--token-IgvLORwjhxLX5NA);
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svg__ui5Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox___7GXkD {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___7GXkD > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox___7GXkD > :global(.__wab_flex-container) > *,
.freeBox___7GXkD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___7GXkD > :global(.__wab_flex-container) > picture > img,
.freeBox___7GXkD
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3S5TLMpb5);
}
@media (max-width: 480px) {
  .freeBox___7GXkD {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .freeBox___7GXkD > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-MZ_89NRuN));
    height: calc(100% + var(--token-MZ_89NRuN));
  }
}
@media (max-width: 480px) {
  .freeBox___7GXkD > :global(.__wab_flex-container) > *,
  .freeBox___7GXkD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___7GXkD > :global(.__wab_flex-container) > picture > img,
  .freeBox___7GXkD
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-MZ_89NRuN);
  }
}
.attachmentTitle:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.text___0Acfw {
  height: auto;
}
.textInput39:global(.__wab_instance) {
  position: relative;
}
.svg___01JwH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xlyby {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__x6OA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lYe5A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__ewYhh:global(.__wab_instance) {
  position: relative;
}
.attachmentFile:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__dkFnP {
  height: auto;
}
.fileUploadField:global(.__wab_instance) {
  position: relative;
}
.svg__fNg0 {
  object-fit: cover;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  height: 1em;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__olQ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  width: var(--token-hFcnMUlxM);
  height: var(--token-hFcnMUlxM);
  display: none;
}
.img__iXrpp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__iXrpp > picture > img {
  object-fit: cover;
}
.symbolCounter__c2Nhc:global(.__wab_instance) {
  position: relative;
}
