.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root {
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    width: auto;
    padding-right: 24px;
    border-right: 1px solid var(--token-EdVWKDB6CssMfRE);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.rootisHorizontal {
  width: auto;
  padding-right: 24px;
  flex-direction: row;
  display: inline-flex;
  border-right: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.rootisHorizontal > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisHorizontal > :global(.__wab_flex-container) > *,
.rootisHorizontal > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootisHorizontal > :global(.__wab_flex-container) > picture > img,
.rootisHorizontal
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.freeBox__qqc2 {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__qqc2 {
    margin-bottom: 0px;
    width: auto;
    flex-shrink: 1;
  }
}
.freeBoxisHorizontal__qqc24GmZb {
  width: auto;
  height: auto;
  margin-bottom: 0px;
  padding-left: 6px;
  flex-direction: row;
}
.text {
  font-size: 14px;
  font-weight: 500;
}
.freeBox__eut8M {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__eut8M > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__eut8M > :global(.__wab_flex-container) > *,
.freeBox__eut8M > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eut8M > :global(.__wab_flex-container) > picture > img,
.freeBox__eut8M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__eut8M {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__eut8M > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__eut8M > :global(.__wab_flex-container) > *,
  .freeBox__eut8M > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__eut8M > :global(.__wab_flex-container) > picture > img,
  .freeBox__eut8M
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 12px;
    margin-top: 0px;
  }
}
.freeBoxisHorizontal__eut8M4GmZb {
  flex-direction: row;
}
.freeBoxisHorizontal__eut8M4GmZb > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxisHorizontal__eut8M4GmZb > :global(.__wab_flex-container) > *,
.freeBoxisHorizontal__eut8M4GmZb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxisHorizontal__eut8M4GmZb
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxisHorizontal__eut8M4GmZb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
  margin-top: 0px;
}
.navButton__kwRgk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
}
.text__o8Gw2 {
  height: auto;
}
.svg__u7PEr {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
