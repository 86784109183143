@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&display=swap");

.plasmic_tokens {
  --token-NPNMQf7VB8u7Kel: #ffffff;
  --plasmic-token-white: var(--token-NPNMQf7VB8u7Kel);
  --token-enYJIO6w3sEL0NO: #f8fafc;
  --plasmic-token-black-050: var(--token-enYJIO6w3sEL0NO);
  --token-nJQnmTwmF4SPdl6: #f1f5f9;
  --plasmic-token-black-100: var(--token-nJQnmTwmF4SPdl6);
  --token-EdVWKDB6CssMfRE: #e2e8f0;
  --plasmic-token-black-200: var(--token-EdVWKDB6CssMfRE);
  --token-sHkWBDcvLab64_p: #ccd5e1;
  --plasmic-token-black-300: var(--token-sHkWBDcvLab64_p);
  --token-5eiHu7b54PkQh5O: #94a4b8;
  --plasmic-token-black-400: var(--token-5eiHu7b54PkQh5O);
  --token-IgvLORwjhxLX5NA: #64758b;
  --plasmic-token-black-500: var(--token-IgvLORwjhxLX5NA);
  --token-eKcWWhfvZ4_f9Lh: #475669;
  --plasmic-token-black-600: var(--token-eKcWWhfvZ4_f9Lh);
  --token-PcBCP-f11VpIoWX: #334255;
  --plasmic-token-black-700: var(--token-PcBCP-f11VpIoWX);
  --token-IOs4wk1cx7qv8eT: #1e2a3b;
  --plasmic-token-black-800: var(--token-IOs4wk1cx7qv8eT);
  --token-jDfd0ASagPOGtqY: #0f182a;
  --plasmic-token-black-900: var(--token-jDfd0ASagPOGtqY);
  --token-WYpGQpgya-mDjv5: #f0f6ff;
  --plasmic-token-blue-050: var(--token-WYpGQpgya-mDjv5);
  --token-TMjhy7b3EKBSbc2: #ddebfe;
  --plasmic-token-blue-100: var(--token-TMjhy7b3EKBSbc2);
  --token-jHG8H2_EchV1WW_: #c0dafc;
  --plasmic-token-blue-200: var(--token-jHG8H2_EchV1WW_);
  --token-dw-C2qwAAM28VJ9: #96c4f8;
  --plasmic-token-blue-300: var(--token-dw-C2qwAAM28VJ9);
  --token-PjtuKumWd61Gyyy: #66a6f4;
  --plasmic-token-blue-400: var(--token-PjtuKumWd61Gyyy);
  --token-6yTcrF4BA3JOPWz: #4487e4;
  --plasmic-token-blue-500: var(--token-6yTcrF4BA3JOPWz);
  --token-AZBC5tFK07a8DV3: #2e72e0;
  --plasmic-token-blue-600: var(--token-AZBC5tFK07a8DV3);
  --token-_4263rh0uaUoehz: #2a61cf;
  --plasmic-token-blue-700: var(--token-_4263rh0uaUoehz);
  --token-vflMxhHqvUmbGfb: #2851a4;
  --plasmic-token-blue-800: var(--token-vflMxhHqvUmbGfb);
  --token-4Vy3YOrYIv8ljp2: #224087;
  --plasmic-token-blue-900: var(--token-4Vy3YOrYIv8ljp2);
  --token-guD2PP-6Ca2XMJ7: 0.75rem;
  --plasmic-token-text-12-px-xs: var(--token-guD2PP-6Ca2XMJ7);
  --token-wZK1o5uz59Brqbf: 0.88rem;
  --plasmic-token-text-14-px-sm: var(--token-wZK1o5uz59Brqbf);
  --token-FutXsu2VTAR30Ez: 1rem;
  --plasmic-token-text-16-px-base: var(--token-FutXsu2VTAR30Ez);
  --token-c5GLNJb7VW_H03Z: 1.13rem;
  --plasmic-token-text-18-px-lg: var(--token-c5GLNJb7VW_H03Z);
  --token-ueN_0TwhBMb9CkO: 1.25rem;
  --plasmic-token-text-20-px-xl: var(--token-ueN_0TwhBMb9CkO);
  --token-Jhhay0F31-lTY91: 1.5rem;
  --plasmic-token-text-24-px-2-xl: var(--token-Jhhay0F31-lTY91);
  --token-3jOrWkEDNu9ZZIv: 1.88rem;
  --plasmic-token-text-30-px-3-xl: var(--token-3jOrWkEDNu9ZZIv);
  --token-yvx2q5VFKqvBxNc: 2.25rem;
  --plasmic-token-text-36-px-4-xl: var(--token-yvx2q5VFKqvBxNc);
  --token-9G5SM2F6ADGgvTF: 3rem;
  --plasmic-token-text-48-px-5-xl: var(--token-9G5SM2F6ADGgvTF);
  --token-Hi3tuh97XXyAuDz: 3.75rem;
  --plasmic-token-text-60-px-6-xl: var(--token-Hi3tuh97XXyAuDz);
  --token-SG9f8vFCBSVNq8a: 1;
  --plasmic-token-leading-none: var(--token-SG9f8vFCBSVNq8a);
  --token-Ni3U1vx0Lq7Wvb8: 1.25;
  --plasmic-token-leading-tight: var(--token-Ni3U1vx0Lq7Wvb8);
  --token-f2GFyMCUtiJjuKU: 1.38;
  --plasmic-token-leading-snug: var(--token-f2GFyMCUtiJjuKU);
  --token-btgzVWfPOZNm1sG: 1.5;
  --plasmic-token-leading-normal: var(--token-btgzVWfPOZNm1sG);
  --token-ibIDiHgP6g_IUQo: 1.65;
  --plasmic-token-leading-relaxed: var(--token-ibIDiHgP6g_IUQo);
  --token-Hr2PUZAYLVGJXm8: 2;
  --plasmic-token-leading-loose: var(--token-Hr2PUZAYLVGJXm8);
  --token-N7ZH9ZZ89u1SktH: #fcf6ee;
  --plasmic-token-gold-050: var(--token-N7ZH9ZZ89u1SktH);
  --token-vQIHntGSmUGoi3x: #f9eedc;
  --plasmic-token-gold-100: var(--token-vQIHntGSmUGoi3x);
  --token-XVEsAlbFfHq6FLt: #efdbb3;
  --plasmic-token-gold-200: var(--token-XVEsAlbFfHq6FLt);
  --token-cb_Nd6iAoCFyn8W: #e3c382;
  --plasmic-token-gold-300: var(--token-cb_Nd6iAoCFyn8W);
  --token-0_AU74ENX98Rg_i: #d9ad54;
  --plasmic-token-gold-400: var(--token-0_AU74ENX98Rg_i);
  --token-QYymIwPX-bo_uMH: #c99521;
  --plasmic-token-gold-500: var(--token-QYymIwPX-bo_uMH);
  --token-NBmi1EBeYuE24RY: #ab811f;
  --plasmic-token-gold-600: var(--token-NBmi1EBeYuE24RY);
  --token-obdCI9Vzwn59gKD: #8d6e1d;
  --plasmic-token-gold-700: var(--token-obdCI9Vzwn59gKD);
  --token-o919cMZe3pTxZb-: #70591a;
  --plasmic-token-gold-800: var(--token-o919cMZe3pTxZb-);
  --token-Wc2tr2MR59RbrGz: #4c3f15;
  --plasmic-token-gold-900: var(--token-Wc2tr2MR59RbrGz);
  --token-yQ5PNjYizuW9slV: #edfcf5;
  --plasmic-token-green-050: var(--token-yQ5PNjYizuW9slV);
  --token-bYBIsaiSK_SL0wu: #d2f9e5;
  --plasmic-token-green-100: var(--token-bYBIsaiSK_SL0wu);
  --token-HYIomnc-tSnfgIb: #aeefd2;
  --plasmic-token-green-200: var(--token-HYIomnc-tSnfgIb);
  --token-og7KBFmjrPhZ3dc: #78e2b8;
  --plasmic-token-green-300: var(--token-og7KBFmjrPhZ3dc);
  --token-eoOGiUMJC4PLiTG: #47cd9c;
  --plasmic-token-green-400: var(--token-eoOGiUMJC4PLiTG);
  --token-djHL9A1ISxE7ONr: #29b382;
  --plasmic-token-green-500: var(--token-djHL9A1ISxE7ONr);
  --token-vejaCN-6u7zUCAr: #1c926a;
  --plasmic-token-green-600: var(--token-vejaCN-6u7zUCAr);
  --token-CXh3DsvYUDbb4NE: #157557;
  --plasmic-token-green-700: var(--token-CXh3DsvYUDbb4NE);
  --token-ewKIolzB6wqGTPX: #115a44;
  --plasmic-token-green-800: var(--token-ewKIolzB6wqGTPX);
  --token--1wl_F9deuezOld: #0d4a3a;
  --plasmic-token-green-900: var(--token--1wl_F9deuezOld);
  --token-Lymfn4M1YIjdOgD: #fdf2f2;
  --plasmic-token-red-050: var(--token-Lymfn4M1YIjdOgD);
  --token-zzo8jryirB3foEj: #fce4e3;
  --plasmic-token-red-100: var(--token-zzo8jryirB3foEj);
  --token-gUr8y4ZPkdFjJ7H: #fbcccb;
  --plasmic-token-red-200: var(--token-gUr8y4ZPkdFjJ7H);
  --token-8Upm2qPCoH9Sb-9: #f8adab;
  --plasmic-token-red-300: var(--token-8Upm2qPCoH9Sb-9);
  --token-k8MBdjDfsyp6Vsa: #f17d79;
  --plasmic-token-red-400: var(--token-k8MBdjDfsyp6Vsa);
  --token-2N8zcUvyUXwuOJv: #e4534e;
  --plasmic-token-red-500: var(--token-2N8zcUvyUXwuOJv);
  --token-Y_1QycPpMrs0BhH: #d03a35;
  --plasmic-token-red-600: var(--token-Y_1QycPpMrs0BhH);
  --token-oT7tnXfbXJAOlYI: #b02b27;
  --plasmic-token-red-700: var(--token-oT7tnXfbXJAOlYI);
  --token-ZC2EQaLgStzAJRW: #8f2724;
  --plasmic-token-red-800: var(--token-ZC2EQaLgStzAJRW);
  --token-jW34YHQobNMSD0Y: #792825;
  --plasmic-token-red-900: var(--token-jW34YHQobNMSD0Y);
  --token-zn-VjztWD: #fff7ed;
  --plasmic-token-orange-050: var(--token-zn-VjztWD);
  --token-rsLFZ8cZG: #ffedd5;
  --plasmic-token-orange-100: var(--token-rsLFZ8cZG);
  --token-lNcAUqsk8: #fed7aa;
  --plasmic-token-orange-200: var(--token-lNcAUqsk8);
  --token-p6OGFuM2O: #fdba74;
  --plasmic-token-orange-300: var(--token-p6OGFuM2O);
  --token-mOJuRJhlC: #fb923c;
  --plasmic-token-orange-400: var(--token-mOJuRJhlC);
  --token-G449N6G1x: #f97316;
  --plasmic-token-orange-500: var(--token-G449N6G1x);
  --token-Hken4BJDt: #ea580c;
  --plasmic-token-orange-600: var(--token-Hken4BJDt);
  --token-6NXubVByI: #c2410c;
  --plasmic-token-orange-700: var(--token-6NXubVByI);
  --token-6i7l1huu6: #9a3412;
  --plasmic-token-orange-800: var(--token-6i7l1huu6);
  --token-ZpmSrRJAo: #7c2d12;
  --plasmic-token-orange-900: var(--token-ZpmSrRJAo);
  --token-klOkpNg9M: 1px;
  --plasmic-token-px: var(--token-klOkpNg9M);
  --token-5K6-m7eB7: 0.125rem;
  --plasmic-token-0-5: var(--token-5K6-m7eB7);
  --token-nPzqyQpH_: 0.25rem;
  --plasmic-token-1: var(--token-nPzqyQpH_);
  --token-MhDGai0bW: 0.375rem;
  --plasmic-token-1-5: var(--token-MhDGai0bW);
  --token-8IFSbVVlb: 0.5rem;
  --plasmic-token-2: var(--token-8IFSbVVlb);
  --token-uoDiu2hxc: 0.625rem;
  --plasmic-token-2-5: var(--token-uoDiu2hxc);
  --token-H3JPdTVdV: 0.75rem;
  --plasmic-token-3: var(--token-H3JPdTVdV);
  --token-B5jiJYDEm: 0.875rem;
  --plasmic-token-3-5: var(--token-B5jiJYDEm);
  --token-MZ_89NRuN: 1rem;
  --plasmic-token-4: var(--token-MZ_89NRuN);
  --token-cTBZAIcMp: 1.25rem;
  --plasmic-token-5: var(--token-cTBZAIcMp);
  --token-3S5TLMpb5: 1.5rem;
  --plasmic-token-6: var(--token-3S5TLMpb5);
  --token-SAmzyWXiq: 1.75rem;
  --plasmic-token-7: var(--token-SAmzyWXiq);
  --token-hFcnMUlxM: 2rem;
  --plasmic-token-8: var(--token-hFcnMUlxM);
  --token-0Rp89NVfd: 2.25rem;
  --plasmic-token-9: var(--token-0Rp89NVfd);
  --token-7dIrE6133: 2.5rem;
  --plasmic-token-10: var(--token-7dIrE6133);
  --token-VGKa8jF1Z: 2.75rem;
  --plasmic-token-11: var(--token-VGKa8jF1Z);
  --token-3wiInXIIr: 3rem;
  --plasmic-token-12: var(--token-3wiInXIIr);
  --token-CzO0IA07s: 3.5rem;
  --plasmic-token-14: var(--token-CzO0IA07s);
  --token-xZRZ7hK_d: 4rem;
  --plasmic-token-16: var(--token-xZRZ7hK_d);
  --token-j8nt-mARd: 5rem;
  --plasmic-token-20: var(--token-j8nt-mARd);
  --token-uoU4lPaiz: 6rem;
  --plasmic-token-24: var(--token-uoU4lPaiz);
  --token-dXQStyNXW: 7rem;
  --plasmic-token-28: var(--token-dXQStyNXW);
  --token-b5PDHnTdQ: 8rem;
  --plasmic-token-32: var(--token-b5PDHnTdQ);
  --token-t-eyXinZg: 9rem;
  --plasmic-token-36: var(--token-t-eyXinZg);
  --token-PiZXYHqcL: 10rem;
  --plasmic-token-40: var(--token-PiZXYHqcL);
  --token-MKhcerXSh: 11rem;
  --plasmic-token-44: var(--token-MKhcerXSh);
  --token-vb4EjUHDK: 12rem;
  --plasmic-token-48: var(--token-vb4EjUHDK);
  --token-HrIbAru9T: 13rem;
  --plasmic-token-52: var(--token-HrIbAru9T);
  --token-GanpBnVQZ: 14rem;
  --plasmic-token-56: var(--token-GanpBnVQZ);
  --token-74Pg-_7te: 15rem;
  --plasmic-token-60: var(--token-74Pg-_7te);
  --token-_-xtZmZWG: 16rem;
  --plasmic-token-64: var(--token-_-xtZmZWG);
  --token-hbRPv7iqh: 18rem;
  --plasmic-token-72: var(--token-hbRPv7iqh);
  --token-_p-4BVfqH: 20rem;
  --plasmic-token-80: var(--token-_p-4BVfqH);
  --token-guaW8-HHw: 24rem;
  --plasmic-token-96: var(--token-guaW8-HHw);
  --token-ZBkpiPCm_28n: #ff0000;
  --plasmic-token-ff-0000: var(--token-ZBkpiPCm_28n);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-KUDEXtKB62Fq5G2_font-family: "Inter", sans-serif;
  --mixin-KUDEXtKB62Fq5G2_font-size: 16px;
  --mixin-KUDEXtKB62Fq5G2_font-weight: 400;
  --mixin-KUDEXtKB62Fq5G2_font-style: normal;
  --mixin-KUDEXtKB62Fq5G2_color: var(--token-IOs4wk1cx7qv8eT);
  --mixin-KUDEXtKB62Fq5G2_text-align: left;
  --mixin-KUDEXtKB62Fq5G2_text-transform: none;
  --mixin-KUDEXtKB62Fq5G2_line-height: 1.5;
  --mixin-KUDEXtKB62Fq5G2_letter-spacing: normal;
  --mixin-KUDEXtKB62Fq5G2_white-space: pre-wrap;
  --mixin-KUDEXtKB62Fq5G2_user-select: text;
  --mixin-KUDEXtKB62Fq5G2_text-decoration-line: none;
  --mixin-KUDEXtKB62Fq5G2_text-overflow: clip;
  --mixin-h-uIRQQlrQsEqjM_font-weight: 600;
  --mixin-h-uIRQQlrQsEqjM_font-size: var(--token-Hi3tuh97XXyAuDz);
  --mixin-h-uIRQQlrQsEqjM_line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  --mixin-h-uIRQQlrQsEqjM_letter-spacing: -1px;
  --mixin-h-uIRQQlrQsEqjM_white-space: pre-wrap;
  --mixin-f1pWlBW4mYIzu6Z_font-size: var(--token-9G5SM2F6ADGgvTF);
  --mixin-f1pWlBW4mYIzu6Z_font-weight: 600;
  --mixin-f1pWlBW4mYIzu6Z_letter-spacing: -1px;
  --mixin-f1pWlBW4mYIzu6Z_line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  --mixin-f1pWlBW4mYIzu6Z_white-space: pre-wrap;
  --mixin-fICeg6ZY-KgtS0p_color: var(--token-AZBC5tFK07a8DV3);
  --mixin-fICeg6ZY-KgtS0p_transition-property: color;
  --mixin-fICeg6ZY-KgtS0p_transition-duration: 0.2s;
  --mixin-fICeg6ZY-KgtS0p_transition-timing-function: ease-in-out;
  --mixin-fICeg6ZY-KgtS0p_white-space: pre-wrap;
  --mixin-2dEJOUSrPK3vPRo_font-size: var(--token-yvx2q5VFKqvBxNc);
  --mixin-2dEJOUSrPK3vPRo_font-weight: 600;
  --mixin-2dEJOUSrPK3vPRo_letter-spacing: -0.8px;
  --mixin-2dEJOUSrPK3vPRo_line-height: var(--token-Ni3U1vx0Lq7Wvb8);
  --mixin-2dEJOUSrPK3vPRo_white-space: pre-wrap;
  --mixin-fabbhbKNRcfCeGb_font-size: var(--token-Jhhay0F31-lTY91);
  --mixin-fabbhbKNRcfCeGb_font-weight: 600;
  --mixin-fabbhbKNRcfCeGb_letter-spacing: -0.5px;
  --mixin-fabbhbKNRcfCeGb_line-height: var(--token-f2GFyMCUtiJjuKU);
  --mixin-fabbhbKNRcfCeGb_white-space: pre-wrap;
  --mixin-IIJR1aDA0HdHwlf_background: linear-gradient(
    var(--token-nJQnmTwmF4SPdl6),
    var(--token-nJQnmTwmF4SPdl6)
  );
  --mixin-IIJR1aDA0HdHwlf_border-bottom-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-bottom-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-bottom-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-left-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-left-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-left-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-right-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-right-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-right-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-top-color: var(--token-sHkWBDcvLab64_p);
  --mixin-IIJR1aDA0HdHwlf_border-top-style: solid;
  --mixin-IIJR1aDA0HdHwlf_border-top-width: 1px;
  --mixin-IIJR1aDA0HdHwlf_border-bottom-left-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_border-bottom-right-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_border-top-left-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_border-top-right-radius: 4px;
  --mixin-IIJR1aDA0HdHwlf_font-family: "Inconsolata";
  --mixin-IIJR1aDA0HdHwlf_padding-bottom: 4px;
  --mixin-IIJR1aDA0HdHwlf_padding-left: 8px;
  --mixin-IIJR1aDA0HdHwlf_padding-right: 8px;
  --mixin-IIJR1aDA0HdHwlf_padding-top: 4px;
  --mixin-IIJR1aDA0HdHwlf_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-IIJR1aDA0HdHwlf_color: var(--token-eKcWWhfvZ4_f9Lh);
  --mixin-IIJR1aDA0HdHwlf_white-space: pre-wrap;
  --mixin-pvMPPZ5C_Wmc5IA_border-left-color: var(--token-sHkWBDcvLab64_p);
  --mixin-pvMPPZ5C_Wmc5IA_border-left-style: solid;
  --mixin-pvMPPZ5C_Wmc5IA_border-left-width: 3px;
  --mixin-pvMPPZ5C_Wmc5IA_color: var(--token-PcBCP-f11VpIoWX);
  --mixin-pvMPPZ5C_Wmc5IA_padding-left: 10px;
  --mixin-pvMPPZ5C_Wmc5IA_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-pvMPPZ5C_Wmc5IA_white-space: pre-wrap;
  --mixin-qwhBWAvcBzcyxtd_background: linear-gradient(
    var(--token-nJQnmTwmF4SPdl6),
    var(--token-nJQnmTwmF4SPdl6)
  );
  --mixin-qwhBWAvcBzcyxtd_border-bottom-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-bottom-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-bottom-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-left-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-left-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-left-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-right-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-right-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-right-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-top-color: var(--token-sHkWBDcvLab64_p);
  --mixin-qwhBWAvcBzcyxtd_border-top-style: solid;
  --mixin-qwhBWAvcBzcyxtd_border-top-width: 1px;
  --mixin-qwhBWAvcBzcyxtd_border-bottom-left-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_border-bottom-right-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_border-top-left-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_border-top-right-radius: 3px;
  --mixin-qwhBWAvcBzcyxtd_font-family: "Inconsolata";
  --mixin-qwhBWAvcBzcyxtd_padding-bottom: 4px;
  --mixin-qwhBWAvcBzcyxtd_padding-left: 8px;
  --mixin-qwhBWAvcBzcyxtd_padding-right: 8px;
  --mixin-qwhBWAvcBzcyxtd_padding-top: 4px;
  --mixin-qwhBWAvcBzcyxtd_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-qwhBWAvcBzcyxtd_white-space: pre-wrap;
  --mixin-mjShifBwQ_ME8gg_display: flex;
  --mixin-mjShifBwQ_ME8gg_flex-direction: column;
  --mixin-mjShifBwQ_ME8gg_align-items: stretch;
  --mixin-mjShifBwQ_ME8gg_justify-content: flex-start;
  --mixin-mjShifBwQ_ME8gg_list-style-position: outside;
  --mixin-mjShifBwQ_ME8gg_position: relative;
  --mixin-mjShifBwQ_ME8gg_list-style-type: disc;
  --mixin-mjShifBwQ_ME8gg_padding-left: 16px;
  --mixin-mjShifBwQ_ME8gg_white-space: pre-wrap;
  --mixin-medEuCdgXEHoGZV_display: flex;
  --mixin-medEuCdgXEHoGZV_flex-direction: column;
  --mixin-medEuCdgXEHoGZV_align-items: stretch;
  --mixin-medEuCdgXEHoGZV_justify-content: flex-start;
  --mixin-medEuCdgXEHoGZV_list-style-position: outside;
  --mixin-medEuCdgXEHoGZV_padding-left: 40px;
  --mixin-medEuCdgXEHoGZV_position: relative;
  --mixin-medEuCdgXEHoGZV_list-style-type: decimal;
  --mixin-medEuCdgXEHoGZV_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-medEuCdgXEHoGZV_white-space: pre-wrap;
  --mixin-2U3cIj4s81v7rqo_font-family: "Inter", sans-serif;
  --mixin-2U3cIj4s81v7rqo_font-size: var(--token-ueN_0TwhBMb9CkO);
  --mixin-2U3cIj4s81v7rqo_font-weight: 600;
  --mixin-2U3cIj4s81v7rqo_line-height: var(--token-btgzVWfPOZNm1sG);
  --mixin-2U3cIj4s81v7rqo_white-space: pre-wrap;
  --mixin-HGj53jAernv5xhH_font-size: var(--token-FutXsu2VTAR30Ez);
  --mixin-HGj53jAernv5xhH_font-weight: 600;
  --mixin-HGj53jAernv5xhH_line-height: var(--token-btgzVWfPOZNm1sG);
  --mixin-HGj53jAernv5xhH_white-space: pre-wrap;
  --mixin-Q0IcMWzpenUJtNM_color: var(--token-PjtuKumWd61Gyyy);
  --mixin-Q0IcMWzpenUJtNM_text-decoration-line: underline;
  --mixin-Q0IcMWzpenUJtNM_white-space: pre-wrap;
  --mixin-zcecuYNRykTX4mY_white-space: pre-wrap;
  --mixin-t0PoiMIp13AOapI_white-space: pre-wrap;
  --mixin-2s_5IASZcfaX4LW_white-space: pre-wrap;
  --mixin-PA4NLZBtPK6nsS-_white-space: pre-wrap;
  --mixin-AU9KxcstOJWk9cK_white-space: pre-wrap;
  --mixin-ehQNbzktU_white-space: pre-wrap;
  --mixin-thUo4KsBx_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-5s4jVsOh2N9o6fo_white-space: pre-wrap;
  --plasmic-mixin-border-top_white-space: var(
    --mixin-5s4jVsOh2N9o6fo_white-space
  );
  --mixin-HNNSprb9GSzhuyw_white-space: pre-wrap;
  --plasmic-mixin-border-bottom_white-space: var(
    --mixin-HNNSprb9GSzhuyw_white-space
  );
  --mixin-Iz5Q33kT1k6uijq_white-space: pre-wrap;
  --plasmic-mixin-border-left_white-space: var(
    --mixin-Iz5Q33kT1k6uijq_white-space
  );
  --mixin-kXeDacjuoH-T4vZ_white-space: pre-wrap;
  --plasmic-mixin-border-right_white-space: var(
    --mixin-kXeDacjuoH-T4vZ_white-space
  );
  --mixin-8HmLQR447_white-space: pre-wrap;
  --plasmic-mixin-radius-sm_white-space: var(--mixin-8HmLQR447_white-space);
  --mixin-RDEbDLrCSlAXwRn_white-space: pre-wrap;
  --plasmic-mixin-radius_white-space: var(--mixin-RDEbDLrCSlAXwRn_white-space);
  --mixin-QYEOt0iYM_white-space: pre-wrap;
  --plasmic-mixin-radius-md_white-space: var(--mixin-QYEOt0iYM_white-space);
  --mixin-7awkawNas_white-space: pre-wrap;
  --plasmic-mixin-radius-lg_white-space: var(--mixin-7awkawNas_white-space);
  --mixin-DAX85Atlc_white-space: pre-wrap;
  --plasmic-mixin-radius-rounded_white-space: var(
    --mixin-DAX85Atlc_white-space
  );
  --mixin-13SviTVaNp-HKiG_white-space: pre-wrap;
  --plasmic-mixin-card_white-space: var(--mixin-13SviTVaNp-HKiG_white-space);
  --mixin-glKfXnNY2_box-shadow: 0px 1px 3px -1px #00000014;
  --plasmic-mixin-shadow-sm_box-shadow: var(--mixin-glKfXnNY2_box-shadow);
  --mixin-glKfXnNY2_white-space: pre-wrap;
  --plasmic-mixin-shadow-sm_white-space: var(--mixin-glKfXnNY2_white-space);
  --mixin-Kb513zc90_box-shadow: 0px 1px 3px 0px #0000001a,
    0px 1px 2px -1px #0000001a;
  --plasmic-mixin-shadow_box-shadow: var(--mixin-Kb513zc90_box-shadow);
  --mixin-Kb513zc90_white-space: pre-wrap;
  --plasmic-mixin-shadow_white-space: var(--mixin-Kb513zc90_white-space);
  --mixin-n498IAZ-a_box-shadow: 0px 4px 6px -1px #0000001a,
    0px 2px 4px -2px #0000001a;
  --plasmic-mixin-shadow-md_box-shadow: var(--mixin-n498IAZ-a_box-shadow);
  --mixin-n498IAZ-a_white-space: pre-wrap;
  --plasmic-mixin-shadow-md_white-space: var(--mixin-n498IAZ-a_white-space);
  --mixin-JdXKs23YF_box-shadow: 0px 10px 15px -4px #0000001a,
    0px 4px 6px -4px #0000001a;
  --plasmic-mixin-shadow-lg_box-shadow: var(--mixin-JdXKs23YF_box-shadow);
  --mixin-JdXKs23YF_white-space: pre-wrap;
  --plasmic-mixin-shadow-lg_white-space: var(--mixin-JdXKs23YF_white-space);
  --mixin-7PoL-cabK_box-shadow: 0px 20px 25px -5px #0000001a,
    0px 8px 10px -6px #0000001a;
  --plasmic-mixin-shadow-xl_box-shadow: var(--mixin-7PoL-cabK_box-shadow);
  --mixin-7PoL-cabK_white-space: pre-wrap;
  --plasmic-mixin-shadow-xl_white-space: var(--mixin-7PoL-cabK_white-space);
  --mixin-ek-o6keUY_white-space: pre-wrap;
  --plasmic-mixin-caps-title-small_white-space: var(
    --mixin-ek-o6keUY_white-space
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-KUDEXtKB62Fq5G2_font-family);
  font-size: var(--mixin-KUDEXtKB62Fq5G2_font-size);
  font-weight: var(--mixin-KUDEXtKB62Fq5G2_font-weight);
  font-style: var(--mixin-KUDEXtKB62Fq5G2_font-style);
  color: var(--mixin-KUDEXtKB62Fq5G2_color);
  text-align: var(--mixin-KUDEXtKB62Fq5G2_text-align);
  text-transform: var(--mixin-KUDEXtKB62Fq5G2_text-transform);
  line-height: var(--mixin-KUDEXtKB62Fq5G2_line-height);
  letter-spacing: var(--mixin-KUDEXtKB62Fq5G2_letter-spacing);
  white-space: var(--mixin-KUDEXtKB62Fq5G2_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  font-weight: var(--mixin-h-uIRQQlrQsEqjM_font-weight);
  font-size: var(--mixin-h-uIRQQlrQsEqjM_font-size);
  line-height: var(--mixin-h-uIRQQlrQsEqjM_line-height);
  letter-spacing: var(--mixin-h-uIRQQlrQsEqjM_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  font-size: var(--mixin-f1pWlBW4mYIzu6Z_font-size);
  font-weight: var(--mixin-f1pWlBW4mYIzu6Z_font-weight);
  letter-spacing: var(--mixin-f1pWlBW4mYIzu6Z_letter-spacing);
  line-height: var(--mixin-f1pWlBW4mYIzu6Z_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-fICeg6ZY-KgtS0p_color);
  transition-property: var(--mixin-fICeg6ZY-KgtS0p_transition-property);
  transition-duration: var(--mixin-fICeg6ZY-KgtS0p_transition-duration);
  transition-timing-function: var(
    --mixin-fICeg6ZY-KgtS0p_transition-timing-function
  );
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-size: var(--mixin-2dEJOUSrPK3vPRo_font-size);
  font-weight: var(--mixin-2dEJOUSrPK3vPRo_font-weight);
  letter-spacing: var(--mixin-2dEJOUSrPK3vPRo_letter-spacing);
  line-height: var(--mixin-2dEJOUSrPK3vPRo_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-size: var(--mixin-fabbhbKNRcfCeGb_font-size);
  font-weight: var(--mixin-fabbhbKNRcfCeGb_font-weight);
  letter-spacing: var(--mixin-fabbhbKNRcfCeGb_letter-spacing);
  line-height: var(--mixin-fabbhbKNRcfCeGb_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: var(--token-nJQnmTwmF4SPdl6);
  font-family: var(--mixin-IIJR1aDA0HdHwlf_font-family);
  font-size: var(--mixin-IIJR1aDA0HdHwlf_font-size);
  color: var(--mixin-IIJR1aDA0HdHwlf_color);
  border-radius: var(--mixin-IIJR1aDA0HdHwlf_border-top-left-radius)
    var(--mixin-IIJR1aDA0HdHwlf_border-top-right-radius)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-right-radius)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-left-radius);
  padding: var(--mixin-IIJR1aDA0HdHwlf_padding-top)
    var(--mixin-IIJR1aDA0HdHwlf_padding-right)
    var(--mixin-IIJR1aDA0HdHwlf_padding-bottom)
    var(--mixin-IIJR1aDA0HdHwlf_padding-left);
  border-top: var(--mixin-IIJR1aDA0HdHwlf_border-top-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-top-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-top-color);
  border-right: var(--mixin-IIJR1aDA0HdHwlf_border-right-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-right-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-right-color);
  border-bottom: var(--mixin-IIJR1aDA0HdHwlf_border-bottom-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-bottom-color);
  border-left: var(--mixin-IIJR1aDA0HdHwlf_border-left-width)
    var(--mixin-IIJR1aDA0HdHwlf_border-left-style)
    var(--mixin-IIJR1aDA0HdHwlf_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-pvMPPZ5C_Wmc5IA_color);
  padding-left: var(--mixin-pvMPPZ5C_Wmc5IA_padding-left);
  font-size: var(--mixin-pvMPPZ5C_Wmc5IA_font-size);
  border-left: var(--mixin-pvMPPZ5C_Wmc5IA_border-left-width)
    var(--mixin-pvMPPZ5C_Wmc5IA_border-left-style)
    var(--mixin-pvMPPZ5C_Wmc5IA_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: var(--token-nJQnmTwmF4SPdl6);
  font-family: var(--mixin-qwhBWAvcBzcyxtd_font-family);
  font-size: var(--mixin-qwhBWAvcBzcyxtd_font-size);
  border-radius: var(--mixin-qwhBWAvcBzcyxtd_border-top-left-radius)
    var(--mixin-qwhBWAvcBzcyxtd_border-top-right-radius)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-right-radius)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-left-radius);
  padding: var(--mixin-qwhBWAvcBzcyxtd_padding-top)
    var(--mixin-qwhBWAvcBzcyxtd_padding-right)
    var(--mixin-qwhBWAvcBzcyxtd_padding-bottom)
    var(--mixin-qwhBWAvcBzcyxtd_padding-left);
  border-top: var(--mixin-qwhBWAvcBzcyxtd_border-top-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-top-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-top-color);
  border-right: var(--mixin-qwhBWAvcBzcyxtd_border-right-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-right-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-right-color);
  border-bottom: var(--mixin-qwhBWAvcBzcyxtd_border-bottom-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-bottom-color);
  border-left: var(--mixin-qwhBWAvcBzcyxtd_border-left-width)
    var(--mixin-qwhBWAvcBzcyxtd_border-left-style)
    var(--mixin-qwhBWAvcBzcyxtd_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-mjShifBwQ_ME8gg_display);
  flex-direction: var(--mixin-mjShifBwQ_ME8gg_flex-direction);
  align-items: var(--mixin-mjShifBwQ_ME8gg_align-items);
  justify-content: var(--mixin-mjShifBwQ_ME8gg_justify-content);
  list-style-position: var(--mixin-mjShifBwQ_ME8gg_list-style-position);
  position: var(--mixin-mjShifBwQ_ME8gg_position);
  list-style-type: var(--mixin-mjShifBwQ_ME8gg_list-style-type);
  padding-left: var(--mixin-mjShifBwQ_ME8gg_padding-left);
  flex-column-gap: var(--mixin-mjShifBwQ_ME8gg_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-medEuCdgXEHoGZV_display);
  flex-direction: var(--mixin-medEuCdgXEHoGZV_flex-direction);
  align-items: var(--mixin-medEuCdgXEHoGZV_align-items);
  justify-content: var(--mixin-medEuCdgXEHoGZV_justify-content);
  list-style-position: var(--mixin-medEuCdgXEHoGZV_list-style-position);
  padding-left: var(--mixin-medEuCdgXEHoGZV_padding-left);
  position: var(--mixin-medEuCdgXEHoGZV_position);
  list-style-type: var(--mixin-medEuCdgXEHoGZV_list-style-type);
  font-size: var(--mixin-medEuCdgXEHoGZV_font-size);
  flex-column-gap: var(--mixin-medEuCdgXEHoGZV_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-2U3cIj4s81v7rqo_font-family);
  font-size: var(--mixin-2U3cIj4s81v7rqo_font-size);
  font-weight: var(--mixin-2U3cIj4s81v7rqo_font-weight);
  line-height: var(--mixin-2U3cIj4s81v7rqo_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-size: var(--mixin-HGj53jAernv5xhH_font-size);
  font-weight: var(--mixin-HGj53jAernv5xhH_font-weight);
  line-height: var(--mixin-HGj53jAernv5xhH_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-Q0IcMWzpenUJtNM_color);
  text-decoration-line: var(--mixin-Q0IcMWzpenUJtNM_text-decoration-line);
}

:where(.root_reset) a:where(.a):active,
a:where(.root_reset.a):active,
:where(.root_reset .__wab_expr_html_text) a:active,
:where(.root_reset_tags) a:active,
a:where(.root_reset_tags):active {
}

:where(.root_reset) a:where(.a):focus,
a:where(.root_reset.a):focus,
:where(.root_reset .__wab_expr_html_text) a:focus,
:where(.root_reset_tags) a:focus,
a:where(.root_reset_tags):focus {
}

:where(.root_reset) blockquote:where(.blockquote):focus,
blockquote:where(.root_reset.blockquote):focus,
:where(.root_reset .__wab_expr_html_text) blockquote:focus,
:where(.root_reset_tags) blockquote:focus,
blockquote:where(.root_reset_tags):focus {
}

:where(.root_reset) blockquote:where(.blockquote):hover,
blockquote:where(.root_reset.blockquote):hover,
:where(.root_reset .__wab_expr_html_text) blockquote:hover,
:where(.root_reset_tags) blockquote:hover,
blockquote:where(.root_reset_tags):hover {
}

:where(.root_reset) code:where(.code):hover,
code:where(.root_reset.code):hover,
:where(.root_reset .__wab_expr_html_text) code:hover,
:where(.root_reset_tags) code:hover,
code:where(.root_reset_tags):hover {
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
