.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-7dIrE6133);
  padding-bottom: var(--token-7dIrE6133);
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - var(--token-7dIrE6133));
  width: calc(100% + var(--token-7dIrE6133));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-7dIrE6133);
}
@media (max-width: 480px) {
  .root {
    display: flex;
    flex-direction: column;
    padding-top: var(--token-3S5TLMpb5);
    padding-bottom: var(--token-3S5TLMpb5);
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-MZ_89NRuN));
    height: calc(100% + var(--token-MZ_89NRuN));
  }
}
@media (max-width: 480px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-MZ_89NRuN);
  }
}
.roothasDivider {
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-nPzqyQpH_));
  height: calc(100% + var(--token-nPzqyQpH_));
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-nPzqyQpH_);
}
.slotTargetSmallTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
.slotTargetTitle {
  font-size: var(--token-FutXsu2VTAR30Ez);
  font-weight: 500;
}
.formGroup:global(.__wab_instance) {
  width: 40%;
}
@media (max-width: 480px) {
  .formGroup:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.booleanSelectButtonGroup:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter:global(.__wab_instance) {
  position: relative;
}
