.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  justify-self: flex-start;
  min-width: 0;
  min-height: 0;
}
.roottextAlignment_left {
  justify-content: flex-start;
  align-items: flex-start;
}
.roottextAlignment_center {
  justify-content: flex-start;
  align-items: center;
}
.roottextAlignment_right {
  justify-content: flex-start;
  align-items: flex-end;
}
.freeBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px 12px 8px 8px;
}
.slotTargetText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-eKcWWhfvZ4_f9Lh);
  white-space: pre;
  text-align: left;
}
.slotTargetTexttextAlignment_left {
  text-align: left;
}
.slotTargetTexttextAlignment_center {
  text-align: center;
}
.slotTargetTexttextAlignment_right {
  text-align: right;
}
.slotTargetTextisBold {
  font-weight: 600;
}
