.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
.banner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text {
  color: var(--token-PcBCP-f11VpIoWX);
}
.freeBox__khk3Y {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#ffffff, #ffffff), var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 10px 15px -4px #0000001a, 0px 4px 6px -4px #0000001a;
  min-width: 0;
  border-radius: 6px;
  padding: 32px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.freeBox__khk3Y > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__khk3Y > :global(.__wab_flex-container) > *,
.freeBox__khk3Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__khk3Y > :global(.__wab_flex-container) > picture > img,
.freeBox__khk3Y
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 480px) {
  .freeBox__khk3Y {
    padding: var(--token-3S5TLMpb5);
  }
}
.freeBox__xbM8Q {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  min-width: 0;
}
@media (max-width: 480px) {
  .freeBox__xbM8Q {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    box-shadow: none;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    padding: 0px;
    border-style: none;
  }
}
.dynamicImpressionListItem__dQhPm:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
.dynamicImpressionListItem__orAs9:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 1;
}
.freeBox__ypBni {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ypBni > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__ypBni > :global(.__wab_flex-container) > *,
.freeBox__ypBni > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ypBni > :global(.__wab_flex-container) > picture > img,
.freeBox__ypBni
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.addButton:global(.__wab_instance) {
  max-width: 100%;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svg__lSPfC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.itemsCounter:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
