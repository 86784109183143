.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  min-width: 0;
  padding: 22px 24px;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .root > :global(.__wab_flex-container) {
    justify-content: space-evenly;
  }
}
@media (max-width: 480px) {
  .root {
    padding: 16px;
  }
}
.freeBox__dRhZ {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__dRhZ > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-3S5TLMpb5));
  width: calc(100% + var(--token-3S5TLMpb5));
}
.freeBox__dRhZ > :global(.__wab_flex-container) > *,
.freeBox__dRhZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dRhZ > :global(.__wab_flex-container) > picture > img,
.freeBox__dRhZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-3S5TLMpb5);
}
.freeBox__xEqTc {
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
  flex-shrink: 0;
}
.freeBox__xEqTc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__xEqTc > :global(.__wab_flex-container) > *,
.freeBox__xEqTc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xEqTc > :global(.__wab_flex-container) > picture > img,
.freeBox__xEqTc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__xEqTc {
    width: auto;
  }
}
.img {
  position: relative;
  object-fit: scale-down;
  max-width: 88px;
}
.img > picture > img {
  object-fit: scale-down;
}
@media (max-width: 480px) {
  .img {
    display: none !important;
  }
}
.navWrapper {
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
  flex-shrink: 0;
}
.navWrapper > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.navWrapper > :global(.__wab_flex-container) > *,
.navWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.navWrapper > :global(.__wab_flex-container) > picture > img,
.navWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.navigationButtonModule:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .navigationButtonModule:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg___2SoGg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___81Isy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navigationButtonArchive:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .navigationButtonArchive:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__d7I0C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hqMdU {
  height: auto;
}
.svg__hzTq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navigationButtonTraining:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .navigationButtonTraining:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__jpvZr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__n3Nsx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navigationButtonAdmin:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .navigationButtonAdmin:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__zh3Zm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wW1Zg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navigationButtonApiDocs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .navigationButtonApiDocs:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__jRxqF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__t5WGn {
  height: auto;
}
.svg__cmP0K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__jiK6H {
  display: flex;
  flex-direction: row;
  height: auto;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.freeBox__jiK6H > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__jiK6H > :global(.__wab_flex-container) > *,
.freeBox__jiK6H > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jiK6H > :global(.__wab_flex-container) > picture > img,
.freeBox__jiK6H
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__jiK6H {
    width: auto;
  }
}
@media (max-width: 480px) {
  .freeBox__jiK6H > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .freeBox__jiK6H > :global(.__wab_flex-container) > *,
  .freeBox__jiK6H > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__jiK6H > :global(.__wab_flex-container) > picture > img,
  .freeBox__jiK6H
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.navHelp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .navHelp:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .navHelp:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__yY83 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zeukj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navProfileDropdown:global(.__wab_instance):global(.__wab_instance) {
  position: relative;
}
