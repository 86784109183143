.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.hoverBg2 {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: 0;
  top: 0;
  background: var(--token-NPNMQf7VB8u7Kel);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  right: 0;
  bottom: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 6px;
}
.freeBox__vjOr4 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 120px;
}
.freeBox__qlyq6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--token-0Rp89NVfd);
  min-width: 0;
  padding: 7px var(--token-8IFSbVVlb);
}
.slotTargetUsageType {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
}
.slotTargetUsageType > :global(.__wab_text),
.slotTargetUsageType > :global(.__wab_expr_html_text),
.slotTargetUsageType > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetUsageType > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetUsageType
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
  text-overflow: clip;
}
.freeBox___1Hl1M {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 120px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___1Hl1M > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___1Hl1M > :global(.__wab_flex-container) > *,
.freeBox___1Hl1M > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1Hl1M > :global(.__wab_flex-container) > picture > img,
.freeBox___1Hl1M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.unitNumber:global(.__wab_instance) {
  position: relative;
}
.svg__hxTwp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dmx1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__x9PuB {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__lxGjY {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__uwsCe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__v1Wfx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__mmMu2 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 120px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__mmMu2 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__mmMu2 > :global(.__wab_flex-container) > *,
.freeBox__mmMu2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mmMu2 > :global(.__wab_flex-container) > picture > img,
.freeBox__mmMu2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.area:global(.__wab_instance) {
  position: relative;
}
.areaisParkplatz:global(.__wab_instance) {
  display: none;
}
.svg__vjpvR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pfmaN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__rfQk {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__o1OYf {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__yGoJe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vu44 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__u8VWx {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__u8VWx > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__u8VWx > :global(.__wab_flex-container) > *,
.freeBox__u8VWx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__u8VWx > :global(.__wab_flex-container) > picture > img,
.freeBox__u8VWx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.revenueChf:global(.__wab_instance) {
  position: relative;
}
.svg__lIjSl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5N5P9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__aNfLw {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___8AeNr {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__ldrFm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___9SspH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox___9Do08 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  background: var(--token-enYJIO6w3sEL0NO);
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox___9Do08 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox___9Do08 > :global(.__wab_flex-container) > *,
.freeBox___9Do08 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9Do08 > :global(.__wab_flex-container) > picture > img,
.freeBox___9Do08
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.revenueChfm2:global(.__wab_instance) {
  position: relative;
}
.revenueChfm2isParkplatz:global(.__wab_instance) {
  display: none;
}
.svg__bzetK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ok42F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___7E4M1 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__myJpF {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__pSsKz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vhOHm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__dgViL {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__dgViL > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__dgViL > :global(.__wab_flex-container) > *,
.freeBox__dgViL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dgViL > :global(.__wab_flex-container) > picture > img,
.freeBox__dgViL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.paymentPlan1:global(.__wab_instance) {
  position: relative;
}
.svg__czvEp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__lFFav {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__nznUt {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__l1DIk {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__nurcC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dslZr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__cbeKc {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__cbeKc > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__cbeKc > :global(.__wab_flex-container) > *,
.freeBox__cbeKc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cbeKc > :global(.__wab_flex-container) > picture > img,
.freeBox__cbeKc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.paymentPlan2:global(.__wab_instance) {
  position: relative;
}
.svg__l7Qtk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tCDx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kXGf {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___8NrHu {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text___9IDdF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__iGhdk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__eArE {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 140px;
  height: auto;
  flex-shrink: 0;
  padding: var(--token-H3JPdTVdV) var(--token-8IFSbVVlb);
}
.freeBox__eArE > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__eArE > :global(.__wab_flex-container) > *,
.freeBox__eArE > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eArE > :global(.__wab_flex-container) > picture > img,
.freeBox__eArE
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.paymentPlan3:global(.__wab_instance) {
  position: relative;
}
.svg__yawpg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bqut {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ai9Yl {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__esWc4 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.text__dYvH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6RXeH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
