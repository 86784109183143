.root:global(.__wab_instance) {
  position: relative;
}
.processCardSection__yoeIq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___3TlDs {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox___3TlDs {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox___3TlDs {
    grid-template-columns: 1fr;
  }
}
.formGroup___7JbHj:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.slider__xqjPc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .slider__xqjPc:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.symbolCounter__k4ET0:global(.__wab_instance) {
  position: relative;
}
.processCardSection__u8VvL:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__zIiUd {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  margin-bottom: 32px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__zIiUd {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox__zIiUd {
    grid-template-columns: 1fr;
  }
}
.formGroup__nBb6H:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.slider__e4W8Y:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .slider__e4W8Y:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.symbolCounter___0OhN0:global(.__wab_instance) {
  position: relative;
}
.optionalFieldsGebaeude {
  background: var(--token-NPNMQf7VB8u7Kel);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 8px;
  padding: var(--token-hFcnMUlxM);
  margin: 0px 0px var(--token-hFcnMUlxM);
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.optionalFieldsGebaeude > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7dIrE6133));
  height: calc(100% + var(--token-7dIrE6133));
}
.optionalFieldsGebaeude > :global(.__wab_flex-container) > *,
.optionalFieldsGebaeude
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.optionalFieldsGebaeude > :global(.__wab_flex-container) > picture > img,
.optionalFieldsGebaeude
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-7dIrE6133);
}
@media (max-width: 480px) {
  .optionalFieldsGebaeude {
    padding: var(--token-3S5TLMpb5);
  }
}
.zustandHerleitung:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.slider___6Ufto:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__wb3Nc:global(.__wab_instance) {
  position: relative;
}
.formGroup___1Gxrs:global(.__wab_instance) {
  position: relative;
}
.slider__qnjgh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__nvmLh:global(.__wab_instance) {
  position: relative;
}
.formGroup__di17H:global(.__wab_instance) {
  position: relative;
}
.slider__ic9Ya:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__fjSc:global(.__wab_instance) {
  position: relative;
}
.formGroup__t34Fe:global(.__wab_instance) {
  position: relative;
}
.slider__cVvdl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter___0QT0G:global(.__wab_instance) {
  position: relative;
}
.formGroup__kCuDo:global(.__wab_instance) {
  position: relative;
}
.slider__auQRz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.symbolCounter__r3Vbi:global(.__wab_instance) {
  position: relative;
}
.divider__mQ0WH:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.zustandGebauedeRenovationenen:global(.__wab_instance) {
  position: relative;
}
.formGroup__kv00L:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput87:global(.__wab_instance) {
  position: relative;
}
.svg__n8F48 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dqtfc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jxrTz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lJilS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__ySm16:global(.__wab_instance) {
  position: relative;
}
.divider__z1Fc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processCardSection__oqFeA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___3KVj9 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  margin-bottom: 32px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox___3KVj9 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox___3KVj9 {
    grid-template-columns: 1fr;
  }
}
.formGroup__mNk5:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
}
.slider___3Dkic:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .slider___3Dkic:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.symbolCounter___3FK2S:global(.__wab_instance) {
  position: relative;
}
.optionalFieldsWohnung {
  background: var(--token-NPNMQf7VB8u7Kel);
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 32px;
  min-width: 0;
  border-radius: 8px;
  padding: 32px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.optionalFieldsWohnung > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.optionalFieldsWohnung > :global(.__wab_flex-container) > *,
.optionalFieldsWohnung
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.optionalFieldsWohnung > :global(.__wab_flex-container) > picture > img,
.optionalFieldsWohnung
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
.freeBox__mdBk7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__rrrRa {
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetChildren {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox___4DGpd {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox___4DGpd {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .freeBox___4DGpd {
    grid-template-columns: 1fr;
  }
}
.divider___4RnN5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.zustandWohnungRenovationenen:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.formGroup__odfqA:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .formGroup__odfqA:global(.__wab_instance) {
    grid-column-end: span 3;
  }
}
.textInput88:global(.__wab_instance) {
  position: relative;
}
.svg___94MV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iCcfx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__g8F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vogsw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__lalBg:global(.__wab_instance) {
  position: relative;
}
.formGroup__jo6Nf:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput89:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__usbVf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4CIdi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hVuUv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___0Jkr6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__d5CZi:global(.__wab_instance) {
  position: relative;
}
.text {
  font-weight: 400;
}
.formGroup__s2JV6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__pM33X:global(.__wab_instance) {
  position: relative;
}
.svg__yCi3X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__o7Ag0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___4BXva {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fsVtD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__w3GCb:global(.__wab_instance) {
  position: relative;
}
.text__mX8Fc {
  padding-right: 0px;
}
.svg___5Rp4D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mjLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fpMuh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.navButton__le7Xb:global(.__wab_instance) {
  max-width: 100%;
  left: auto;
  top: auto;
  position: relative;
}
.svg__lQIpM {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
  color: var(--token-AZBC5tFK07a8DV3);
  height: 25px;
  display: none;
  margin: 0px 5px 2px 0px;
}
.text__kp1P9 {
  height: auto;
}
