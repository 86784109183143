.select:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.option__ucs8P:global(.__wab_instance) {
  position: relative;
}
.svg__bL03N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__b7HgL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__ly8XF:global(.__wab_instance) {
  position: relative;
}
.svg__cqEt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg___1FD2U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option___2HiF:global(.__wab_instance) {
  position: relative;
}
.svg__qriZi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__mV0Vb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__oqNVn:global(.__wab_instance) {
  position: relative;
}
.svg__vq6Ly {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__k04Up {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__sYx3S {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
