.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-WYpGQpgya-mDjv5);
  min-width: 0;
  min-height: 0;
}
.navigation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__iIsn9 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__iIsn9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__iIsn9 > :global(.__wab_flex-container) > *,
.freeBox__iIsn9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__iIsn9 > :global(.__wab_flex-container) > picture > img,
.freeBox__iIsn9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
@media (max-width: 480px) {
  .freeBox__iIsn9 {
    position: relative;
  }
}
.freeBox__poUuS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 10%;
  padding: 8px 0px 0px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__poUuS {
    display: flex;
  }
}
@media (max-width: 480px) {
  .freeBox__poUuS {
    display: flex;
    padding: 40px 16px;
  }
}
.labels:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.svg__iDeks {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qi0Yq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.stao:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.svg___1DXgH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vkXga {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__sTjKl {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
