.custom-date-picker {
    color: rgb(30, 42, 59);
    font-family: "Inter";
    height: 35.5938px;
    border-width: 1.2px;
    padding: 8px 10px;
    font-size: auto;
    position: relative;
    width: 100%;
  }
  
  .custom-date-picker .ant-picker-input > input {
    color: inherit;
    font-family: inherit;
    height: inherit;
    padding: inherit;
    font-size: 14px;
  }
  .custom-date-picker .ant-picker-input > input::placeholder {
    color: rgb(147, 163, 184);
}
  
  .custom-date-picker:hover {
    border-color: rgb(204, 213, 225);
  }
  
  .custom-date-picker:focus-within{
    border-color: rgb(93, 161, 243);
    box-shadow: 0px 0px 0px 4px rgba(189, 212, 249);
  }
  
  .custom-date-picker:not(:hover):not(:focus-within) {
    border-color: rgb(226, 232, 240);
  }