.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.searchInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.autocompleteResult__eqnC:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.autocompleteResult__juuTl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
