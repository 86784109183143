.root {
  display: flex;
  flex-direction: column;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0px;
}
.formGroup:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox {
  padding-right: 0px;
  display: flex;
  flex-direction: row;
}
.booleanSelectButtonGroup:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter:global(.__wab_instance) {
  position: relative;
}
