.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.top {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  max-height: 320px;
  border-radius: 6px;
}
.toprelativePlacement_bottom {
  height: 2px;
  flex-shrink: 0;
}
.middle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 8px 32px -8px #00000038, 0px 8px 20px -16px #00000024,
    0px 4px 16px 0px #00000033;
  overflow: auto;
  max-height: 320px;
  min-height: 0;
  border-radius: 6px;
}
.middlerelativePlacement_top {
  box-shadow: 0px 8px 32px -8px #00000038, 0px 8px 20px -16px #00000024;
}
.left {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.leftrelativePlacement_right {
  width: 2px;
  flex-shrink: 0;
}
.main {
  position: relative;
  width: 100%;
  overflow: auto;
  align-self: flex-start;
  min-width: 0;
}
.right {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.rightrelativePlacement_left {
  width: 2px;
  flex-shrink: 0;
}
.bottom {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  max-height: 320px;
  border-radius: 6px;
}
.bottomrelativePlacement_top {
  height: 2px;
  flex-shrink: 0;
}
