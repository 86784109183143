.root {
  flex-direction: column;
  display: flex;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: var(--token-8IFSbVVlb);
}
.text__izuMw {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  color: var(--token-5eiHu7b54PkQh5O);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
}
@media (max-width: 480px) {
  .text__izuMw {
    font-size: var(--token-wZK1o5uz59Brqbf);
  }
}
.freeBox {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
._switch:global(.__wab_instance) {
  max-width: 100%;
}
.text__aleoA {
  padding-bottom: 0px;
}
