.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      var(--token-enYJIO6w3sEL0NO),
      var(--token-enYJIO6w3sEL0NO)
    ),
    var(--token-NPNMQf7VB8u7Kel);
  position: relative;
  min-width: 0;
  border-radius: 4px;
  padding: 16px 24px 24px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.freeBox__ibUdt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__kdFO {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__whhmC {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
  color: var(--token-IgvLORwjhxLX5NA);
  margin-right: 4px;
}
.slotTargetIndex {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
  color: var(--token-IgvLORwjhxLX5NA);
}
.deleteButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .deleteButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__nDa6T {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svg___73PKr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.imageTitle:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__dIvU {
  height: auto;
}
.textInput5:global(.__wab_instance) {
  position: relative;
}
.svg__dImz0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pRvIk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__opxl3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__k9OjD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__dueEs:global(.__wab_instance) {
  position: relative;
}
.imageFile:global(.__wab_instance) {
  position: relative;
}
.text__gjbB2 {
  height: auto;
}
.fileUploadField:global(.__wab_instance) {
  position: relative;
}
.svg__o65Od {
  object-fit: cover;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  height: 1em;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.svg__mgQ7Q {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sHkWBDcvLab64_p);
  width: var(--token-hFcnMUlxM);
  height: var(--token-hFcnMUlxM);
  display: none;
}
.img__meNvC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__meNvC > picture > img {
  object-fit: cover;
}
.symbolCounter__lXm1M:global(.__wab_instance) {
  position: relative;
}
