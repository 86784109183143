.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-5K6-m7eB7));
  height: calc(100% + var(--token-5K6-m7eB7));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-5K6-m7eB7);
}
.rootisOpen > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - var(--token-nPzqyQpH_));
  height: calc(100% + var(--token-nPzqyQpH_));
}
.rootisOpen > :global(.__wab_flex-container) > *,
.rootisOpen > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootisOpen > :global(.__wab_flex-container) > picture > img,
.rootisOpen
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: var(--token-nPzqyQpH_);
}
.textInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.textInputshowStartIcon:global(.__wab_instance) {
  flex-shrink: 0;
}
.textInputisOpen:global(.__wab_instance) {
  flex-shrink: 0;
}
.textInputstate_error:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__vv4Rb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___5AuUq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__upX11 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg___8TRpE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.dropdown {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background: var(--token-NPNMQf7VB8u7Kel);
  left: 0px;
  top: 38px;
  align-self: auto;
  box-shadow: 0px 8px 32px -8px #00000033, 0px 8px 20px -16px #00000033;
  z-index: 1000;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  display: none;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 1s;
  border-radius: 6px;
  padding: 6px 0px;
}
.dropdownisOpen {
  display: flex;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.autocompleteResult__tNmOy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.autocompleteResult__cYft:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
