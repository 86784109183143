.root {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--token-nJQnmTwmF4SPdl6);
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.processHeader:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .processHeader:global(.__wab_instance) {
    position: relative;
    left: auto;
    top: auto;
  }
}
.scrollNav__sSwbn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__sSwbn:global(.__wab_instance) {
    left: auto;
    top: auto;
    z-index: 1;
    position: relative;
  }
}
@media (max-width: 480px) {
  .scrollNav__sSwbn:global(.__wab_instance) {
    left: auto;
    top: auto;
  }
}
.scrollNavGroup__ff9Ff:global(.__wab_instance) {
  position: relative;
}
.navButton__qwo0J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__nmgRb {
  height: auto;
}
.svg__hfS0T {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___79Rt9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__fUpSb {
  height: auto;
}
.svg__ns9J3 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__yVvu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__p3Oo6 {
  height: auto;
}
.svg__y31Cq {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__bYa4H:global(.__wab_instance) {
  position: relative;
}
.navButton__atwhr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__pwKpC {
  height: auto;
}
.svg__f2Dyv {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__uqYjk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__agmM {
  height: auto;
}
.svg__t6SE {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__stEbl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___37Cow {
  height: auto;
}
.svg__uy1Kj {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__jCKh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__rqO4H {
  height: auto;
}
.svg__kgNps {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__wiUdn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__avlh8 {
  height: auto;
}
.svg__ud0Kq {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__kRpBo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ud5Sb {
  height: auto;
}
.svg__fKq3O {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__fpVcj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__bxsR {
  height: auto;
}
.svg__aEgul {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__bjFss:global(.__wab_instance) {
  position: relative;
}
.navButton__p9AsB:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__tC0K {
  height: auto;
}
.svg___4Tgdc {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__jfUq3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__kImLm {
  height: auto;
}
.svg__bihM5 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__lq0Jw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ccr6N {
  height: auto;
}
.svg__iZb32 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__lyeya:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___2JIrD {
  height: auto;
}
.svg__b2O0M {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton___2QQfp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__jC5Xo {
  height: auto;
}
.svg__qa2Cn {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__kUd5C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__wjvy8 {
  height: auto;
}
.svg__dz0Tl {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__cGTv:global(.__wab_instance) {
  position: relative;
}
.navButton__mKtQr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ynyN0 {
  height: auto;
}
.svg__z5PBu {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.scrollNavGroup__sUs3I:global(.__wab_instance) {
  position: relative;
}
.navButton__yvUnZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ydm2T {
  height: auto;
}
.svg__hruL9 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.freeBox__qc1Df {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  background: var(--token-nJQnmTwmF4SPdl6);
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  margin: 0px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__qc1Df {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0px;
    margin: 0px;
  }
}
@media (max-width: 480px) {
  .freeBox__qc1Df {
    display: flex;
    flex-direction: column;
  }
}
.scrollNav__rlx2Y:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .scrollNav__rlx2Y:global(.__wab_instance) {
    left: 0px;
    top: 0px;
    z-index: 1;
    position: sticky;
  }
}
.scrollNavGroup__n3WFl:global(.__wab_instance) {
  position: relative;
}
.navButton__nfnEu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___3Bb2T {
  height: auto;
}
.svg__jXqJv {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__zhSok:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ljbtu {
  height: auto;
}
.svg__k6Cgc {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__iIG8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__dl7Eh {
  height: auto;
}
.svg__lPyVl {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__fzcRu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ao7TS {
  height: auto;
}
.svg__lYkLi {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.navButton__fzHy0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__yp9DV {
  height: auto;
}
.svg__hcVtF {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.processPageContent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processPageActions:global(.__wab_instance) {
  position: relative;
}
.processSection:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.processCard__xN2Fk:global(.__wab_instance) {
  position: relative;
}
.processCardSection__wvC1C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__l23Zo {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__l23Zo {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
.formGroup__pSyt:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__gVvu {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._15:global(.__wab_instance) {
  position: relative;
}
.svg__doot4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xdKfS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hLWs2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wDx3E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__pMmy0:global(.__wab_instance) {
  position: relative;
}
.formGroup__vYvIe:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__q92AU {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___8Giaj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__uF8I8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___6S2Nc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg___2HcQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.symbolCounter__fc0Fc:global(.__wab_instance) {
  position: relative;
}
.formGroup__baqAb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput20:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg___89Pkv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__war7T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__byPCe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___0IKd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__wxm35:global(.__wab_instance) {
  position: relative;
}
.text__gw1Wc {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.percentageInput7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput10:global(.__wab_instance) {
  position: relative;
}
.svg__rxkmb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__tIw5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__snCa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__uvLi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__fyZ5:global(.__wab_instance) {
  position: relative;
}
.text__w51PS {
  padding-right: 0px;
}
.svg__wd7Rn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__opbru {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___07VB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__aky8Z:global(.__wab_instance) {
  position: relative;
}
.processCardSection___5YpV4:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__xu0Ba {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__t5VQz:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 2;
}
.booleanSelectButtonGroup__ifEEr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__vr1Lx:global(.__wab_instance) {
  position: relative;
}
.formGroup__lci4X:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 2;
}
.booleanSelectButtonGroup__qyd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__tGcP7:global(.__wab_instance) {
  position: relative;
}
.formGroup__d7I3:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 2;
}
.booleanSelectButtonGroup__soOt2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__vK8Bz:global(.__wab_instance) {
  position: relative;
}
.formGroup__ty4W:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__yCYwS {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.gebbem:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__qm3Ac {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___7GSa1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___9KVsa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__lfG9U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__m3HwK:global(.__wab_instance) {
  position: relative;
}
.formGroup__icLs7:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__tEsGm {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.servitWertkorr:global(.__wab_instance) {
  position: relative;
}
.svg__vw9Me {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__dqAeV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xBaj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jOgcd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eu1Bw {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__vpCxn:global(.__wab_instance) {
  position: relative;
}
.formGroup__g3Rpj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput7:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__jACde {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___8Jwnm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ccze4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__i6F5X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__oqT5:global(.__wab_instance) {
  position: relative;
}
.text__jTbFw {
  font-weight: 400;
}
.formGroup__iIjtz:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__xfTzw:global(.__wab_instance) {
  position: relative;
}
.svg__v2Dxn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yt3Tp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__huSiK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wL0F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___5J8R:global(.__wab_instance) {
  position: relative;
}
.text__rvez {
  padding-right: 0px;
}
.svg__mVhu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oHkhd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uyg6K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__zcBjQ:global(.__wab_instance) {
  position: relative;
}
.processCardSection___0Zjej:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__lriK {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__vVlhr:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
.singleSelectButtonGroup__eArj4:global(.__wab_instance) {
  max-width: 100%;
}
.symbolCounter___2RjGp:global(.__wab_instance) {
  position: relative;
}
.formGroup__sUgTo:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__mmfaL {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.gebbem2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__dqgt5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9Hh1G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ds3V9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sgNk8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___86SPk:global(.__wab_instance) {
  position: relative;
}
.formGroup__es4H:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__taBee {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.servitAltlasten:global(.__wab_instance) {
  position: relative;
}
.svg___1VlW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__fd7Vj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__p3Dlr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xq0Y4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___5MhWz {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__rFrf:global(.__wab_instance) {
  position: relative;
}
.formGroup__jPcev:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput9:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__g53L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9UvIc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__roKsH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___0T4F4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__brnFz:global(.__wab_instance) {
  position: relative;
}
.text__ukWpV {
  font-weight: 400;
}
.formGroup__we4Zu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__yfzbK:global(.__wab_instance) {
  position: relative;
}
.svg__yohOn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__w2HzA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___0RgWe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__ziJtO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__cmYwU:global(.__wab_instance) {
  position: relative;
}
.text__vabf0 {
  padding-right: 0px;
}
.svg__okhDd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__l9Mq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___0RUya {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__czGZe:global(.__wab_instance) {
  position: relative;
}
.processCardSection__kg70:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox___13JpP {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup___1ZjV3:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__diCug {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.land:global(.__wab_instance) {
  position: relative;
}
.svg___7GunJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__uEYj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___3QDqm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__nRo1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__m3PG2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__jlUkp {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__bpKfg:global(.__wab_instance) {
  position: relative;
}
.formGroup__mSUb:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.text__cvu0T {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.bauzc:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.svg__avOl0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.option__ck4Xh:global(.__wab_instance) {
  position: relative;
}
.svg__wTmkm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__brhc4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.option__pLfEs:global(.__wab_instance) {
  position: relative;
}
.svg__glJgo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.svg__kmH3L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__ot4Ze {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__sCgq:global(.__wab_instance) {
  position: relative;
}
.formGroup___4WgIi:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text___2Rsnf {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.bauzm:global(.__wab_instance) {
  position: relative;
}
.svg__uWuGa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__haK60 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__tmymX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___31MsP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zHfxp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wh1Yz {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__kpnPf:global(.__wab_instance) {
  position: relative;
}
.formGroup__kTa0:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__i7Kpr {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.bz:global(.__wab_instance) {
  position: relative;
}
.svg__fzJwO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__cpsBp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__mVvic {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__qaiMb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__v2Qis {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dCnL {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter___30Eua:global(.__wab_instance) {
  position: relative;
}
.formGroup__c6J8V:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__sv9Ng {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.az:global(.__wab_instance) {
  position: relative;
}
.svg___7EgOk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__qaX8Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jJEtm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__mTVuo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sfHe5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__mmygy {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__toZcs:global(.__wab_instance) {
  position: relative;
}
.formGroup___1UnBy:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__tQkb {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.uez:global(.__wab_instance) {
  position: relative;
}
.svg__zfjU7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vvcWc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__b6DzK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__bqJ71 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__f2Hx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vP7Rw {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__w8VJk:global(.__wab_instance) {
  position: relative;
}
.formGroup__joF9X:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__zkIqm {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.gfz:global(.__wab_instance) {
  position: relative;
}
.svg__whsi7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__j0QK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__cD2Yg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__b9BUw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zcV96 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__z3Oru {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__pY5G:global(.__wab_instance) {
  position: relative;
}
.formGroup__kLc8F:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.booleanSelectButtonGroup__jwxDn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.symbolCounter__c0Pfh:global(.__wab_instance) {
  position: relative;
}
.formGroup__y4Jxq:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.singleSelectButtonGroup__psnYu:global(.__wab_instance) {
  max-width: 100%;
}
.symbolCounter__b4Gu:global(.__wab_instance) {
  position: relative;
}
.formGroup___6Am1D:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.singleSelectButtonGroup___6YMt0:global(.__wab_instance) {
  max-width: 100%;
}
.symbolCounter__dsWpR:global(.__wab_instance) {
  position: relative;
}
.formGroup__g0MI1:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__rNg6 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._4:global(.__wab_instance) {
  position: relative;
}
.svg__q8Xy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__k8Lav {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xuK9G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__xjkC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__zf8O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__qfioQ:global(.__wab_instance) {
  position: relative;
}
.formGroup__gIv8:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__exBh8 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._3:global(.__wab_instance) {
  position: relative;
}
.svg__vUhR6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__pQKvv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__jkVry {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__aIZy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__aaKjt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__btZpc:global(.__wab_instance) {
  position: relative;
}
.formGroup__hhmc1:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__bkFt3 {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._6:global(.__wab_instance) {
  position: relative;
}
.svg__etj7C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___5HKgr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fWmv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__lfScJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kumHj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__hYa6K:global(.__wab_instance) {
  position: relative;
}
.formGroup__dBg7F:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__cC0SS {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._5:global(.__wab_instance) {
  position: relative;
}
.svg__zD6Kw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__vo9Az {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__igoH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___0Qu7I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__l66Wv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__fl1It:global(.__wab_instance) {
  position: relative;
}
.formGroup__n1SGj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput12:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__g9GhG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__gDuqX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fMpTy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__xnu19 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__uutQk:global(.__wab_instance) {
  position: relative;
}
.text__lZm7L {
  font-weight: 400;
}
.formGroup__mFbIh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__qcnw1:global(.__wab_instance) {
  position: relative;
}
.svg__u8OjR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__v7O0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kdIs3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__brF4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter___9Mtdj:global(.__wab_instance) {
  position: relative;
}
.text__pIfrP {
  padding-right: 0px;
}
.svg__tnqSa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xXsou {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__u0DfO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processCard__chOp:global(.__wab_instance) {
  position: relative;
}
.processCardSection__q2Esn:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__cz7BG {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-hFcnMUlxM);
  grid-column-gap: var(--token-hFcnMUlxM);
  width: 100%;
  min-width: 0;
}
.formGroup__bfhqp:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__x112C {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._2:global(.__wab_instance) {
  position: relative;
}
.svg___94R1S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zkexe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__x4RmQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___8TesI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___1U5Nx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__tXt0Z {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__rY1:global(.__wab_instance) {
  position: relative;
}
.formGroup__rjQf2:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__mqiDm {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._11:global(.__wab_instance) {
  position: relative;
}
.svg__eGQwM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9V2Cp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__f2YFy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___7Tp3B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dao1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text___90Q1R {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter___9QsPn:global(.__wab_instance) {
  position: relative;
}
.formGroup__oi9Xe:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__bYj2A {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._12:global(.__wab_instance) {
  position: relative;
}
.svg___5Zrql {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3IQOf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__lhn6I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__yBlxx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__cWyG1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__fl1AQ {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__cv3Bj:global(.__wab_instance) {
  position: relative;
}
.formGroup___9XMwu:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__dndGy {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._7:global(.__wab_instance) {
  position: relative;
}
.svg__ffKnF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___9DkGp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pPo2I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text__uruAy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__dHsha {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__cpmxr:global(.__wab_instance) {
  position: relative;
}
.formGroup__oJHbi:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__zzOFo {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._13:global(.__wab_instance) {
  position: relative;
}
.svg__pDvsa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ad85V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__oe9Wx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___4Hgun {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__kZtuZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__iAEh {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__fd6H:global(.__wab_instance) {
  position: relative;
}
.formGroup__bo2LU:global(.__wab_instance) {
  position: relative;
  grid-column-end: span 3;
}
.text__aJqvJ {
  height: auto;
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  font-style: normal;
  color: var(--token-IOs4wk1cx7qv8eT);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
._14:global(.__wab_instance) {
  position: relative;
}
.svg__a245W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__roQis {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__f58U2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-5eiHu7b54PkQh5O);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
  min-width: 0;
}
.text___7Wob {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__eFrj0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__qKsdx {
  font-family: "Inter", sans-serif;
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 400;
  font-style: normal;
  color: var(--token-IgvLORwjhxLX5NA);
  text-align: left;
  text-transform: none;
  line-height: var(--token-f2GFyMCUtiJjuKU);
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none !important;
  text-overflow: clip !important;
}
.symbolCounter__cq107:global(.__wab_instance) {
  position: relative;
}
.formGroup__g1K7:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput13:global(.__wab_instance) {
  position: relative;
  margin-right: 0px;
}
.svg__ucoMa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__n955Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__baz8H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__wwWOi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__ucYhE:global(.__wab_instance) {
  position: relative;
}
.text__moa5Y {
  font-weight: 400;
}
.formGroup__zM8TK:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.textInput__ydTe:global(.__wab_instance) {
  position: relative;
}
.svg__vTt8U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___1QvNf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__bBlQh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__sFujo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.symbolCounter__gUbsd:global(.__wab_instance) {
  position: relative;
}
.text___8Bvrg {
  padding-right: 0px;
}
.svg___6HdEl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__oXgmn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__bDjZh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.processBottomNav:global(.__wab_instance) {
  max-width: 100%;
  position: fixed;
  left: 0;
  top: auto;
  z-index: 3;
  right: 0;
  bottom: 0;
}
.nextParzelleDetailButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___6HTgv {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__tB2Mf {
  height: auto;
}
.svg__xL52G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ertragLeerstandButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rKfaF {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text___4Rkox {
  height: auto;
}
.svg__nI8Cr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.submitButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0DFz {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.text__yoTxI {
  height: auto;
}
.svg___2VUvb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
