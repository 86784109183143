.root {
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 1px 3px -1px #00000014;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 12;
  overflow: hidden;
  position: relative;
  min-width: 0;
  border-radius: 6px;
  padding: 0px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.rootcardState_success {
  border-color: var(--token-HYIomnc-tSnfgIb);
}
.rootcardState_warning {
  border-color: var(--token-lNcAUqsk8);
}
.rootcardState_error {
  border-color: var(--token-gUr8y4ZPkdFjJ7H);
}
.freeBox__mKaqy {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  background: var(--token-nJQnmTwmF4SPdl6);
  min-height: 80px;
  min-width: 0;
  padding: 24px 32px;
}
.freeBox__mKaqy > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__mKaqy > :global(.__wab_flex-container) > *,
.freeBox__mKaqy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mKaqy > :global(.__wab_flex-container) > picture > img,
.freeBox__mKaqy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBoxcardState_success__mKaqy6K7Nk {
  background: var(--token-bYBIsaiSK_SL0wu);
}
.freeBoxcardState_warning__mKaqy1Srey {
  background: var(--token-rsLFZ8cZG);
}
.freeBoxcardState_error__mKaqy0SvLc {
  background: var(--token-zzo8jryirB3foEj);
}
.freeBox___1AGCf {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox___1AGCf > :global(.__wab_flex-container) {
  min-width: 0;
}
.freeBoxhasNavigation___1AGCfnKcjd {
  display: flex;
}
.freeBoxhasNavigation___1AGCfnKcjd > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxhasNavigation___1AGCfnKcjd > :global(.__wab_flex-container) > *,
.freeBoxhasNavigation___1AGCfnKcjd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxhasNavigation___1AGCfnKcjd
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxhasNavigation___1AGCfnKcjd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.button__e4Us9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sd9Lz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__kJtgs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__qjZpE:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gYWs5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__iZbZg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button__kCfTj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___4Kigk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rYla {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__exjNl {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.slotTargetTitle {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.slotTargetTitlecardState_success {
  color: var(--token--1wl_F9deuezOld);
}
.slotTargetTitlecardState_warning {
  color: var(--token-ZpmSrRJAo);
}
.slotTargetTitlecardState_error {
  color: var(--token-jW34YHQobNMSD0Y);
}
.toggleButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.toggleButtonisExpandable:global(.__wab_instance) {
  display: flex;
}
.toggleButtonisOpen_isExpandable:global(.__wab_instance) {
  display: flex;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.textisOpen {
  padding-bottom: 0px;
}
.textisOpen_isExpandable {
  padding-left: 0px;
}
.svg__hqbd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__g1JKt {
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  min-width: 0;
  display: none;
}
.freeBoxisOpen__g1JKtVvcmm {
  display: flex;
}
.freeBoxisScrollable__g1JKtkCk8Y {
  overflow-x: auto;
  overflow-y: hidden;
}
.freeBox___8AxqA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBoxisScrollable___8AxqAkCk8Y {
  min-width: 600px;
}
.freeBox__mPdRi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 24px 32px;
  border-top: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.text__xPt9N {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.freeBox__j5Lj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  min-width: 0;
  border-bottom: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.marktwertTableRow__wrOx4:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox___2Izvu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator__eUig1:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__eYQk6:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__cBAw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__aFjip {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator__wsntO:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__hNhxs:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__tbzkJ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__ff8H4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator___0O96:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__nacfr:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.freeBox__pocoy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  min-width: 0;
  padding: 24px 32px;
}
.text___3KdAk {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 600;
}
.freeBox__bb0It {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  min-width: 0;
}
.marktwertTableRow___7Xwv6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__cgZu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator__eFdlK:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__l5OSe:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__e1Pvv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__peKlM {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator__a3Mg3:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__bY4Ic:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__erEp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__bsNJb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator__nOgtQ:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__gRwCe:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__guSpy:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__zxz4M {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator___7Zq34:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__kknBh:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__ziWbn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox__rgGcF {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator__ioN5:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell__kAlzI:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
.marktwertTableRow__trHnb:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-end;
}
.freeBox___4Nfgb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 12px;
}
.statusIndicator__pofSf:global(.__wab_instance) {
  position: relative;
}
.formTextTableCell___6Do8U:global(.__wab_instance) {
  position: relative;
  width: 100%;
  grid-column-end: span 9;
  min-width: 0;
}
