.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 12px 8px 8px;
}
.buttonContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.svg__hHdJf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.slotTargetType {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
}
.text__bq7 {
  color: var(--token-IgvLORwjhxLX5NA);
  font-size: var(--token-guD2PP-6Ca2XMJ7);
  align-self: auto;
}
.svg__ab5Xv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
