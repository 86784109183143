.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 6px;
}
.freeBox__lzTq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  padding-left: 2px;
  align-self: stretch;
  min-width: 0;
}
.freeBox__lzTq > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__lzTq > :global(.__wab_flex-container) > *,
.freeBox__lzTq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lzTq > :global(.__wab_flex-container) > picture > img,
.freeBox__lzTq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.freeBoxhasNoLabel__lzTqrwEDz {
  display: none;
}
.slotTargetLabel {
  font-size: var(--token-wZK1o5uz59Brqbf);
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.slotTargetLabelisRequired {
  color: var(--token-Hken4BJDt);
}
.freeBox__jn2Bs {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.freeBox__jn2Bs > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__jn2Bs > :global(.__wab_flex-container) > *,
.freeBox__jn2Bs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jn2Bs > :global(.__wab_flex-container) > picture > img,
.freeBox__jn2Bs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBox__plBJu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBoxisStacked__plBJuKqDxm {
  width: 100%;
  min-width: 0;
}
.textInput__hVdU7:global(.__wab_instance) {
  position: relative;
}
.svg__ipe5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xXjYw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fMdPd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.text__vhV00 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__mOYvr {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
  display: none;
}
.freeBoxhasStateText__mOYvrYNd3 {
  display: flex;
}
.slotTargetStateText {
  color: var(--token-IgvLORwjhxLX5NA);
}
.text__pOPn2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-djHL9A1ISxE7ONr);
  font-size: var(--token-wZK1o5uz59Brqbf);
  line-height: var(--token-f2GFyMCUtiJjuKU);
  min-width: 0;
}
