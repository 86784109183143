.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.freeBox__yzcYc {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #0f182acc;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.freeBox___052I {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 10vh;
  left: auto;
  max-width: 800px;
  width: 100%;
  min-width: 0;
  padding: 16px;
}
.freeBox___052I > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.freeBox___052I > :global(.__wab_flex-container) > *,
.freeBox___052I > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___052I > :global(.__wab_flex-container) > picture > img,
.freeBox___052I
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
.freeBox__ygwEt {
  background: var(--token-nJQnmTwmF4SPdl6);
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
  border-radius: 12px;
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.closeButton:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: 16px;
  right: 16px;
  z-index: 1;
}
.svg__njc2E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__waH6M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.freeBox__fl222 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 32px 40px 40px;
}
.freeBox__fl222 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.freeBox__fl222 > :global(.__wab_flex-container) > *,
.freeBox__fl222 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fl222 > :global(.__wab_flex-container) > picture > img,
.freeBox__fl222
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
@media (max-width: 480px) {
  .freeBox__fl222 {
    padding: 24px;
  }
}
.freeBox__z5HDd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.freeBox__z5HDd > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__z5HDd > :global(.__wab_flex-container) > *,
.freeBox__z5HDd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__z5HDd > :global(.__wab_flex-container) > picture > img,
.freeBox__z5HDd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox__e9U7G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__uVLic {
  width: 100%;
  height: auto;
  max-width: 90%;
  font-size: var(--token-ueN_0TwhBMb9CkO);
  font-weight: 500;
  min-width: 0;
}
.text___1LtIh {
  position: relative;
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__sbHbq {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__sbHbq > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__sbHbq > :global(.__wab_flex-container) > *,
.freeBox__sbHbq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sbHbq > :global(.__wab_flex-container) > picture > img,
.freeBox__sbHbq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__sbHbq {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__sbHbq > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .freeBox__sbHbq > :global(.__wab_flex-container) > *,
  .freeBox__sbHbq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__sbHbq > :global(.__wab_flex-container) > picture > img,
  .freeBox__sbHbq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.freeBox__tkEf7 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__tkEf7 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__tkEf7 > :global(.__wab_flex-container) > *,
.freeBox__tkEf7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tkEf7 > :global(.__wab_flex-container) > picture > img,
.freeBox__tkEf7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__n28Zu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  max-width: 100%;
  background: var(--token-EdVWKDB6CssMfRE);
  flex-shrink: 0;
  border-radius: 200px;
  padding: 8px;
}
.svg__arIQb {
  object-fit: cover;
  max-width: 100%;
  width: 20px;
  height: 20px;
  color: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
}
.freeBox___4Hr9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.link__v2HOu {
  font-size: var(--token-FutXsu2VTAR30Ez);
  padding-bottom: 0px;
  font-weight: 500;
  padding-right: 0px;
}
.text__dzLgi {
  font-size: var(--token-wZK1o5uz59Brqbf);
  padding-bottom: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__onYg {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__onYg > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__onYg > :global(.__wab_flex-container) > *,
.freeBox__onYg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__onYg > :global(.__wab_flex-container) > picture > img,
.freeBox__onYg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__gtCq5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  max-width: 100%;
  background: var(--token-EdVWKDB6CssMfRE);
  flex-shrink: 0;
  border-radius: 200px;
  padding: 8px;
}
.svg__jhuuU {
  object-fit: cover;
  max-width: 100%;
  width: 20px;
  height: 20px;
  color: var(--token-5eiHu7b54PkQh5O);
  flex-shrink: 0;
}
.freeBox__ay2T5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.link___2Lisw {
  font-size: var(--token-FutXsu2VTAR30Ez);
  padding-bottom: 0px;
  font-weight: 500;
}
.text__zTUn1 {
  font-size: var(--token-wZK1o5uz59Brqbf);
  padding-bottom: 0px;
  color: var(--token-IgvLORwjhxLX5NA);
}
