.root {
  background: var(--token-NPNMQf7VB8u7Kel);
  box-shadow: 0px 1px 3px -1px #00000014;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  border-radius: 12px;
  padding: var(--token-cTBZAIcMp);
  border: 1px solid var(--token-EdVWKDB6CssMfRE);
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-cTBZAIcMp));
  height: calc(100% + var(--token-cTBZAIcMp));
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: var(--token-cTBZAIcMp);
}
.rootisLocked {
  background: var(--token-nJQnmTwmF4SPdl6);
  box-shadow: none;
}
.freeBox__dd6Nt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.freeBox__dd6Nt > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__dd6Nt > :global(.__wab_flex-container) > *,
.freeBox__dd6Nt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dd6Nt > :global(.__wab_flex-container) > picture > img,
.freeBox__dd6Nt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-8IFSbVVlb);
}
.freeBox___6Qeu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.slotTargetTitle {
  font-size: var(--token-FutXsu2VTAR30Ez);
  font-weight: 500;
}
.slotTargetTitleisLocked {
  color: var(--token-IgvLORwjhxLX5NA);
}
.freeBox__fydwn {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__fydwn > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__fydwn > :global(.__wab_flex-container) > *,
.freeBox__fydwn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fydwn > :global(.__wab_flex-container) > picture > img,
.freeBox__fydwn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
}
.freeBoxhasTag_makrolage__fydwnPrhfM {
  display: flex;
}
.freeBoxhasTag_mikrolage__fydwNpaksm {
  display: flex;
}
.freeBoxhasTag_marktlage__fydwnCnu0 {
  display: flex;
}
.freeBoxhasTag_objekt__fydwn11APm {
  display: flex;
}
.tag___3SX6W:global(.__wab_instance) {
  max-width: 100%;
}
.taghasTag_makrolage___3SX6WPrhfM:global(.__wab_instance) {
  display: flex;
}
.tag__lKh5:global(.__wab_instance) {
  max-width: 100%;
}
.taghasTag_mikrolage__lKh5Paksm:global(.__wab_instance) {
  display: flex;
}
.tag__qFmUg:global(.__wab_instance) {
  max-width: 100%;
}
.taghasTag_marktlage__qFmUgCnu0:global(.__wab_instance) {
  display: flex;
}
.tag___8W6N:global(.__wab_instance) {
  max-width: 100%;
}
.taghasTag_objekt___8W6N11APm:global(.__wab_instance) {
  display: flex;
}
.slotTargetText {
  font-size: var(--token-wZK1o5uz59Brqbf);
  color: var(--token-IgvLORwjhxLX5NA);
  line-height: var(--token-f2GFyMCUtiJjuKU);
}
.freeBox__fbZqd {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__fbZqd > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: calc(0px - var(--token-8IFSbVVlb));
  width: calc(100% + var(--token-8IFSbVVlb));
  margin-top: calc(0px - var(--token-8IFSbVVlb));
  height: calc(100% + var(--token-8IFSbVVlb));
}
.freeBox__fbZqd > :global(.__wab_flex-container) > *,
.freeBox__fbZqd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fbZqd > :global(.__wab_flex-container) > picture > img,
.freeBox__fbZqd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-8IFSbVVlb);
  margin-top: var(--token-8IFSbVVlb);
}
.pdfButtontype_pdf:global(.__wab_instance) {
  display: flex;
}
.svg__uVcJl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__k29M5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.startButtontype_start:global(.__wab_instance) {
  display: flex;
}
.svg___3E4F2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ufJkV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.excelButtontype_excel:global(.__wab_instance) {
  display: flex;
}
.svg__fNpEe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__veN2C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.wordButtontype_word:global(.__wab_instance) {
  display: flex;
}
.svg__tCgMk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__sPIyD {
  height: auto;
}
.svg__dNu8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.economyButtontype_konjunktur:global(.__wab_instance) {
  display: flex;
}
.svg__nAo46 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__xPso8 {
  height: auto;
}
.svg__htlia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.immoButtontype_immobilien:global(.__wab_instance) {
  display: flex;
}
.svg__nqw55 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__vcoO2 {
  height: auto;
}
.svg__giRh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.dropdownButton:global(.__wab_instance) {
  align-self: auto;
  flex-shrink: 0;
}
.dropdownButtonisLocked:global(.__wab_instance) {
  flex-shrink: 0;
}
.dropdownButtonhasDropdown:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.dropdownButtonisDisabled:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__wq2Yf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ufAHo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
